<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Service Desk</h1>
          </div>
          <div class="main_section__content">
            <div class="service-desk_header">
              <span class="service-desk_title"> Tickets Opened By You </span>
              <AskForHelp @completed="getTicketsFromAPI" />
            </div>
            <div v-if="!notFound">
              <div class="eventsTable">
                <SimpleTable
                  :cols="ticket_cols"
                  :rows="ticket_rows"
                  :tableType="tableType"
                  :tiedToRow="false"
                  @selectRow="changeRowInfo"
                />
              </div>
              <TicketDetail
                :openDialog="open_dialog_info"
                :dialogInfo="dialog_info"
                @input="open_dialog_info = false"
              />
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-tickets"></div>
                <div class="not-found__space-text"></div>
                <p class="not-found__text">You have no opened tickets</p>
              </div>
            </div>

            <Paginate
              v-if="!notFound"
              :selectedPage="selectedPageNumber"
              :pagecount="eventsPagination"
              @changePageNumber="changePageNumber"
            />
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SimpleTable from "@/common/SimpleTable";
import Paginate from "@/components/learning-events/Paginate";
import AskForHelp from "@/components/service-desk/AskForHelp";
import TicketDetail from "@/components/service-desk/TicketDetail";
import { getServiceTickets } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const userInfoHelpers = createNamespacedHelpers("userInfo");
import moment from "moment";

export default {
  name: "LearningEvents",
  components: {
    Paginate,
    SimpleTable,
    AskForHelp,
    TicketDetail,
  },
  data() {
    return {
      filters: {
        status: [],
      },
      tableType: "select_row",
      show: true,
      notFilter: false,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      ticket_cols: [
        "reference",
        "summary",
        "severity level",
        "status",
        "sent on type date",
        "assignee",
      ],
      fullRows: [],
      ticket_rows: [],
      open_dialog_info: false,
      dialog_info: [],
    };
  },
  computed: {
    ...userInfoHelpers.mapGetters(["userInfo"]),
    notFound() {
      return this.ticket_rows.length == 0;
    },
  },
  created() {
    this.getTicketsFromAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getTicketsFromAPI() {
      this.getLoading(true);
      await this.getTickets();
      this.getLoading(false);
    },
    async getTickets() {
      try {
        let result = await getServiceTickets();
        this.fullRows = [];
        if (result.status == 200) {
          let gettingTickets = result.data.data.response;
          if (Object.keys(gettingTickets).length !== 0) {
            gettingTickets.sort(function (a, b) {
              if (a.create_on < b.create_on) return 1;
              if (a.create_on > b.create_on) return -1;
              return 0;
            });
            gettingTickets.forEach((ticket, index) => {
              if (this.userInfo.name.includes(ticket.reporter)) {
                let sent_on = ticket.create_on
                  ? moment.utc(ticket.create_on).local().format("D/MMM/yyyy")
                  : "-";
                let row = {
                  reference: `#${ticket.code}` || "-",
                  summary: ticket.subject || "-",
                  "severity level": ticket.severity[1] || "-",
                  status: "-",
                  "sent on type date": sent_on || "=",
                  assignee: ticket.assignee || "=",
                  description: ticket.description || "-",
                };
                this.fullRows.push(row);
              }
            });
            // this.fullRows.sort(function (a, b) {
            //   if (a.create_on > b.create_on) return 1;
            //   if (a.create_on < b.create_on) return -1;
            //   return 0;
            // });

            // this.fullRows.sort(function (a, b) {
            //   return b.create_on - a.create_on;
            // });

            this.firstViewRoles();
          }
        }
      } catch (error) {
        console.log("GetRoleError==>", error);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    firstViewRoles() {
      if (this.fullRows.length == 0) {
        this.notFilter = true;
      } else {
        this.notFilter = false;
        this.selectedPageNumber = 1;
        this.eventsPagination = Math.ceil(this.fullRows.length / 10);
        this.getViewRolesByPagination(this.selectedPageNumber);
      }
    },
    getViewRolesByPagination(_pageNum) {
      let startIndex = 10 * (_pageNum - 1);
      let endIndex = 10 + 10 * (_pageNum - 1);
      this.ticket_rows = [];

      if (this.fullRows.length > endIndex) {
        for (startIndex; startIndex < endIndex; startIndex++) {
          const element = this.fullRows[startIndex];
          this.ticket_rows.push(element);
        }
      } else {
        for (startIndex; startIndex < this.fullRows.length; startIndex++) {
          const element = this.fullRows[startIndex];
          this.ticket_rows.push(element);
        }
      }
    },
    changePageNumber(_value) {
      this.selectedPageNumber = _value;
      this.getViewRolesByPagination(this.fullRows, this.selectedPageNumber);
    },
    changeRowInfo(row) {
      this.open_dialog_info = true;
      this.dialog_info = row;
      console.log(this.open_dialog_info);
      console.log(this.dialog_info);
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
.service-desk_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 40px 25px 40px;
  top: 40px;
  position: relative;
  .service-desk_title {
    font-weight: 700;
    font-size: 24px;
    color: $black;
  }
  .service-desk_button {
    background-color: $blue;
    padding: 15px 40px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    color: $white;
    cursor: pointer;
  }
}
@media screen and (max-width: 900px) {
  .service-desk_header {
    padding-bottom: 40px;
  }
}
</style>
