import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getExperts = (responsibilityId) => {
    let result = axios()({
        url: API.GETEXPERTS,
        method: "patch",
        params: {
            action: "get_experts",
            responsibility_id: responsibilityId,
        },
    });
    return result;
};

export const getTrackers = () => {
    let result = axios()({
        url: API.GETTRACKERS,
        method: "patch",
        params: {
            action: "get_trackers",
            employee_id: localStorage.getItem("employee_id"),
        },
    });
    return result;
};

export const requestCoach = (responsibilityId, coachId, coachingStyle) => {
    let result = axios()({
        url: API.GETTRACKERS,
        method: "patch",
        params: {
            action: 'request_coach',
            responsibility_id: responsibilityId, //1508
            coach_id: coachId, //4968,
            coaching_style: coachingStyle,//'s2 - selling',
        },
    });
    return result;
};

export const requestAccept = (requestId) => {
    let result = axios()({
        url: API.GETTRACKERS + '/' + requestId,
        method: "patch",
        params: {
            action: 'call_action_accept',
        },
    });
    return result;
};

export const requestDecline = (requestId, declineReason) => {
    let result = axios()({
        url: API.GETTRACKERS + '/' + requestId,
        method: "patch",
        params: {
            action: 'call_action_decline',
            reason: declineReason
        },
    });
    return result;
};

export const requestRemove = (requestId) => {
    let result = axios()({
        url: API.GETTRACKERS + '/' + requestId,
        method: "patch",
        params: {
            action: 'call_action_remove',
        },
    });
    return result;
};

export const stopCoaching = (requestId) => {
    let result = axios()({
        url: API.GETTRACKERS + '/' + requestId,
        method: "patch",
        params: {
            action: 'call_action_stop',
        },
    });
    return result;
};

export const changeCoachingStyle = (requestId, coachingStyle) => {
    let result = axios()({
        url: API.GETTRACKERS + '/' + requestId,
        method: "patch",
        params: {
            action: 'call_action_update_coaching_style',
            coaching_style: coachingStyle,//'s2 - selling',
        },
    });
    return result;
};

export const removeCoachRequest = (employeeId, coachId, responsibilityId) =>{
    let result = axios()({
        url: API.REMOVECOACHREQUEST,
        method:"patch",
        params:{
            action:"coach_actions",
            method:"remove",
            employee_id:employeeId,
            coach_id:coachId,
            responsibility_id:responsibilityId
        }
    })
    return result;
}

