<template>
  <div class="table1-container">
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows"
      :columns="cols"
      key-expr="Certification Program"
      :showColumnHeaders="true"
      :show-borders="true"
      :showRowLines="true"
      :showColumnLines="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      @selection-changed="selectionChanged"
      :selected-row-keys="selectedItemKeys"
    >
      <DxColumn data-field="Certification Program" />
      <DxColumn data-field="Responsibilities" cell-template="tagsTemplate" />
      <DxColumn data-field="Status" cell-template="statusTemplate" />
      <DxColumn data-field="Expires On" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <DxSelection mode="single" />
      <DxMasterDetail :enabled="true" template="detailTemplate" />
      <template #tagsTemplate="{ data: rowData }">
        <div>
          <v-chip
            v-for="(chip, index) in rowData.data['Responsibilities']"
            :key="index"
            class="tags-sm"
            color="#53A0DE33"
            text-color="#53A0DE"
          >
            {{ chip }}
          </v-chip>
        </div>
      </template>
      <template #statusTemplate="{ data: rowData }">
        <div class="status-container">
          <div
            v-for="(bar, i) in rowData.data.status"
            :key="i"
            :style="{
              color:
                bar.color == '#fff200' || bar.color == '#e5e5e5'
                  ? '#000000'
                  : '#ffffff',
              'background-color': bar.color,
              width: `${bar.size * 2.3}px`,
            }"
            class="status-bar status-bar__center"
            :class="
              bar.size == 100
                ? 'bar'
                : i == rowData.data.status.length - 1
                ? 'bar-right'
                : i == 0
                ? 'bar-left'
                : ''
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="div-tooltip" v-bind="attrs" v-on="on"></div>
              </template>
              <span>{{ bar.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template #detailTemplate="{ data: rowData }">
        <TableDetail
          :rows="rowData.data.childs"
          :column_names="cols_table_la"
        />
        <!-- <span>{{rowData.data.Status}}</span> -->
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxSelection,
  DxPager,
  DxPaging,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { createNamespacedHelpers } from "vuex";
import TableDetail from "@/components/required-certifications/TableDetail.vue";
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxPager,
    DxPaging,
    DxMasterDetail,
    TableDetail,
  },
  props: ["cols", "rows"],
  data() {
    return {
      cols_table_la: ["Learning Activity", "Status"],
      selectedItemKeys: [],
      selectedItemData: [],
    };
  },
  methods: {
    selectionChanged(e) {
      // this.selectedItemKeys = e.selectedRowKeys;
      // this.selectedItemData = e.selectedRowsData;
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
.table1-container {
  padding: 40px;
}
.tags-sm {
  padding: 6px 14px 4px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px !important;
  line-height: 12.38px;
}
.txt_red {
  color: #ff6c6c !important;
}
.status-bar {
  display: flex;
  height: 16px;
  color: $white;
  font-weight: 500;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  white-space: nowrap !important;
  position: relative;
  &__left {
    justify-content: left !important;
  }
  &__center {
    justify-content: center;
  }
  .div-tooltip {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.status-bg {
  width: 200px;
}
.status-complete {
  display: flex;
}
.status-container {
  display: flex;
}
.bar-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.bar-right {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.bar {
  border-radius: 8px;
  // border-radius: 16px;
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
