import {
  getUserTasksByType,
  getTaskForm,
  updateTaskForm,
  getCountryList,
  getStateList,
  getLanguages,
  getTShirtSizes,
  getEducationLevels,
} from "@/api";

// we should add Intro tab
const introTab = {
  request_intro: "Select a task on the left to see what needs to be done.",
  initial: true,
};

export default {
  namespaced: true,
  state: {
    tasks: [introTab],
    tasks_no_intro: [],
    form: {},
    updatedForm: false,
    request_type: "",
    countryList: [],
    stateList: [],
    languagesList: [],
    tShirtSizeList: [],
    educationLevelList: [],
  },
  actions: {
    async dispatchUserTasksByType({ commit }) {
      try {
        const data = await getUserTasksByType();
        if (data.data.data.response.length > 0) {
          let tasks_all = data.data.data.response
            .map((t) => {
              let courseTitle =
                t.course_details?.length > 0 ? t.course_details[0].title : "";
              let courseAvailable = true;
              if (t.course_details) {
                if (
                  t.course_details.length == 0 ||
                  (t.course_details.length > 0 &&
                    t.course_details[0].state == "retired")
                ) {
                  courseAvailable = false;
                }
              }
              const {
                id,
                request_name,
                onboarding_id,
                onboard_type,
                request_model_name,
                date_deadline,
                // course_details,
                task_type,
                reject_message,
                channel_id,
                display_name,
                description,
              } = t;
              return {
                id,
                request_name,
                onboarding_id,
                onboard_type,
                request_model_name,
                date_deadline,
                // course_details,
                title: courseTitle,
                task_type,
                reject_message,
                channel_id,
                display_name,
                description,
                available: courseAvailable,
              };
            })
            .filter(
              (allowed_task) =>
                allowed_task.request_model_name ==
                  "onboarding.request.hremployee.personal" ||
                allowed_task.request_model_name ==
                  "onboarding.request.hremployee.profile.picture" ||
                (allowed_task.request_model_name ==
                  "onboarding.request.hremployee.course" &&
                  allowed_task.channel_id &&
                  allowed_task.available) ||
                allowed_task.request_model_name ==
                  "onboarding.request.approve.course"
            );
          tasks_all.unshift(introTab);
          commit("setUserTasks", tasks_all);
        } else {
          commit("setUserTasks", []);
        }
      } catch (error) {
        console.log(error);
        commit("setUserTasksFailure");
      }
    },
    async dispatchUserTasksByCourse({ commit, getters }) {
      try {
        // commit("setTasksNoIntro", []);
        // let _courses = getters.tasksNoIntro;
        // if (_courses.length <= 0) {
        const data = await getUserTasksByType();
        if (data.status == 200) {
          let response = data.data.data.response;
          let tasks_all = [];
          if (response.length > 0) {
            for (const t of response) {
              if (
                t.request_model_name ==
                  "onboarding.request.hremployee.course" &&
                t.course_details?.length > 0
              ) {
                const _tasks = new task_data(t);
                tasks_all.push(_tasks);
              }
            }
          }
          commit("setTasksNoIntro", tasks_all);
        } else {
          commit("setTasksNoIntro", []);
        }
        // }
      } catch (error) {
        console.log(error);
        commit("setTasksNoIntro", []);
      }
    },
    async dispatchTaskForm({ commit }, id) {
      try {
        const data = await getTaskForm(id);
        let result = data.data.data.response.filter((item) => item.fields);
        commit("setTaskForm", result);
      } catch (error) {
        commit("setTaskFormFailure");
      }
    },
    async dispatchCountryList({ commit }) {
      try {
        const data = await getCountryList();
        commit("setCountryList", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },

    async getStateList({ commit }, country_id) {
      try {
        const data = await getStateList(country_id);
        commit("setStateList", data.data.data.response);
      } catch (error) {
        console.log(error);
      }
    },

    async dispatchLanguagesList({ commit }) {
      try {
        const data = await getLanguages();
        commit("setLanguagesList", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },

    async dispatchTShirtSizeList({ commit }) {
      try {
        const data = await getTShirtSizes();
        commit("setTShirtSizeList", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },

    async dispatchEducationLevelList({ commit }) {
      try {
        const data = await getEducationLevels();
        commit("setEducationLevelsList", data.data.data.response);
      } catch (error) {
        console.log(error);
      }
    },

    async updateTaskForm({ commit }, { id, taskForm }) {
      try {
        const data = await updateTaskForm(id, taskForm);
        commit("setUpdatedForm", true);
        // commit("setTaskForm", data?.response);
      } catch (error) {
        commit("setTaskFormFailure");
        commit("setUpdatedForm", false);
      }
    },
  },
  mutations: {
    initTaskForm(state) {
      state.form = {};
    },
    setCountryList(state, countryList) {
      state.countryList = countryList;
    },
    setStateList(state, stateList) {
      state.stateList = stateList;
    },
    setUserTasks(state, status) {
      //   state.tasks = [];
      //   state.tasks.push({
      //     request_intro:
      //       "Select a task on the left to see what needs to be done.",
      //     initial: true,
      //   });
      // state.tasks.length = 1;
      // state.tasks.push(...tasks);
      // state.tasks = [...status];
      state.tasks = status;
    },
    setTasksNoIntro(state, status) {
      state.tasks_no_intro = status;
    },
    setUserTasksFailure(state) {
      state.tasks = [];
    },
    setTaskForm(state, taskForm) {
      state.form = taskForm;
    },
    setTaskFormFailure(state) {
      state.form = {};
    },
    setUpdatedForm(state, b_value) {
      state.updatedForm = b_value;
    },
    setRequestType(state, status) {
      state.request_type = status;
    },
    setLanguagesList(state, status) {
      state.languagesList = status;
    },
    setTShirtSizeList(state, status) {
      state.tShirtSizeList = status;
    },
    setEducationLevelsList(state, status) {
      state.educationLevelList = status;
    },
  },
  getters: {
    tasks(state) {
      return state.tasks;
    },
    tasksNoIntro(state) {
      return state.tasks_no_intro;
    },
    tasksCount(state) {
      return state.tasks.length - 1; // don't count intro page
    },
    taskForm(state) {
      return state.form;
    },
    getUpdatedForm(state) {
      return state.updatedForm;
    },
    getRequestType(state) {
      return state.request_type;
    },
    countryList(state) {
      return state.countryList;
    },
    stateList(state) {
      return state.stateList;
    },
    languagesList(state) {
      return state.languagesList;
    },
    tShirtSizesList(state) {
      return state.tShirtSizeList;
    },
    educationLevelList(state) {
      return state.educationLevelList;
    },
  },
};

function task_data(data) {
  this.id = data.id;
  this.request_name = data.request_name;
  this.onboarding_id = data.onboarding_id;
  this.onboard_type = data.onboard_type;
  this.request_model_name = data.request_model_name;
  this.date_deadline = data.date_deadline;
  this.task_type = data.task_type;
  this.reject_message = data.reject_message;
  this.channel_id = data.channel_id;
  this.display_name = data.display_name;
  this.description = data.description;
  this.course = Object.create({});
  if (data.course_details?.length > 0) {
    this.course.id = data.course_details[0].id;
    this.course.bg = data.course_details[0].bg;
    this.course.title = data.course_details[0].title;
    this.course.points = data.course_details[0].points;
    this.course.completion = data.course_details[0].completion;
    this.course.completed = data.course_details[0].completed;
    this.course.button = data.course_details[0].button;
    this.course.task_id = data.id;
    this.course.request_model_name = data.request_model_name;
  }
}
