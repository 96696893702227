<template>
  <v-row class="login-page container--fluid">
    <v-col class="login-part col-md-6 col-lg-4">
      <div class="login-logo">
        <img src="@/assets/img/logo.svg" />
      </div>
      <div class="login-title">
        <p class="login-title__text">Welcome to Nanoramic Employee Portal.</p>
      </div>
      <transition mode="out-in" :duration="550">
        <component :is="activeForm" @on-switch="switchForm"></component>
      </transition>
    </v-col>

    <v-col class="col-md-6 col-lg-8 login-right">
      <!-- <img src="@/assets/img/Sign-up-mage.png" /> -->
      <div class="login-img"></div>
    </v-col>
  </v-row>
</template>

<script>
// import Vue from "vue";
// import VueLazyload from "vue-lazyload";
// Vue.use(VueLazyload);
import LoginForm from "@/components/login/LoginForm";
import ResetPasswordForm from "@/components/login/ResetPasswordForm";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  name: "Login",
  components: {
    LoginForm,
    ResetPasswordForm,
  },
  data() {
    return {
      activeForm: "LoginForm",
    };
  },

  computed: {},
  created() {
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    switchForm(form) {
      this.activeForm = form;
    },
  },
};
</script>

<style lang="scss">
.login-page {
  height: 100%;
  display: flex;
  background: $white;
  margin: 0 !important;
  .login-part {
    // display: inline-block;
    padding: 0;
    .login-logo {
      margin-left: 9.6875rem;
      margin-top: 18.1875rem;
      margin-right: 26.8125rem;
    }
    .login-title {
      margin-left: 9.6875rem;
      margin-top: 5.335625rem;
      margin-right: 4.3rem;
      &__text {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 2.6875rem;
        color: #323a44;
        // max-width: 25.625rem;
      }
    }
  }
  .login-right {
    padding: 0;
    padding-left: 5.375rem;
    .login-img {
      background-image: url("~@/assets/img/Sign-up-mage.png");
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .login-right {
    display: none;
  }
  .login-logo {
    margin-top: 10rem !important;
  }
  .login-title__text {
    font-size: 1.6rem !important;
  }
}
@media screen and (min-width: 959px) and (max-width: 1723px) {
  .login-title {
    margin-left: 3.75rem !important;
    margin-right: 1rem !important;
    .login-title__text {
      font-size: 1.7rem !important;
    }
  }
  .login-right {
    padding-left: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .login-logo {
    margin-top: 6rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
  }
  .login-title {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-top: 50px !important;
    text-align: center;
  }
}
</style>
