<template>
  <div class="table1-container">
    <CommitmentSigns />
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows"
      :columns="column_names"
      key-expr="responsibility_id"
      :show-borders="true"
      :showRowLines="true"
      :showColumnLines="true"
      @selection-changed="selectionChanged"
      :selected-row-keys="selectedItemKeys"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wraped-enabled="true"
    >
      <!-- :column-hiding-enabled="true" -->
      <DxSorting mode="multiple" />
      <DxColumn
        data-field="Coach Employee"
        cell-template="coachTemplate"
        :calculate-sort-value="values_name_and_leadership"
        :sorting-method="sortTable"
        sort-order="asc"
        :width="25 + '%'"
      />
      <DxColumn
        data-field="For Responsibility"
        cell-template="responsibilityTemplate"
        :width="25 + '%'"
      />
      <!-- :hiding-priority="2" -->
      <!-- <DxColumn data-field="Current Tactical Readiness" cell-template="readinessTemplate" :hiding-priority="0"/> -->
      <!-- <DxColumn
        data-field="Coaching Style"
        cell-template="coachingStyleTemplate"
        :hiding-priority="1"
      /> -->
      <DxColumn
        data-field="Proficiency"
        cell-template="proficiencyTemplate"
        :width="30 + '%'"
      />
      <!-- :hiding-priority="3" -->
      <DxColumn
        data-field="Actions"
        alignment="left"
        cell-template="actionsTemplate"
        :width="20 + '%'"
      />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #coachTemplate="{ data: rowData }">
        <div>
          <v-list-item>
            <v-list-item-avatar rounded="30px">
              <div
                class="selectedImg"
                :style="{
                  backgroundImage: 'url(' + rowData.data.coach_image + ')',
                }"
              ></div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="rowData.data['Coach Employee']">
              </v-list-item-title>
              <span style="color: #c3c5ca">{{
                rowData.data["job_title"]
              }}</span>
            </v-list-item-content>
          </v-list-item>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: fit-content;
              gap: 0.5rem;
            "
          >
            <v-tooltip
              bottom
              v-for="(item, index) in rowData.data.role_assignment"
              v-bind:key="index"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="role_assignment_chip"
                  width="100"
                >
                  <v-icon
                    v-if="rowData.data.commitment == 'primary'"
                    size="22"
                    class="link-icon"
                    >$levelHigh</v-icon
                  >
                  <v-icon
                    v-else-if="rowData.data.commitment == 'backup'"
                    size="22"
                    class="link-icon"
                    >$levelMedium</v-icon
                  >
                  <v-icon
                    v-else-if="rowData.data.commitment == 'preparing'"
                    size="22"
                    class="link-icon"
                    >$levelLow</v-icon
                  >
                  <span class="role_assignment_title">{{ item.name }}</span>
                  <img
                    style="
                      width: 22px !important;
                      height: 22px !important;
                      margin-left: 10px;
                    "
                    :src="
                      require(`../../assets/img/intensity_${item.intensity}.png`)
                    "
                  />
                </v-chip>
              </template>
              <span
                >Role Assignment with commitment
                {{ rowData.data.commitment }} and intensity
                {{ item.intensity }} bars</span
              >
            </v-tooltip>
          </div>
        </div>
      </template>
      <template #responsibilityTemplate="{ data: rowData }">
        <div
          style="
            display: flex;
            flex-direction: column;
            background: #e0e0e0;
            border-radius: 5px;
            padding: 15px;
            white-space: break-spaces;
          "
        >
          <div>
            <div
              style="margin-bottom: 10px; display: flex; align-items: center"
            >
              <!-- <v-icon
              v-if="rowData.data.commitment == 'primary'"
              size="30"
              class="link-icon"
              >$levelHigh</v-icon
            >
            <v-icon
              v-else-if="rowData.data.commitment == 'backup'"
              size="30"
              class="link-icon"
              >$levelMedium</v-icon
            >
            <v-icon
              v-else-if="rowData.data.commitment == 'preparing'"
              size="30"
              class="link-icon"
              >$levelLow</v-icon
            > -->
              <img width="25px" :src="rowData.data.responsibility_intensity" />
              <span class="ml-2">{{ rowData.data["For Responsibility"] }}</span>
            </div>
            <ChildBulletChartWidget :barData="readinessValues(rowData.data)" />
          </div>
        </div>
      </template>
      <template #readinessTemplate="{ data: rowData }">
        <div>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                class="progress-bar-container"
                :value="rowData.data.certification_progress * 100"
                :color="'#A7D42E'"
                height="16"
                v-bind="attrs"
                v-on="on"
              >
              </v-progress-linear>
            </template>
            <span>{{ rowData.data["Current Tactical Readiness"] }}</span>
          </v-tooltip> -->
          <BulletChartResponsibilities
            :barData="readinessValues(rowData.data)"
          />
        </div>
      </template>
      <template #coachingStyleTemplate="{ data: rowData }">
        <div
          class="situational-leadership-label d-flex"
          :style="{
            color: colorLeadership(rowData.data.coaching_id),
          }"
        >
          {{ rowData.data["Coaching Style"] }}

          <!-- :disabled="act_as_me == false" -->
          <v-btn
            depressed
            rounded
            icon
            color="#000000"
            @click="
              onItemClick({ itemData: 'Change Coaching Style' }, rowData.data)
            "
          >
            <img
              style="height: 30px"
              src="../../assets/img/Edit_from_cell.png"
            />
          </v-btn>
        </div>
      </template>
      <template #proficiencyTemplate="{ data: rowData }">
        <div>
          <!-- <div class="d-flex position-relative tempate_bg"> -->
          <!-- v-if="rowData.data.badge_id" -->
          <!-- <div class="d-flex position-relative">
              <div
                :style="{
                  'border-left': `10px solid ${colorProficiency(
                    rowData.data.badge_id
                  )}`,
                }"
              ></div>
              <div class="template-img">
                <img src="../../assets/img/badgeIcon.png" />
              </div>
            </div>
            <div class="d-flex align-center">
              <div class="badge_text">
                {{ rowData.data.badge }}
              </div>
              <div>
                <v-btn
                  depressed
                  rounded
                  class="btn-accept"
                  @click="removeDialog(rowData.data)"
                  icon
                  color="#000000"
                >
                  <v-icon size="20"> $deleteBlack </v-icon>
                </v-btn>
              </div>
            </div> -->
          <div
            v-on:dblclick="redirect(rowData.data)"
            :id="'chip' + rowData.data.tracker_id"
          >
            <chipComponent
              :chipData="getBadgeChipData(rowData.data)"
              @onClickClose="removeDialog(rowData.data)"
              @showBadgeTooltip="showToolTip()"
            ></chipComponent>
          </div>
          <DxTooltip
            :hide-on-outside-click="false"
            show-event="mouseenter"
            hide-event="mouseleave"
            :target="'#chip' + rowData.data.tracker_id"
            v-if="showBadgeTooltips"
          >
            <span>Double click to evaluate proficiency</span>
          </DxTooltip>
          <!-- </div> -->
          <!-- <div >
            <p
              class="addCoach"
              @click="addBadge(rowData.data)"
              v-if="act_as_me"
            >
              + Add Badge
            </p>
          </div> -->
          <!-- <p
            v-if="
              rowData.data.target_id &&
              rowData.data.target_id != rowData.data.badge_id
            "
            class="target-label"
          >
            > {{ rowData.data.target_id }}
          </p> -->
        </div>
      </template>
      <template #actionsTemplate="{ data: rowData }">
        <div>
          <div class="actionButtons">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- :disabled="act_as_me == false" -->
                <v-btn
                  class="btn-stop"
                  @click="openModalDelete('stop_coaching', rowData.data)"
                  color="#000000"
                  v-bind="attrs"
                  v-on="on"
                  :width="100 + 'px'"
                >
                  <!-- <v-icon size="20"> $deleteBlack </v-icon> -->
                  STOP
                </v-btn>
              </template>
              <span>Stop Coaching</span>
            </v-tooltip>
            <!-- <v-tooltip top> -->
            <!-- <template v-slot:activator="{ on, attrs }"> -->
            <div>
              <!-- v-bind="attrs"
                    v-on="on" -->
              <img
                src="@/assets/img/Chatter.png"
                style="height: 24px; width: 24px; cursor: pointer"
              />
            </div>
            <!-- </template> -->
            <!-- <span>Inspect Proficiency CheckPoint</span> -->
            <!-- </v-tooltip> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- :disabled="act_as_me == false" -->
                <div>
                  <div v-if="act_as_me == true">
                    <DxDropDownButton
                      :items="editMenuItem"
                      :drop-down-options="{ width: 230 }"
                      icon="fas fa-ellipsis-v"
                      @item-click="onItemClick($event, rowData.data)"
                      @button-click="onButtonClick(rowData.data)"
                      stylingMode="text"
                      :showArrowIcon="false"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </div>
                  <div v-else>
                    <!-- <v-icon size="20" @click="isActingAsAnotherUser = true">
                      <img src="../../assets/logo.png">
                    </v-icon> -->
                    <!-- <button class="btn"> -->
                    <!-- <span size="20" class="btn fas fa-ellipsis-v"></span>
                       -->
                    <img
                      style="height: 24px"
                      src="../../assets/img/vertical_dot.png"
                      @click="isActingAsAnotherUser = true"
                    />
                    <!-- </button> -->
                  </div>
                </div>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <YesCancelModal
              :open="modalDeleteOpen"
              @yes="stopTracking"
              @cancel="closeDeleteModal"
            />
          </div>
          <div style="margin-top: 5px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-on:dblclick="
                    onItemClick(
                      { itemData: 'Change Coaching Style' },
                      rowData.data
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  class="situational-leadership-label d-flex"
                  :style="{
                    color: colorLeadership(rowData.data.coaching_id),
                    border:
                      '2px solid ' + colorLeadership(rowData.data.coaching_id),
                  }"
                >
                  <span>{{ rowData.data["Coaching Style"] }}</span>
                </div>
              </template>
              <span>Double click to change coaching style</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </DxDataGrid>
    <v-dialog v-model="dialogEdit" content-class="dialog-select">
      <div class="cust-dialog">
        <div class="cust-dialog-header">
          <h2 class="cust-dialog-title">
            {{ savedCorrectlyData.title }}
          </h2>
        </div>
        <div class="cust-dialog-body">
          <p
            class="cust-dialog-label"
            v-if="
              savedCorrectlyData.titleId &&
              savedCorrectlyData.titleId != 'Coaching Style'
            "
          >
            Current Proficiency {{ savedCorrectlyData.titleId }} is
            {{ savedCorrectlyData[savedCorrectlyData.titleId] }}
          </p>
          <p
            class="cust-dialog-label"
            v-if="
              savedCorrectlyData.titleId &&
              savedCorrectlyData.titleId == 'Coaching Style'
            "
          >
            Current {{ savedCorrectlyData.titleId }} is
            {{ savedCorrectlyData[savedCorrectlyData.titleId] }}
          </p>
          <div class="cust-dialog-bodyContent">
            <v-row>
              <v-col class="cust-dialog-label"> Employee </v-col>
              <v-col class="cust-dialog-labelContent">{{
                savedCorrectlyData["Coach Employee"]
              }}</v-col>
            </v-row>
            <v-row>
              <v-col class="cust-dialog-label"> Employee Responsibility</v-col>
              <v-col class="cust-dialog-labelContent">{{
                savedCorrectlyData["For Responsibility"]
              }}</v-col>
            </v-row>
            <v-row>
              <v-col
                class="cust-dialog-label"
                v-if="savedCorrectlyData.title != 'Add Badge'"
              >
                Change to</v-col
              >
              <v-col v-if="savedCorrectlyData.title != 'Add Badge'">
                <div>
                  <v-select
                    v-if="savedCorrectlyData.titleId == 'target'"
                    v-model="editingData.target_id"
                    label="Select"
                    :items="listTargets(savedCorrectlyData.target_id)"
                    item-text="name"
                    item-value="id"
                    flatonRemoveBadge
                    hide-details
                    single-line
                  >
                  </v-select>

                  <v-select
                    v-if="savedCorrectlyData.titleId == 'badge'"
                    v-model="editingData.badge_id"
                    label="Select"
                    :items="listTargets(savedCorrectlyData.badge_id)"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    hide-details
                    single-line
                  >
                  </v-select>

                  <v-select
                    v-if="savedCorrectlyData.titleId == 'Coaching Style'"
                    v-model="editingData.coaching_id"
                    label="Select"
                    :items="listCoaching(savedCorrectlyData.coaching_id)"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    hide-details
                    single-line
                  >
                    <template v-slot:selection="data">
                      <span
                        :disabled="act_as_me == false"
                        v-bind="data.attrs"
                        :style="{ color: colorLeadership(data.item.id) }"
                      >
                        {{ data.item.name }}
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <div
                        class="coach-color"
                        :style="{
                          background: colorLeadership(data.item.id),
                        }"
                      ></div>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col
                class="cust-dialog-label"
                v-if="savedCorrectlyData.title == 'Add Badge'"
              >
                Proficiency Badge</v-col
              >
              <v-col v-if="savedCorrectlyData.title == 'Add Badge'">
                <div>
                  <v-select
                    v-model="editingData.badge_id"
                    label="Select"
                    :items="listTargets(savedCorrectlyData.badge_id)"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    hide-details
                    single-line
                  >
                  </v-select>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="cust-dialog-footer">
          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            class="btn-accept btn-save"
            @click="onSave"
            color="#53a0de"
            >Save</v-btn
          >

          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            class="btn-accept btn-save"
            @click="closeDialog"
            color="#ff6c6c"
            >Cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="isActingAsAnotherUser"
      content-class="dialog-box-action-as-another"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-body d-flex flex-column align-center">
          <p class="cust-dialog-label">
            You cannot do this because you are acting as another user
          </p>
          <v-btn
            depressed
            rounded
            class="btn-accept btn-save"
            @click="closeDialog"
            color="#53a0de"
            >OK</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="isTargetSameAsBadge"
      content-class="dialog-box-action-as-another"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-body d-flex flex-column align-center">
          <p class="cust-dialog-label">
            Target proficiency was achieved. Do you want to remove yourself as
            coach?
          </p>
          <div>
            <v-btn
              depressed
              rounded
              class="btn-accept btn-save btn-blue"
              @click="openModalRemoveCoach()"
              >Yes</v-btn
            >
            <!-- @click="closeDialog" -->
            <v-btn
              depressed
              rounded
              class="btn-accept btn-save btn-red"
              @click="isTargetSameAsBadge = false"
              >No</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="removeBadges"
      persistent
      content-class="dialog-box-decline-request"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-header">
          <h2 class="cust-dialog-title">Remove badge</h2>
        </div>
        <div class="cust-dialog-body">
          <v-row>
            <v-col cols="3"> Please provide a reason for remove badge </v-col>
            <v-col cols="9">
              <v-form ref="reason">
                <v-text-field
                  :rules="rules"
                  hide-details="auto"
                  v-model="removeReason"
                  style="margin-left: 10px"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <div class="cust-dialog-footer d-flex pa-2">
          <v-btn
            depressed
            rounded
            class="btn-decline me-4"
            @click="onRemoveBadge()"
            color="#a7d42e"
            >Remove</v-btn
          >

          <v-btn
            depressed
            rounded
            class="btn-accept"
            @click="onCancel"
            color="#ff6c6c"
            >Discard</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import {
  changeCoachingStyle,
  stopCoaching,
  updateResponsibilityTarget,
  updateResponsibilityGrantedBadge,
  removeBadge,
  updatesBadge,
} from "@/api";
import DxDataGrid, {
  DxSelection,
  DxColumn,
  DxPager,
  DxPaging,
  DxSorting,
} from "devextreme-vue/data-grid";
import CommitmentSigns from "@/common/CommitmentSigns.vue";
import YesCancelModal from "@/common/YesCancelModal";
import BulletChartResponsibilities from "@/common/DevExtreme/BulletChartResponsibilities";
import {
  COACHING_STYLES,
  TARGETS,
  LEADERSHIP,
  PROFICIENCY,
} from "@/utils/constants.js";
import { createNamespacedHelpers } from "vuex";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
import DxDropDownButton from "devextreme-vue/drop-down-button";

const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");
import ChildBulletChartWidget from "../readiness/childBulletChartWidget.vue";
import chipComponent from "@/common/chips";
import { DxTooltip } from "devextreme-vue/tooltip";
import moment from "moment";

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxColumn,
    DxPager,
    DxPaging,
    DxSorting,
    YesCancelModal,
    CommitmentSigns,
    BulletChartResponsibilities,
    DxDropDownButton,
    ChildBulletChartWidget,
    chipComponent,
    DxTooltip,
  },
  props: ["column_names", "rows"],
  data() {
    return {
      editMenuItem: [
        "Change Coaching Style",
        "Change Target Proficiency",
        "Evaluate Proficiency Checkpoints",
      ],
      act_as_me: true,
      modalDeleteOpen: false,
      selectedItemKeys: [],
      selectedItemData: [],
      editingData: {
        target_id: "",
        badge_id: "",
        coaching_id: "",
      },
      savedCorrectlyData: {},
      dialogEdit: false,
      isActingAsAnotherUser: false,
      isTargetSameAsBadge: false,
      listError: [],
      values_name_and_leadership(rowData) {
        return [rowData["Coach Employee"], rowData.coaching_id];
      },
      modalAction: "",
      removeReason: "",
      removeBadges: false,
      rules: [
        (value) => !!value || "Reason is required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      showBadgeTooltips: true,
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  computed: {
    allListCoaching() {
      return COACHING_STYLES;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    listCoaching(currentValue) {
      return COACHING_STYLES.filter((x) => x.id != currentValue);
    },
    listTargets(currentValue) {
      if (currentValue) {
        return TARGETS.filter((x) => x.id != currentValue);
      } else {
        return TARGETS;
      }
    },
    selectionChanged(e) {
      this.selectedItemKeys = e.selectedRowKeys;
      this.selectedItemData = e.selectedRowsData;
    },
    sortTable(a, b) {
      const name_emp1 = a[0];
      const name_emp2 = b[0];
      const coach_sty1 = a[1];
      const coach_sty2 = b[1];
      if (name_emp1 == name_emp2) {
        return coach_sty1.localeCompare(coach_sty2);
      }
      if (name_emp1 > name_emp2) {
        return 1;
      }
      if (name_emp1 < name_emp2) {
        return -1;
      }
      return 0;
    },
    colorLeadership(situational_leadership) {
      let _color = LEADERSHIP[situational_leadership].color;
      return _color ? _color : "#000000";
    },
    colorProficiency(proficiency) {
      let _color = PROFICIENCY[proficiency].color;
      return _color ? _color : "#000000";
    },
    readinessValues(rowData) {
      // let arrayBar = new Array();
      // arrayBar.push({
      //   value: rowData.responsibility_certification_progress,
      // });
      // arrayBar.push({
      //   value: rowData.responsibility_proficiency_progress,
      // });
      // console.log('arrayBar: ', arrayBar);
      // return arrayBar;

      let barData = {
        ID: rowData.employee_responsibility_id,
        isFromManageOthers: true,
        readinessChartOption: {
          attrs: {
            chartValues: [],
            options: {
              leftPadding: 0,
              height: 3,
            },
            label: "",
          },
        },
      };

      //for Certification value
      let certification = {};

      if (rowData.certification_requirements_count != 0) {
        certification.value =
          rowData.responsibility_certification_progress > 1
            ? rowData.responsibility_certification_progress * 0.5
            : rowData.responsibility_certification_progress * 0.5 * 100;
        if (certification.value == 50) {
          certification.color = "#C0F9C0";
        } else {
          certification.color = "#FFF7A1";
        }
        barData.readinessChartOption.attrs.chartValues.push(certification);
      } else {
        barData.readinessChartOption.attrs.chartValues.push({
          value: 50,
          color: "#282C33",
        });
      }

      //for Proficiency value
      let proficiency = {
        value:
          rowData.responsibility_proficiency_progress > 1
            ? rowData.responsibility_proficiency_progress * 0.5
            : rowData.responsibility_proficiency_progress * 0.5 * 100,
      };
      if (proficiency.value == 50) {
        proficiency.color = "#54C45E";
      } else {
        proficiency.color = "#FFE342";
      }
      barData.readinessChartOption.attrs.chartValues.push(proficiency);

      //for tooltip
      if (
        rowData.certification_requirements_count == 0 &&
        proficiency.value == 50
      ) {
        barData.readinessChartOption.attrs.label =
          "No Required Certification<br/>Achieved Proficiency";
      } else if (
        rowData.certification_requirements_count == 0 &&
        proficiency.value != 50
      ) {
        barData.readinessChartOption.attrs.label =
          "No Required Certification<br/>" +
          proficiency.value.toFixed(0) * 2 +
          "% Proficient";
      } else if (
        rowData.certification_requirements_count != 0 &&
        proficiency.value == 50 &&
        certification.value == 50
      ) {
        barData.readinessChartOption.attrs.label =
          "Fully Certified<br/>Achieved Proficiency";
      } else if (
        rowData.certification_requirements_count != 0 &&
        proficiency.value != 50 &&
        certification.value != 50
      ) {
        barData.readinessChartOption.attrs.label =
          certification.value.toFixed(0) * 2 +
          "% Certified<br/>" +
          proficiency.value.toFixed(0) * 2 +
          "% Proficient";
      } else if (
        rowData.certification_requirements_count != 0 &&
        proficiency.value == 50 &&
        certification.value != 50
      ) {
        barData.readinessChartOption.attrs.label =
          certification.value.toFixed(0) * 2 +
          "% Certified<br/>Achieved Proficiency";
      } else if (
        rowData.certification_requirements_count != 0 &&
        proficiency.value != 50 &&
        certification.value == 50
      ) {
        barData.readinessChartOption.attrs.label =
          "Fully Certified<br/>" +
          proficiency.value.toFixed(0) * 2 +
          "% Proficient";
      } else {
        barData.readinessChartOption.attrs.label = "";
      }

      return barData;
    },
    openModalDelete(action, rowData) {
      if (this.act_as_me == false) {
        this.isActingAsAnotherUser = true;
      } else {
        this.modalAction = action;
        this.savedCorrectlyData = rowData;
        this.modalDeleteOpen = true;
        this.isTargetSameAsBadge = false;
      }
    },
    closeDeleteModal() {
      this.modalDeleteOpen = false;
      this.isTargetSameAsBadge = false;
    },
    openDialogEdit(rowData) {
      this.editingData = JSON.parse(JSON.stringify(rowData));
      this.savedCorrectlyData = rowData;
      this.dialogEdit = true;
    },
    closeDialog() {
      this.dialogEdit = false;
      this.isActingAsAnotherUser = false;
    },
    onItemClick(e, rowData) {
      if (this.act_as_me == false) {
        this.isActingAsAnotherUser = true;
      } else {
        if (e.itemData !== "Remove Badge") {
          this.editingData = JSON.parse(JSON.stringify(rowData));
          this.savedCorrectlyData = rowData;
          this.savedCorrectlyData.title = e.itemData || e.itemData.name;
          if (this.savedCorrectlyData.title == "Change Badge") {
            this.savedCorrectlyData.titleId = "badge";
          } else if (
            this.savedCorrectlyData.title == "Change Target Proficiency"
          ) {
            this.savedCorrectlyData.titleId = "target";
          } else if (this.savedCorrectlyData.title == "Change Coaching Style") {
            this.savedCorrectlyData.titleId = "Coaching Style";
          } else if (
            this.savedCorrectlyData.title == "Evaluate Proficiency Checkpoints"
          ) {
            this.$router.push({
              name: "Employee Inspect Proficiency",
              params: {
                id: rowData.responsibility_id,
                data: { ...rowData, employeeId: rowData.employee_id },
              },
            });
            this.dialogEdit = false;
          }
          this.dialogEdit = true;
        } else if (e.itemData == "Remove Badge") {
          // this.openModalDelete("remove_proficiency_badge", rowData);
          // remove badge reason pop up
          if (this.act_as_me == false) {
            this.isActingAsAnotherUser = true;
          } else {
            this.removeBadges = true;
            this.savedCorrectlyData = rowData;
          }
        }
      }
    },
    async onSave() {
      this.getLoading(true);
      if (this.editingData.coaching_id != this.savedCorrectlyData.coaching_id)
        await this.editCoachingStyle();
      if (this.editingData.target_id != this.savedCorrectlyData.target_id)
        await this.editBadge("update_target");
      if (this.editingData.badge_id != this.savedCorrectlyData.badge_id)
        await this.editBadge("update_badge");
      this.getLoading(false);
      this.dialogEdit = false;
      if (this.listError.length > 0) {
        this.dispatchOpenAlert({
          type: "error",
          message: "Error at saving: " + this.listError.join(),
        });
        this.listError = [];
      }
    },
    async editCoachingStyle() {
      let tracking_resp = this.selectedItemData[0];
      try {
        const result = await changeCoachingStyle(
          tracking_resp["tracker_id"],
          this.editingData.coaching_id
        );
        if (result.status == 200) {
          const label = this.allListCoaching.find(
            (l) => l.id == this.editingData.coaching_id
          );
          // this.editingData.coaching_id = _val;

          this.savedCorrectlyData["Coaching Style"] =
            label.name.split(" - ")[1];
          this.savedCorrectlyData.coaching_id = this.editingData.coaching_id;
        }
      } catch (error) {
        this.listError.push("Coaching Style");
      }
    },

    // edit Badge and target
    async editBadge(action) {
      try {
        let _dataRequest = {
          action: "update_proficiency_target",
          employee_resp: this.savedCorrectlyData.employee_responsibility_id,
        };
        if (action == "update_target") {
          _dataRequest.target = this.editingData.target_id;
        } else {
          _dataRequest.proficiency = this.editingData.badge_id;
        }
        const result = await updatesBadge(_dataRequest);
        if (result.status == 200) {
          if (action == "update_target") {
            this.savedCorrectlyData.target = CapitalizeFirstLetter(
              this.editingData.target_id
            );
            this.savedCorrectlyData.target_id = this.editingData.target_id;
          } else {
            this.savedCorrectlyData.badge = CapitalizeFirstLetter(
              this.editingData.badge_id
            );
            this.savedCorrectlyData.badge_id = this.editingData.badge_id;
          }
          if (this.editingData.target_id == this.editingData.badge_id) {
            this.isTargetSameAsBadge = true;
          }
        }
      } catch (error) {
        this.listError.push("Badge");
      }
    },
    async stopTracking() {
      this.getLoading(true);
      try {
        if (this.modalAction === "stop_coaching") {
          this.isTargetSameAsBadge = false;
          let tracking_resp = this.selectedItemData[0];
          const result = await stopCoaching(tracking_resp["tracker_id"]);
          if (result.status == 200) {
            this.$emit("deleteRow", tracking_resp["tracker_id"]);
            this.closeDeleteModal();
          }
        } else if (this.modalAction === "remove_proficiency_badge") {
          const result = await removeBadge(
            this.savedCorrectlyData.employee_responsibility_id
          );
          if (result.status == 200) {
            this.$emit(
              "removeBadge",
              this.savedCorrectlyData.employee_responsibility_id
            );
            this.closeDeleteModal();
          }
        }
      } catch (error) {
        this.closeDeleteModal();
        if (error.response) {
          this.dispatchOpenAlert({
            type: "error",
            message: error.response.data.message,
          });
        } else {
          this.dispatchOpenAlert({
            type: "error",
            message: "Something went wrong, try again",
          });
        }
      }
      this.getLoading(false);
    },
    openModalRemoveCoach() {
      this.modalAction = "stop_coaching";
      this.stopTracking();
    },
    onButtonClick(rowData) {
      if (rowData.badge_id) {
        this.editMenuItem = [
          "Change Coaching Style",
          "Change Target Proficiency",
          "Remove Badge",
        ];
      } else {
        this.editMenuItem = [
          "Change Coaching Style",
          "Change Target Proficiency",
          "Evaluate Proficiency Checkpoints",
        ];
      }
    },
    addBadge(rowData) {
      // this.editingData = JSON.parse(JSON.stringify(rowData));
      // this.savedCorrectlyData = rowData;
      // this.savedCorrectlyData.title = "Add Badge";
      // this.dialogEdit = true;
      this.redirect(rowData);
    },
    redirect(rowData) {
      if (this.act_as_me) {
        this.$router.push({
          name: "Employee Inspect Proficiency",
          params: {
            id: rowData.responsibility_id,
            data: { ...rowData, employeeId: rowData.employee_id },
          },
        });
      } else {
        this.isActingAsAnotherUser = true;
      }
    },
    removeDialog(rowData) {
      if (this.act_as_me == false) {
        this.isActingAsAnotherUser = true;
      } else {
        this.removeBadges = true;
        this.savedCorrectlyData = rowData;
      }
    },
    onCancel() {
      this.removeBadges = false;
      this.$refs.reason.reset();
    },
    async onRemoveBadge() {
      if (this.$refs.reason.validate()) {
        this.$emit("removeBadge", {
          employee_responsibility_id:
            this.savedCorrectlyData.employee_responsibility_id,
          removeReason: this.removeReason,
        });
        this.$refs.reason.reset();
        this.removeBadges = false;
        this.savedCorrectlyData = {};
      }
      // this.getLoading(true);
      // let employee_resp = this.savedCorrectlyData.employee_responsibility_id;
      // try {
      //   const result = await removeBadge(employee_resp, this.removeReason);
      //   // await this.getCheckPoints(
      //   //   this.$route.params.id,
      //   //   this.responsibility_data.employee_id
      //   // );
      //   this.$refs.reason.reset();
      //   this.removeBadges = false;
      //   this.savedCorrectlyData = {};
      //   this.getLoading(false);
      // } catch (error) {
      //   this.getLoading(false);
      //   console.log("error: ", error);
      // }
    },
    getBadgeChipData(rowData) {
      let ChipData = {
        // textIsBold: true,
        textColor: "black",
        hasImageToolTip: true,
        id: rowData.tracker_id,
      };

      let badge = rowData.badge;
      ChipData.close = badge ? true : false;

      let target = rowData.target;

      let is_super_expert = rowData.is_super_expert;

      let days = moment(rowData.expiration_date).diff(moment(), "days");

      ChipData.image = require(days > 30
        ? "../../assets/img/expiration_date.png"
        : days <= 30
        ? "../../assets/img/expiration_date_less.png"
        : "../../assets/img/badgeIcon.png");

      ChipData.toolTip = ChipData.image.includes("expiration_date")
        ? `Expire in ${days} days (${moment(rowData.expiration_date).format(
            "DD-MMM-YYYY"
          )})`
        : ChipData.image.includes("expiration_date_less")
        ? `Expire in ${days} days (${moment(rowData.expiration_date).format(
            "DD-MMM-YYYY"
          )})`
        : "Does not expire";
      if (badge == "Apprentice") {
        ChipData.text =
          target != "Apprentice"
            ? `${badge} > ${
                target == "Professional" ? "50%" : "33%"
              } to ${target}`
            : badge;
        ChipData.color = "#CFE4FF";
      } else if (badge == "Professional") {
        ChipData.text =
          target == "Apprentice"
            ? `${badge} > ${target}`
            : target == "Expert"
            ? `${badge} > 66% to ${target}`
            : badge;
        ChipData.color = "#E3FAE3";
      } else if (badge == "Expert") {
        (ChipData.text = is_super_expert
          ? "Master"
          : target == "Apprentice" || target == "Professional"
          ? `${badge}  >  ${target}`
          : badge),
          (ChipData.color = is_super_expert ? "#FFD5F5" : "#FCFCCA");
      } else {
        ChipData.text = `> 0% to ${target}`;
        ChipData.color = "black";
        ChipData.isOutlined = true;
      }
      ChipData.hasProgressbar = true;
      ChipData.progress_bar = this.proficiencyProgressBar(rowData);

      return ChipData;
    },
    proficiencyProgressBar(rowData) {
      // if (rowData.badge == "Master" || rowData.badge == "Super Expert") {
      //   return false;
      // } else if (
      //   rowData.progressbar_stacked_values[rowData.target.toLowerCase()]
      //   .passed == 100 &&
      //   rowData.badge != rowData.target &&
      //   rowData.badge != ""
      //   ) {
      //   return true;
      // } else {
      const barValues =
        rowData.progressbar_stacked_values[rowData.target.toLowerCase()];
      let barData = {
        ID: rowData.employee_responsibility_id,
        readinessChartOption: {
          attrs: {
            chartValues: [
              { value: barValues.passed, color: "#A3D977" },
              { value: barValues.failed, color: "#C92D39" },
              { value: barValues.not_applicable, color: "#000000" },
              { value: barValues.not_evaluated, color: "#BFBFBF" },
            ],
            options: {
              leftPadding: 0,
              height: 3,
            },
            label: "",
          },
        },
      };
      return barData;
      // }
    },
    showToolTip() {
      this.showBadgeTooltips = !this.showBadgeTooltips;
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.table1-container {
  padding: 40px;
}
.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.btn-decline {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.btn-stop {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  background-color: $red !important;
  // border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.situational-leadership-label {
  font-weight: 600;
  align-items: center;
  padding: 15px;
  height: 35px;
  justify-content: center !important;
  span {
    font-size: 16px;
  }
}
.badge-tag {
  display: block;
  padding: 6px 14px 4px;
  text-align: center;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.target-label {
  margin-top: 10px;
  text-align: center;
  text-transform: capitalize;
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
.coach-color {
  width: 15px;
  height: 20px;
  margin-right: 15px;
}
.dx-button-has-icon .dx-button-content {
  padding-top: 0px;
}

.cust-dialog {
  .cust-dialog-header {
    margin-bottom: 1rem;
    .cust-dialog-title {
      color: $blue;
      text-align: center;
    }
  }
  .cust-dialog-body {
    font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
    font-size: 1.08333333rem;
    font-weight: 400;
    line-height: 1.5;
    .cust-dialog-bodyContent {
      margin-top: 25px;
      width: max-content;

      .cust-dialog-labelContent {
        font-weight: bold;
        color: #008784;
        text-align: left;
      }
    }
    .cust-dialog-label {
      font-weight: bold;
      color: #666666;
      text-align: left;
    }
  }
}

.tempate_bg {
  align-items: center;
  // justify-content: space-between;
  // background: #f2f3f5;
}
.template-img img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
.badge_text {
  font-weight: bolder;
  font-size: 15px;
  color: black;
}

.btn-save {
  width: 64px !important;
  float: left !important;
  margin: 20px 10px 10px 10px !important;
}
.btn-blue {
  background-color: $blue !important;
}
.btn-red {
  background-color: $red !important;
}
.addCoach {
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  padding: 5px 10px;
  color: $blue;
}

.v-dialog.dialog-select {
  padding: 30px 30px 10px 30px;
  border-radius: 30px;
  background-color: $white;
  width: min(70vw, 900px);
}
.v-dialog.dialog-box-action-as-another {
  padding: 30px 30px 10px 30px;
  border-radius: 30px;
  background-color: $white;
  width: max-content;
}

.actionButtons .dx-button .dx-icon {
  line-height: 12px !important;
}
.dx-scrollview-content {
  padding: 10px;
}
.v-chip.v-size--large {
  height: 80px !important;
  // height: 35px !important;
  font-size: 20px !important;
  padding: 18px;
}
.role_assignment_chip {
  padding: 15px !important;
  .v-icon {
    margin-left: -10px;
  }
  .role_assignment_title {
    margin-left: 15px;
  }
}
</style>
