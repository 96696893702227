<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="page_router">
            <span class="txt_router text_redirect" @click="redirect()"
              >{{ pronounEmployee }} Readiness</span
            >
            <div class="img_arrow"></div>
            <div class="txt_router">{{ pronounEmployee }} Expert Badges</div>
          </div>
          <div class="main_section__header">
            <h1 class="main_section-title">
              {{ pronounEmployee }} Expert Badges
            </h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <div class="eventsTable">
                <DxDataGrid
                  id="gridContainer1"
                  :data-source="rows"
                  key-expr="id"
                  :show-borders="true"
                  :showRowLines="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :word-wrap-enabled="true"
                  :column-hiding-enabled="true"
                >
                  <DxColumn
                    data-field="Expert in responsibility"
                    :allow-sorting="false"
                  />
                  <DxColumn data-field="Expires on" :allow-sorting="false" />
                  <DxColumn
                    data-field="Granted By/On"
                    sort-order="desc"
                    cell-template="granted"
                    :calculate-sort-value="sortByDate"
                  />
                  <template #granted="{ data: options }">
                    <div>
                      <div>{{ options.data["Granted By/On"] }}</div>
                      <div>{{ options.data["Granted On Date"] }}</div>
                    </div>
                  </template>
                  <DxSorting mode="multiple" />
                  <DxPaging :page-size="20" />
                  <DxPager
                    :visible="true"
                    display-mode="compact"
                    :show-page-size-selector="false"
                    :show-info="true"
                  />
                </DxDataGrid>
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getExpertBadges } from "@/api";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxSorting,
} from "devextreme-vue/data-grid";
import moment from "moment";

const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);

export default {
  name: "YourExpertBadges",
  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxPager,
    DxPaging,
  },
  data() {
    return {
      pronounEmployee: "Your",
      // pageSizes: [ 5, 10, "all"],
      not_current_user: false,
      rows: [],
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    if (this.$route.name == "Your Expert Badges") {
      await this.getDataTable(localStorage.getItem("employee_id"));
    } else if (this.$route.name == "Employee Expert Badges") {
      this.pronounEmployee = "";
      this.not_current_user = true;
      const id_employee = Object.values(this.$route.params)[0];
      const employee_data = this.employeeNameJobList.find(
        (e) => e.id == id_employee
      );
      this.pronounEmployee = employee_data.name;
      await this.getDataTable(id_employee);
    }
  },
  watch: {},
  methods: {
    ...mapActions(["getLoading"]),
    async getDataTable(employeeId) {
      this.getLoading(true);
      try {
        const result = await getExpertBadges(employeeId);
        let res = result.data.data.response;
        res.forEach((element) => {
          let obj = {
            id: element.responsibility_id,
            "Expert in responsibility": element.responsibility_name,
            "Expires on":
              element.expiration_date == false
                ? ""
                : moment(element.expiration_date).format("DD-MMM-YYYY"),
            "Granted By/On":
              element.granted_by_name == false
                ? ""
                : "Granted by" + " " + element.granted_by_name,
            "Granted On Date":
              "Granted on" +
              " " +
              moment(element.granted_on).format("DD-MMM-YYYY"),
          };
          this.rows.push(obj);
        });
      } catch (error) {
        console.log("getExpertBadges==>", error);
      }
      this.getLoading(false);
    },
    sortByDate(rowData) {
      return moment(rowData["Granted On Date"]).format("YYYY/MM/DD");
    },

    redirect() {
      // this.$router.push(path);
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
.page_router {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .txt_router {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7f868e;
  }
  .text_redirect {
    color: #7f868e;
  }
  .text_redirect:hover {
    cursor: pointer;
    color: $blue;
    text-decoration-line: underline;
  }
  .img_arrow {
    background-image: url("~@/assets/img/arrow_right.svg");
    width: 5px;
    height: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>