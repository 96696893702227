<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      content-class="dialog"
      persistent
      scrollable
      width="85%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="act_as_me == false"
          depressed
          rounded
          color="#53a0de"
          v-bind="attrs"
          v-on="on"
          height="40"
          width="170"
          class="btn-popup"
          @click="onClickTask"
        >
          Complete this task
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title class="headline">
          <base-btn :onClick="closeDialog">
            <v-icon class="icon--close">$close</v-icon>
            Close
          </base-btn>
          <span class="title-text">Please complete this task</span>
          <base-btn :onClick="saveDialog">Save</base-btn>
        </v-card-title>

        <div class="form-wrapper">
          <v-form
            ref="myForm"
            v-model="validForm"
            class="popup-form-wrapper"
            v-if="model_name === 'onboarding.request.hremployee.personal'"
          >
            <FormFields
              class="form"
              v-for="(section, index) in requestForm"
              :key="index"
              :fields="section.fields"
              :class="{
                'fit-form-25': section.fields.length < 2 && section.multiple,
                'fit-form-50': section.fields.length < 2 && !section.multiple,
              }"
              @changeCountry="updateStates"
            >
              <template v-slot:header>
                <span>{{ section.name }}</span>
                <!-- <v-icon class="icon">{{ form.icon }}</v-icon> -->
              </template>
            </FormFields>
          </v-form>

          <div
            class="headshot-wrapper"
            v-if="
              model_name === 'onboarding.request.hremployee.profile.picture'
            "
          >
            <Headshot />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseBtn from "@/components/tasks/BaseBtn";
import FormFields from "@/common/FormFields";
import Headshot from "@/components/tasks/Headshot";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const TasksHelper = createNamespacedHelpers("tasks");
const userInfoHelpers = createNamespacedHelpers("userInfo");
const HeadshotHelpers = createNamespacedHelpers("uploadHeadshot");
import { uploadedHeadshot } from "@/api";
export default {
  name: "PersonalInfo",
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
    },
    model_name: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseBtn,
    FormFields,
    Headshot,
  },
  data() {
    return {
      act_as_me: true,
      dialog: false,
      requestForm: [],
      validForm: false,
    };
  },
  created() {
    this.getLoading(false);
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  computed: {
    ...userInfoHelpers.mapGetters(["getUserGender"]),
    ...TasksHelper.mapGetters([
      "taskForm",
      "getUpdatedForm",
      "getRequestType",
      "countryList",
      "languagesList",
      "tShirtSizesList",
      "educationLevelList",
      "stateList",
    ]),
    ...HeadshotHelpers.mapGetters(["getIsCropped", "getCroppedImg"]),
  },
  methods: {
    ...TasksHelper.mapActions([
      "dispatchTaskForm",
      "updateTaskForm",
      // "getUserTasks",
      "dispatchCountryList",
      "dispatchUserTasksByType",
      "dispatchLanguagesList",
      "dispatchTShirtSizeList",
      "dispatchEducationLevelList",
      "getStateList",
    ]),
    ...TasksHelper.mapMutations([
      "setUpdatedForm",
      "initTaskForm",
      "setRequestType",
    ]),
    ...mapActions(["getLoading"]),
    ...HeadshotHelpers.mapMutations(["setIsCropped", "setImgSaved"]),
    ...HeadshotHelpers.mapActions(["dispatchClearImg"]),
    async onClickTask() {
      this.getLoading(true);
      let _type = this.model_name.split(".")[3];
      this.setRequestType(_type);
      if (this.model_name == "onboarding.request.hremployee.personal") {
        await this.dispatchTaskForm(this.id);
        await this.dispatchLanguagesList();
        await this.dispatchTShirtSizeList();
        await this.dispatchCountryList();
        await this.dispatchEducationLevelList();
        for (const form of this.taskForm) {
          if (form.name == "Home Address") {
            await this.getStateList(form.fields[0].value);
            break;
          }
        }
        this.requestForm = this.taskForm.map((section, index) => {
          section.multiple =
            section.fields.length == 1 ? this.multipleInARow(index) : false;
          section.fields.forEach((f) => {
            f.size = section.fields.length > 2 ? 3 : 12;
            f.required = true;
            switch (f.name) {
              case "middle_name":
                f.required = false;
                break;
              case "nick_name":
                f.required = false;
                break;
              case "country_id":
                f.list = this.countryList;
                f.action = "changeCountry";
                break;
              case "state_id":
                f.list = [];
                for (const id in this.stateList) {
                  f.list.push({
                    display_name: this.stateList[id],
                    id: parseInt(id),
                  });
                }
                break;
              case "address_2":
                f.required = false;
                break;
              case "language_ids":
                f.list = this.languagesList;
                break;
              case "tshirt_size":
                f.list = this.tShirtSizesList.filter(
                  (s) => s.gender == this.getUserGender
                );
                f.searchInput = null;
                break;
              case "education_level":
                f.list = [];
                for (const id in this.educationLevelList) {
                  f.list.push({
                    display_name: this.educationLevelList[id],
                    id: parseInt(id),
                  });
                }
                break;
              case "citizenships_ids":
                f.list = this.countryList;
                break;
              case "employee_pronoun":
                f.type = "many2one";
                f.label = "Select Pronoun";
                f.list = [
                  { display_name: "He / him", id: "he" },
                  { display_name: "She / her", id: "she" },
                  { display_name: "They / them", id: "they" },
                ];
                break;
              default:
                break;
            }
          });
          return section;
        });
      }
      this.getLoading(false);
    },
    multipleInARow(index) {
      let _multiple1 = false;
      let _multiple2 = false;
      if (index > 0) {
        let _previous = this.taskForm[index - 1].fields.length == 1;
        if (_previous) {
          _multiple1 = true;
        }
      }
      if (index < this.taskForm.length - 1) {
        let _next = this.taskForm[index + 1].fields.length == 1;
        if (_next) {
          _multiple2 = true;
        }
      }
      return _multiple1 || _multiple2;
    },

    async updateStates(_id_country) {
      this.getLoading(true);
      await this.getStateList(_id_country);
      let _list = [];
      for (const id in this.stateList) {
        _list.push({
          display_name: this.stateList[id],
          id: parseInt(id),
        });
      }
      for (let i = 0; i < this.requestForm.length; i++) {
        if (this.requestForm[i].name == "Home Address") {
          this.requestForm[i].fields[1].list = _list;
          this.requestForm[i].fields[1].value = 2; //Random value assigned to can update the list
          this.requestForm[i].fields[1].value = 0;
          break;
        }
      }
      this.getLoading(false);
    },

    closeDialog() {
      this.dispatchClearImg();
      this.dialog = false;
    },

    async saveDialog() {
      let { id, taskForm } = this;
      if (this.id && this.taskForm) {
        if (this.getRequestType == "profile") {
          if (this.getIsCropped && this.getCroppedImg) {
            this.getLoading(true);

            try {
              const result = await uploadedHeadshot(
                this.id,
                this.getCroppedImg
              );
              console.log("image_result==>", result);
              if (result.status == 200) {
                this.dialog = false;
                // await this.getUserTasks();//NOT FOUND ON tasksServiceAPI
                this.setIsCropped(false);
                this.setImgSaved(true);
                // this.getTasksFromAPI();
              }
            } catch (error) {
              console.log(error);
              this.dialog = true;
            } finally {
              this.getLoading(false);
            }
          }
        }
        if (this.getRequestType == "personal") {
          if (this.validForm) {
            this.getLoading(true);
            await this.updateTaskForm({ id, taskForm });
            if (this.getUpdatedForm) {
              this.initTaskForm();
              this.setUpdatedForm(false);
            }
            this.dialog = false;
            this.getLoading(false);
            this.$emit("closeUpdateTasks");
          } else {
            this.$refs.myForm.validate();
          }
        }
      }
    },
    async getTasksFromAPI() {
      // this.getLoading(true);
      await this.dispatchUserTasksByType();
      // this.getLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  height: 40px;
}
.btn-popup {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  text-transform: none;
  margin-top: 26px;
}

.v-dialog.dialog {
  margin: 80px;
  // height: calc(100% - 160px);
  border-radius: 30px;

  .v-card__title.headline {
    padding: 20px 30px;
    justify-content: space-between;
    background-color: #53a0de;

    .icon--close {
      margin-right: 8px;
    }
  }

  .card {
    height: 100%;
    overflow: hidden;

    .title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $white;
    }

    .form-wrapper {
      height: calc(100% - 64px);

      .popup-form-wrapper {
        padding: 20px 30px 10px 30px;
        background: white;
        display: flex;
        flex-wrap: wrap;
        gap: 20px 1.4%;
      }

      .headshot-wrapper {
        height: 100%;
      }

      .form {
        // margin-bottom: 24px;
        background: $light-blue-1;
        width: 100%;
        &.fit-form-25 {
          // width: fit-content;
          width: 23.9%;
          padding-bottom: 25px;
        }
        &.fit-form-50 {
          // width: fit-content;
          width: 49%;
          padding-bottom: 25px;
        }
      }

      .icon {
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .form {
    &.fit-form-25 {
      // width: fit-content;
      width: 49% !important;
    }
  }
}
@media screen and (max-width: 700px) {
  .v-dialog__content {
    ::v-deep {
      .v-dialog {
        margin: 0 10px !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .form {
    &.fit-form-25 {
      // width: fit-content;
      width: 100% !important;
    }
    &.fit-form-50 {
      // width: fit-content;
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 450px) {
  .v-card__title.headline {
    padding: 20px 10px !important;
    .icon--close {
      margin-right: 2px !important;
    }
  }
  .popup-form-wrapper {
    padding: 20px !important;
  }
}
</style>
