<template>
  <div>
    <!-- display badge below responsibility level -->
    <div v-if="data.dummy1.widget == 'tr_badge_widget'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex position-relative tempate_bg"
            v-bind="attrs"
            v-on="on"
            v-if="data.fields.find((x) => x.name == 'proficiency').value"
          >
            <div class="d-flex position-relative">
              <div
                :style="{
                  'border-left': `10px solid ${colorProficiency(
                    data.fields.find((x) => x.name == 'proficiency').value
                  )}`,
                }"
              ></div>
              <div class="template-img">
                <img src="../../assets/img/badgeIcon.png" />
              </div>
            </div>
            <div class="d-flex align-center">
              <div class="badge_text">
                <!-- {{ rowData.data.badge }} -->
                {{ proficiencyName(data.fields) }}
              </div>
            </div>
          </div>
          <p
            class="target-label"
            v-if="
              data.fields.find((x) => x.name == 'proficiency').value &&
              data.fields
                .find((x) => x.name == 'proficiency')
                .value.toLowerCase() !=
                data.fields.find((x) => x.name == 'target').value
            "
          >
            > {{ data.fields.find((x) => x.name == "target").value }}
          </p>
          <p
            class="target-label"
            v-if="
              !data.fields.find((x) => x.name == 'proficiency').value &&
              data.fields.find((x) => x.name == 'target').value
            "
          >
            > {{ data.fields.find((x) => x.name == "target").value }}
          </p>
        </template>
        <span
          v-html="data.fields.find((x) => x.name == 'tooltip').value"
        ></span>
      </v-tooltip>
    </div>

    <!-- display credits pill below responsibility level  -->
    <div v-else-if="data.dummy1.widget == 'tr_fulfillment_widget'">
      <div
        v-if="data.dummy1.fulfillmentID"
        class="tr-fulfillment-pill tr-fulfillment-pill-normal tr-fulfillment-badge-pill tr-fulfillment-pill-credit"
      >
        {{ data.dummy2.learningActivityCredits }} Credits
      </div>
    </div>
    <div v-else-if="data.dummy1.widget == 'tr_certificate_widget'">
      <div
        v-if="
          data.fields.find((x) => x.name == 'dummy_1').value.length != 0 &&
          data.dummy1.certificateLabel != false
        "
        :class="fulfillmentPillStyle()"
        class="tr-fulfillment-pill tr-fulfillment-badge-pill tr-fulfillment-pil-certificate"
      >
        {{ data.dummy1.certificateLabel }}
      </div>
    </div>

    <!-- Proficiency not required -->
    <div
      v-else-if="
        data.model == 'tactical.role.assignment.responsibility' &&
        !!data.children &&
        data.readinessChartOption.attrs.chartValues[1].value == 50 &&
        data.readinessChartOption.attrs.chartValues[1].color == '#808080'
      "
    >
      <chipComponent
        class="proficienct_not_required"
        :chipData="proficiencyNotrequired"
      >
      </chipComponent>
    </div>
    <div
      v-else-if="
        data.model == 'tactical.role.assignment.responsibility' &&
        !!data.children &&
        data.readinessChartOption.attrs.chartValues[1].color != '#808080'
      "
    >
      <chipComponent
        class="proficienct_not_required"
        :chipData="getBadgeChipData()"
      >
      </chipComponent>
    </div>

    <!-- empty -->
    <div v-else></div>
  </div>
</template>

<script>
import { PROFICIENCY } from "@/utils/constants.js";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import chipComponent from "@/common/chips";

const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  name: "Dummy1Widget",
  props: ["data", "actAsMe"],
  components: {
    chipComponent,
  },
  data() {
    return {
      act_as_me: true,
      proficiencyNotrequired: {
        textIsBold: false,
        image: "",
        color: "#F2F3F5",
        text: "Proficiency not required",
        isOutlined: false,
        textColor: "#000",
        close: false,
      },
    };
  },
  created() {
    this.act_as_me = this.actAsMe;
  },
  computed: {
    dateTimeFormatted() {
      let time;
      if (this.data.dummy1.options) {
        time =
          "Acknowledged on " +
          moment(
            this.data.fields.find((x) => x.name == "dummy_1").value
          ).format("DD-MMM-yyyy");
      }
      return time;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    colorProficiency(proficiency) {
      if (
        this.data.fields.find((x) => x.name == "is_super_expert").value == true
      ) {
        return PROFICIENCY["super_expert"].color;
      } else {
        let _color = PROFICIENCY[proficiency.toLowerCase()].color;
        return _color ? _color : "#000000";
      }
    },
    proficiencyName(data) {
      if (
        this.data.fields.find((x) => x.name == "is_super_expert").value == true
      ) {
        return "Super Expert";
      } else {
        return data.find((x) => x.name == "proficiency").value;
      }
    },
    fulfillmentPillStyle() {
      // this.$emit("getCredit", this.data.ID);
      if (
        this.data.fields.find((x) => x.name == "submitted_type").value ==
        "no_prior_knowledge"
      ) {
        return "tr-fulfillment-pill-normal";
      } else {
        return "tr-certificate-pill-prior";
      }
    },
    getBadgeChipData() {
      let ChipData = {
        // textIsBold: true,
        textColor: "black",
        id: this.data.ID,
        hasImageToolTip: true,
      };

      let badge = this.data.children?.fields.find(
        (x) => x.name == "proficiency"
      ).value;

      let target = this.data.children?.fields.find(
        (x) => x.name == "target"
      ).value;

      let is_super_expert = this.data.children?.fields.find(
        (x) => x.name == "is_super_expert"
      ).value;

      let expiration_date = this.data.children?.fields.find(
        (x) => x.name == "expiration_date"
      ).value;

      let days = moment(expiration_date).diff(moment(), "days");

      ChipData.image = require(days > 30
        ? "../../assets/img/expiration_date.png"
        : days <= 30
        ? "../../assets/img/expiration_date_less.png"
        : "../../assets/img/badgeIcon.png");

      ChipData.toolTip = ChipData.image.includes("expiration_date")
        ? `Badge will expire in ${days} days (${moment(expiration_date).format(
            "DD-MMM-YYYY"
          )})`
        : ChipData.image.includes("expiration_date_less")
        ? `Badge will expire in ${days} days (${moment(expiration_date).format(
            "DD-MMM-YYYY"
          )})`
        : "Badge does not have expiration date";

      if (badge == "Apprentice") {
        ChipData.text =
          target != "apprentice"
            ? `${badge} > ${
                target == "professional" ? "50%" : "33%"
              } to ${target}`
            : badge;
        ChipData.color = "#CFE4FF";
      } else if (badge == "Professional") {
        ChipData.text =
          target == "apprentice"
            ? `${badge} > ${target}`
            : target == "expert"
            ? `${badge} > 66% to ${target}`
            : badge;
        ChipData.color = "#E3FAE3";
      } else if (badge == "Expert") {
        ChipData.text = is_super_expert
          ? "Master"
          : target == "apprentice" || target == "professional"
          ? `${badge}  >  ${target}`
          : badge;
        ChipData.color = is_super_expert ? "#FFD5F5" : "#FCFCCA";
      } else {
        ChipData.text = `> 0% to ${target}`;
        ChipData.color = "black";
        ChipData.isOutlined = true;
      }

      return ChipData;
    },
  },
};
</script>

<style lang="scss" scoped>
.emptyMessage {
  flex-grow: 1;
  color: #ffffff;
  background: #e81313;
  padding: 4px;
  margin-bottom: 0;
  cursor: pointer;
  text-align: center;
}

.tempate_bg {
  width: max-content;
  align-items: center;
  // justify-content: flex-start;
  justify-content: space-between;
  background: #f2f3f5;
}

.template-img img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.template-img {
  padding-right: 20px;
}

.badge_text {
  padding-right: 10px;
  font-weight: bold;
  font-size: 13px;
  color: black;
}

.target-label {
  /* margin-top: 10px; */
  /* text-align: center; */
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}

// .addBadge {
//   font-size: 13px;
//   color: $blue;
//   margin: 8px 10px;
// }

.tr-fulfillment-pill-normal {
  background-color: #c3f7c8;
}

.tr-certificate-pill-prior {
  background-color: #ffd6f5;
}

.tr-fulfillment-badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0.9rem;
  border: 1px solid #000000;
  color: black;
}

.tr-fulfillment-pill {
  margin: 3px;
  font-size: 13px;
  font-weight: bold;
  width: max-content;
}

.tr-fulfillment-pill-credit::before {
  content: " "
    url(https://d2toxhhcnffe95.cloudfront.net/web_widget_tr_fulfillment/static/src/img/done_sm.png);
  display: inline-block;
  vertical-align: middle;
}

.tr-fulfillment-pil-certificate::before {
  content: " "
    url(https://d2toxhhcnffe95.cloudfront.net/web_widget_tr_certificate/static/src/img/certification_sm.png);
  display: inline-block;
  vertical-align: middle;
}

// .v-btn--icon.v-size--default {
//   height: 16px;
//   width: 16px;
// }
.alignText {
  text-align: center !important;
  color: #aba5a5;
}

.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}

.chip {
  width: max-content;
  padding: 0 20px 0 5px;
}

// .v-chip.v-size--large {
//   height: 40px !important;
//   font-size: 18px !important;
// }

.v-chip__content {
  font-size: 14px !important;
  width: 100%;
  margin: 0 auto;
}
</style>
