<template>
  <div class="na-value">
    <div
      class="na-value__number-container"
      :style="{ background: value.txtColor }"
    >
      <span class="na-text--value-number">{{ index + 1 }}</span>
    </div>
    <div class="na-value__content">
      <h3 class="na-text--value-header" :style="{ color: value.txtColor }">
        {{ value.display_name }}
      </h3>
      <p class="na-text--value">{{ value.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Value",
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.na-value {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 245px;
  padding: 0 30px;
  background: white;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.4);

  &__number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    // background: red;
    border-radius: 30px 0;

    .na-text--value-number {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }

  &__content {
    .na-text--value-header {
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-transform: capitalize;
      text-align: center;
    }

    .na-text--value {
      margin-bottom: 15px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #5d646d;
    }
  }
}
</style>
