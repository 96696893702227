export default {
  namespaced: true,
  state: {
    open: false,
    type: "error",
    message: "Something went wrong",
  },
  actions: {
    dispatchOpenAlert({ commit }, { type, message }) {
      let alertInfo = {
        type: type || "error",
        message: message || "Something went wrong",
      };
      commit("setOpen", alertInfo);
      setTimeout(() => {
        commit("setClose");
      }, 4000);
    },
    dispatchCloseAlert({ commit }) {
      commit("setClose");
    },
  },
  mutations: {
    setOpen(state, status) {
      state.open = true;
      state.type = status.type;
      state.message = status.message;
    },
    setClose(state) {
      state.open = false;
    },
  },
  getters: {
    open(state) {
      return state.open;
    },
    type(state) {
      return state.type;
    },
    message(state) {
      return state.message;
    },
  },
  // state: {
  //   alert: {
  //     open: false,
  //     type: "error",
  //     message: "Something went wrong",
  //   },
  // },
  // actions: {
  //   dispatchOpenAlert({ commit },{type, message}) {
  //     let alertInfo = {
  //       type: type || "error",
  //       message: message || "Something went wrong",
  //     };
  //     commit("setOpen", alertInfo);

  //   },
  //   dispatchCloseAlert({ commit }) {
  //     commit("setClose")
  //   }
  // },
  // mutations: {
  //   setOpen(state, status) {
  //     state.alert.open = true;
  //     state.alert.type = status.type;
  //     state.alert.message = status.message;
  //   },
  //   setClose(state) {
  //     state.alert.open = false;
  //   }
  // },
  // getters: {
  //   alert(state) {
  //     return state.alert;
  //   },
  // },
};
