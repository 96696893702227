<template>
  <div>
    <div class="employees-page">
      <div class="page_title">
        <div class="back-item" v-if="Object.values($route.query)[0]">
          <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
          <span
            class="back-item__text"
            @click="backToPages(Object.keys($route.query)[0])"
            >&nbsp;Back to {{ Object.keys($route.query)[0] }}</span
          >
        </div>
        <div v-else class="back-item">
          <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
          <span class="back-item__text" @click="backToPages('directory')"
            >&nbsp;Back to Directory</span
          >
        </div>
        <span class="txt_pageTitle">Employees</span>

        <span
          class="txt_subTitle"
          v-if="Object.keys($route.query)[0] == 'departments'"
          >{{ selectedDepartment }}</span
        >
        <span class="txt_subTitle" v-else>{{
          Object.values($route.query)[0]
        }}</span>
      </div>

      <div class="employee_search">
        <v-autocomplete
          v-model="searchEmployee"
          :items="searchEmployeesList"
          solo
          rounded
          flat
          hide-details
          :label="searchEmployee ? searchEmployee : 'Search Employees'"
          item-text="name"
          item-value="name"
          :menu-props="{ maxWidth: '300' }"
          @change="selectedEmployee()"
        >
          <!-- <template v-slot:selection="data">
            <v-chip
              class="selectedEmployeeInfo"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="gotoEmployeeDetail(data.item)"
              @click:close="removeEmployee()"
            >
              <div
                class="selectedImg"
                :style="{
                  backgroundImage: 'url(' + data.item.image_128_b64 + ')',
                }"
              ></div>
              <div class="selectedTxtName">
                <span class="txt_name">{{ data.item.name }}</span>
                <span class="txt_title">{{ data.item.job_title }}</span>
              </div>
            </v-chip>
          </template> -->
          <template v-slot:item="data">
            <v-list-item-avatar rounded>
              <!-- <img :src="data.item.image_128_b64" /> -->
              <div
                class="selectedImg"
                :style="{
                  backgroundImage: 'url(' + data.item.image_128 + ')',
                }"
              ></div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.job_title"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="data.item.employee_pronoun"
                v-html="data.item.employee_pronoun"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div class="employee_list">
        <div
          v-for="employee_list in dividedEmployeeNameJobList"
          :key="employee_list.id"
          class="list_container"
        >
          <div
            v-for="employee in employee_list"
            :key="employee.id"
            class="list_item"
          >
            <div v-if="employee.firstLetter" class="txt_firstLetter">
              {{ employee.firstLetter }}
            </div>
            <div
              v-else
              class="employee_info"
              @click="gotoEmployeeDetail(employee)"
            >
              <div
                class="employee_img"
                :style="{
                  backgroundImage: 'url(' + employee.image_128 + ')',
                }"
              ></div>
              <div class="employee_name_job">
                <span class="txt_name">{{ employee.name }}</span>
                <span class="txt_job">{{ employee.job_title }}</span>
                <span class="txt_job" v-if="employee.employee_pronoun">{{
                  employee.employee_pronoun
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);
export default {
  name: "Employees",
  data() {
    return {
      searchEmployee: "",
      dividedCount: 0,
      firstLetterWithEmployeeNameJobList: [],
      dividedEmployeeNameJobList: [],
      searchEmployeesList: [],
      filteredDepartmentList: [],
    };
  },
  created() {
    this.getLoading(false);
    this.filterEmployeesList();
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters([
      "employeeNameJobList",
      "selectedDepartment",
      "departmentList",
    ]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    filterEmployeesList() {
      if (
        this.$route.name == "Employees" &&
        Object.keys(this.$route.query).length == 0
      ) {
        this.getEmployeeList(this.employeeNameJobList);
      } else {
        if (Object.keys(this.$route.query)[0] == "locations") {
          let filteredCountry = Object.values(this.$route.query)[0];
          let filteredCountryList = [];
          if (Object.keys(this.$route.query)[1] == "departments") {
            let filteredDepartment = Object.values(this.$route.query)[1];
            filteredDepartment = JSON.parse(filteredDepartment);
            filteredCountryList = this.employeeNameJobList.filter(function (
              employee
            ) {
              return (
                filteredCountry == employee.country_name &&
                this.indexOf(employee.department_name) != -1
              );
            },
            filteredDepartment);
          } else {
            filteredCountryList = this.employeeNameJobList.filter(
              (employee) => filteredCountry == employee.country_name
            );
          }
          this.getEmployeeList(filteredCountryList);
        } else if (Object.keys(this.$route.query)[0] == "departments") {
          let departmentAndChildren = null;
          if (
            Object.keys(this.$route.query)[1] == "is_parent" &&
            Object.values(this.$route.query)[1] == "true"
          ) {
            departmentAndChildren = this.departmentList.find(
              (d) => d.name == this.selectedDepartment
            );
          }
          this.findEmployees(departmentAndChildren);
          this.getEmployeeList(this.filteredDepartmentList);
        }
      }
    },
    findEmployees(departmentAndChildren) {
      let departmentSelected =
        departmentAndChildren?.name || this.selectedDepartment;
      this.employeeNameJobList.forEach((employee) => {
        if (departmentSelected == employee.department_name) {
          this.filteredDepartmentList.push(employee);
        }
      });
      if (departmentAndChildren?.child_ids?.length) {
        departmentAndChildren.child_ids.forEach((element) => {
          this.findEmployees(element);
        });
      }
    },
    selectedEmployee() {
      let _searchEmployeeID = "";
      this.searchEmployeesList.forEach((employee) => {
        if (employee.name == this.searchEmployee) {
          _searchEmployeeID = employee.id;
        }
      });
      let url = `/employees/profile?employee=${_searchEmployeeID}`;
      this.$router.push(url);
    },
    getEmployeeList(_employeesList) {
      _employeesList.sort(function (a, b) {
        let a_name = a.name;
        let b_name = b.name;
        a_name = a_name.split("");
        b_name = b_name.split("");

        if (a_name[0] == "(") {
          a_name = a_name[1];
        } else {
          a_name = a_name[0];
        }
        if (b_name[0] == "(") {
          b_name = b_name[1];
        } else {
          b_name = b_name[0];
        }
        a.first_letter = a_name.toUpperCase();
        b.first_letter = b_name.toUpperCase();
        return a.first_letter.localeCompare(b.first_letter);
      });
      this.searchEmployeesList = _employeesList;
      let firstLetter = "";
      for (let index = 0; index < _employeesList.length; index++) {
        const employee = _employeesList[index];
        let _name = employee.name;
        _name = _name.split("");
        if (_name[0] == "(") {
          if (_name[1].toUpperCase() == firstLetter.toUpperCase()) {
            this.firstLetterWithEmployeeNameJobList.push(employee);
          } else {
            firstLetter = _name[1].toUpperCase();
            this.firstLetterWithEmployeeNameJobList.push(
              { firstLetter: firstLetter },
              employee
            );
          }
        } else {
          if (_name[0].toUpperCase() == firstLetter.toUpperCase()) {
            this.firstLetterWithEmployeeNameJobList.push(employee);
          } else {
            firstLetter = _name[0].toUpperCase();
            this.firstLetterWithEmployeeNameJobList.push(
              { firstLetter: firstLetter },
              employee
            );
          }
        }

        /*if (firstLetter != employee.first_letter) {
          firstLetter = employee.first_letter;
          this.firstLetterWithEmployeeNameJobList.push(
            { firstLetter: firstLetter },
            employee
          );
        } else {
          this.firstLetterWithEmployeeNameJobList.push(employee);
        }*/
      }
      // this.dividedCount = Math.ceil(
      //   this.firstLetterWithEmployeeNameJobList.length / 4
      // );

      let listLength = this.searchEmployeesList.length;

      if (listLength <= 6) {
        this.dividedCount = 1;
        this.dividedEmployeeNameJobList = Array.from(
          Array(1),
          () => new Array(0)
        );
      } else if (listLength > 6 && listLength <= 15) {
        this.dividedCount = 2;
        this.dividedEmployeeNameJobList = Array.from(
          Array(2),
          () => new Array(0)
        );
      } else {
        this.dividedCount = 4;
        this.dividedEmployeeNameJobList = Array.from(
          Array(4),
          () => new Array(0)
        );
      }

      for (
        let index = 0;
        index < this.dividedEmployeeNameJobList.length;
        index++
      ) {
        // const dividedEmployee = this.dividedEmployeeNameJobList[index];
        for (
          let mIndex = 0;
          mIndex < this.firstLetterWithEmployeeNameJobList.length;
          mIndex++
        ) {
          const employee = this.firstLetterWithEmployeeNameJobList[mIndex];
          const divListCount =
            this.firstLetterWithEmployeeNameJobList.length / this.dividedCount;
          if (
            divListCount * index <= mIndex &&
            mIndex < divListCount * (index + 1)
          ) {
            this.dividedEmployeeNameJobList[index].push(employee);
          }
        }
      }
    },
    backToPages(_pageName) {
      let url = "";
      if (_pageName == "departments") {
        url = `/departments`;
      } else if (_pageName == "locations") {
        url = `/locations`;
      } else {
        url = `/home/directory`;
      }
      this.$router.push(url);
    },
    gotoEmployeeDetail(_employee) {
      let url = `/employees/profile?employee=${_employee.id}`;
      this.$router.push(url);
    },

    removeEmployee() {
      this.searchEmployee = "";
    },
  },
};
</script>
<style lang="scss">
.selectedImg {
  min-width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #cce3f5;
  border-radius: 12px;
}
.employees-page {
  margin: 30px 80px;

  .page_title {
    display: flex;
    flex-flow: column;

    font-style: normal;
    font-weight: bold;
    color: #323a44;
    margin: 10px 0px;
    .txt_pageTitle {
      font-size: 24px;
      margin: 5px 0px;
      text-transform: capitalize;
    }
    .back-item {
      margin-top: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      &__img {
        width: 0.3rem;
        height: 0.55rem;
        margin-right: 5px;
      }
      &__text {
        margin-left: 5px;
        font-style: normal;
        font-family: Gilroy;
        font-weight: 600;
        font-size: 1rem;
        color: #323a44;
        text-transform: capitalize;
      }
    }
    .txt_subTitle {
      font-size: 16px;
      color: #53a0de;
      margin: 5px 0px;
      text-transform: capitalize;
    }
  }
  .employee_search {
    margin: 30px 0px;
    .v-text-field--solo > .v-input__control > .v-input__slot {
      background: white;
      width: 300px;
    }
    .v-text-field--rounded > .v-input__control > .v-input__slot {
      padding: 0 10px;
    }
    .selectedEmployeeInfo {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      min-width: 350px;
      background: #f6f6fb;
      padding: 0px;
      .selectedImg {
        min-width: 40px;
        height: 40px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #cce3f5;
        border-radius: 12px;
      }
      .selectedTxtName {
        display: flex;
        flex-flow: column;
        margin-left: 10px;
        margin-right: 5px;
        color: #323a44;
        .txt_name {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
        }
        .txt_title {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
  .employee_list {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    .list_container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      border-radius: 30px;
      margin-right: 40px;
      background: white;
      padding: 20px 40px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      .list_item {
        width: 100%;
      }

      .txt_firstLetter {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        // line-height: 22px;
        text-transform: uppercase;
        color: #53a0de;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
      }
      //   .txt_firstLetter:first-child {
      //     padding-top: 0px;
      //   }
      .employee_info {
        margin: 10px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .employee_img {
          border-radius: 12px;
          min-width: 40px;
          height: 40px;
          background: #dfebf5;
          margin-right: 8px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .employee_name_job {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: flex-start;
          margin-left: 8px;
          .txt_name {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            color: #323a44;
          }
          .txt_job {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-transform: capitalize;
            color: #a1a4ac;
          }
        }
      }
    }
    .list_container:last-child {
      margin-right: 0px;
    }
  }
}
@media screen and (max-width: 1650px) {
  .employee_list {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 1280px) {
  .employee_list {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 900px) {
  .employees-page {
    margin: 30px 30px 50px 30px;
    .employee_list {
      grid-template-columns: 1fr !important;
      grid-gap: 20px;
    }
  }
}
</style>
