import { render, staticRenderFns } from "./YourExpertBadges.vue?vue&type=template&id=b0d24464&"
import script from "./YourExpertBadges.vue?vue&type=script&lang=js&"
export * from "./YourExpertBadges.vue?vue&type=script&lang=js&"
import style0 from "./YourExpertBadges.vue?vue&type=style&index=0&id=b0d24464&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports