<template>
  <div class="html-content">
    <div class="html-content-text" v-html="slideHTMLDetail"></div>
    <div class="btn-html-content-checked" v-if="!slideChecked">
      <span class="text-checked" @click="checked_HTMLContent"> Checked </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const takeCourseHelper = createNamespacedHelpers("takeCourse");

export default {
  computed: {
    ...takeCourseHelper.mapGetters(["slideHTMLDetail", "slideChecked"]),
  },
  methods: {
    checked_HTMLContent() {
      this.$emit("completeSlide");
    },
  },
};
</script>
