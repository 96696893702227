<template>
  <div class="bio-container">
    <img :src="image" class="bio-img" width="40" height="40" />
    <div class="bio-data">
      <span class="bio-name">{{ name }}</span>
      <span class="bio-department">{{ department }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
    },
    department: {
      type: String,
    },
    image: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.bio-container {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  .bio-img {
    border-radius: 10px;
    object-fit: cover;
  }
  .bio-data {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    .bio-name {
      font-style: normal;
      font-weight: 400;
      line-height: 19.41px;
      font-size: 16px;
      color: $black;
    }
    .bio-department {
      font-style: normal;
      font-weight: 400;
      line-height: 14.56px;
      font-size: 12px;
      color: $light-gray-5;
    }
  }
}
</style>
