<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">
              {{ pronounEmployee }} Assignments
            </h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <!-- <div class="not-filter" v-show="!notFilter">
                <div class="not-filter__img-fluid"></div>
                <div class="not-filter__text">
                  <p class="not-filter-subtitle">
                    Please, try to choose other filters or delete some of them.
                  </p>
                </div>
              </div> -->
              <div class="eventsTable">
                <TableTemplate
                  :roles="rows"
                  :column_names="role_cols"
                  :disabled_button="not_current_user"
                />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
                <!-- <div class="not-found__text"></div> -->
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableTemplate from "@/components/assignments/TableTemplate";
import { createNamespacedHelpers } from "vuex";
import { tableRoles } from "@/utils/table_your_jobs.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);

export default {
  name: "YourAssignments",
  components: {
    TableTemplate,
  },
  data() {
    return {
      pronounEmployee: "Your",
      not_current_user: false,
      filters: {
        status: [],
      },
      show: true,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      setRoleRows: [],
      role_cols: [
        [
          "Role",
          "Effective Date",
          // "Relieve Date",
          // "Status",
          "Responsibilities",
          "Readiness",
          "Accepted On",
        ],
        [
          "Responsibility",
          "Current Version",
          "Required Certifications",
          "Readiness",
          "Acknowledged On",
        ],
        ["Tactical Roles having this responsibility"],
      ],
      rows: [],
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    this.getLoading(true);
    await this.getDataAPI();
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataAPI() {
      if (this.$route.name == "Your Assignments") {
        this.rows = await tableRoles();
      } else if (this.$route.name == "Employee Roles") {
        this.pronounEmployee = "";
        this.not_current_user = true;
        const id_employee = Object.values(this.$route.params)[0];
        const employee_data = this.employeeNameJobList.find(
          (e) => e.id == id_employee
        );
        this.pronounEmployee = employee_data.name;
        this.rows = await tableRoles(id_employee);
      }
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
