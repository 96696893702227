import axios from "./_axios";
import { API } from "./apiEndPoints";

// export const tasksService = {
//   getUserTasks,
//   getTaskForm,
//   updateTaskForm,
//   getCountryList,
//   getStateList,
// };
export const getUserTasksByType = () => {
  let result = axios()({
    url: API.GETTASKFORM,
    method: "patch",
    params: { action: "get_user_tasks" },
  });
  return result;
};

export const getRepresentativeTasks = () => {
  let result = axios()({
    url: API.GETTASKS,
    method: "patch",
    params: { action: "get_onboarding_representative" },
  });
  return result;
};

export const getTaskForm = (taskId) => {
  let result = axios()({
    url: API.GETTASKFORM,
    method: "patch",
    params: {
      action: "get_sections_definition",
      task_id: taskId,
    },
  });
  return result;
};
export const approveRejectCourse = (_task_id, _approve, _reason) => {
  let approve_text = _approve ? "approve" : "reject";
  let _params = {
    action: "approve_reject_course",
    task_id: _task_id,
    to_do: approve_text,
  };
  if (_reason) {
    _params.reason = _reason;
  }
  let result = axios()({
    url: `${API.GETTASKFORM}/`,
    method: "patch",
    params: _params,
  });
  return result;
};
export const getCountryList = () => {
  let result = axios()({
    url: API.GETCOUNTRYLIST,
    method: "get",
  });
  return result;
};
export const getStateList = (countryId) => {
  let result = axios()({
    url: API.GETCOUNTRYSTATELIST,
    method: "patch",
    params: {
      action: "get_country_states",
      country_id: countryId,
    },
  });
  return result;
};

export const getLanguages = () => {
  let result = axios()({
    method: "get",
    url: API.GETLANGUAGES,
    params: { order: "name asc" },
  });
  return result;
};

export const getTShirtSizes = () => {
  let result = axios()({
    method: "get",
    url: API.GETTSHIRTSIZE,
    params: { fields: "id,display_name,gender" },
  });
  return result;
};
export const getEducationLevels = () => {
  let result = axios()({
    method: "patch",
    url: API.GETEDUCATIONLEVELS,
    params: { action: "get_employee_education_level" },
  });
  return result;
};

export const updateTaskForm = (taskId, taskForm) => {
  const vals = getValues(taskForm);
  let result = axios()({
    url: API.GETTASKFORM,
    method: "patch",
    params: {
      action: "save_info_request",
      task_id: taskId,
      vals,
    },
  });
  return result;
};

function getValues(taskForm) {
  const arr = taskForm
    .map((form) => form.fields)
    .flat()
    .map((obj) => {
      return { [obj.name]: obj.value.toString() };
    });

  let b = {};
  arr.forEach((c) => {
    b = { ...b, ...c };
  });
  return JSON.stringify(b);
}
