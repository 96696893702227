<template>
  <div>
    <div v-if="barData?.readinessChartOption.attrs.chartValues">
      <div
        class="progress progress-stacked"
        :style="{
          'margin-left': `${
            barData?.readinessChartOption.attrs.options.leftPadding * 17
          }px`,
          'margin-right': '15px',
          height: `${barData?.readinessChartOption.attrs.options.height * 5}px`,
          background: barData?.isFromManageOthers ? 'white' : '#e9ecef',
        }"
        @mouseenter="toggleDefault"
        @mouseleave="toggleDefault"
        :id="'link' + barData?.ID"
      >
        <div
          class="progress-bar"
          v-for="(bar, i) in barData?.readinessChartOption.attrs.chartValues"
          :key="i"
          :style="{
            // 'color': bar.color,
            background: bar.color,
            width: `${bar.value}%`,
            height: '15px',
          }"
        ></div>
        <DxTooltip
          v-if="barData?.readinessChartOption.attrs.label != ''"
          :visible="defaultVisible"
          :hide-on-outside-click="false"
          :target="'#link' + barData?.ID"
        >
          <div v-html="barData?.readinessChartOption.attrs.label"></div>
        </DxTooltip>
      </div>
    </div>
    <div v-else>
      <div
        class="progress progress-stacked"
        :style="{
          'margin-left': `${
            barData?.readinessChartOption.attrs.options.leftPadding * 17
          }px`,
          'margin-right': '15px',
          height: `${barData?.readinessChartOption.attrs.options.height * 5}px`,
          background: barData?.isFromManageOthers ? 'white' : '#e9ecef',
        }"
        @mouseenter="toggleDefault"
        @mouseleave="toggleDefault"
        :id="'link' + barData?.ID"
      >
        <div
          class="progress-bar"
          :style="{
            // 'color': bar.color,
            background: barData?.readinessChartOption.attrs.color,
            width: `${barData?.employeeReadinessValue}%`,
            height: '15px',
          }"
        ></div>
        <DxTooltip
          :visible="defaultVisible"
          :hide-on-outside-click="false"
          :target="'#link' + barData?.ID"
        >
          <div v-html="barData?.readinessChartOption.attrs.label"></div>
        </DxTooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  props: ["barData"],
  components: {
    DxTooltip,
  },
  data() {
    return {
      defaultVisible: false,
    };
  },
  created() {
    // console.log(JSON.parse(JSON.stringify(this.barData)));
  },
  methods: {
    toggleDefault() {
      this.defaultVisible = !this.defaultVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
