<template>
  <div>
    {{ calculateDeadlineText(deadline) }}
  </div>
</template>

<script>
export default {
  props: {
    deadline: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    calculateDeadlineText(deadline) {
      let text = "No Deadline";
      if (deadline) {
        const today = new Date();
        const diffTime = Math.abs(Date.parse(today) - Date.parse(deadline));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (Date.parse(today) > Date.parse(deadline)) {
          text = `Overdue task`;
        } else if (diffDays <= 7) {
          text = `${diffDays} days to deadline`;
        } else if (diffDays >= 8) {
          text = `${diffDays} days to deadline`;
        }
      }
      return text;
    },
  },
};
</script>
