<template>
  <div class="reSetPassword-info">
    <div class="reSet_asking">Password Reset</div>
    <div class="reSet_enterEmail_text">
      Enter your new password for your account.
    </div>

    <el-alert
      class="alert-container is-light"
      v-if="alert.type"
      :title="alert.message"
      @close="alert = {}"
      :type="alert.type"
    >
    </el-alert>
    <el-form
      class="reSetPassword-form"
      :model="model"
      ref="form"
      @submit.native.prevent="reSetPassword"
    >
      <el-form-item prop="password" label="New Password">
        <el-input
          v-model="model.newPW"
          placeholder="New Password"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password" label="Confirm New Password">
        <el-input
          v-model="model.conNewPW"
          placeholder="Confirm New Password"
          type="password"
        ></el-input>
      </el-form-item>

      <el-form-item class="reSetPassword_button">
        <el-button
          :loading="loading"
          class="reSet_button"
          type="primary"
          native-type="submit"
          block
          >Change password</el-button
        >
        <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
        <!-- <v-dialog v-model="dialog" persistent max-width="450">
          <template v-slot:activator="{ on, attrs }">
            <el-button
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :loading="loading"
              class="reSet_button"
              native-type="submit"
              @click.stop="checkingValidate"
            >
              Change password
            </el-button>
          </template>
          <v-card>
            <v-card-title class="headline">
              Use Google's location service?
            </v-card-title>
            <v-card-text
              >Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are
              running.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" text @click="dialog = false">
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
          
        </v-dialog> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { change_Password } from "@/api";
import Simplert from "./Simplert.vue";
export default {
  name: "ChangePasswordForm",
  components: { Simplert },
  data() {
    return {
      model: {
        newPW: "",
        conNewPW: "",
      },
      loading: false,
      alert: {},
      //   dialog: false,
    };
  },
  methods: {
    async reSetPassword() {
      this.closeError();
      try {
        this.loading = true;
        let alert = {
          type: "",
          message: "",
        };
        if (this.model.newPW.length < 6 || this.model.newPW == "") {
          alert.type = "error";
          alert.message = "Password must be at least 6 characters long.";
          this.showError(alert);
          this.loading = false;
          return;
        } else {
          if (this.model.newPW != this.model.conNewPW) {
            alert.type = "error";
            alert.message = "Passwords don't match. Try again.";
            this.showError(alert);
            this.loading = false;
            return;
          }
        }
        let reset_token = this.$route.query.token;
        let reset_company_id = this.$route.query.company_id;
        let result = await change_Password(
          reset_token,
          this.model.newPW,
          reset_company_id
        );
        if (result.status == 200) {
          let obj = {
            title: "Password Reset",
            message: "Success! Please continue to the login page",
            type: "success",
            onClose: this.onLogin,
            disableOverlayClick: true,
          };
          this.$refs.simplert.openSimplert(obj);
          this.loading = false;
        }
      } catch (error) {
        let alert = {
          type: "error",
          message: "",
        };
        if (!error) alert.message = "Please enter the correct Password";

        if (error.response) {
          alert.message = error.response.data.message + "...Please try again";
        }
        this.showError(alert);
        this.loading = false;
      }
    },
    onLogin() {
      let url = `/login`;
      this.$router.push(url);
    },
    showError(data = {}) {
      this.alert = data;
    },
    closeError() {
      let alert = {
        type: "",
        message: "",
      };
      this.showError(alert);
    },
  },
};
</script>

<style lang="scss">
.reSetPassword-info {
  font-family: Gilroy;
  font-style: normal;
  margin-left: 9.9375rem;
  margin-top: 3.125rem;
  .reSet_asking {
    font-size: 1.375rem;
    color: #323a44;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
  .reSet_enterEmail_text {
    font-size: 1rem;
    color: #323a44;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }
  .el-alert--success.is-light {
    background-color: #bee9e8;
    color: black;
    max-width: 20rem;
  }
  .el-alert--error.is-light {
    width: 20rem;
  }
  .el-alert.is-light .el-alert__closebtn {
    color: #ff8a5b;
  }
  .reSetPassword-form {
    width: 20rem;
    padding-top: 1.875rem;
    .el-input__inner {
      background: #f6f6fb !important;
      //   background-color: #f2f2f2 !important;
      border-radius: 1.875rem;
      border: 1px solid #f6f6fb;
      height: 3rem;
      margin-bottom: 0.625rem;
      color: #323a44 !important;
    }
    .reSetPassword_button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
      vertical-align: middle;
      text-align: center;
      .reSet_button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.4rem;
        width: 15rem;
        height: 3rem;
        text-align: center;
        font-size: 1rem;
        border-radius: 0.875rem;
      }
    }
  }
  .el-form-item__error {
    padding-top: 0 !important;
  }
  .backLogin {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    cursor: pointer;
    p {
      font-size: 1rem;
      color: #323a44;
      font-weight: 600;
    }

    p:hover {
      color: #53a0de;
    }
  }
}
@media screen and (min-width: 959px) and (max-width: 1723px) {
  .reSetPassword-info {
    margin-left: 3.75rem !important;
    margin-right: 1rem !important;
  }
}
@media screen and (max-width: 575px) {
  .reSetPassword-info {
    text-align: center;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    .alert-container.is-light {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .el-form.reSetPassword-form {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
</style>
