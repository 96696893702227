<template>
  <div>
    <div class="table-content1">
      <table>
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.id">
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td v-for="col in cols" :key="col.id">
              <span>{{ row[col] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getTacticalRole } from "@/api";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
export default {
  name: "ThirdGrid",
  props: ["cols", "role_ids"],
  data() {
    return {
      rows: [],
      selectedRow: {},
      progress_bar_color: "#A7D42E",
      menuContentWidth: 760,
      menu_position_y: 0,
      menu_position_x: 0,
      dialog: false,
    };
  },
  created() {
    this.initialMenuList();
  },
  methods: {
    async initialMenuList() {
      for (const [index, role_id] of this.role_ids.entries()) {
        const result_role = await getTacticalRole(role_id);
        const role_name =
          result_role.data.data.response.data[0].role_id[0].name;
        const role_row = {
          INDEX: `${role_id}+${index}`,
          "Tactical Roles having this responsibility": role_name,
        };
        this.rows.push(role_row);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.space {
  padding-left: 50px;
}
.table-content1 {
  width: 100%;
  height: auto;

  table {
    width: 100%;
    height: auto;
    border-collapse: separate;
    border-spacing: 0;
    // padding-bottom: 50px;
  }

  .tr-click {
    cursor: pointer;
  }

  .selectedRow {
    background-color: #f6f6fb;
  }
  // table tr td.txt_orange {
  //   color: #f2994a;
  // }
  // table tr td.txt_orange + td {
  //   color: #f2994a;
  // }
  // table tr td.txt_red {
  //   color: #ff6c6c;
  // }
  // table tr td.txt_red + td {
  //   color: #ff6c6c;
  // }

  table tr th,
  table tr td {
    border-bottom: 0.0625rem solid #e0e0e7;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1rem;
    color: #323a44;
    padding-top: 16px;
    padding-left: 50px;
    padding-bottom: 16px;
    // text-transform: capitalize;
    padding-right: 10px;
    background-color: $white;
  }
  table tr th {
    border-top: 0.0625rem solid #e0e0e7;
    text-transform: capitalize;
  }

  table tr th:first-child,
  table tr td:first-child {
    text-transform: none;
  }
  table tr th:first-child {
    text-transform: capitalize;
  }
  table tr th {
    background: $white;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 0.875rem;
    color: #53a0de;
  }
}
.mobile_events {
  display: none;
}
.menu-content {
  padding: 40px;
  display: flex;
  flex-flow: column;
  .events-detail {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .detail-1 {
      display: flex;
      flex-flow: row;
      .img-event {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .txt_type {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          margin-bottom: 12px;
          text-transform: capitalize;
        }
        .txt_credits {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          color: #53a0de;
          padding: 0px 10px;
          border: solid 1px #53a0de;
          border-radius: 15px;
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 360px;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
    .txt_gotoEvent {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 15px 40px;
      border-radius: 30px;
      background-color: #a7d42e;
      border: solid 1px #a7d42e;
      cursor: pointer;
    }
  }
}
.dialog-content {
  padding: 24px 30px;
  display: flex;
  flex-flow: column;
  .events-detail {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 70px;
    .detail-1 {
      display: flex;
      flex-flow: column;

      .img-event {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .type_credits {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          .txt_type {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #323a44;

            margin-right: 16px;
            text-transform: capitalize;
          }
          .txt_credits {
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            color: #53a0de;
            padding: 0px 17px;
            border: solid 1px #53a0de;
            border-radius: 15px;
          }
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      margin-top: 25px;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
    .txt_gotoEvent {
      margin-top: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 15px 40px;
      border-radius: 30px;
      background-color: #a7d42e;
      border: solid 1px #a7d42e;
      cursor: pointer;
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 100%;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
  }
}
</style>
