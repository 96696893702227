<template>
  <v-container class="container--fluid fill-height pa-0 ma-0">
    <v-row class="justify-center ma-0 fill-height">
      <v-col
        md="5"
        :style="{
          backgroundImage: 'url(' + image + ')',
        }"
        class="cropper-wrapper pa-0"
      >
        <cropper
          v-show="!image"
          ref="cropper"
          class="cropper"
          @change="onChange"
          :stencil-props="{
            aspectRatio: 1,
            handlers: {
              eastNorth: true,
              north: false,
              westNorth: true,
              west: false,
              westSouth: true,
              south: false,
              eastSouth: true,
              east: false,
            },
            handlersClasses: {
              default: 'handler',
              hover: 'handler--hover',
              eastNorth: 'handler--east-north',
              westNorth: 'handler--west-north',
              westSouth: 'handler--west-south',
              eastSouth: 'handler--east-south',
            },
            linesClasses: {
              default: 'line',
            },
          }"
          :src="file"
          backgroundClass="background"
          default-boundaries="fit"
        />
        <div class="btn-group" v-if="!preview">
          <button class="btn" @click="cancel">
            <div class="btn-text">Cancel</div>
          </button>
          <button class="btn" @click="crop">
            <div class="btn-text">
              Crop
              <v-icon>$crop</v-icon>
            </div>
          </button>
          <button class="btn" @click="zoomOut">
            <div class="btn-text">
              <v-icon>$zoomOut</v-icon>
            </div>
          </button>
          <button class="btn btn--zoom">
            <div class="btn-text">Zoom</div>
          </button>
          <button class="btn" @click="zoomIn">
            <div class="btn-text"><v-icon>$zoomIn</v-icon></div>
          </button>
          <button class="btn" @click="onDone">
            <div class="btn-text">Done</div>
          </button>
        </div>

        <div class="btn-group" v-if="preview">
          <button class="btn btn--back" @click="back">
            <div class="btn-text">Back</div>
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { createNamespacedHelpers } from "vuex";
const HeadshotHelpers = createNamespacedHelpers("uploadHeadshot");
import "vue-advanced-cropper/dist/style.css";
export default {
  props: {
    file: {
      required: true,
    },
  },
  components: {
    Cropper,
  },
  data() {
    return {
      image: null,
      preview: false,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
    };
  },
  methods: {
    ...HeadshotHelpers.mapActions(["getImg"]),
    ...HeadshotHelpers.mapMutations(["setIsCropped"]),
    onChange({ coordinates }) {
      this.coordinates = coordinates;
    },
    zoomIn() {
      this.$refs.cropper.zoom(2);
    },
    zoomOut() {
      this.$refs.cropper.zoom(0.5);
    },
    cancel() {
      this.image = null;
      this.$emit("cancel");
    },
    crop() {
      if (this.image) {
        this.image = null;
        this.setIsCropped(false);
      } else {
        const { canvas } = this.$refs.cropper.getResult();
        this.image = canvas.toDataURL();
      }
    },
    back() {
      this.image = null;
      this.setIsCropped(false);
      this.preview = false;
    },
    onDone() {
      try {
        const { canvas } = this.$refs.cropper.getResult();
        this.image = canvas.toDataURL();
        // let crop_img = this.image.split(",")[1];
        this.getImg(this.image);
        this.preview = true;
      } catch (e) {
        console.error(e);
        let size_exeeded = e.toString();
        if (
          size_exeeded.includes(
            "Setting the value of 'vuex' exceeded the quota."
          )
        ) {
          alert("Image size is too big to upload");
          this.image = null;
          this.$emit("cancel");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container.fill-height {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.cropper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  border: 1px dashed rgba(50, 58, 68, 0.2);
  border-radius: 30px;
  background: rgba(50, 58, 68, 0.05);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .btn-group {
    display: flex;
    position: absolute;
    bottom: 20px;
    border-radius: 10px;
    background: #323a44;
    opacity: 0.9;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      outline: none;

      font-size: 14px !important;
      font-weight: 600;
      line-height: 17px !important;

      &.btn--zoom {
        cursor: default;
        .btn-text {
          margin: 15px 0;
        }
      }

      .btn-text {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        margin: 15px;
        color: $white;
        opacity: 0.7;
      }

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
      }

      &:last-child {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        .btn-text {
          color: #53a0de;
          opacity: 1;
        }
      }

      &.btn--back {
        border-left: none;
        border-right: none;
      }
    }
  }
}
::v-deep {
  .line {
    border-style: dashed;
    border-color: #a7d42e;
  }

  .handler--west-north,
  .handler--east-north {
    border-top: 2px solid $white;
    top: 14px;
  }

  .handler--west-north {
    border-left: 2px solid $white;
    left: 14px;
  }

  .handler--east-north {
    border-right: 2px solid $white;
    right: 14px;
  }

  .handler--west-south,
  .handler--east-south {
    border-bottom: 2px solid $white;
    bottom: 14px;
  }

  .handler--west-south {
    border-left: 2px solid $white;
    left: 14px;
  }

  .handler--east-south {
    border-right: 2px solid $white;
    right: 14px;
  }

  .handler--east-north,
  .handler--west-north,
  .handler--west-south,
  .handler--east-south {
    width: 20px;
    height: 20px;
    background: none;
    position: absolute;
    box-sizing: border-box;
  }
}
</style>
