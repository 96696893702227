import axios from "./_axios";
import { API } from "./apiEndPoints";

export const directoryEmployeeList = () => {
  let result = axios()({
    url: API.DIRECTORYEMPLOYEE,
    method: "get",
  });
  return result;
};

export const directoryEmployeeDetail = (_id) => {
  let result = axios()({
    url: `${API.DIRECTORYEMPLOYEE}/${_id}`,
    method: "get",
  });
  return result;
};

export const departmentHierarchy = () => {
  let result = axios()({
    url: API.DEPARTMENTHIERARCHY,
    method: "patch",
    params: { action: "get_hierarchy" },
  });
  return result;
};
