<template>
  <div>
    <div class="departments-page">
      <div class="back-item">
        <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
        <span class="back-item__text" @click="backToPages"
          >&nbsp;Back to Directory</span
        >
      </div>
      <span class="txt_pageTitle">Departments</span>
      <div class="department-list">
        <!-- <div v-for="department in adjustDepartmentList" :key="department.id">
          <div class="list_container">
            <div v-if="!department.child_ids.length">{{ department.name }}</div>
            <div v-else>
              <v-list>
                <v-list-group :value="false" :no-action="false">
                  <template v-slot:activator>
                    <v-list-item-title>{{ department.name }}</v-list-item-title>
                  </template>

                  <v-list-item
                    v-for="child_1 in department.child_ids"
                    :key="child_1.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="child_1.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
          </div>
        </div> -->
        <div v-for="department in adjustDepartmentList" :key="department.id">
          <div class="list_container">
            <v-treeview
              :items="department"
              dense
              expand-icon="mdi-chevron-down"
              hoverable
              rounded
            >
              <!-- <template slot="label" slot-scope="{ item }">
                <span @click="selectedItem(item)">{{ item.name }}</span>
              </template> -->
              <template v-slot:label="{ item }">
                <div
                  class="parentDepartment"
                  v-if="item.parent"
                  :style="{ color: item.txtColor }"
                  @click="selectedDepartment(item, true)"
                >
                  <span>{{ item.name }}</span>
                  <span
                    class="txt_parent_number"
                    :style="{
                      backgroundColor: item.txtColor + '4d',
                    }"
                    >{{ item.number }}</span
                  >
                </div>
                <div
                  class="department_name_number"
                  v-else
                  @click="selectedDepartment(item, false)"
                >
                  <span class="txt_name" :style="{ color: item.txtColor }">{{
                    item.name
                  }}</span>
                  <span
                    class="txt_number"
                    :style="{
                      backgroundColor: item.txtColor + '4d',
                      color: item.txtColor,
                    }"
                    >{{ item.number }}</span
                  >
                </div>
              </template>
            </v-treeview>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);
export default {
  name: "Departments",
  data() {
    return {
      adjustDepartmentList: [],
      deep1: 0,
      deep2: 0,
      departmentColor: ["#53A0DE", "#99c328", "#BB6BD9", "#FF6C6C", "#969612"],
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters([
      "departmentList",
      "employeeNameJobList",
    ]),
  },
  created() {
    this.getLoading(false);
    this.checkDepartmentList();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...nanoramicLabsDetailHelpers.mapActions(["dispatchSelectedDepartment"]),
    selectedDepartment(_item, is_parent) {
      this.dispatchSelectedDepartment(_item.name);
      let url = `/employees?departments=${_item.name}&is_parent=${is_parent}`;
      this.$router.push(url);
    },
    checkDepartmentList() {
      let departmentListCopy = JSON.stringify(this.departmentList);
      this.adjustDepartmentList = JSON.parse(departmentListCopy);
      let colorCount = 0;
      let colorListIndex = 0;
      this.adjustDepartmentList.forEach((element, index) => {
        element.deep = 1;
        element.parent = 1;
        element.number = 0;
        if (element.child_ids.length > 0) {
          element.child_ids.unshift({
            child_ids: [],
            id: element.id,
            name: element.name,
          });
        }
        colorCount++;
        element.txtColor = this.departmentColor[index - 5 * colorListIndex];
        if (colorCount >= 5) {
          colorCount = 0;
          colorListIndex++;
        }
        if (element.child_ids.length > 0) {
          this.checkDepartment(element);
        } else {
          this.countEmployeesDepartment(element);
        }
        element.deep = this.deep1;
        this.deep1 = 0;
        this.deep2 = 0;
      });
      let newArray = Array.from(
        Array(this.adjustDepartmentList.length),
        () => new Array(0)
      );
      for (let index = 0; index < newArray.length; index++) {
        newArray[index].push(this.adjustDepartmentList[index]);
      }
      this.adjustDepartmentList = newArray;
    },
    countEmployeesDepartment(_array) {
      this.employeeNameJobList.forEach((employee) => {
        if (_array.name == employee.department_name) {
          _array.number++;
        }
      });
    },
    checkDepartment(_array) {
      _array.children = _array.child_ids;
      _array.child_ids?.forEach((element) => {
        element.number = 0;
        element.txtColor = _array.txtColor;
        element.deep = _array.deep + 1;
        this.countEmployeesDepartment(element);
        _array.number += element.number;
      });
    },
    backToPages() {
      let url = `/home/directory`;
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-icon {
  color: red !important;
}
.departments-page {
  margin: 30px 80px;
  .back-item {
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      margin-left: 5px;
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      text-transform: capitalize;
    }
  }
  .txt_pageTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #323a44;
  }
  .department-list {
    display: grid;
    grid-gap: 30px 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 30px;
    .list_container {
      border-radius: 30px;
      background: white;
      padding: 40px 30px 40px 15px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      .v-treeview-node__root {
        .v-treeview-node__content {
          order: 1;
          .department_name_number {
            display: flex;
            justify-content: space-between;

            cursor: pointer;
            .txt_name {
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 19px;
              color: #323a44;
              text-transform: capitalize;
            }
            .txt_number {
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              min-width: 24px;
              min-height: 24px;

              border-radius: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .parentDepartment {
            cursor: pointer;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            padding-bottom: 10px;
            // padding-right: 5px;
            // margin-right: 10px;
            margin-left: 10px;
            border-bottom: 1px solid;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .txt_parent_number {
              min-width: 30px;
              min-height: 30px;

              border-radius: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1650px) {
  .department-list {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 1280px) {
  .department-list {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 900px) {
  .departments-page {
    margin: 30px 30px 50px 30px;
    .department-list {
      grid-template-columns: 1fr !important;
      grid-gap: 20px;
      .list_container {
        padding: 20px 20px 25px 10px;
        .parentDepartment {
          margin-left: 0px !important;
          font-size: 15px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .departments-page {
    margin: 30px 10px 50px 10px;
    .back-item {
      margin-left: 20px;
    }
    .txt_pageTitle {
      margin-left: 20px;
    }
  }
}
</style>
