<template>
  <!-- Selected tags container -->
  <div class="selected-tags">
    <!-- Tags -->

    <div class="selected-tags__tags" v-bind:class="{ hide: hide }">
      <div v-if="selectedTagResult" class="tags-list">
        <div v-for="(tag, index) in selectedTagResult" :key="index">
          <div class="item" v-if="tag != null && tag.length > 0">
            <span class="text">{{ tag }} </span>
            <img
              class="img-fluid"
              src="@/assets/img/remove-filter.svg"
              @click="removeTag(tag)"
            />
          </div>
        </div>
      </div>
      <div v-if="selectedTag">
        <div class="item">
          <span class="text">{{ selectedTag }}</span>
          <img
            class="img-fluid"
            src="@/assets/img/remove-filter.svg"
            @click="removeTagOne(selectedTag)"
          />
        </div>
      </div>
    </div>

    <!-- Creal tags button -->
    <div class="selected-tags__clear" v-bind:class="{ hide: hide }">
      <span class="text" @click="removeTags">Clear filters</span>
      <img
        class="img-fluid"
        src="@/assets/img/clear-filters.svg"
        @click="removeTags"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectedTags",
  props: {
    selectedTags: {
      type: Array,
      default: new Array(),
    },
    selectedTag: {
      type: String,
      default: "",
    },
  },
  // Handles weather clear tags button is shown or hidden.
  computed: {
    selectedTagResult: function () {
      return this.selectedTags.filter(function (e) {
        return e != null;
      });
    },
    hide() {
      return this.selectedTags.length === 0 && this.selectedTag === "";
    },
  },
  methods: {
    // Send event to parent to remove a tag from tags array
    removeTag(tag) {
      if (this.selectedTagResult.length === 1) {
        this.$emit("removeTags");
      } else {
        this.$emit("removeTag", tag);
      }
      // this.$emit("removeTag", tag);
    },
    removeTagOne(tag) {
      this.$emit("removeTagOne", tag);
    },
    // Send event to parent to remove all the tags.
    removeTags() {
      this.$emit("removeTags");
    },
  },
};
</script>
<style lang="scss">
.hide {
  display: none !important;
}

.selected-tags {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding-top: 1.5625rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  &__tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 85%;

    .tags-list {
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      align-items: center;
      background-color: $dark-blue;
      border-radius: 30px;
      display: flex;
      margin-right: 0.625rem;
      padding-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      .text {
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 14px;
        margin-right: 0.5rem;
        // text-transform: capitalize;
      }

      .img-fluid {
        cursor: pointer;
        width: 0.5rem;
      }
    }
  }

  &__clear {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 1rem;

    .text {
      color: $black;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      margin-right: 0.625rem;
    }

    .img-fluid {
      width: 1.25rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .selected-tags {
    flex-flow: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-items: flex-start;

    &__tags {
      width: 100%;
    }

    &__clear {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem;
    }
  }
}
</style>
