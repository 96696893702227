<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Required eLearning</h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <!-- <div class="not-filter" v-show="!notFilter">
                <div class="not-filter__img-fluid"></div>
                <div class="not-filter__text">
                  <p class="not-filter-subtitle">
                    Please, try to choose other filters or delete some of them.
                  </p>
                </div>
              </div> -->
              <div class="eventsTable">
                <TableTemplate :rows="rows_fields" :column_names="cols" />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
                <div class="not-found__text"></div>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getTacticalCertificationEmployee, getLearningEvent } from "@/api";
import { createNamespacedHelpers } from "vuex";
import TableTemplate from "@/components/required-elearning/TableTemplate.vue";
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  name: "RequiredElearning",
  components: {
    TableTemplate,
  },
  data() {
    return {
      filters: {
        status: [],
      },
      rows_fields: [],
      cols: [
        ["Course", "Description", "View"],
        ["Certifications that require the course"],
      ],
    };
  },
  computed: {
    notFound() {
      return this.rows_fields.length == 0;
    },
  },
  created() {
    this.getDataTable();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataTable() {
      this.getLoading(true);
      await this.getDataFromAPI();
      this.getLoading(false);
    },
    async getDataFromAPI() {
      try {
        let result = await getTacticalCertificationEmployee();
        if (result.status == 200) {
          let gettingCertifications = result.data.data.response;
          this.rows_fields = await this.courseCertifications(
            gettingCertifications
          );
        }
      } catch (error) {
        console.error("GetResponsibilityError==>", error);
      }
    },
    async courseCertifications(_gettingCertifications) {
      let setCourses = [];
      let le_list = new Set();
      if (Object.keys(_gettingCertifications).length !== 0) {
        for (const i in _gettingCertifications) {
          const cert = _gettingCertifications[i];
          let name_certification = cert.name;
          let learnin_event = cert.requirements
            .map((req) => req.learning_events)
            .flat();
          for (const learn_evt of learnin_event) {
            let row = {
              id: "",
              Course: "",
              Description: "-",
              View: "",
              "Certifications that require the course": [],
              learning_event: 0,
            };
            if (learn_evt) {
              const id_le = learn_evt.id;
              const state_le = learn_evt.state;
              let newVal = true;
              if (state_le != "done") {
                for (const course of setCourses) {
                  // let course = setCourses[c]
                  if (course.learning_event == id_le) {
                    course["Certifications that require the course"].push(
                      name_certification
                    );
                    newVal = false;
                    break;
                  }
                }
                if (newVal) {
                  let result2 = await getLearningEvent(id_le);
                  let course_info = result2.data.data.response.data[0];
                  if (course_info?.activity_type?.channel_id) {
                    let elearning_added = setCourses.find(
                      (c) => c.id == course_info.activity_type.channel_id
                    );
                    if (!elearning_added) {
                      row.id = course_info.activity_type.channel_id;
                      row.Course = course_info.activity_type.name;
                      row.Description = course_info.description;
                      row.View = course_info.state;
                      row.learning_event = id_le;
                      row["Certifications that require the course"].push(
                        name_certification
                      );
                      setCourses.push(row);
                    }
                  }
                }
              }
            }
          }
        }
      }
      return setCourses;
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
