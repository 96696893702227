<template>
  <paginate
    v-model="selected_page"
    :pageCount="pagecount"
    :click-handler="pageNumber"
    :containerClass="'paginate'"
    :prev-class="'paginate-prev'"
    :next-class="'paginate-next'"
    :page-class="'page-item'"
    :page-link-class="'page-link'"
    :next-link-class="'next-link'"
    :prev-link-class="'prev-link'"
  >
    <template v-slot:prev>
      <div class="navigation-wrapper">
        <div class="img_leftArrow" v-if="selected_page != 1"></div>
        <span class="text" v-if="selected_page != 1">Prev</span>
      </div>
    </template>
    <template v-slot:next>
      <div class="navigation-wrapper">
        <span class="text" v-if="pagecount != 1 && selected_page != pagecount"
          >Next</span
        >
        <div
          class="img_rightArrow"
          v-if="pagecount != 1 && selected_page != pagecount"
        ></div>
      </div>
    </template>
  </paginate>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  props: {
    pagecount: {
      type: Number,
      default: 0,
    },
    selectedPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      current_page: 1,
    };
  },
  created() {},
  components: {
    Paginate,
  },
  computed: {
    selected_page: {
      get: function () {
        return this.selectedPage;
      },
      set: function () {
        this.selectedPage;
      },
    },
  },
  methods: {
    pageNumber(pageNum) {
      this.$emit("changePageNumber", pageNum);
    },
  },
};
</script>

<style lang="scss">
ul.paginate {
  display: flex;
  list-style: none;
  padding: 20px 40px !important;
}

.page-item {
  margin: 0 10px;
  &.active {
    text-decoration-line: none;

    .page-link {
      border-bottom: 1px solid #53a0de;
    }
  }
}

.paginate-prev,
.paginate-next {
  font-weight: 600;
}

.paginate-prev {
  margin-right: auto;
}

.paginate-next {
  margin-left: auto;
}

.page-link,
.next-link,
.prev-link {
  outline: none;
}
.disabled {
  pointer-events: none !important;
}
.navigation-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  .img_leftArrow {
    background-image: url("~@/assets/img/previous-arrow-blue.svg");
    width: 7px;
    height: 11px;
  }
  .img_rightArrow {
    background-image: url("~@/assets/img/next-arrow-blue.svg");
    width: 7px;
    height: 11px;
  }
  .text {
    padding: 0 6px;
  }
}
</style>
