import axios from "./_axios";
import { API } from "./apiEndPoints";

export const createPriorKnowledgeCertificate = (
  _id_employee,
  _certification_id
) => {
  let result = axios()({
    url: API.TACTICALCERTIFICATE,
    method: "patch",
    params: {
      action: "create_certificate_prior_knowledge",
      employee_id: _id_employee
        ? _id_employee
        : localStorage.getItem("employee_id"),
      certification_id: _certification_id,
    },
  });
  return result;
};
