import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getLearningEvents = () => {
  let result = axios()({
    url: API.GETLEARNINGEVENTs,
    method: "patch",
    params: { action: "get_learning_event" },
  });
  return result;
};
export const getLearningEvent = (_id_event) => {
  let result = axios()({
    url: API.GETLEARNINGEVENTs,
    method: "patch",
    params: {
      action: "get_learning_event",
      event_id: _id_event,
    },
  });
  return result;
};
export const getLearningEventByType = (_typeLE) => {
  let result = axios()({
    url: API.GETLEARNINGEVENTs,
    method: "patch",
    params: {
      action: "get_learning_event",
      activity_type: `${_typeLE}`,
    },
  });
  return result;
};
