<template>
  <div>
    <div class="header-container">
      <div>
        <span class="txt-current-assign-count">{{ subtitle }}</span>
        <CommitmentSigns />
      </div>
      <v-checkbox
        v-if="notAcceptedRoles"
        v-model="showNotAcknowledged"
        label="Show only not accepted yet"
        color="#53a0de"
        hide-details
      ></v-checkbox>
    </div>
    <DxDataGrid
      id="gridContainer1"
      :ref="gridRefKey"
      :data-source="row"
      :columns="col"
      key-expr="INDEX"
      :show-borders="true"
      :showRowLines="true"
      @selection-changed="selectionChanged"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
    >
      <DxSorting mode="multiple" />
      <DxColumn
        data-field="Role"
        cell-template="roleTemplate"
        :calculate-sort-value="valuesCommitmentAndName"
        :sorting-method="sortCommitmentAndName"
        sort-order="asc"
      />
      <DxColumn
        data-field="Effective Date"
        cell-template="effectiveDateTemplate"
        alignment="center"
      />
      <!-- <DxColumn data-field="Relieve Date" /> -->
      <!-- <DxColumn data-field="Status" /> -->
      <DxColumn data-field="Responsibilities" alignment="center" />
      <DxColumn
        data-field="Readiness"
        alignment="center"
        cell-template="readinessTemplate"
      />
      <DxColumn data-field="Accepted On" cell-template="acceptedOnTemplate" />
      <DxSelection mode="single" />
      <DxMasterDetail :enabled="true" template="detailTemplate" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #roleTemplate="{ data: rowData }">
        <div>
          <v-icon v-if="rowData.data.Commitment == 'primary'" size="15"
            >$levelHigh</v-icon
          >
          <v-icon v-else-if="rowData.data.Commitment == 'backup'" size="15"
            >$levelMedium</v-icon
          >
          <v-icon v-else-if="rowData.data.Commitment == 'preparing'" size="15"
            >$levelLow</v-icon
          >
          <span class="ml-2">{{ rowData.data.Role }}</span>
        </div>
      </template>
      <template #effectiveDateTemplate="{ data: rowData }">
        <div>
          <span>{{ rowData.data["Effective Date"] }}</span>
          <br />
          <span v-if="rowData.data['Relieve Date']" class="red-txt">
            Ends on {{ rowData.data["Relieve Date"] }}
          </span>
        </div>
      </template>
      <template #readinessTemplate="{ data: rowData }">
        <div>
          <BulletChartRoles
            :respAckCert="rowData.data.childs_resp_ack_cert"
            :respAckNoCert="rowData.data.childs_resp_ack_no_cert"
            :respNotAck="rowData.data.childs_resp_not_ack"
            :certAchieved="rowData.data.childs_cert_achie"
            :respNotAckCount="rowData.data.childs_resp_not_ack_count"
            :missingCert="rowData.data.childs_miss_cert"
          />
        </div>
      </template>
      <template #acceptedOnTemplate="{ data: rowData }">
        <div>
          <span v-if="rowData.data['Accepted On']">{{
            rowData.data["Accepted On"]
          }}</span>
          <v-btn
            v-else
            :disabled="act_as_me == false || disabled_button"
            depressed
            rounded
            color="#e61921"
            class="btn-accept"
            @click.stop="onAcceptRole(rowData.data)"
          >
            Accept now
          </v-btn>
        </div>
      </template>
      <template #detailTemplate="{ data: rowData }">
        <slot :col="[]" :row="rowData.data"></slot>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import { updateAcceptanceRoleDate } from "@/api";
import EditDateToUTC from "@/utils/edit_date_to_utc_1.js";
import DxDataGrid, {
  DxSelection,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxColumn,
  DxSorting,
} from "devextreme-vue/data-grid";
import { createNamespacedHelpers } from "vuex";
import CommitmentSigns from "@/common/CommitmentSigns.vue";
import BulletChartRoles from "@/common/DevExtreme/BulletChartRoles";
const { mapActions } = createNamespacedHelpers("isLoading");

const gridRefKey = "data-grid";

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxColumn,
    DxSorting,
    CommitmentSigns,
    BulletChartRoles,
  },
  props: ["col", "row", "disabled_button"],
  data() {
    return {
      act_as_me: true,
      gridRefKey,
      showNotAcknowledged: false,
      valuesCommitmentAndName(rowData) {
        return [rowData.Commitment, rowData.Role];
      },
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    if (this.notAcceptedRoles > 0) this.showNotAcknowledged = true;
  },
  computed: {
    dataGrid: function () {
      return this.$refs[gridRefKey].instance;
    },
    notAcceptedRoles() {
      const _pending_accept = this.row.filter((a) => a["Accepted On"] == "");
      return _pending_accept.length;
    },
    subtitle() {
      if (this.notAcceptedRoles > 0)
        return `Your Current Assignments (${this.notAcceptedRoles} pending acceptance out of ${this.row.length})`;
      return `Your Current Assignments`;
    },
  },
  watch: {
    showNotAcknowledged: function (current) {
      if (current) {
        this.dataGrid.filter([["Accepted On", "=", ""]]);
      } else {
        this.dataGrid.filter([
          ["Accepted On", "<>", ""],
          "or",
          ["Accepted On", "=", ""],
        ]);
      }
    },
    notAcceptedRoles: function (current) {
      if (current == 0) {
        this.showNotAcknowledged = false;
      }
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    sortCommitmentAndName(value1, value2) {
      if (value1[0] == value2[0]) {
        return value1[1].localeCompare(value2[1]);
      }
      if (value1[0] == "primary") {
        return -1;
      }
      if (value2[0] == "primary") {
        return 1;
      }
      if (value1[0] == "backup") {
        return -1;
      }
      if (value2[0] == "backup") {
        return 1;
      }
      return 1;
    },
    editAcceptence() {
      this.dataGrid.filter([["Accepted On", "<>", ""]]);
      this.dataGrid.filter([["Accepted On", "=", ""]]);
    },
    readinessValues(rowData) {
      let arrayBar = new Array();
      arrayBar.push({
        value: rowData.responsibility_certification_progress,
      });
      arrayBar.push({
        value: rowData.responsibility_proficiency_progress,
      });
      return arrayBar;
    },
    async onAcceptRole(_row) {
      this.getLoading(true);
      try {
        const _today = new Date();
        const result = await updateAcceptanceRoleDate(_row.id);
        if (result.status == 200) {
          _row["Accepted On"] = EditDateToUTC(_today);
          this.editAcceptence();
        }
      } catch (error) {
        console.log("acceptRoleError==>", error);
      }
      this.getLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .txt-current-assign-count {
    color: $gray-3;
    font-family: Gilroy-Bold;
    width: 700;
    font-size: 24px;
  }
}
.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.red-txt {
  color: $red;
  font-family: Gilroy-Bold;
  // width: 700;
}
</style>
