import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import createPersistedState from "vuex-persistedstate";
import isLoading from "./isLoading";
import coursePresentation from "./coursePresentation";
import userInfo from "./userInfo";
import vuexPersist from "./vuexPersist";
import setCoursePageInfo from "./setCoursePageInfo";
import nanoramicLabsDetail from "./nanoramicLabsDetail";
import tasks from "./tasks";
import uploadHeadshot from "./uploadHeadshot";
import setBookClubPageInfo from "./setBookClubPageInfo";
import alert from "./alert";
import faq from "./faq";
import dialogComplete from "./dialogComplete";
import tacticalReadines from "./tacticalReadines";
import takeCourse from "./takeCourse";
import CurrentVsDeservedSalary from "./CurrentVsDeservedSalary";
import readiness from "./readiness";

Vue.use(Vuex);

export const initialStoreModules = {
  coursePresentation,
  userInfo,
  isLoading,
  setCoursePageInfo,
  nanoramicLabsDetail,
  tasks,
  uploadHeadshot,
  setBookClubPageInfo,
  faq,
  alert,
  dialogComplete,
  tacticalReadines,
  takeCourse,
  CurrentVsDeservedSalary,
  readiness,
};

export default new Vuex.Store({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    },
  },
  plugins: [vuexPersist.plugin, createPersistedState()],
});
