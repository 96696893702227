<template>
  <div>
    <div
      id="chart"
      v-if="data.chartType == 'fusion_chart_bullet_chart_widget'"
      class="bulletChart"
    >
      <fusioncharts
        type="hbullet"
        height="70"
        dataFormat="json"
        data-empty-message="Data is loading"
        :dataSource="getDataSource(data)"
      ></fusioncharts>
      <!-- :dataSource="dataSource" -->
    </div>
    <div
      v-if="data.chartType == 'progressbar_stacked'"
      style="margin-left: 38px"
    >
      <!-- child chart -->
      <ChildBulletChartWidget v-if="!hasMasterBadge" :barData="data" />
      <ChildBulletChartWidget v-if="hasMasterBadge" :barData="isMaster()" />
    </div>
  </div>
</template>

<script>
import ChildBulletChartWidget from "./childBulletChartWidget.vue";

export default {
  components: {
    ChildBulletChartWidget,
  },
  props: ["data"],
  data: function () {
    return {
      type: "hbullet",
      width: "100%",
      height: "70",
      dataFormat: "json",
      hasMasterBadge: false,
    };
  },
  created() {
    let badge = this.data.children?.fields.find(
      (x) => x.name == "proficiency"
    ).value;

    let is_super_expert = this.data.children?.fields.find(
      (x) => x.name == "is_super_expert"
    ).value;
    if (badge && is_super_expert == true) {
      this.hasMasterBadge = true;
    }
  },
  methods: {
    getDataSource(data) {
      const dataSource = {
        chart: {
          numbersuffix: "%",
          theme: "fusion",
          // plottooltext:
          //  "Downloaded $datavalue times against a target of $targetDataValue",
          // lowerLimit: "0",
          // upperLimit: "120",
          showTickMarks: "0",
          showTickValues: "0",
          showValue: "0",
          upperLimit: data.readinessChartOption.attrs.options.end,
          lowerLimit: data.readinessChartOption.attrs.options.start,
          plotFillColor: data.bulletColor,
        },
        colorrange: {
          color: data.readinessChartOption.attrs.options.color,
          //   {
          //     minvalue: "0",
          //     maxvalue: "50",
          //     code: "#F2726F",
          //   },
          //   {
          //     minvalue: "50",
          //     maxvalue: "75",
          //     code: "#FFC533",
          //   },
          //   {
          //     minvalue: "75",
          //     maxvalue: "100",
          //     code: "#62B58F",
          //   },
        },
        value: data.employeeReadinessValue,
      };
      return dataSource;
    },
    isMaster() {
      let barData = {
        ID: this.data.children?.fields[0].value,
        readinessChartOption: {
          attrs: {
            chartValues: [
              {
                value: 100,
                color: "#FFD5F5",
              },
            ],
            options: {
              leftPadding: 0,
              height: 3,
            },
            label: "Master is always 100% ready",
          },
        },
      };
      return barData;
    },
  },
};
</script>

<style>
.progress-stacked {
  height: 15px;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.8125rem;
  background-color: #e9ecef;
  border-radius: 0;
}
.progress-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #00a09d;
  transition: width 0.6s ease;
}
.bulletChart {
  width: 100%;
  overflow: hidden;
  margin-bottom: -36px;
  margin-top: -8px;
  padding-left: 0px;
}
</style>
