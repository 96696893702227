export default {
  namespaced: true,
  state: {
    dialogComplete: false,
    dataCompleted: {
      type_dialog: "success1",
      title: "",
      subtitle1: "",
      points: "",
      subtitle2: "",
      button: "",
    },
  },
  mutations: {
    setDialogComplete(state, status) {
      state.dialogComplete = status;
    },
    setDataCompleted(state, status) {
      state.dataCompleted = status;
    },
  },
  getters: {
    getDialogComplete(state) {
      return state.dialogComplete;
    },
    getDataCompleted(state) {
      return state.dataCompleted;
    },
  },
};
