export default {
  namespaced: true,
  state: {
    pageNumber: 1,
    searchKey: "",
  },
  mutations: {
    setPageNumber(state, status) {
      state.pageNumber = status;
    },
    setSearchKey(state, status) {
      state.searchKey = status;
    },
  },
  getters: {
    getPageNumber(state) {
      return state.pageNumber;
    },
    getSearchkey(state) {
      return state.searchKey;
    },
  },
};
