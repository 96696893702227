<template>
  <svg
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.78872 1.01108L1.78863 1.01099C1.63139 0.854022 1.39488 0.806661 1.18929 0.891853C0.983833 0.97699 0.849839 1.17758 0.849839 1.39996C0.849839 1.54583 0.907858 1.68573 1.01096 1.78883L5.01096 5.78882L5.01103 5.78889C5.22584 6.00343 5.57384 6.00343 5.78865 5.78889L5.78872 5.78882L9.78872 1.78883L9.78873 1.78885L9.79058 1.78694C9.99892 1.57108 9.99601 1.22814 9.78383 1.01596L9.67777 1.12203L9.78383 1.01596C9.57166 0.803784 9.22871 0.800878 9.01286 1.00922L9.01284 1.0092L9.01096 1.01108L5.39984 4.62219L1.78872 1.01108Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.3"
    />
  </svg>
</template>

<script>
import Icon from "./Icon.js";

export default {
  mixins: [Icon],
};
</script>
