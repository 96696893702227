<template>
  <div class="youtube-player">
    <youtube
      :video-id="slideVideoID"
      ref="youtube"
      @playing="youtube_playing"
      @ready="youtube_ready"
      @ended="youtube_ended"
    ></youtube>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const takeCourseHelper = createNamespacedHelpers("takeCourse");

export default {
  computed: {
    ...takeCourseHelper.mapGetters([
      "slideChecked",
      "selectedSlideID",
      "slideVideoID",
    ]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    youtube_playing() {
      // console.log(" we are watching!!!");
    },
    youtube_ready(event) {
      this.player = event.target;
      // console.log("videoready===");
    },
    youtube_ended(event) {
      // console.log("videoend===");
      if (!this.slideChecked) {
        this.$emit("completeSlide");
      }
    },
  },
};
</script>
