<template>
  <v-app id="app">
    <v-main>
      <!-- App content in router -->
      <Header
        v-if="!($route.name == 'Login' || $route.name == 'ChangePassword')"
      />
      <v-alert
        v-if="open"
        :type="type"
        :dismissible="true"
        transition="scale-transition"
        @click="dispatchCloseAlert"
        class="alert"
      >
        {{ message }}
      </v-alert>
      <router-view :key="$route.path"/>
      <loading
        :active.sync="isLoading"
        :can-cancel="canCancel"
        :is-full-page="fullPage"
        :color="color"
        :loader="loader"
        :background-color="bgColor"
      />
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");

import Header from "@/components/header/Header";
export default {
  components: {
    Loading,
    Header,
  },
  data() {
    return {
      fullPage: true,
      canCancel: false,
      color: "#53A0DE",
      bgColor: "#ffffff",
      loader: "spinner",
      closeAlert: false,
    };
  },
  created() {},
  computed: {
    ...mapState({ isLoading: (state) => state.isLoading }),
    ...alertHelpers.mapGetters(["open", "type", "message"]),
  },
  methods: {
    ...alertHelpers.mapActions(["dispatchCloseAlert"]),
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy"), url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
}
html {
  font-family: "Gilroy", "Gilroy-Bold";
  font-size: 16px;
}

body {
  background-color: $light-blue-1 !important;
}
.img-fluid {
  width: 100%;
  height: auto;
}
// a {
//   color: $blue;
// }
// a:link {
//   color: $blue;
// }
// a:visited {
//   color: $dark-blue;
// }
#app {
  font-family: "Gilroy" !important;
  background-color: $light-blue-1 !important;
}
.v-application p {
  margin-bottom: 0rem !important;
}
.v-application ul {
  padding-left: 0rem !important;
}
.v-alert {
  // position: -webkit-sticky;
  position: sticky !important;
  top: 10px;
  z-index: 999999;
}
.weight-100 {
  font-weight: 100;
}
.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  /* NORMAL */
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  /* BOLD */
  font-weight: 700;
}
.weight-800 {
  font-weight: 800;
}
.weight-900 {
  font-weight: 900;
}
.size-11 {
  font-size: 0.6875rem; /* 11/16 */
}
.size-12 {
  font-size: 0.75rem;
}
.size-14 {
  font-size: 0.875rem;
}
.size-16 {
  font-size: 1rem;
}
.size-18 {
  font-size: 1.125rem;
}
.size-20 {
  font-size: 1.25rem;
}
.size-24 {
  font-size: 1.5rem;
}
.size-32 {
  font-size: 2rem;
}
.size-34 {
  font-size: 2.125rem;
}
.size-36 {
  font-size: 2.25rem;
}
</style>
