<template>
  <div>
    <div class="faq-page">
      <v-container class="na-container--faq pa-0">
        <v-row class="justify-center ma-0">
          <v-col class="pa-0 col-md-7 col-lg-5 na-header-text-wrapper">
            <h1 class="na-heading-text">Frequently asked questions</h1>
          </v-col>
        </v-row>
        <v-row class="justify-center ma-0">
          <v-col class="pa-0">
            <div class="na-faq-wrapper">
              <div class="na-search-panel">
                <v-row class="justify-space-between align-center">
                  <v-col class="col-md-2 na-drop-down-wrapper col-4">
                    <drop-down
                      :name="'Tags'"
                      :items="tags"
                      @select-item="addSelectedTags"
                    />
                  </v-col>
                  <v-col class="col-md-4 na-search-wrapper col-8">
                    <div class="input-box">
                      <form class="search-form">
                        <input
                          required
                          class="text-search"
                          type="text"
                          placeholder="Search"
                          :value="st_search"
                          @input="filterByQuery($event)"
                        />
                        <button
                          class="clear-search"
                          type="reset"
                          @click="onClearSearch"
                        ></button>
                      </form>
                      <img src="@/assets/img/input-mag.svg" />
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div class="na-chips-wrapper" v-if="selectedTagsList.length">
                <v-chip
                  v-for="(selectedTag, index) in selectedTagsList"
                  :key="index"
                  class="na-chip na-text-sm"
                  color="primary"
                  close
                  close-icon="$closeChips"
                  @click:close="removetags(index)"
                >
                  {{ selectedTag }}
                </v-chip>
              </div>

              <faq-panel :items="faqItems" />
              <faq-paginate
                :selectedPage="selectedPageNumber"
                :pagecount="faqPagination"
                @updateFAQ="setUpdatedFAQByPage"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  getFAQByPageANDSize,
  getTagList,
  getFAQByTags,
  getFAQByFilter,
} from "@/api";
import FaqPanel from "@/components/faq/FaqPanel";
import FaqPaginate from "@/components/faq/FaqPaginate";
import DropDown from "@/components/form/DropDown";
// import BaseTextField from "@/components/form/BaseTextField";
import { createNamespacedHelpers } from "vuex";
const faqHelper = createNamespacedHelpers("faq");
const LoadingHelpers = createNamespacedHelpers("isLoading");

export default {
  name: "Faq",
  components: {
    FaqPanel,
    FaqPaginate,
    // BaseTextField,
    DropDown,
  },
  data() {
    return {
      appenIcon: {
        size: 16,
        icon: "$search",
        // icon: "@/assets/img/input-mag.svg",
      },
      faqItems: [],
      tags: [],
      pageNumber: 1,
      selectedTagsList: [],
      selectedPageNumber: 0,
      st_search: "",
      awaitingSearch: false,
    };
  },
  computed: {
    ...faqHelper.mapGetters(["faqSize", "faqPagination"]),
    // ...mapState({ faqCount: state => state.FAQ_count })
  },
  created() {
    this.getLoading(false);
    this.getFAQByPageANDSize();
    this.getTagsList();
  },
  methods: {
    ...faqHelper.mapActions(["setFAQPagination"]),
    ...LoadingHelpers.mapActions(["getLoading"]),
    async getFAQByPageANDSize() {
      try {
        this.getLoading(true);
        const result = await getFAQByPageANDSize(this.pageNumber, this.faqSize);
        if (result.status == 200) {
          this.getFAQPagination(result.data.count);
          this.getFAQList(result.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getLoading(false);
      }
    },
    getFAQList(_items) {
      this.faqItems = [];
      _items.forEach((e) => {
        let _item = { question: "", answer: "" };
        _item.question = e.question;
        _item.answer = e.answer;
        this.faqItems.push(_item);
      });
    },
    getFAQPagination(_count) {
      let pagination = 0;
      if (_count == 0) {
        pagination = 1;
      } else {
        pagination = Math.ceil(_count / 20);
      }
      this.setFAQPagination(pagination);
    },
    async getTagsList() {
      try {
        this.getLoading(true);
        const result = await getTagList();
        if (result.status == 200) {
          this.tags = result.data.data.map((item) => item.display_name);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getLoading(false);
      }
    },
    setUpdatedFAQByPage(_value) {
      this.faqItems = [];
      this.selectedPageNumber = _value;
      this.pageNumber = _value;
      if (this.selectedTagsList.length != 0 && !this.st_search) {
        this.getFAQByTags();
      }

      if (this.selectedTagsList.length == 0 && !this.st_search) {
        this.getFAQByPageANDSize();
      }
      if (this.st_search) {
        this.getFAQByFilter();
      }
    },
    addSelectedTags(item) {
      const _exist = this.selectedTagsList.some((_tag) => _tag === item);
      if (!_exist) {
        this.selectedTagsList.push(item);
        this.initConditionByTag();
        this.getFAQByTags();
      }
    },
    removetags(index) {
      this.selectedTagsList.splice(index, 1);
      this.initConditionByTag();
      if (this.selectedTagsList.length == 0) {
        this.getFAQByPageANDSize();
      } else {
        this.getFAQByTags();
      }
    },
    initConditionByTag() {
      this.faqItems = [];
      this.st_search = "";
      this.selectedPageNumber = 1;
      this.pageNumber = 1;
      this.setFAQPagination(0);
    },
    async getFAQByTags() {
      let st_tagsList = this.selectedTagsList.toString();
      try {
        this.getLoading(true);
        const result = await getFAQByTags(
          st_tagsList,
          this.pageNumber,
          this.faqSize
        );
        if (result.status == 200) {
          this.getFAQPagination(result.data.count);
          this.getFAQList(result.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getLoading(false);
      }
    },
    onClearSearch() {
      this.selectedPageNumber = 1;
      this.pageNumber = 1;
      this.st_search = "";
      this.selectedTagsList = [];
      this.faqItems = [];
      this.getFAQByFilter();
    },
    filterByQuery($event) {
      this.selectedPageNumber = 1;
      this.pageNumber = 1;
      this.st_search = $event.target.value;
      this.selectedTagsList = [];
      this.faqItems = [];
      this.setFAQPagination(0);
      if (this.st_search) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getFAQByFilter();
            this.awaitingSearch = false;
          }, 800);
          this.awaitingSearch = true;
        }
      } else {
        this.getFAQByPageANDSize();
      }
    },
    async getFAQByFilter() {
      try {
        this.getLoading(true);
        const result = await getFAQByFilter(
          this.st_search,
          this.pageNumber,
          this.faqSize
        );
        if (result.status == 200) {
          console.log("====", result.data);
          this.getFAQPagination(result.data.count);
          this.getFAQList(result.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getLoading(false);
      }
    },
    getTyping(_query) {
      console.log("typing===>", _query);
      this.st_search = _query;
      this.selectedTagsList = [];
      this.faqItems = [];
      this.setFAQPagination(0);
    },
  },
  watch: {
    faqPagination: function (prev, next) {
      console.log("~~~~~~~ watching faqPagination", prev, next);
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 67.2px 80px;
  font-family: Gilroy;
  font-style: normal;
}

.na-container--faq {
  max-width: 1240px;
}

.na-header-text-wrapper {
  margin-bottom: 40px;

  .na-heading-text {
    margin-bottom: 15px;
    color: $blue;
    text-transform: capitalize;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
  }

  .na-header-text {
    padding: 0 35px;
    margin-bottom: 0;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
  }
}

.na-faq-wrapper {
  position: relative;
  padding: 90px 60px 30px 60px;
  border-radius: $default-br;
  background-color: $white;
  .na-search-panel {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px;
    background: $blue;
    border-radius: 30px;

    .na-drop-down-wrapper {
      padding: 0 0 0 45px;
    }

    .na-search-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 0 40px;

      .na-search-field {
        max-width: 320px;

        ::v-deep {
          .v-input__append-inner {
            margin-right: -5px;
          }
        }
      }
    }
    .input-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      background-color: $white !important;
      border-radius: 30px;
      height: 3rem;
      // margin-bottom: 1rem;
      // margin-right: 1rem;
      // margin-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.02125rem;
      position: relative;
      width: 17.47875rem;
      .search-form {
        display: flex;
        flex-wrap: nowrap;
        .text-search {
          background-color: $white !important;
          border: 0;
          color: $gray;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 16px;
          outline: none;
          width: 90%;
        }
        .clear-search {
          position: relative;
          border: 1px solid transparent;
          background-color: $blue;
          display: inline-block;
          vertical-align: middle;
          outline: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 10px;
          cursor: pointer;
        }
        .clear-search:after {
          content: url("~@/assets/img/close_icon.svg");
          display: block;
          position: absolute;
          background-color: transparent;
          left: 5px;
          top: 5px;
          text-align: center;
          line-height: 0;
          cursor: pointer;
        }
        .text-search:not(:valid) ~ .clear-search {
          display: none;
        }
      }
    }
  }
}

.na-chips-wrapper {
  margin: 10px 0;

  .na-chip {
    padding: 0 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 600px) {
  .faq-page {
    margin: 67.2px 10px;
  }
  .na-faq-wrapper {
    position: relative;
    padding: 90px 10px 30px 10px;
    border-radius: $default-br;
  }
  .na-drop-down-wrapper {
    padding: 0 0 0 20px !important;
  }
  .na-search-wrapper {
    padding: 0px !important;
  }
}
</style>
