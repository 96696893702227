<template>
  <div>
    <div class="table-content1">
      <table>
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.id">{{ col }}</th>
          </tr>
        </thead>
        <tbody v-if="tableType == 'select_row'">
          <tr
            v-for="row in rows"
            :key="row.id"
            :class="{ relievedRow: row.status == 'Relieved' }"
            @click="emitAction(row)"
            class="tr-click"
          >
            <td v-for="col in cols" :key="col.id">
              <span>{{ row[col] }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="tableType == 'req_cer'">
          <tr v-for="row in rows" :key="row.id">
            <td
              v-for="col in cols"
              :key="col.id"
              :class="row[col] == 'Missing' && col == 'status' ? 'txt_red' : ''"
            >
              <div v-if="Array.isArray(row[col])">
                <v-chip
                  v-for="(chip, index) in row[col]"
                  :key="index"
                  class="tags-sm"
                  color="#53A0DE33"
                  text-color="#53A0DE"
                >
                  {{ chip }}
                </v-chip>
              </div>
              <span v-else>{{ row[col] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mobile_events">
      <div v-for="row in rows" :key="row.id">
        <div class="detail_row">
          <div v-for="col in cols" :key="col.id">
            <div class="detail_col">
              <span class="txt_col">{{ col }}</span>
              <div v-if="Array.isArray(row[col])">
                <v-chip
                  v-for="(chip, index) in row[col]"
                  :key="index"
                  class="tags-sm"
                  color="#53A0DE33"
                  text-color="#53A0DE"
                >
                  {{ chip }}
                </v-chip>
              </div>
              <span
                v-else
                class="txt_row_col"
                :class="
                  row[col] == 'Today' && col == 'deadline'
                    ? 'txt_orange'
                    : row[col] == 'Overdue' && col == 'deadline'
                    ? 'txt_red'
                    : row[col] == 'Today' && col == 'expiration date'
                    ? 'txt_orange'
                    : row[col] == 'Overdue' && col == 'expiration date'
                    ? 'txt_red'
                    : row[col] == 'Today' && col == 'started on'
                    ? 'txt_orange'
                    : row[col] == 'Today' && col == 'completed on'
                    ? 'txt_orange'
                    : row[col] == 'Approved' && col == 'status'
                    ? 'txt_green'
                    : row[col] == 'Submitted' && col == 'status'
                    ? 'txt_orange'
                    : row[col] == 'Rejected' && col == 'status'
                    ? 'txt_red'
                    : row[col] == 'Draft' && col == 'status'
                    ? 'txt_grey'
                    : row[col] == 'Missing' && col == 'status'
                    ? 'txt_red'
                    : ''
                "
              >
                {{ row[col] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
export default {
  name: "SimpleTable",
  props: ["cols", "rows", "courses", "tableType", "tiedToRow"],
  data() {
    return {
      menuList: [],
      selectedRow: {},
      progress_bar_color: "#A7D42E",
      menuContentWidth: 760,
      menu_position_y: 0,
      menu_position_x: 0,
      dialog: false,
    };
  },
  created() {
    this.initialMenuList();
  },
  methods: {
    ...coursePresentationHelper.mapActions(["dispatchCourseDetail"]),
    initialMenuList() {
      this.menuList = Array.from(Array(this.rows.length), () => new Array(0));
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i] = false;
      }
    },
    emitAction(_row, _index) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (i == _index) {
          this.menuList[i] = !this.menuList[i];
        } else {
          this.menuList[i] = false;
        }
      }
      this.selectedRow = {};
      this.selectedRow = _row;
      this.selectedRow.index = _index;
      if (this.tiedToRow) {
        let row_rect = this.$refs.row[_index].getBoundingClientRect();
        this.menu_position_y = row_rect.y + 40;
        this.menu_position_x =
          window.innerWidth / 2 - this.menuContentWidth / 2;
      }
      this.$emit("selectRow", _row);
    },
    goToEvent(_id) {
      for (let index = 0; index < this.courses.length; index++) {
        const course = this.courses[index];
        if (course.id == _id) {
          this.dispatchCourseDetail(course);
          let url = `/course-presentation?${course.title}`;
          this.$router.push(url);
          break;
        }
      }
    },
    onSelectedMobileRow(_row) {
      console.log(_row);
      this.dialog = true;
      this.selectedRow = {};
      this.selectedRow = _row;
    },
  },
};
</script>
<style lang="scss" scoped>
.tags-sm {
  padding: 6px 14px 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12.38px;
  // margin: 2px;
}
.txt_orange {
  color: #f2994a !important;
}
.txt_red {
  color: #ff6c6c !important;
}
.txt_green {
  color: #a7d42e !important;
}
.txt_grey {
  color: #8a929b !important;
}
.txt_pending {
  color: #800080 !important;
}
.txt_refused {
  color: #c205c5 !important;
}
.noEventImg {
  display: none !important;
}
.table-content1 {
  padding: 40px;
  width: 100%;
  height: auto;

  table {
    width: 100%;
    height: auto;
    border-collapse: separate;
    border-spacing: 0;
    // padding-bottom: 50px;
  }

  .tr-click {
    cursor: pointer;
  }
  .relievedRow {
    background-color: #f6f6fb;
  }

  .selectedRow {
    background-color: #f6f6fb;
  }
  // table tr td.txt_orange {
  //   color: #f2994a;
  // }
  // table tr td.txt_orange + td {
  //   color: #f2994a;
  // }
  // table tr td.txt_red {
  //   color: #ff6c6c;
  // }
  // table tr td.txt_red + td {
  //   color: #ff6c6c;
  // }

  table tr th,
  table tr td {
    border-right: 0.0625rem solid #e0e0e7;
    border-bottom: 0.0625rem solid #e0e0e7;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #323a44;
    padding-top: 16px;
    padding-left: 15px;
    padding-bottom: 16px;
    // text-transform: capitalize;
    padding-right: 10px;
  }
  table tr th {
    border-top: 0.0625rem solid #e0e0e7;
    text-transform: capitalize;
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 0.0625rem solid #e0e0e7;
    text-transform: none;
  }
  table tr th:first-child {
    text-transform: capitalize;
  }
  table tr th {
    background: $white;
    text-align: left;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #53a0de;
  }
  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 1.25rem;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 1.25rem;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 1.25rem;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-of-type {
    border-bottom-right-radius: 1.25rem;
  }
}
.mobile_events {
  display: none;
}
.menu-content {
  padding: 40px;
  display: flex;
  flex-flow: column;
  .events-detail {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .detail-1 {
      display: flex;
      flex-flow: row;
      .img-event {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .txt_type {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          margin-bottom: 12px;
          text-transform: capitalize;
        }
        .txt_credits {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          color: #53a0de;
          padding: 0px 10px;
          border: solid 1px #53a0de;
          border-radius: 15px;
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 360px;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
    .txt_gotoEvent {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 15px 40px;
      border-radius: 30px;
      background-color: #a7d42e;
      border: solid 1px #a7d42e;
      cursor: pointer;
    }
  }
}
.dialog-content {
  padding: 24px 30px;
  display: flex;
  flex-flow: column;
  .events-detail {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 70px;
    .detail-1 {
      display: flex;
      flex-flow: column;

      .img-event {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .type_credits {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          .txt_type {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #323a44;

            margin-right: 16px;
            text-transform: capitalize;
          }
          .txt_credits {
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            color: #53a0de;
            padding: 0px 17px;
            border: solid 1px #53a0de;
            border-radius: 15px;
          }
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      margin-top: 25px;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
    .txt_gotoEvent {
      margin-top: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 15px 40px;
      border-radius: 30px;
      background-color: #a7d42e;
      border: solid 1px #a7d42e;
      cursor: pointer;
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 100%;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .table-content1 {
    display: none !important;
  }
  .mobile_events {
    display: grid !important;
    grid-gap: 16px 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    .detail_row {
      background: white;
      border-radius: 16px;
      padding: 0px 16px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      padding-bottom: 20px;
      padding-top: 4px;
      .detail_col {
        display: flex;
        flex-flow: row;
        padding: 16px 0px;
        border-bottom: solid 1px #e0e0e7;
        .txt_col {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: #53a0de;
          text-transform: capitalize;
          width: 45%;
        }
        .txt_row_col {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #323a44;
          width: 55%;
        }
      }
      & > div:nth-child(9) .detail_col {
        border-bottom: none;
      }
      .btn_detailRow {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .txt_detailRow {
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: white;
          padding: 12px 74px;
          background-color: #53a0de;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
