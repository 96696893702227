<template>
  <div class="page-maintenance">
    <img
      class="img-maintenance"
      src="@/assets/img/Maintenance illustration.png"
    />
    <p class="maintenance-text-1">We're down for maintenance.</p>
    <p class="maintenance-text-2">
      Unfortunately the site is down for a bit maintenance right now. But soon
      we'll come back better. Thanks for your patience.
    </p>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>
<style lang="scss">
.page-maintenance {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .img-maintenance {
    margin-top: 6rem;
    width: 30.169375rem;
    height: 26.81125rem;
  }
  .maintenance-text-1 {
    margin-top: 2.916875rem;
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.6875rem;
    text-align: center;
    color: #53a0de;
  }
  .maintenance-text-2 {
    margin-top: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #323a44;
    max-width: 30.375rem;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .page-maintenance .img-maintenance {
    width: 350px;
    height: 100%;
  }
}
</style>
