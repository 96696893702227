<template>
  <div class="table1-container">
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows"
      :columns="column_names"
      key-expr="tactical_responsibility_tracker_id"
      :show-borders="true"
      :showRowLines="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :column-hiding-enabled="true"
    >
      <DxColumn
        caption="Request to coach"
        data-field="employee_name"
        :allow-sorting="false"
        cell-template="coachTemplate"
      />
      <DxColumn
        caption="For responsibility"
        data-field="responsibility_name"
        :allow-sorting="false"
      />
      <DxColumn
        caption="Requested by/on"
        data-field="requested_on"
        cell-template="requestedOnTemplate"
        sort-order="desc"
        :calculate-sort-value="sortByDate"
      />
      <DxColumn
        data-field="Actions"
        :allow-sorting="false"
        alignment="center"
        cell-template="actionsTemplate"
      />
      <template #coachTemplate="{ data: rowData }">
        <v-list-item>
          <v-list-item-avatar rounded="30px">
            <div
              class="selectedImg"
              :style="{
                backgroundImage: 'url(' + rowData.data.employee_image_128 + ')',
              }"
            ></div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ rowData.data.employee_name }}
            </v-list-item-title>
            <span style="color: #c3c5ca">{{ rowData.data["job_title"] }}</span>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #requestedOnTemplate="{ data: rowData }">
        {{ rowData.data.requested_by_name }}
        on
        {{ formattedDate(rowData.data.requested_on) }}
      </template>
      <template #actionsTemplate="{ data: rowData }">
        <div class="actionButtons">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn-accept"
                @click="openModalDelete('accept_coaching', rowData.data)"
                color="#000000"
                v-bind="attrs"
                v-on="on"
                :disabled="isDisable"
              >
                Accept
              </v-btn>
            </template>
            <span>Accept coaching request</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn-decline"
                @click="openModalDelete('decline_coaching', rowData.data)"
                color="#000000"
                v-bind="attrs"
                v-on="on"
                :disabled="isDisable"
              >
                Decline
              </v-btn>
            </template>
            <span>Decline coaching request</span>
          </v-tooltip>
        </div>
      </template>
      <DxSorting mode="multiple" />
      <DxPaging :page-size="5" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
    </DxDataGrid>

    <v-dialog
      v-model="isActingAsAnotherUser"
      content-class="dialog-box-action-as-another"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-body d-flex flex-column align-center">
          <p class="cust-dialog-label">
            You cannot do this because you are acting as another user
          </p>
          <v-btn
            depressed
            rounded
            class="btn-ok"
            @click="closeDialog"
            color="#53a0de"
            >OK</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="declineRequest"
      persistent
      content-class="dialog-box-decline-request"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-header">
          <h2 class="cust-dialog-title">Decline request</h2>
        </div>
        <div class="cust-dialog-body">
          <v-row>
            <v-col cols="3"> Please provide a reason for your decline </v-col>
            <v-col cols="9">
              <v-form ref="reason">
                <v-text-field
                  :rules="rules"
                  hide-details="auto"
                  v-model="declineReason"
                  class="ms-6"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <div class="cust-dialog-footer d-flex py-2">
          <v-btn
            depressed
            rounded
            class="btn-decline me-4"
            @click="onDecline"
            color="#a7d42e"
            >Decline</v-btn
          >

          <v-btn
            depressed
            rounded
            class="btn-accept"
            @click="onCancel"
            color="#ff6c6c"
            >Discard</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import DxDataGrid, {
  //   DxSelection,
  DxColumn,
  DxPager,
  DxPaging,
  DxSorting,
} from "devextreme-vue/data-grid";
import moment from "moment";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxSorting,
  },
  props: ["column_names", "rows"],
  data() {
    return {
      isActingAsAnotherUser: false,
      act_as_me: true,
      declineRequest: false,
      selectedRow: {},
      declineReason: "",
      rules: [
        (value) => !!value || "Reason is required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      isDisable: true,
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    if (this.act_as_me == false) {
      return (this.isDisable = true);
    } else {
      return (this.isDisable = false);
    }
  },
  methods: {
    openModalDelete(action, rowData) {
      this.selectedRow = rowData;
      // if (this.act_as_me == false) {
      //   this.isActingAsAnotherUser = true;
      // } else {
      if (action == "decline_coaching") {
        this.declineRequest = true;
      } else {
        this.$emit("requestResponse", { rowData, action });
      }
      // }
    },
    closeDialog() {
      this.isActingAsAnotherUser = false;
    },

    sortByDate(rowData) {
      return moment(rowData["requested_on"]).format("YYYY/MM/DD");
    },

    formattedDate(date) {
      return moment(date).format("D-MMM-YYYY");
    },

    onCancel() {
      this.declineRequest = false;
      this.$refs.reason.reset();
    },
    onDecline() {
      const rowData = {
        ...this.selectedRow,
        declineReason: this.declineReason,
      };
      if (this.$refs.reason.validate()) {
        this.$emit("requestResponse", { rowData, action: "decline_coaching" });
        this.declineRequest = false;
        this.$refs.reason.reset();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.table1-container {
  padding: 40px;
}
.actionButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
}

.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}

.btn-decline {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  background-color: $red !important;
  // border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}

.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  background-color: $green !important;
  // border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}

.btn-ok {
  width: 64px !important;
  float: left !important;
  margin: 20px 10px 10px 10px !important;
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}

.cust-dialog {
  .cust-dialog-header {
    margin-bottom: 1rem;
    .cust-dialog-title {
      color: $blue;
      text-align: center;
    }
  }
  .cust-dialog-body {
    font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
    font-size: 1.08333333rem;
    font-weight: 400;
    line-height: 1.5;
    .cust-dialog-bodyContent {
      margin-top: 25px;
      width: max-content;

      .cust-dialog-labelContent {
        font-weight: bold;
        color: #008784;
        text-align: left;
      }
    }
    .cust-dialog-label {
      font-weight: bold;
      color: #666666;
      text-align: left;
    }
  }
}
</style>
<style lang="scss">
.v-dialog.dialog-box-decline-request {
  padding: 30px 30px 10px 30px;
  border-radius: 30px;
  background-color: $white;
  // width: max-content;
}
</style>
