//   January 01
const EditDate = (_date) => {
  if (_date) {
    let split_date = _date.split("-", 3);

    let _month = split_date[1];
    let _day = split_date[2];
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let selectedMonthName = months[parseInt(_month) - 1];
    return selectedMonthName + " " + _day;
  }
  return "";
};
export default EditDate;
