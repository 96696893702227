<template>
  <div class="popup-form-wrapper">
    <!-- <div class="header">
      <slot name="header"> </slot>
    </div> -->
    <v-row class="content pa-0">
      <v-col
        class="field"
        cols="12"
        sm="6"
        md="3"
        v-for="(field, index) in fields"
        :key="index"
      >
        <p class="label-text-top">{{ field.label }}</p>
        <v-text-field
          v-if="field.type === 'integer' && field.name === 'cle_credits'"
          v-model="points"
          disabled
          type="number"
          suffix="XP"
          required
          solo
          rounded
          flat
          hide-details
          class="text-field"
        >
        </v-text-field>
        <v-text-field
          v-if="field.type === 'date_time'"
          v-model="dateTimeFormatted"
          disabled
          solo
          rounded
          flat
          hide-details
          class="text-field"
        >
        </v-text-field>
        <v-autocomplete
          v-if="field.type === 'many2one' && field.name == 'la_type'"
          v-model="field.value"
          :items="getLearningActivityTypes"
          @change="pickLA($event)"
          item-text="text"
          item-value="value"
          solo
          rounded
          flat
          single-line
          class="text-field"
          required
          :rules="field.required ? fieldRequired : []"
          :validate-on-blur="true"
          hide-details="auto"
          type="text"
        ></v-autocomplete>
      </v-col>
      <v-col
        class="field"
        cols="12"
        sm="12"
        md="12"
        v-if="fields.length == 1 && fields[0].type == 'long_text'"
      >
        <v-textarea
          v-if="fields[0].type === 'long_text'"
          :value="fields[0].value"
          @input="($event) => this.$emit('input', $event)"
          no-resize
          height="144px"
          solo
          rounded
          flat
          class="text-field col-md-12"
          :rules="fields[0].required ? fieldRequired : []"
          :validate-on-blur="true"
          hide-details="auto"
          type="text"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const tacticalReadinesHelper = createNamespacedHelpers("tacticalReadines");
import moment from "moment";

export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      la_types_list: [],
      points: "",
      requested_on: "",
      fieldRequired: [(v) => !!v || "You must provide this field"],
    };
  },
  computed: {
    ...tacticalReadinesHelper.mapGetters(["getLearningActivityTypes"]),
    dateTimeFormatted() {
      if (this.fields.length > 1 && this.fields[2].type == "date_time") {
        return moment(this.fields[2].value).format("D/MMM/yyyy HH:mm");
      }
      return this.fields[2].value;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    pickLA(c) {
      let learning_act = this.getLearningActivityTypes.find(
        (la) => la.value == c
      );
      this.points = learning_act.credits;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-form-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 30px;
  background-color: #f6f6fb;

  .header {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: capitalize;
  }

  .content {
    .field {
      // min-width: 320px;
      // max-width: 320px;
      // padding: 0 0 20px 0;
      padding-bottom: 20px;
      // margin-right: 24px;
      &.one-field {
        margin-right: 0;
        min-width: 320px;
        max-width: 320px;
        padding: 0 20px;
      }
    }
  }
}
.label-text-top {
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  margin-left: 30px;
  text-align: start;
}
.theme--light.auto-complete {
  ::v-deep {
    .v-input__control {
      padding: 0 24px;
    }
  }
}
.theme--light.text-field {
  ::v-deep {
    .v-input__control {
      padding: 0 24px;
      .v-input__slot {
        .v-label {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .content {
    .field {
      &.one-field {
        min-width: 250px !important;
      }
    }
  }
  .theme--light.auto-complete {
    ::v-deep {
      .v-input__control {
        padding: 0 14px;
      }
    }
  }
  .theme--light.text-field {
    ::v-deep {
      .v-input__control {
        padding: 0 14px;
      }
    }
  }
}
</style>
