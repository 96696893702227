import axios from "./_axios";
import { API } from "./apiEndPoints";

export const reset_Password = (_email) => {
  let result = axios()({
    url: API.RESET_PASSWORD,
    method: "post",
    params: {
      login: _email,
      skip_raise_error: 1,
      base_url: `${process.env.https://pre-release.prd.nanoramic.com}`,
      route: "reset_password",
    },
  });
  return result;
};

export const change_Password = (_token, _password, _company_id) => {
  let result = axios()({
    url: API.CHANGE_PASSWORD,
    method: "post",
    params: {
      token: _token,
      password: _password,
      company_id: _company_id,
    },
  });
  return result;
};
