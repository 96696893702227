<template>
  <div class="bookClubCard">
    <div class="bookClubCard__thumbnail">
      <div
        class="book_img"
        :style="{ 'background-image': 'url(' + bookImg + ')' }"
        @click="goToBookPresentation"
      >
        <div class="see_amazon_img" @click.stop="goToBookAmazon"></div>
      </div>
    </div>
    <div class="bookClubCard__body">
      <div class="book-description">
        <span class="txt_bookName" @click="goToBookPresentation">{{
          bookName
        }}</span>
        <span class="txt_bookSubTitle">{{ bookSubTitle }}</span>
        <span class="txt_bookAuthor">By {{ bookAuthor }}</span>
        <span class="txt_bookDescription">{{ bookDescription }}</span>
        <a :href="linkToSeeMore">
          <span class="txt_more" @click="goToBookPresentation">see more</span>
        </a>
      </div>
      <div class="book-credits">
        <!-- <div v-if="bookLearningActivity" class="credits-action">
          <div v-if="bookCreditsGranted">
            <v-icon class="icon-blue">$checkBlue</v-icon>
            <span class="credits-requested">Credits Granted</span>
          </div>
          <div v-else-if="bookCreditsRequested">
            <v-icon class="icon-blue">$clockBlue</v-icon>
            <span class="credits-requested">Credits Requested</span>
          </div>
          <v-btn
            v-else
            :disabled="act_as_me == false"
            depressed
            rounded
            color="#53a0de"
            class="btn-request-credits"
            @click="onRequestCredits"
          >
            Request Credits
          </v-btn>
        </div> -->
        <div class="credits-xp">
          <span class="txt-credits-xp"> {{ bookCredits }} XP </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { requestCredits, submitCreditsRequest } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");
export default {
  name: "BookClubCard",
  props: {
    book: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      act_as_me: true,
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  computed: {
    // Background for thumbnail
    /*bookImg() {
      return this.book.cover
        ? `"data:image/jpeg;base64, ${this.book.cover}"`
        : "";
    },*/
    bookImg() {
      return this.book.cover_url ? this.book.cover_url : "";
    },
    bookName() {
      return this.book.name ? this.book.name : "";
    },
    bookSubTitle() {
      return this.book.subtitle ? this.book.subtitle : "";
    },
    bookAuthor() {
      return this.book.author ? this.book.author : "";
    },
    bookDescription() {
      return this.book.description ? this.book.description : "";
    },
    bookAmazonLink() {
      return this.book.amazon_link ? this.book.amazon_link : "";
    },
    bookCredits() {
      return this.book.credits ? this.book.credits : "0";
    },
    bookLearningActivity() {
      return this.book.learning_activity_type_id;
    },
    bookCreditsGranted() {
      return this.book.grantedCredits ? this.book.grantedCredits : false;
    },
    bookCreditsRequested() {
      return this.book.requestedCredits ? this.book.requestedCredits : false;
    },
    linkToSeeMore() {
      return `/book-presentation?${this.bookName}`;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    goToBookAmazon() {
      window.open(this.bookAmazonLink, "_blank");
    },
    goToBookPresentation() {
      this.$emit("setBookClubPageInfo");
      let url = `/book-presentation?${this.bookName}`;
      this.$router.push(url);
    },
    async onRequestCredits() {
      this.getLoading(true);
      if (!this.bookLearningActivity) {
        this.dispatchOpenAlert({
          type: "error",
          message: "This Book does not have a Learning Activity",
        });
      } else {
        try {
          const result = await requestCredits(
            "Request from employee portal",
            this.bookLearningActivity
          );
          if (result.status == 200) {
            await this.onSubmit(result.data.data.id);
          }
        } catch (error) {
          console.log("requestCreditsError==>", error);
        }
      }
      this.getLoading(false);
    },
    async onSubmit(_id) {
      try {
        const result = await submitCreditsRequest(_id);
        if (result.status == 200) {
          this.$emit("creditsRequestSucceded", this.bookName);
        }
      } catch (error) {
        console.log("SubmitCreditsError==>", error);
      }
    },
  },
};
</script>
<style lang="scss">
.bookClubCard {
  background: $light-blue-1 !important;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
  // width: 21.875rem;
  display: flex;
  flex-flow: row;
  padding: 20px;
  &__thumbnail {
    min-width: 148px;
    height: 234px;
    border-radius: 16px;
    padding: 16px;
    background-color: white;
    margin-right: 20px;
    .book_img {
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100%;
      min-width: 100%;
      border-radius: 16px;
      position: relative;
      .see_amazon_img {
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 28px;
        width: 100%;
        background-image: url("~@/assets/img/see_on_amazon.svg");
        bottom: -0.5px;
      }
    }
  }
  &__body {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    .book-description {
      display: flex;
      flex-flow: column;
      .txt_bookName {
        margin-top: 1rem; //16px;
        font-weight: 600;
        font-size: 1rem; //16px;
        line-height: 1.25rem; //20px;
        text-transform: capitalize;
        color: $black;
        cursor: pointer;
      }
      .txt_bookSubTitle {
        margin-top: 0.375rem; //6px;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem; //14px;
        line-height: 1.0625rem; //17px;
        text-transform: capitalize;
        color: $black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .txt_bookAuthor {
        margin-top: 0.375rem; //6px;
        font-style: italic;
        font-weight: normal;
        font-size: 0.875rem; //14px
        line-height: 1.0625rem; //17px;
        color: $black;
      }
      .txt_bookDescription {
        margin-top: 1rem; //16px
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem; //14px
        line-height: 1.0625rem; //17px
        color: $black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .txt_more {
        font-size: 0.875rem; //14px
        color: $blue;
        text-decoration-line: underline;
        line-height: 1.0625rem; //17px
        cursor: pointer;
      }
    }
    .book-credits {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // flex-wrap: nowrap;
      flex-wrap: wrap;
      row-gap: 5px;
      .credits-action {
        justify-content: flex-start;
        .icon-blue {
          margin-right: 0.875rem; //14px
        }
        .credits-requested {
          font-weight: 400;
          font-size: 0.875rem;
          color: $blue;
          line-height: 1.3125rem; //21px
          width: min(30px, 70%);
        }
        .btn-request-credits {
          font-size: 0.875rem; //14px
          font-weight: 600;
          line-height: 1.071875rem; //17.15px
          color: $white;
          text-transform: none;
          letter-spacing: normal;
          width: 160px;
          height: 48px;
        }
      }
      .credits-xp {
        border-radius: 30px;
        border: 1px solid $blue;
        padding: 0px 10px;
        text-align: center;
        // justify-content: flex-end;
        margin-right: 0;
        // display: inline;
        .txt-credits-xp {
          // position:relative;
          word-break: keep-all;
          font-size: 1.5rem; //24px;
          font-weight: 900;
          color: $blue;
          display: inline-flex;
          // display: flex;
          // flex-wrap: nowrap;
          // height: 26px;
          // overflow: hidden;
          line-height: 0px;
          vertical-align: middle;
          font-family: "Gilroy-Bold";
          // -webkit-font-smoothing: antialiased;
          // text-rendering: optimizeLegibility;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .book-credits {
    display: flex;
    flex-direction: column !important;
    // justify-content: flex-start;
    // align-items: center;
    flex-wrap: nowrap;
  }
}
</style>
