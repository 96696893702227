<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Credit Requests</h1>
            <p class="text">
              The list of all credit requests and request information including
              current state.
            </p>
          </div>
          <div class="main_section__content">
            <Filters
              :filters="filters"
              :searchVal="query"
              searchPlaceholder="Search by description"
              @selectedTag="filterByTags"
              @search="filterByQuery"
            />

            <SelectedTags
              :selectedTags="selectedTags"
              @removeTag="removeFilter"
              @removeTags="removeFilters"
              :class="{ eventActive: getMobileMenuActive }"
            />

            <!-- <transition
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
            > -->
            <div v-if="getDialogComplete">
              <DisplayComplete
                :type_dialog="getDataCompleted.type_dialog"
                :title="getDataCompleted.title"
                :subtitle1="getDataCompleted.subtitle1"
                :closeText="'Back to Credit Request list'"
                @close="closeCompleteDialog"
              ></DisplayComplete>
            </div>
            <div v-else>
              <div v-if="!notFound">
                <div class="not-filter" v-show="notFilter">
                  <div class="not-filter__img-fluid"></div>
                  <div class="not-filter__text">
                    <p class="not-filter-title">
                      No event was found matching your filters
                    </p>
                    <p class="not-filter-subtitle">
                      Please, try to choose other filters or delete some of
                      them.
                    </p>
                  </div>
                </div>
                <div v-show="!notFilter" class="eventsTable">
                  <EventsTable
                    :cols="credit_cols"
                    :rows="credit_rows"
                    :class="{ eventActive: getMobileMenuActive }"
                    :tableType="tableType"
                  />
                  <PopupCreditRequest />
                </div>
              </div>
              <div v-else>
                <div class="not-found">
                  <div class="not-found__img-fluid-credit"></div>
                  <div class="not-found__space-text"></div>
                  <p class="not-found__text">There are no requests yet</p>
                  <PopupCreditRequest class="mb-5" />
                </div>
              </div>
              <!-- </transition> -->

              <Paginate
                v-if="!notFound && !notFilter"
                :selectedPage="selectedPageNumber"
                :pagecount="eventsPagination"
                @changePageNumber="changePageNumber"
                :class="{ eventActive: getMobileMenuActive }"
              />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/learning-events/EventsFilters";
import EventsTable from "@/components/credit-requests/EventsTable";
import Paginate from "@/components/learning-events/Paginate";
import SelectedTags from "@/components/course-catalog/SelectedTags";
import PopupCreditRequest from "@/components/credit-requests/PopupCreditRequest";
import DisplayComplete from "@/common/DisplayComplete";
import { getUserCreditRequest } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
const dialogCompleteHelpers = createNamespacedHelpers("dialogComplete");
import EditDateTime from "@/utils/edit_date_time_2.js";

export default {
  name: "CreditRequests",
  components: {
    Filters,
    Paginate,
    SelectedTags,
    EventsTable,
    PopupCreditRequest,
    DisplayComplete,
  },
  data() {
    return {
      filters: {
        status: [],
      },
      tableType: "credits",
      show: true,
      notFilter: false,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      credit_cols: [
        "beneficiary",
        "requester",
        "type",
        "description",
        "created by",
        "requested on",
        "status",
      ],
      fullCreditRows: [],
      setCreditRows: [],
      credit_rows: [],
      request_popup: false,
    };
  },
  computed: {
    notFound() {
      return this.fullCreditRows.length == 0;
    },
    ...setCoursePageInfoHelpers.mapGetters(["getMobileMenuActive"]),
    ...dialogCompleteHelpers.mapGetters([
      "getDialogComplete",
      "getDataCompleted",
    ]),
  },
  created() {
    this.getCreditRequestFromAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...dialogCompleteHelpers.mapMutations(["setDialogComplete"]),
    async getCreditRequestFromAPI() {
      this.getLoading(true);
      await this.getDisplayCreditRequest();
      this.getLoading(false);
    },

    async getDisplayCreditRequest() {
      try {
        let result = await getUserCreditRequest();
        if (result.status == 200) {
          this.fullCreditRows = [];
          const gettingCredits = result.data.data.response.events;

          if (gettingCredits.length != 0) {
            this.filters = {
              status: ["Draft", "Approved", "Submitted", "Rejected"],
            };
            for (let index = 0; index < gettingCredits.length; index++) {
              const event = gettingCredits[index];
              let row = {
                beneficiary: event.employee_name_full
                  ? event.employee_name_full
                  : "-",
                requester: event.requested_by ? event.requested_by : "-",
                type: event.type ? event.type : "-",
                description: event.description ? event.description : "-",
                "created by": event.create_name ? event.create_name : "-",
                "requested on": event.requested_on
                  ? EditDateTime(event.requested_on)
                  : "-",
                status: "",
              };

              if (event.state) {
                let st_status = event.state;
                st_status = st_status.split("");
                st_status[0] = st_status[0].toUpperCase();
                st_status = st_status.join("");
                row.status = st_status;
              } else {
                row.status = "-";
              }

              this.fullCreditRows.push(row);
            }

            this.setCreditRows = this.fullCreditRows;

            this.firstViewCredits(this.setCreditRows);
          }
        }
      } catch (error) {
        console.log("GetLearningEventsError==>", error);
      }
    },
    firstViewCredits(_creditsList) {
      if (_creditsList.length == 0) {
        this.notFilter = true;
      } else {
        this.notFilter = false;
        this.selectedPageNumber = 1;
        /*_creditsList.sort(function (a, b) {
          return a.deadlineDate - b.deadlineDate;
        });*/
        this.eventsPagination = Math.ceil(_creditsList.length / 10);
        this.getViewCreditsByPagination(_creditsList, this.selectedPageNumber);
      }
    },
    getViewCreditsByPagination(_creditsList, _pageNum) {
      let startIndex = 10 * (_pageNum - 1);
      let endIndex = 10 + 10 * (_pageNum - 1);
      this.credit_rows = [];

      if (_creditsList.length > endIndex) {
        for (startIndex; startIndex < endIndex; startIndex++) {
          const element = _creditsList[startIndex];
          this.credit_rows.push(element);
        }
      } else {
        for (startIndex; startIndex < _creditsList.length; startIndex++) {
          const element = _creditsList[startIndex];
          this.credit_rows.push(element);
        }
      }
    },
    changePageNumber(_value) {
      this.selectedPageNumber = _value;
      this.getViewCreditsByPagination(
        this.setCreditRows,
        this.selectedPageNumber
      );
      // this.show = false;
    },
    // beforeLeave() {
    //   this.getViewCreditsByPagination(
    //     this.setCreditRows,
    //     this.selectedPageNumber
    //   );
    // },
    // afterLeave() {
    //   this.show = true;
    // },
    removeFilters() {
      this.selectedTags = [];

      this.setCreditRows = this.fullCreditRows;
      this.firstViewCredits(this.setCreditRows);
    },
    removeFilter(filter) {
      const index = this.selectedTags.findIndex((t) => filter === t);

      this.selectedTags.splice(index, 1);
      if (this.selectedTags.length === 0) {
        this.removeFilters();
      } else {
        this.setCreditRows = this.fullCreditRows.filter((c) =>
          this.filterTags(c, this.selectedTags)
        );
        this.firstViewCredits(this.setCreditRows);
      }
    },
    filterByTags(tag) {
      this.query = "";
      if (this.selectedTags) {
        if (!this.selectedTags.includes(tag)) this.selectedTags.push(tag);
      } else {
        this.selectedTags.push(tag);
      }

      this.setCreditRows = this.fullCreditRows.filter((c) =>
        this.filterTags(c, this.selectedTags)
      );

      this.firstViewCredits(this.setCreditRows);
    },
    filterTags(a, b) {
      let sendBack = false;
      if (b.includes(a.status)) {
        sendBack = true;
      }
      return sendBack;
    },
    filterByQuery(value) {
      this.selectedTags = [];
      this.query = value;
      this.setCreditRows = this.fullCreditRows.filter((item) => {
        return this.query
          .toLowerCase()
          .split(" ")
          .every((v) => item.description.toLowerCase().includes(v));
      });
      this.firstViewCredits(this.setCreditRows);
    },
    openPopup() {
      this.request_popup = true;
    },
    closeCompleteDialog() {
      this.setDialogComplete(false);
      this.getCreditRequestFromAPI();
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
