<template>
  <v-expansion-panels accordion class="na-panel">
    <v-expansion-panel v-for="(item, i) in items" :key="i">
      <v-expansion-panel-header class="na-header na-text--light-gray">
        {{ item.question }}
        <template v-slot:actions>
          <v-icon size="26" class="content-arrow"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="na-content">
        <div class="content-dot-text" v-if="item.answer">
          <div class="content-dot"></div>
          <span class="na-content-text na-text-sm">{{ item.answer }}</span>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-arrow {
  color: $blue !important;
}
.na-panel {
  margin-bottom: 42px;
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
  .na-header {
    padding: 20px 0;
    border-radius: 0;
    font-size: 16px;
    line-height: 19px;
  }

  .na-content {
    margin-bottom: 20px;
    .content-dot-text {
      display: flex;
      flex-flow: row;
      justify-content: left;
      align-items: center;
      .content-dot {
        &:before {
          content: "•";
          font-size: 40px;
          font-weight: 900;
          margin-right: 5px;
          vertical-align: -15%;
          color: $blue;
        }
        // width: 8px;
        // height: 8px;
        // padding-right: 8px;
        // background-image: url("../../assets/faq_circle_img.svg");
      }
      .na-content-text {
        padding: 0px 20px 0px 12px;
        font-weight: 600;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .na-panel {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
