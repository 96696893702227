<template>
  <div>
    <!-- <div class="orgchart-view">
      <OrgChart />
    </div> -->
    <ComingSoon />
  </div>
</template>

<script>
// import OrgChart from "@/components/orgchart/OrgChart";
import ComingSoon from "@/common/ComingSoon.vue";
export default {
  name: "OrgChartView",
  components: {
    // OrgChart,
    ComingSoon,
  },
};
</script>
<style lang="scss">
.orgchart-view {
  margin-left: 5rem;
  margin-top: 4rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
  // background: red;
}
</style>
