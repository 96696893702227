<template>
  <v-row no-gutters class="description-page__content">
    <v-col cols="12" md="6" sm="12" class="pa-0 col-img">
      <div class="content-img">
        <div class="content-img__img" :style="courseDetail.bg"></div>
      </div>
    </v-col>
    <v-col cols="12" md="6" sm="12" class="pa-0 mt-4">
      <div class="page-content">
        <div class="description-tag-list">
          <div
            class="description-tag-list__tag"
            v-for="tag in courseDetail.tags"
            :key="tag.id"
          >
            {{ tag }}
          </div>
        </div>
        <div class="description-txt">
          <h1 class="detail-title">{{ courseDetail.title }}</h1>
          <div class="min-step">
            <div class="min-step__min">
              <img class="min-img" src="@/assets/img/minute-red-dot.svg" />
              <span class="min-text">&nbsp;{{ courseDetail.minutes }}</span>
            </div>
            <div class="min-step__step">
              <img class="step-img" src="@/assets/img/step-green-dot.svg" />
              <span class="step-text">&nbsp;{{ courseDetail.steps }}</span>
            </div>
          </div>
          <div
            v-if="
              courseDetail.prerequisites &&
              courseDetail.prerequisites.length > 0
            "
            class="prev-course"
          >
            <p class="prev-course__title">
              In order to complete this eLearning course, you first need to
              complete the following courses:
            </p>
            <router-link
              v-for="pre in courseDetail.prerequisites"
              class="prev-course__tag"
              :class="
                pre.completed
                  ? 'prev-course__tag__green'
                  : 'prev-course__tag__red'
              "
              :key="pre.id"
              target="_blank"
              :to="{ path: `/course-presentation?${pre.name}` }"
            >
              <span
                class="prev-course__tag__txt"
                :class="
                  pre.completed
                    ? 'prev-course__tag__txt__green'
                    : 'prev-course__tag__txt__red'
                "
                >{{ pre.name }}</span
              >
            </router-link>
          </div>
          <p class="content-info" v-if="courseDetail.completed">
            You completed this course!
            <br />You were granted
            {{ courseDetail.points }}
            Credits.
          </p>
          <v-btn
            depressed
            rounded
            color="#ffa500"
            height="48"
            width="154"
            class="content-btn-action"
            v-if="preview"
            @click="setPreviewCourse"
          >
            Preview
          </v-btn>
          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            color="#ffa500"
            height="48"
            width="154"
            class="content-btn-action"
            v-else-if="courseDetail.completed"
            @click="setStartCourse"
          >
            Review
          </v-btn>
          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            color="#a7d42e"
            height="48"
            width="154"
            class="content-btn-action"
            v-else-if="courseDetail.button == 'start'"
            @click="setStartCourse"
          >
            Start
          </v-btn>
          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            color="#53a0de"
            height="48"
            width="154"
            class="content-btn-action"
            v-else-if="courseDetail.button == 'continue'"
            @click="setStartCourse"
          >
            Continue
          </v-btn>
          <v-btn
            :disabled="act_as_me == false"
            depressed
            rounded
            color="#ddde00"
            height="48"
            width="154"
            class="content-btn-action content-enroll"
            v-else-if="courseDetail.button == 'enroll'"
            @click="setEnrollCourse"
          >
            Enroll
          </v-btn>
          <div class="description-course-about" v-if="courseDetail.description">
            <h1 class="description-course-about__title">About this course</h1>
            <p class="description-course-about__text">
              {{ courseDetail.description }}
            </p>
          </div>
          <div class="description-course-content">
            <div class="description-course-content__title">
              <p>Content</p>
            </div>
            <div class="description-course-content__list">
              <ul class="content-list">
                <li
                  class="content-list__item"
                  v-for="item in courseDetail.content"
                  :key="item.id"
                >
                  <!-- <img
                    v-if="!Object.values(item)[2]"
                    class="content-list-dot"
                    src="@/assets/img/content-list-blue-dot.svg"
                  /> -->
                  <img
                    v-if="!item.is_category"
                    class="content-list-dot"
                    src="@/assets/img/content-list-blue-dot.svg"
                  />
                  <p
                    class="content-list-text"
                    :class="{ content_title: Object.values(item)[2] }"
                  >
                    <!-- {{ Object.values(item)[1] }} -->
                    {{ item.name }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    courseDetail: {
      required: true,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      act_as_me: true,
    };
  },
  computed: {
    isCourseToApprove() {
      return this.courseDetail.state
        ? this.courseDetail.state == "pending_approval"
        : false;
    },
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  methods: {
    setStartCourse() {
      this.$emit("setStartCourse");
    },
    setEnrollCourse() {
      this.$emit("setEnrollCourse");
    },
    setPreviewCourse() {
      this.$emit("setPreviewCourse");
    },
  },
};
</script>
<style lang="scss" scoped>
// @/views/CoursePresentation.vue

.description-page {
  &__content {
    background: $white;
    border-radius: 30px;
    padding: 40px 60px;
    // padding: 2.9375rem !important;
    // margin-top: 20px;
    .col-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .content-img {
        padding-right: 2.9375rem;
        vertical-align: middle;
        &__img {
          width: 100%;
          height: 40rem;
          // height: 50.375rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 1.875rem;
          border: 1px solid transparent;
        }
      }
    }
    .page-content {
      // display: inline-block;
      text-align: left;
      font-family: Gilroy;
      .description-tag-list {
        // margin-top: 1.25rem;
        margin-left: 0.1875rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        // width: 46.25rem;
        &__tag {
          background: $yellow;
          border-radius: 1.875rem;
          margin-right: 0.375rem;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          font-weight: bold;
          padding: 0.4375rem 1.0625rem;
          color: white;
          margin-bottom: 0.5rem;
        }
      }
      .description-txt {
        padding-right: 2.9375rem;
        padding-bottom: 2.9375rem;
        padding-left: 2.9375rem;
        .detail-title {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.8125rem;
          text-transform: capitalize;
          color: #323a44;
        }
        .min-step {
          margin-top: 1.25rem;
          margin-left: 0.25rem;
          &__min {
            display: inline-block;
            margin-right: 1rem;
            .min-img {
              padding-right: 0.375rem;
              vertical-align: middle;
            }
            .min-text {
              font-weight: 500;
              line-height: 1rem;
              font-size: 0.875rem;
              color: #323a44;
            }
          }
          &__step {
            vertical-align: middle;
            display: inline-block;
            margin-left: 1rem;
            .step-img {
              padding-right: 0.375rem;
              vertical-align: middle;
            }
            .step-text {
              font-weight: 500;
              line-height: 1rem;
              font-size: 0.875rem;
              color: #323a44;
            }
          }
        }
        .prev-course {
          margin-top: 19px;
          margin-bottom: 8px;
          &__title {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.3125rem;
            color: $black;
          }
          &__tag {
            border-radius: 30px;
            margin-right: 6px;
            &__green {
              background-color: #a7d42e33;
              &:link {
                color: $green-2 !important;
              }
              &:visited {
                color: $green-2 !important;
              }
            }
            &__red {
              background-color: #ff6c6c33;
              &:link {
                color: $red !important;
              }
              &:visited {
                color: $red !important;
              }
            }
            &__txt {
              margin: 3px 10px;
              font-size: 0.625rem;
              font-weight: 600;
              // line-height: 0.875rem;
              vertical-align: text-top;
              word-break: keep-all;
              &__green {
                color: $green-2;
              }
              &__red {
                color: $red;
              }
            }
          }
        }
        .content-info {
          padding-left: 0.25rem;
          padding-top: 1.25rem;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.3125rem;
          max-width: 18.75rem;
          color: #323a44;
        }
        .content-btn-action {
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 1.0625rem;
          margin-top: 1rem;
          margin-left: 0.1875rem;
          color: white;
          text-transform: none;
          letter-spacing: normal;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.6);
        }
        .content-enroll {
          color: #323a44 !important;
        }
        .description-course-about {
          margin-top: 1.875rem;
          // max-width: 46.25rem;
          margin-left: 0.1875rem;
          &__title {
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            // text-decoration-line: underline;
            color: #53a0de;
            padding-bottom: 1rem;
          }
          &__text {
            padding-top: 1rem;
            color: #323a44;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.312rem;
          }
        }
        .description-course-content {
          margin-top: 1.875rem;
          margin-left: 0.1875rem;
          &__title {
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            // text-decoration-line: underline;
            color: #53a0de;
            padding-bottom: 1rem;
          }
          &__list {
            .content-list {
              &__item {
                padding-bottom: 0.75rem;
                display: flex;

                .content-list-dot {
                  padding-right: 0.75rem;
                }
                .content-list-text {
                  font-size: 0.875rem;
                  line-height: 1.3125rem;
                  font-weight: 500;
                  color: #323a44;
                }
                .content_title {
                  font-weight: 600 !important;
                  font-size: 0.95rem !important;
                  line-height: 1.4rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .description-page__content .content-img__img {
    height: 28rem;
  }
}
@media screen and (max-width: 1400px) {
  .description-page__content .content-img__img {
    height: 25rem;
  }
}
@media screen and (max-width: 1100px) {
  .description-page__content .content-img__img {
    height: 20rem;
  }
}
@media screen and (max-width: 959px) {
  .description-page__content {
    .content-img {
      padding-right: 0.5rem !important;
      .content-img__img {
        width: 4.2rem;
        height: 3rem;
        border-radius: 0.8rem;
      }
    }
    .page-content {
      margin-top: 1.1rem;
    }
  }
  .description-page__content .page-content .detail-title {
    font-size: 1rem;
  }
  .description-txt {
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1rem !important;
  }
}
</style>
