<template>
  <div class="login-info">
    <el-alert
      class="alert-container is-light"
      v-if="alert.type"
      :title="alert.message"
      @close="closeError"
      :type="alert.type"
    >
    </el-alert>
    <el-form
      class="login-form"
      :model="model"
      :rules="rules"
      ref="form"
      @submit.native.prevent="login"
    >
      <!-- <el-form-item prop="email">
        <el-input
          v-model="model.email"
          placeholder="Email"
          type="email"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="model.password"
          placeholder="Password"
          type="password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <label class="forget-password" @click="resetPassword"
        >Forgot password?</label
      > -->
      <!-- SIGN IN WITH GOOGLE -->
      <!-- <div id="g_id_onload"
        data-client_id="694466881684-mv3hd2clor1q5hb6dmtil5ajqbogl8ap.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-callback="handleLogin"
        data-nonce="">
      </div>

      <div class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="filled_blue"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left">
      </div> -->

      <!-- <div
        id="g_id_onload"
        data-client_id="694466881684-mv3hd2clor1q5hb6dmtil5ajqbogl8ap.apps.googleusercontent.com"
        :data-auto_prompt="true"
        data-nonce="<randomly generated UUID>"
        data-login_uri="http://localhost:3000/login"
      >
      </div>
      <div
        class="g_id_signin"
        data-type="standard"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        data-width="269"
      >
      </div> -->
      <!-- SIGN IN WITH GOOGLE -->

      <el-form-item class="Google_Login">
        <!-- <img
          class="Google_btn_img"
          src="@/assets/img/GoogleSign_button.svg"
          @click="signInWithGoogle"
        /> -->
        <!-- SIGN IN WITH GOOGLE -->
        <div id="buttonDiv"></div>
        <!-- SIGN IN WITH GOOGLE -->
        <!-- submit button -->
        <!-- <el-button
          :style="{
            backgroundImage: 'url(' + img_button + ')',
          }"
          :loading="loading"
          class="login-button"
          native-type="submit"
          block
        ></el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/*import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);*/
import { loginEmailPW, loginWithGoogleToken, getEmployeeInfo } from "@/api";
import { createNamespacedHelpers } from "vuex";
const userInfoHelpers = createNamespacedHelpers("userInfo");
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");

export default {
  name: "LoginForm",
  data() {
    return {
      img_button: require("../../assets/img/Arrow_ button.png"),
      validCredentials: {
        email: "lightscope",
        password: "lightscope",
      },
      model: {
        email: "",
        password: "",
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            min: 4,
            message: "Email length should be at least 5 characters",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Add correct email address",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          { required: true, message: "Password is required", trigger: "blur" },
          {
            min: 5,
            message: "Password length should be at least 5 characters",
            trigger: "blur",
          },
        ],
      },
      alert: {},
    };
  },
  mounted() {
    // SIGN IN WITH GOOGLE
    this.loadGoogleButton();
    // SIGN IN WITH GOOGLE
  },
  computed: {},
  methods: {
    ...userInfoHelpers.mapActions(["dispatchUser"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    ...mapActions(["getLoading"]),
    loadGoogleButton() {
      window.google.accounts.id.initialize({
        client_id:
          "694466881684-mv3hd2clor1q5hb6dmtil5ajqbogl8ap.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
        context: "signin",
        ux_mode: "popup",
        auto_prompt: false,
        nonce: "<randomly generated UUID>",
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          type: "standard",
          shape: "rectangular",
          theme: "outline",
          text: "signin_with",
          size: "large",
          logo_alignment: "left",
          width: "256px",
        }
      );
      window.google.accounts.id.prompt(); // also display the One Tap dialog
    },
    simulateLogin() {
      return new Promise((resolve) => {
        setTimeout(resolve, 300);
      });
    },
    // async login() {
    //   this.closeError();
    //   try {
    //     const isValid = await this.$refs.form.validate();
    //     if (!isValid) {
    //       return;
    //     }
    //     this.loading = true;
    //     this.getLoading(true);
    //     let result = await loginEmailPW(this.model);
    //     if (result.status == 200) {
    //       this.goWithToken_UID_Expire(
    //         result.data.access_token,
    //         result.data.user_id,
    //         result.data.expires_in
    //       );
    //       this.loading = false;
    //     }
    //   } catch (error) {
    //     this.getLoading(false);
    //     console.log("loginError===>", JSON.stringify(error));
    //     let alert = {
    //       type: "error",
    //       message: "Login failed. Please try again",
    //     };
    //     if (!error)
    //       alert.message = "Please enter the correct Email and Password";
    //     this.showError(alert);
    //     this.loading = false;
    //   }

    //   // await this.simulateLogin();
    // },
    showError(data = {}) {
      this.alert = data;
    },
    resetPassword() {
      this.$emit("on-switch", "ResetPasswordForm");
    },
    async handleCredentialResponse(response) {
      // decodeJwtResponse() is a custom function defined by you
      // to decode the credential response.
      console.log(response);
      this.getLoading(true);
      try {
        let result = await loginWithGoogleToken(response.credential);
        console.log(result);
        if (result.status == 200) {
          this.goWithToken_UID_Expire(
            result.data.access_token,
            result.data.uid,
            result.data.expires_in
          );
        }
      } catch (error) {
        console.log(error);
      }
      //  const responsePayload = decodeJwtResponse(response.credential);

      //  console.log("ID: " + responsePayload.sub);
      //  console.log('Full Name: ' + responsePayload.name);
      //  console.log('Given Name: ' + responsePayload.given_name);
      //  console.log('Family Name: ' + responsePayload.family_name);
      //  console.log("Image URL: " + responsePayload.picture);
      //  console.log("Email: " + responsePayload.email);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    async signInWithGoogle() {
      this.closeError();
      this.model.email = "";
      this.model.password = "";
      try {
        this.$store.commit("resetState");
        const googleUser = await this.$gAuth.signIn();
        this.getLoading(true);
        const googleAccessToken = googleUser.getAuthResponse(true).access_token;
        // console.log(googleAccessToken);
        if (googleAccessToken) {
          let result = await loginWithGoogleToken(googleAccessToken);
          if (result.status == 200) {
            this.goWithToken_UID_Expire(
              result.data.access_token,
              result.data.uid,
              result.data.expires_in
            );
          }
        }
      } catch (error) {
        this.getLoading(false);
        console.log("signWithGoogleError===>", JSON.stringify(error));
        if (!error) {
          let alert = {
            type: "error",
            message: "Please try again, now loading..",
          };
          this.showError(alert);
        }
      }
    },
    closeError() {
      let alert = {
        type: "",
        message: "",
      };
      this.showError(alert);
    },
    async goWithToken_UID_Expire(_token, _uid, _expire) {
      localStorage.clear();
      localStorage.setItem("token", _token);
      const createdDate = Date.now();
      const expireDate = parseInt(createdDate) + parseInt(_expire);
      localStorage.setItem("expireDate", expireDate);
      localStorage.setItem("user_id", _uid);
      localStorage.setItem("act_as_me", true);
      try {
        const result = await getEmployeeInfo();
        if (result.status == 200) {
          const response = result.data.data.response;
          if (
            response &&
            Object.keys(response).length > 0 &&
            Object.getPrototypeOf(response) === Object.prototype
          ) {
            localStorage.setItem("employee_id", response.id);
            await this.dispatchUser();
            if (response.can_act_as_another) {
              const principalUser = {
                token: _token,
                user_id: _uid,
                employee_id: response.id,
                expireDate: expireDate,
              };
              localStorage.setItem("user1", JSON.stringify(principalUser));
            }
            let url = `/`;
            this.$router.push(this.$route.query?.nextUrl || url);
          } else {
            throw "Access Token Expired";
          }
        }
      } catch (error) {
        console.log("getEmployeeInfoError==>", error);
        this.dispatchOpenAlert({
          type: "error",
          message: error ? error : "Something went wrong",
        });
        localStorage.clear();
      } finally {
        this.getLoading(false);
      }
    },
  },
};
</script>
<style lang="scss">
.login-info {
  margin-left: 9.9375rem;
  margin-top: 3.125rem;
  .alert-container.is-light {
    margin-bottom: 1.875rem;
    max-width: 20rem;
  }
  .login-form {
    max-width: 20rem;
    .el-input__inner {
      background: #f6f6fb !important;
      border-radius: 1.875rem;
      height: 3rem;
      border: 1px solid #f6f6fb;
      margin-bottom: 0.625rem;
      color: #323a44 !important;
    }
    .forget-password {
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 0.9375rem;
      color: #af101e;
      // margin-left: 13.75rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
    }
    .forget-password:hover {
      color: #007c89;
    }
    .Google_Login {
      margin-left: 0rem;
      margin-top: 3.1875rem;
      margin-bottom: 5rem;
      .Google_btn_img {
        cursor: pointer;
        margin-left: 0rem;
        margin-top: 0rem;
        max-width: 16rem;
        max-height: 3rem;
        border-radius: 1.875rem;
        vertical-align: top;
      }
      .login-button {
        margin-left: 1rem;
        width: 3rem;
        height: 3rem;
        // background-image: url(https://www.linkpicture.com/q/Arrow_-button.svg) !important;
        background-size: cover;
        border-radius: 1.875rem;
      }
    }
    .el-form-item__error {
      padding-top: 0 !important;
    }
  }
}
@media screen and (min-width: 959px) and (max-width: 1723px) {
  .login-info {
    margin-left: 3.75rem !important;
    margin-right: 1rem !important;
  }
}
@media screen and (max-width: 575px) {
  .login-info {
    text-align: center;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    .alert-container.is-light {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .login-form {
      margin-left: auto !important;
      margin-right: auto !important;
      .forget-password {
        text-align: right;
      }
    }
  }
}
</style>
