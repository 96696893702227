<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="page_router">
            <span class="txt_router text_redirect" @click="redirect()"
              >{{ pronounEmployee }} Readiness</span
            >
            <div class="img_arrow"></div>
            <div class="txt_router">{{ pronounEmployee }} Current Responsibilities</div>
          </div>
          <div class="main_section__header">
            <h1 class="main_section-title">
              {{ pronounEmployee }} Current Responsibilities
            </h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <!-- <div class="not-filter" v-show="!notFilter">
                <div class="not-filter__img-fluid"></div>
                <div class="not-filter__text">
                  <p class="not-filter-subtitle">
                    Please, try to choose other filters or delete some of them.
                  </p>
                </div>
              </div> -->
              <div class="eventsTable">
                <TableCurrentResp
                  :rows="rows"
                  :column_names="resp_cols"
                  :disabled_button="not_current_user"
                />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
                <!-- <div class="not-found__text"></div> -->
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import TableCurrentResp from "@/components/current-responsibilities/TableCurrentResp.vue";
import { tableResponsibilities } from "@/utils/table_your_jobs";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);

export default {
  name: "CurrentResponsibilities",
  components: {
    TableCurrentResp,
  },
  data() {
    return {
      pronounEmployee: "Your",
      not_current_user: false,
      filters: {
        status: [],
      },
      resp_cols: [
        "Commitment / Responsibility",
        "Your current roles including this responsibility",
        "Tactical Readiness",
        "Proficiency Badge",
        "Acknowledged On",
      ],
      rows: [],
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    this.getLoading(true);
    await this.getDataTable();
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataTable() {
      if (this.$route.name == "Your Current Responsibilities") {
        this.rows = await tableResponsibilities();
      } else if (this.$route.name == "Employee Responsibilities") {
        this.pronounEmployee = "";
        this.not_current_user = true;
        const id_employee = Object.values(this.$route.params)[0];
        const employee_data = this.employeeNameJobList.find(
          (e) => e.id == id_employee
        );
        this.pronounEmployee = employee_data.name;
        this.rows = await tableResponsibilities(id_employee);
      }
    },
    redirect() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
.page_router {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .txt_router {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7f868e;
  }
  .text_redirect {
    color: #7f868e;
  }
  .text_redirect:hover {
    cursor: pointer;
    color: $blue;
    text-decoration-line: underline;
  }
  .img_arrow {
    background-image: url("~@/assets/img/arrow_right.svg");
    width: 5px;
    height: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
