<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Course Catalog</h1>
            <p class="text">
              All your courses and learning plans in which you’re enrolled,
              including all your courses and learning plans in progress and
              already completed.
            </p>
          </div>
          <div class="main_section__content">
            <Filters
              :filtersTags="filtersTags"
              :searchVal="query"
              @selectedTag="filterByTags"
              @search="filterByQuery"
              :searchPlaceholder="searchPlaceholder"
            />
            <SelectedTags
              :selectedTags="selectedTags"
              :selectedTag="selectedTag"
              @removeTagOne="removeFilterOne"
              @removeTag="removeFilter"
              @removeTags="removeFilters"
              :class="{ eventActive: getMobileMenuActive }"
            />
            <transition
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
            >
              <div v-if="show">
                <div class="not-found" ref="notFoundImg" v-show="notFound">
                  <div class="not-found__img-fluid-events"></div>
                  <div class="not-found__space-text"></div>
                  <p class="not-found__text">
                    No course was found matching your filters
                  </p>
                  <p class="not-found__subtitle">
                    Please, try to choose other filters or delete some of them.
                  </p>
                </div>
                <div v-show="!notFound" ref="cardsGrid" class="cards-grid">
                  <CourseCard
                    v-for="course in page"
                    :key="course.id"
                    :course="course"
                    @setTag="filterByTag"
                    :allCourses="allList"
                    @setCoursePageInfo="setCoursePageInfo"
                    :class="{ courseActive: getMobileMenuActive }"
                  />
                </div>
              </div>
            </transition>
            <Paginator
              v-if="!notFound"
              :currentPage="currentPage"
              :pageCount="pageCount"
              :totalPages="totalPages"
              @updateRange="setPaginationProcess"
              :class="{ eventActive: getMobileMenuActive }"
            />
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/course-catalog/Filters";
import CourseCard from "@/components/course-catalog/CourseCard";
import Paginator from "@/components/course-catalog/Paginator";
import SelectedTags from "@/components/course-catalog/SelectedTags";
import { getTagGroup, getCourses } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
export default {
  name: "CourseCatalog",
  components: {
    Filters,
    CourseCard,
    Paginator,
    SelectedTags,
  },
  data() {
    return {
      allList: [],
      show: true,
      // notFound: false,
      // list of courses array
      courses: [], // This saves the original array with the whole courses information
      list: [], // This saves a copy of the above array and get filtered whenever we need to.
      //
      // These are the filters
      filtersTags: {
        tags: [],
        filters: ["Not Yet Completed", "Completed", "Enrolled", "Not Enrolled"],
        // order: [
        //   "Most popular courses",
        //   "By Granted Credits starting with most credits",
        //   "By Granted Credits starting with least credits",
        //   "Date of publishing starting with most recent",
        //   "Date of publishing starting with least recent",
        // ],
      },
      selectedTags: [],
      selectedTag: "",
      query: "",
      searchPlaceholder: "Search courses",
      // Paginator data
      visibleItemsPerPageCount: 8,
      init: 0,
      end: 8,
      currentPage: 1,
      pageCount: 0,
      totalPages: [],
      paginationProcess: null,
    };
  },
  computed: {
    ...setCoursePageInfoHelpers.mapGetters([
      "getPageNumber",
      "getFilterTagList",
      "getFilterTag",
      "getSearchkey",
      "getMobileMenuActive",
    ]),
    // This is the list of 8 elements that is rendered on the page.
    page() {
      let elements = [];
      let i = 0;

      for (i = this.init; i < this.end; i++) {
        if (this.list[i]) {
          elements.push(this.list[i]);
        } else {
          break;
        }
      }
      return elements;
    },
    notFound() {
      return this.list.length === 0;
    },
  },
  created() {
    this.setValuesDefault();
    this.getLoading(false);
    this.getCoursesTagsFromAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...setCoursePageInfoHelpers.mapMutations([
      "setValuesDefault",
      "setPageNumber",
      "setFilterTagList",
      "setFilterTag",
      "setSearchKey",
    ]),
    // getNotFound() {
    //   if (this.list.length === 0) this.notFound = true;
    //   else this.notFound = false;
    // },
    setCoursePageInfo() {
      this.setPageNumber(this.currentPage);
      this.setFilterTagList(this.selectedTags);
      this.setFilterTag(this.selectedTag);
      this.setSearchKey(this.query);
    },

    async getCoursesTagsFromAPI() {
      this.getLoading(true);
      await this.getCourses();
      await this.getCouresTags();
      this.getLoading(false);
    },

    async getCouresTags() {
      try {
        let result = await getTagGroup();
        if (result.status == 200) {
          result.data.data.response.forEach((item) => {
            /*if (item.key == "odoo") {
              item.tags.map((e) => {
                this.filters.odoo = [...this.filters.odoo, e.name];
              });
            }
            if (item.key == "confluence") {
              item.tags.map((e) => {
                this.filters.confluence = [...this.filters.confluence, e.name];
              });
            }
            if (item.key == "tags") {
              item.tags.map((e) => {
                this.filters.tags = [...this.filters.tags, e.name];
              });
            }*/
            item.tags.map((e) => {
              this.filtersTags.tags = [...this.filtersTags.tags, e.name];
            });
          });
        }
      } catch (error) {
        console.log("getCouresTagsError===>", JSON.stringify(error));
      }
    },

    async getCourses() {
      try {
        let result = await getCourses();
        if (result.status == 200) {
          this.getCoursePageInfo(result);
        }
      } catch (error) {
        console.log("getCoursesError==>", error);
      }
    },
    getCoursePageInfo(_result) {
      this.courses = _result.data.data.response;
      this.list = [...this.courses];
      this.allList = this.list;

      let _getPageNumber = this.getPageNumber;
      if (
        this.getPageNumber == 1 &&
        this.getFilterTagList.length == 0 &&
        !this.getFilterTag &&
        !this.getSearchkey
      ) {
        this.setPages();
      }
      if (this.getFilterTagList.length != 0) {
        this.selectedTags = this.getFilterTagList;
        this.list = this.courses.filter((c) =>
          this.filterTags(c, this.getFilterTagList)
        );
        this.setPages();
      }
      if (this.getFilterTag) {
        this.filterByTag(this.getFilterTag);
      }
      if (this.getSearchkey) {
        this.filterByQuery(this.getSearchkey);
      }
      if (_getPageNumber != 1) {
        this.setPages();
        this.setPaginationProcess(_getPageNumber);
      }
    },

    filterByTag(tag) {
      // this.show = false;
      this.query = "";
      this.selectedTags = [];
      this.selectedTag = tag;
      this.list = this.courses.filter((c) =>
        this.filterTag(c, this.selectedTag)
      );
      this.setPages();
    },
    filterByTags(tag) {
      this.selectedTag = "";
      this.query = "";

      if (this.selectedTags) {
        if (!this.selectedTags.includes(tag)) this.selectedTags.push(tag);
      } else {
        this.selectedTags.push(tag);
      }

      this.list = this.courses.filter((c) =>
        this.filterTags(c, this.selectedTags)
      );

      this.setPages();
    },
    filterByQuery(value) {
      this.selectedTag = "";
      this.selectedTags = [];
      this.query = value;
      this.list = this.courses.filter((item) => {
        return this.query
          .toLowerCase()
          .split(" ")
          .every((v) => item.title.toLowerCase().includes(v));
      });
      this.setPages();
    },
    removeFilterOne(filter) {
      this.removeFilters();
    },
    removeFilter(filter) {
      const index = this.selectedTags.findIndex((t) => filter === t);
      this.selectedTags.splice(index, 1);
      if (this.selectedTags.length === 0) {
        this.list = [...this.courses];
      } else {
        this.list = this.courses.filter((c) =>
          this.filterTags(c, this.selectedTags)
        );
      }
      this.getLoading(false);
      this.setPages();
    },
    removeFilters() {
      this.selectedTags = [];
      this.selectedTag = "";
      this.list = [...this.courses];
      this.setPages();
    },
    filterTags(course, tags) {
      let sendBack = false;
      course.tags.forEach((t) => {
        if (tags.includes(t)) sendBack = true;
      });
      // if (tags.includes("Completed by me")) {
      //   if (course.completed) sendBack = true;
      // }
      // if (tags.includes("Not yet completed by me")) {
      //   if (!course.completed) sendBack = true;
      // }
      let filtersAnd = true;
      let noTags = 0;
      if (tags.includes("Completed")) {
        noTags++;
        if (!course.completed) {
          filtersAnd = false;
        }
      }
      if (tags.includes("Not Yet Completed")) {
        noTags++;
        if (course.completed) {
          filtersAnd = false;
        }
      }
      if (tags.includes("Not Enrolled")) {
        noTags++;
        if (course.button != "enroll") {
          filtersAnd = false;
        }
      }
      if (tags.includes("Enrolled")) {
        noTags++;
        if (
          course.button == "start" ||
          course.button == "continue" ||
          course.completed
        ) {
          // filtersAnd = false;
        } else {
          filtersAnd = false;
        }
      }
      if (tags.length == noTags) {
        sendBack = sendBack || filtersAnd;
      } else {
        sendBack = sendBack && filtersAnd;
      }
      return sendBack;
    },
    filterTag(a, b) {
      let sendBack = false;
      a.tags.forEach((t) => {
        if (t === b) sendBack = true;
      });
      return sendBack;
    },
    setPages() {
      this.pageCount = Math.ceil(
        this.list.length / this.visibleItemsPerPageCount
      );
      this.totalPages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
      this.currentPage = 1;
      this.paginationProcess = this.currentPage;
      this.setRange();
    },
    setPaginationProcess(value) {
      this.paginationProcess = value;
      this.show = false;
    },
    // Sets the ragen to show (from 0 to 8, from 16 to 24, etc etc).
    setRange() {
      switch (this.paginationProcess) {
        case "next":
          this.init += 8;
          this.end += 8;
          this.currentPage += 1;
          break;
        case "previous":
          this.init -= 8;
          this.end -= 8;
          this.currentPage -= 1;
          break;
        default:
          this.end = this.visibleItemsPerPageCount * this.paginationProcess;
          this.init = this.end - 8;
          this.currentPage = this.paginationProcess;
          break;
      }
      this.setCoursePageInfo();
    },
    beforeLeave: function () {
      this.setRange();
    },
    afterLeave: function () {
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
// assets/styles/components/shared-distribution.scss
.courseActive {
  display: none !important;
}
.selected-page {
  font-weight: bold !important;
  text-decoration-line: underline !important;
  text-transform: capitalize;
}
.disabledLink {
  pointer-events: none !important;
}
.cards-grid {
  display: grid;
  width: 100%;
  grid-gap: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 1.5625rem;
  padding-left: 2.5rem;
  height: 100%;
  padding-right: 2.5rem;
}
@media screen and (max-width: 1019px) {
  .cards-grid {
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 623px) {
  .cards-grid {
    grid-template-columns: 1fr;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
