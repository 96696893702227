<template>
  <div class="document-content">
    <iframe
      type="application/pdf"
      name="pdf-frame"
      :src="openInIFrame ? slideDocumentFile : ableToDownload"
      frameborder="0"
      class="pdf-frame"
      :style="{ height: openInIFrame ? '90%' : '0' }"
      id="c-pdf-frame"
    >
    </iframe>
    <button @click="onDownload" class="download-btn c-position-download-btn">
      Download Content
    </button>
    <div class="btn-html-content-checked" v-if="!slideChecked">
      <span class="text-checked btn-check-file" @click="checkedContent">
        Checked
      </span>
    </div>
  </div>
</template>

<script>
import { getSlideDetail } from "@/api";
import b64toBlob from "@/utils/b64_to_blob.js";
import { createNamespacedHelpers } from "vuex";
const takeCourseHelper = createNamespacedHelpers("takeCourse");

export default {
  data() {
    return {
      slideDocumentFile: "",
      openInIFrame: false,
      ableToDownload: false,
      mimeType: "",
      documentName: "",
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters(["slideChecked", "selectedSlideID"]),
  },
  created() {
    this.datasDocument();
  },
  watch: {
    selectedSlideID(_val, _prev) {
      this.datasDocument();
    },
  },
  methods: {
    async datasDocument() {
      try {
        const result = await getSlideDetail(this.selectedSlideID);
        const datas = result.data.data.response.content.datas;
        this.documentName = result.data.data.response.name;
        const { openInIFrame, blobURL, mimeType } = b64toBlob(datas);
        this.openInIFrame = openInIFrame;
        this.slideDocumentFile = blobURL;
        this.mimeType = mimeType;
        if (openInIFrame) {
          setTimeout(() => {
            let iFrame = document.getElementById("c-pdf-frame");
            let iFrameImg =
              iFrame.contentWindow.document.getElementsByTagName("img")[0];
            iFrameImg.style.width = "100%";
          }, 500);
        }
      } catch (error) {
        console.log("DatasError===>", error);
      }
    },
    onDownload() {
      this.ableToDownload = this.slideDocumentFile;
      const link = document.createElement("a");
      link.href = this.ableToDownload;
      link.download = this.documentName;
      link.click();
      setTimeout(() => {
        this.ableToDownload = "";
      }, 500);
    },
    checkedContent() {
      this.$emit("completeSlide");
    },
  },
};
</script>
<style lang="scss" scoped>
button.download-btn.c-position-download-btn {
  position: absolute !important;
  bottom: 0 !important;
}
</style>
