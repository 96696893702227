var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tasks-page"},[_c('div',{staticClass:"back-item"},[_c('img',{staticClass:"back-item__img",attrs:{"src":require("@/assets/img/page-arrow-black.svg")}}),_c('span',{staticClass:"back-item__text",on:{"click":_vm.backToPages}},[_vm._v(" Back to Dashboard")])]),_c('v-toolbar',{staticClass:"toolbar-header",attrs:{"flat":"","color":"primary"}},[(_vm.overdueTask)?_c('v-toolbar-title',{staticClass:"toolbar-title bullet bullet__danger justify-start",staticStyle:{"margin-left":"24px"}},[_vm._v(" You have overdue tasks ")]):_vm._e(),_c('v-toolbar-title',{staticClass:"toolbar-title"},[_vm._v(" You have things to do: ")])],1),_c('v-tabs',{staticClass:"tabs",attrs:{"vertical":_vm.windowWidth > 800,"hide-slider":""}},[_c('DxResizable',{staticClass:"dx-resizable",attrs:{"id":"tabResizer","area":".tasks-page .tabs","min-width":_vm.windowWidth > 800 ? 205 : _vm.windowWidth - 105,"max-width":_vm.windowWidth > 800 ? 700 : _vm.windowWidth - 105,"width":400,"keep-aspect-ratio":true,"handles":"right"}},[_c('div',{staticClass:"overflow-y-auto tabs-scroll rounded-0"},[(_vm.tasks.length <= 1)?_c('div',[_c('v-tab',{staticClass:"tab"},[_c('span',{staticClass:"task-text ml-5"},[_vm._v("No tasks yet")])])],1):_c('div',_vm._l((_vm.tasks),function(task,index){return _c('v-tab',{key:index,staticClass:"tab",class:{ 'tab--hide': task.initial }},[_c('div',{staticClass:"typetasks",class:_vm._f("typeTask")(_vm.typeTaskColor,task.onboarding_id, task.onboard_type)},[_c('span',{staticClass:"typetask-text"},[_vm._v(_vm._s(_vm._f("typeTask")(_vm.typeTaskLabel,task.onboarding_id, task.onboard_type)))])]),(
                  task.request_model_name ==
                  'onboarding.request.approve.course'
                )?_c('v-tooltip',{attrs:{"top":"","openDelay":"1600","transition":"scroll-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g(_vm._b({staticClass:"task-text bullet",class:`bullet__${_vm.calculateDeadline(
                      task.date_deadline
                    )}`},'p',attrs,false),on),[_vm._v(" "+_vm._s(task.display_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(task.display_name)+" ")])]):_c('v-tooltip',{attrs:{"top":"","openDelay":"800","transition":"scroll-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g(_vm._b({staticClass:"task-text bullet",class:`bullet__${_vm.calculateDeadline(
                      task.date_deadline
                    )}`},'p',attrs,false),on),[_vm._v(" "+_vm._s(task && task.display_name ? task.display_name : "Complete this task")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(task && task.display_name ? task.display_name : "Complete this task")+" ")])])],1)}),1)])]),(_vm.tasks.length <= 1)?_c('v-tab-item',{staticClass:"tab-items overflow-y-auto",attrs:{"transition":"fade","reverse-transition":"fade"}},[_c('v-row',{staticClass:"ma-0 justify-center"},[_c('v-col',{staticClass:"ma-0 tab-item-wrapper",attrs:{"cols":"12"}},[_c('div',{staticClass:"tab-item"},[_c('div',{staticClass:"img-txt"},[_c('div',{staticClass:"img-container mb-0"},[_c('div',{staticClass:"img-bg-no-tasks"})]),_c('p',{staticClass:"tab-item-text mb-4 px-10"},[_vm._v(" You have no assigned tasks yet. ")])])])])],1)],1):_vm._e(),_vm._l((_vm.tasks),function(task,index){return _c('v-tab-item',{key:index,staticClass:"tab-items",attrs:{"transition":"fade","reverse-transition":"fade"}},[(task.initial)?_c('v-row',{staticClass:"ma-0 justify-center"},[_c('v-col',{staticClass:"ma-0 tab-item-wrapper",attrs:{"cols":"12"}},[_c('div',{staticClass:"tab-item"},[_c('div',{staticClass:"img-txt mt-10"},[_c('div',{staticClass:"img-container mb-0"},[_c('div',{staticClass:"img-bg-select-task"})]),_c('p',{staticClass:"tab-item-text mb-4 px-10"},[_vm._v(" "+_vm._s(task.request_intro)+" ")])])])])],1):_c('v-row',{staticClass:"ma-0 justify-center"},[_c('v-col',{staticClass:"ma-0 tab-item-wrapper",attrs:{"cols":"12"}},[_c('DeadlineBanner',{staticClass:"banner",class:`banner__${_vm.calculateDeadline(task.date_deadline)}`,attrs:{"deadline":task.date_deadline}}),(
                task.request_model_name ==
                  'onboarding.request.hremployee.course' ||
                task.request_model_name == 'onboarding.request.approve.course'
              )?_c('div',{staticClass:"tab-item-scroll"},[_c('v-card',{staticClass:"item-scroll"},[_c('div',{staticClass:"item-content"},[_c('h1',{staticClass:"txt-title"},[_vm._v(" "+_vm._s(task.description)+" ")]),_c('span',{staticClass:"txt-course-intro"},[_vm._v(" We need you to take this course ")]),_c('div',{staticClass:"card-elevated"},[_c('CourseSelected',{staticClass:"course-presentation",attrs:{"task_id":task.id,"course_id":task.channel_id[0]},on:{"completedCourse":_vm.completedCourse}})],1)])])],1):_c('div',{staticClass:"tab-item"},[(
                  task.request_model_name ==
                  'onboarding.request.hremployee.profile.picture'
                )?_c('div',[(_vm.imgSaved)?_c('div',{staticClass:"img-txt mt-10 text-center"},[_c('div',{staticClass:"img-container-complete mb-0"},[_c('div',{staticClass:"img-bg-picture-left"}),_c('div',{staticClass:"img-bg-picture-center",style:({
                        'background-image': 'url(' + _vm.getCroppedImg + ')',
                      })}),_c('div',{staticClass:"img-bg-picture-right"})]),_c('h1',{staticClass:"tab-item-title"},[_vm._v("Thank you!")]),_c('p',{staticClass:"tab-item-text mb-4 px-10"},[_vm._v(" Your picture has been uploaded successfully ")]),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","rounded":"","color":"primary","height":"40","width":"160"},on:{"click":_vm.onClickTask}},[_vm._v(" Close ")])],1):_c('div',{staticClass:"img-txt mt-10"},[_c('div',{staticClass:"img-container-empty mb-0"},[_c('div',{staticClass:"img-bg-picture-empty"})]),_c('h1',{staticClass:"tab-item-title"},[_vm._v("Upload A Picture")]),_c('p',{staticClass:"tab-item-text mb-4 px-10"},[_vm._v(" We need a good picture of you to use in all our systems. We have no photo of you at this time."),_c('br'),_vm._v(" Please, upload a picture in .jpeg or .png format. ")])])]):_vm._e(),(task.reject_message)?_c('p',{staticClass:"reject-message"},[_vm._v(" Your task was rejected : "+_vm._s(task.reject_message)+" ")]):_vm._e(),(!_vm.imgSaved)?_c('PersonalInfo',{attrs:{"type":task.task_type,"model_name":task.request_model_name,"id":task.id},on:{"closeUpdateTasks":_vm.getTasksFromAPI}}):_vm._e()],1)],1)],1)],1)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }