<template>
  <paginate
    v-model="selected_page"
    :pageCount="pageCount"
    :click-handler="pageNumber"
    :containerClass="'paginate'"
    :prev-class="'paginate-prev'"
    :next-class="'paginate-next'"
    :page-class="'page-item'"
    :page-link-class="'page-link'"
    :next-link-class="'next-link'"
    :prev-link-class="'prev-link'"
  >
    <template v-slot:prev>
      <div class="navigation-wrapper">
        <div class="img_leftArrow" v-if="selected_page != 1"></div>
        <span class="text" v-if="selected_page != 1">Prev</span>
      </div>
    </template>
    <template v-slot:next>
      <div class="navigation-wrapper">
        <span class="text" v-if="selected_page != pageCount && pageCount != 1"
          >Next</span
        >
        <div
          class="img_rightArrow"
          v-if="selected_page != pageCount && pageCount != 1"
        ></div>
      </div>
    </template>
  </paginate>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  name: "Paginator",
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Array,
      default: new Array(),
    },
  },
  created() {},
  components: {
    Paginate,
  },
  computed: {
    selected_page: {
      get: function () {
        return this.currentPage;
      },
      set: function () {
        this.currentPage;
      },
    },
  },
  methods: {
    pageNumber(pageNum) {
      this.$emit("updateRange", pageNum);
    },
  },
};
</script>
<style scoped>
ul.paginate {
  display: flex;
  list-style: none;
  padding: 2.5rem !important;
}
@media screen and (max-width: 900px) {
  ul.paginate {
    padding: 40px 20px !important;
  }
}
</style>
