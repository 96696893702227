const EditFutureDate = (_date) => {
  let today = new Date();
  let thisTimeZone = today.getTimezoneOffset();

  let setdate = new Date(_date);
  let set_date = new Date(setdate - thisTimeZone * 60 * 1000);

  let countDate = Math.trunc((set_date - today) / (60000 * 60 * 24));
  if (countDate == 0) {
    return "Today";
  } else if (countDate == 1) {
    return "Tomorrow";
  } else if (countDate == -1) {
    return "Yesterday";
  } else if (countDate < -1) {
    return "Overdue";
  } else if (countDate > 1) {
    if (countDate > 1 && countDate < 7) {
      return "In " + countDate + " Days";
    } else if (countDate >= 7 && countDate < 30) {
      if (Math.trunc(countDate / 7) == 1) {
        return "In Next week";
      } else {
        return "In " + Math.trunc(countDate / 7) + " Weeks";
      }
    } else if (countDate >= 30 && countDate < 365) {
      if (Math.trunc(countDate / 30) == 1) {
        return "In A Month";
      } else {
        return "In " + Math.trunc(countDate / 30) + " Months";
      }
    } else if (countDate >= 365) {
      if (Math.trunc(countDate / 365) == 1) {
        return "In A Year";
      } else {
        return "In " + Math.trunc(countDate / 365) + " Years";
      }
    }
  }
};
export default EditFutureDate;
