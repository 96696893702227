const LOGIN = "api/auth/token";
const GOOGLETOKEN = "api/auth/google-token/v2";
const EMPLOYEE_INFO = "api/hr.employee";
const GET_DIRECTORY_EMPLOYEE = "api/hr.employee.directory";
const GET_TAGS = "api/slide.channel.tag.group";
const GET_COURSES = "api/slide.channel";
const RESET_PASSWORD = "api/auth/request_reset_password";
const CHANGE_PASSWORD = "api/auth/reset_password";
const ENROLL_COURSE = "api/slide.channel.partner";
const DIRECTORYEMPLOYEE = "directory/employee";
const DEPARTMENTHIERARCHY = "api/hr.department";
const GETVALUES = "api/corporate.value";
const GETTASKS = "api/res.users";
const GETEMPLOYEESTATUS = "api/hr.employee.status";
const GETTASKFORM = "api/onboarding.task";
const GETCOUNTRYLIST = "api/res.country";
const GETCOUNTRYSTATELIST = "api/res.country.state";
const GETLEARNINGEVENTs = "api/learning.event";
const GETLEARNINGACTIVITY = "api/learning.activity";
const CREDITREQUEST = "api/credits.request";
const TACTICALROLE = "api/tactical.role";
const TACTICALROLEASSIGNMENT = "api/tactical.role.assignment";
const TACTICALCERTIFICATON = "api/tactical.certification";
const TACTICALCERTIFICATE = "api/tactical.certificate";
const BOOKCLUB = "api/reading.book";
const GETFAQBYPAGEANDSIZE = "api/hr.employee.faq";
const GETTAGLIST = "api/hr.employee.category";
const GETLANGUAGES = "api/hr.language";
const GETTSHIRTSIZE = "api/hr.tshirt.size";
const GETEDUCATIONLEVELS = "api/hr.employee.education.level";
const GETPEOPLESERVICETICKET = "api/people.service.ticket";
const GETSEVERITYLEVEL = "api/hr.severity.level";
const EDUCATIONEVENTS = "api/education.event";
const RESPONSIBILITYTRACKER = "api/tactical.responsibility.tracker";
const EMPLOYEERESPONSIBILITY = "api/employee.tactical.responsibility";
const GETTACTICALREADINESS = "api/hr.employee.tactical.readiness";
const GETEMPLOYEECHARGES = "api/account.analytic.line";
const GETEMPLOYEECOST = "api/hr.employee";
const GETBUDGETPERIOD = "api/account.budget.period";
const GETREADINESS = "api/hr.employee";
const READINESSDATAPOINTS = "api/employee.readiness.data.point";
const GETTRACKERS = "api/responsibility.coach.employee";
const GETEXPERTS = "api/tactical.responsibility";
const GETEXPERTSEMPLOYEE = "api/employee.responsibility.badge";
const REMOVECOACHREQUEST ="api/responsibility.coach.employee";
const EVALUATECHECKPOINT = "api/proficiency.checkpoint.evaluation";

export const API = {
  LOGIN,
  GOOGLETOKEN,
  EMPLOYEE_INFO,
  GET_DIRECTORY_EMPLOYEE,
  GET_TAGS,
  GET_COURSES,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  ENROLL_COURSE,
  DIRECTORYEMPLOYEE,
  DEPARTMENTHIERARCHY,
  GETVALUES,
  GETTASKS,
  GETEMPLOYEESTATUS,
  GETTASKFORM,
  GETCOUNTRYLIST,
  GETCOUNTRYSTATELIST,
  GETLEARNINGEVENTs,
  GETLEARNINGACTIVITY,
  CREDITREQUEST,
  TACTICALROLE,
  TACTICALROLEASSIGNMENT,
  TACTICALCERTIFICATON,
  TACTICALCERTIFICATE,
  BOOKCLUB,
  GETFAQBYPAGEANDSIZE,
  GETTAGLIST,
  GETLANGUAGES,
  GETTSHIRTSIZE,
  GETEDUCATIONLEVELS,
  GETPEOPLESERVICETICKET,
  GETSEVERITYLEVEL,
  EDUCATIONEVENTS,
  RESPONSIBILITYTRACKER,
  EMPLOYEERESPONSIBILITY,
  GETTACTICALREADINESS,
  GETEMPLOYEECHARGES,
  GETEMPLOYEECOST,
  GETBUDGETPERIOD,
  GETREADINESS,
  READINESSDATAPOINTS,
  GETTRACKERS,
  GETEXPERTS,
  GETEXPERTSEMPLOYEE,
  REMOVECOACHREQUEST,
  EVALUATECHECKPOINT,
};
