import axios from "./_axios";
import { API } from "./apiEndPoints";
import EditDateTimeToGMT from "@/utils/edit_date_time_to_gmt.js";

export const updateResponsibilityAcknowledgeDate = (
  _tacticalRoleAssignmentID,
  _date
) => {
  let result = axios()({
    url: API.EMPLOYEERESPONSIBILITY,
    method: "patch",
    params: {
      action: "updates_acknowledge_date",
      employee_resp: _tacticalRoleAssignmentID,
      date: EditDateTimeToGMT(_date),
    },
  });
  return result;
};
export const updateResponsibilityTarget = (props) => {
  let result = axios()({
    url: API.EMPLOYEERESPONSIBILITY,
    method: "patch",
    params: {
      action: "update_proficiency_target",
      employee_resp: props.id,
      target: props.target_id,
    },
  });
  return result;
};
export const updateResponsibilityGrantedBadge = (props) => {
  let result = axios()({
    url: API.EMPLOYEERESPONSIBILITY,
    method: "patch",
    params: {
      action: "update_proficiency_target",
      employee_resp: props.id,
      proficiency: props.badge_id,
    },
  });
  return result;
};
