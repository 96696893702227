var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"container--fluid fill-height pa-0 ma-0"},[_c('v-row',{staticClass:"justify-center ma-0 fill-height"},[_c('v-col',{staticClass:"cropper-wrapper pa-0",style:({
        backgroundImage: 'url(' + _vm.image + ')',
      }),attrs:{"md":"5"}},[_c('cropper',{directives:[{name:"show",rawName:"v-show",value:(!_vm.image),expression:"!image"}],ref:"cropper",staticClass:"cropper",attrs:{"stencil-props":{
          aspectRatio: 1,
          handlers: {
            eastNorth: true,
            north: false,
            westNorth: true,
            west: false,
            westSouth: true,
            south: false,
            eastSouth: true,
            east: false,
          },
          handlersClasses: {
            default: 'handler',
            hover: 'handler--hover',
            eastNorth: 'handler--east-north',
            westNorth: 'handler--west-north',
            westSouth: 'handler--west-south',
            eastSouth: 'handler--east-south',
          },
          linesClasses: {
            default: 'line',
          },
        },"src":_vm.file,"backgroundClass":"background","default-boundaries":"fit"},on:{"change":_vm.onChange}}),(!_vm.preview)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn",on:{"click":_vm.cancel}},[_c('div',{staticClass:"btn-text"},[_vm._v("Cancel")])]),_c('button',{staticClass:"btn",on:{"click":_vm.crop}},[_c('div',{staticClass:"btn-text"},[_vm._v(" Crop "),_c('v-icon',[_vm._v("$crop")])],1)]),_c('button',{staticClass:"btn",on:{"click":_vm.zoomOut}},[_c('div',{staticClass:"btn-text"},[_c('v-icon',[_vm._v("$zoomOut")])],1)]),_c('button',{staticClass:"btn btn--zoom"},[_c('div',{staticClass:"btn-text"},[_vm._v("Zoom")])]),_c('button',{staticClass:"btn",on:{"click":_vm.zoomIn}},[_c('div',{staticClass:"btn-text"},[_c('v-icon',[_vm._v("$zoomIn")])],1)]),_c('button',{staticClass:"btn",on:{"click":_vm.onDone}},[_c('div',{staticClass:"btn-text"},[_vm._v("Done")])])]):_vm._e(),(_vm.preview)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn--back",on:{"click":_vm.back}},[_c('div',{staticClass:"btn-text"},[_vm._v("Back")])])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }