<template>
  <!-- Course card container -->
  <div class="card">
    <!-- Thumbnail -->
    <div :style="bg" class="card__thumbnail" @click="seeCourseCourse(title)">
      <div
        v-if="!course.prerequisites_meet"
        :class="{ lockedImg: !course.prerequisites_meet }"
      >
        <div class="img-lockIcon"></div>
      </div>
      <div v-if="course_enrolled" class="course_enrolled">Enrolled</div>
    </div>
    <!-- Body -->
    <div class="card__body">
      <!-- Course title -->
      <span class="title-txt" @click="seeCourseCourse(title)">{{ title }}</span>
      <!-- Minutes and steps -->
      <ul class="meta">
        <li class="meta__item">
          <div class="dot bg-red"></div>
          <span class="min-step-txt">{{ minutes }}</span>
        </li>
        <li class="meta__item">
          <div class="dot bg-green"></div>
          <span class="min-step-txt">{{ steps }}</span>
        </li>
        <li>
          <div v-if="course_completed" class="course_completed">
            Completed
            <div class="img_check_mark"></div>
          </div>
        </li>
      </ul>
      <!-- Tags -->
      <div class="tags">
        <div
          class="tags__item"
          v-for="(tag, index) in tags"
          :key="index"
          @click="selectTag(tag)"
        >
          {{ tag }}
        </div>
      </div>
      <div class="pre-requisites" v-if="prerequisites_length">
        <span class="pre-requisites-title">Pre-requisites</span>
        <div class="pre-requisites-tags">
          <div v-for="(item, index) in prerequisites" :key="index">
            <div v-if="item.completed" class="requisites_tag_completed">
              {{ item.name }}
              <div class="img_check_mark"></div>
            </div>
            <div
              v-else
              class="requisites_tag"
              @click="selectRequisitesTag(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
export default {
  name: "CourseCard",
  props: {
    course: {
      type: Object,
      default: new Object(),
    },
    allCourses: {
      type: Array,
      default: new Array(),
    },
  },
  computed: {
    // Background for thumbnail
    bg() {
      return this.course.bg;
    },
    // Course title
    title() {
      return this.course.title;
    },
    // Minutes of course
    minutes() {
      return this.course.minutes;
    },
    // Course steps
    steps() {
      return this.course.steps;
    },
    // Course tags
    tags() {
      return this.course.tags;
    },
    prerequisites_length() {
      return this.course.prerequisites.length;
    },
    prerequisites() {
      return this.course.prerequisites;
    },
    course_completed() {
      return this.course.completed;
    },
    course_enrolled() {
      return (
        this.course.button === "start" ||
        this.course.button === "continue" ||
        this.course.completed
      );
    },
  },
  methods: {
    ...coursePresentationHelper.mapActions(["dispatchCourseDetail"]),
    // Send event to set a single tag into parent component
    selectTag(tag) {
      this.$emit("setTag", tag);
    },
    // Navigates to course profile's page.
    seeCourseCourse(title) {
      if (this.course.prerequisites_meet) {
        this.$emit("setCoursePageInfo");
        let url = `/course-presentation?${title}`;
        // let url = `/course-presentation`;
        this.$router.push(url);
        // this.dispatchCourseDetail(this.course);
      }

      // this.$store.dispatch("coursePresentation/setMinutes", this.minutes);
    },
    selectRequisitesTag(_item) {
      this.allCourses.forEach((course) => {
        if (course.id == _item.id) {
          this.$emit("setCoursePageInfo");
          let url = `/course-presentation?${_item.name}`;
          this.$router.push(url);
          this.dispatchCourseDetail(course);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.lockedImg {
  // opacity: 0.8;
  background: #323a44cc;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
  .img-lockIcon {
    background-image: url("~@/assets/img/lock.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 37px;
    height: 40px;
    margin-bottom: 16px;
  }
}
.card {
  background: $light-blue-1 !important;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
  // width: 21.875rem;
  &__thumbnail {
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 11.25rem;
    overflow: auto;
  }
  &__body {
    padding: 1.25rem;
    .title-txt {
      color: $black;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      text-decoration: none;
    }
    .meta {
      display: flex;
      margin-top: 0.5rem;
      &__item {
        align-items: center;
        display: flex;
        margin-right: 1rem;
        .dot {
          border-radius: 50%;
          height: 0.375rem;
          margin-right: 0.375rem;
          width: 0.375rem;
        }
        .min-step-txt {
          color: $black;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 16px;
        }
      }
    }

    .tags {
      align-items: center;
      display: flex;
      margin-top: 1rem;
      flex-wrap: wrap;
      &__item {
        background-color: $dark-blue;
        border-radius: 30px;
        color: $white;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 17px;
        margin-right: 0.375rem;
        margin-bottom: 0.375rem;
        padding: 0.375rem 0.9375rem;
      }
    }
    .pre-requisites {
      margin-top: 1rem;
      .pre-requisites-title {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: #323a44;
      }
      .pre-requisites-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .requisites_tag {
          background-color: #ff6b6b33;
          border-radius: 1.875rem;
          color: $red;
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 0.875rem;
          margin-right: 0.375rem;
          margin-top: 0.4375rem;
          padding: 0.1875rem 0.625rem;
          cursor: pointer;
        }
        .requisites_tag_completed {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          background-color: #a7d42ee6;
          border-radius: 1.875rem;
          color: #4f6b05;
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 0.875rem;
          margin-right: 0.375rem;
          margin-top: 0.4375rem;
          padding: 0.1875rem 0.625rem;
          .img_check_mark {
            margin-left: 0.3125rem;
            background-image: url("~@/assets/img/requisites-check_mark.svg");
            width: 0.4375rem;
            height: 0.34rem;
            background-position: center;
            background-size: cover;
          }
        }
      }
    }
  }
  .course_completed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #a7d42ee6;
    border-radius: 1.875rem;
    color: #4f6b05;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 0.875rem;
    margin-right: 0.375rem;
    margin-top: 0.4375rem;
    padding: 0.1875rem 0.625rem;
    .img_check_mark {
      margin-left: 0.3125rem;
      background-image: url("~@/assets/img/requisites-check_mark.svg");
      width: 0.4375rem;
      height: 0.34rem;
      background-position: center;
      background-size: cover;
    }
  }
  .course_enrolled {
    width: 80px;
    margin: 1rem 1.2rem;
    border-radius: 1.875rem;
    background-color: #ddecf8;
    color: #53a0de;
    text-align: center;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
  }
}
</style>
