<template>
  <div class="reSetPassword-info">
    <div class="reSet_asking">Forgot Password?</div>
    <div class="reSet_enterEmail_text">
      Enter your email to reset your password:
    </div>

    <el-alert
      class="alert-container is-light"
      v-if="alert.type"
      :title="alert.message"
      @close="closeError"
      :type="alert.type"
    >
    </el-alert>
    <el-form
      class="reSetPassword-form"
      :model="model"
      :rules="rules"
      ref="form"
      @submit.native.prevent="reSetPassword"
    >
      <el-form-item prop="email">
        <el-input
          v-model="model.email"
          placeholder="Email"
          type="email"
        ></el-input>
      </el-form-item>

      <el-form-item class="reSetPassword_button">
        <el-button
          :loading="loading"
          class="reSet_button"
          type="primary"
          native-type="submit"
          block
          >Reset</el-button
        >
      </el-form-item>
      <div class="backLogin" @click="goBack">
        <p>Back to Log in</p>
      </div>
    </el-form>
  </div>
</template>
<script>
import { reset_Password } from "@/api";
export default {
  name: "ResetPasswordForm",
  data() {
    return {
      validCredentials: {
        email: "lightscope",
      },
      model: {
        email: "",
      },
      alert: {},
      loading: false,
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            min: 4,
            message: "Email length should be at least 5 characters",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Add correct email address",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    simulateReset() {
      return new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    },
    async reSetPassword() {
      this.closeError();
      try {
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        this.loading = true;
        let result = await reset_Password(this.model.email);
        if (result.status == 200) {
          let alert = {
            type: "success",
            message: result.data.message,
          };
          this.showError(alert);
          this.loading = false;
        }
      } catch (error) {
        let alert = {
          type: "error",
          message: "",
        };
        if (!error) alert.message = "Please enter the correct Email";

        if (error.response) {
          alert.message = error.response.data.message;
        }
        this.showError(alert);
        this.loading = false;
      }
      // await this.simulateReset();

      /*webAuth.changePassword(
        {
          connection: webAuthDBConnection,
          email: this.model.email,
        },
        (err, resp) => {
          if (err && err.description) {
            let alert = {
              type: "error",
              message: err.description,
            };
            this.showError(alert);
          } else {
            let alert = {
              type: "success",
              message: resp,
            };
            this.showError(alert);
          }
          this.onLoading = false;
        }
      );*/
    },
    closeError() {
      let alert = {
        type: "",
        message: "",
      };
      this.showError(alert);
    },
    showError(data = {}) {
      this.alert = data;
    },
    goBack() {
      this.$emit("on-switch", "LoginForm");
    },
  },
};
</script>

<style lang="scss">
.reSetPassword-info {
  font-family: Gilroy;
  font-style: normal;
  margin-left: 9.9375rem;
  margin-top: 3.125rem;
  .reSet_asking {
    font-size: 1.375rem;
    color: #323a44;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
  .reSet_enterEmail_text {
    font-size: 1rem;
    color: #323a44;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }
  .el-alert--success.is-light {
    background-color: #bee9e8;
    color: black;
    max-width: 20rem;
  }
  .el-alert--error.is-light {
    width: 20rem;
  }
  .el-alert.is-light .el-alert__closebtn {
    color: #ff8a5b;
  }
  .reSetPassword-form {
    width: 20rem;
    padding-top: 1.875rem;
    .el-input__inner {
      background: #f6f6fb !important;
      //   background-color: #f2f2f2 !important;
      border-radius: 1.875rem;
      border: 1px solid #f6f6fb;
      height: 3rem;
      margin-bottom: 0.625rem;
      color: #323a44 !important;
    }
    .reSetPassword_button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
      vertical-align: middle;
      text-align: center;
      .reSet_button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.4rem;
        width: 10rem;
        height: 3rem;
        text-align: center;
        font-size: 1rem;
        border-radius: 0.875rem;
      }
    }
  }
  .el-form-item__error {
    padding-top: 0 !important;
  }
  .backLogin {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    cursor: pointer;
    p {
      font-size: 1rem;
      color: #323a44;
      font-weight: 600;
    }

    p:hover {
      color: #53a0de;
    }
  }
}
@media screen and (min-width: 959px) and (max-width: 1723px) {
  .reSetPassword-info {
    margin-left: 3.75rem !important;
    margin-right: 1rem !important;
  }
}
@media screen and (max-width: 575px) {
  .reSetPassword-info {
    text-align: center;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    .alert-container.is-light {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .el-form.reSetPassword-form {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
</style>
