<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="page_router" v-if="pronounEmployee != 'Your'">
            <span class="txt_router text_redirect" @click="goBack()"
              >Managing others</span
            >
            <div class="img_arrow"></div>
            <div class="txt_router">{{ pronounEmployee }}</div>
          </div>
          <div class="main_section__content">
            <div class="main_section__header" style="text-align: left">
              <h1 class="main_section-title d-flex align-center">
                {{ pronounEmployee }} Readiness
                <DxSparkline
                  v-if="source.length != 0"
                  :data-source="source"
                  value-field="readiness"
                  class="sparkline"
                  argument-field="date"
                  type="bar"
                  :show-first-last="false"
                  :on-drawn="setColors"
                  :column-auto-width="true"
                >
                  <DxTooltip :enabled="false" />
                </DxSparkline>
                <!-- first-last-color="#ebdd8f"
                bar-positive-color="#e55253"
                point-color="#e8c267" -->
              </h1>
              <div v-if="flag" class="d-flex">
                <div class="d-flex align-center" style="padding-right: 50px">
                  <bulletChartWidget
                    :data="mainChartOption"
                    style="display: flex; align-items: center"
                  >
                  </bulletChartWidget>
                  <div class="mainChartOption-readinessValue">
                    {{ mainChartOption.employeeReadinessValue }}%
                  </div>
                </div>
                <!-- label="Select" -->
                <v-select
                  v-model="selectedFromDropdown"
                  :items="dropDownList"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  hide-details
                  single-line
                  v-on:change="onSelect"
                >
                </v-select>
              </div>
            </div>
            <!-- <div class="main_section__content"> -->
            <div>
              <DxTreeList
                id="tasks"
                :data-source="dataSource"
                :show-borders="true"
                :showRowLines="true"
                :column-auto-width="true"
                :word-wrap-enabled="true"
                key-expr="ID"
                parent-id-expr="ParentID"
                :expanded-row-keys="expandedRowKeys"
              >
                <!-- 
                  @row-expanded="onExpandedRowKeysChange($event)"
                  :expanded-row-keys="expandedRowKeys"
                  :root-value="0"
                  :show-row-lines="true"
                 -->
                <DxColumn
                  :width="25 + '%'"
                  caption="Role Assignment"
                  data-field="name"
                  cell-template="RoleAssignmentTemplate"
                />
                <DxColumn
                  :width="25 + '%'"
                  :allow-sorting="true"
                  caption="Readiness"
                  cell-template="employeeTemplate"
                />
                <DxColumn
                  data-field="dummy1"
                  caption=""
                  data-type="string"
                  cell-template="dummy1Template"
                  :width="30 + '%'"
                />
                <DxColumn
                  data-field="dummy2"
                  caption=""
                  data-type="string"
                  :width="20 + '%'"
                  cell-template="dummy2Template"
                />
                <!-- <DxColumn
                  :width="10 + '%'"
                  data-field="weight"
                  caption="Weight"
                  cell-template="employeeTemplateWeight"
                  css-class="cell-text-align"
                /> -->
                <DxColumn
                  data-field=""
                  caption=""
                  :width="5 + '%'"
                  cell-template="action"
                />

                <template #employeeTemplate="{ data: options }">
                  <bulletChartWidget :data="options.data"></bulletChartWidget>
                </template>
                <template #RoleAssignmentTemplate="{ data: options }">
                  <roleAssignment :data="options.data"></roleAssignment>
                </template>
                <template #dummy1Template="{ data: options }">
                  <div
                    v-if="
                      options.data.dummy1.widget ||
                      options.data.model ==
                        'tactical.role.assignment.responsibility'
                    "
                  >
                    <dummy1Widget
                      :data="options.data"
                      :actAsMe="act_as_me"
                    ></dummy1Widget>
                  </div>
                </template>
                <template #dummy2Template="{ data: options }">
                  <dummy2Widget
                    :data="options.data"
                    :actAsMe="act_as_me"
                    v-if="
                      options.data.dummy2.widget ||
                      options.data.dummy1.widget ==
                        'devexpress_emptyible_date_widget'
                    "
                  ></dummy2Widget>
                </template>
                <template #action="{ data: options }">
                  <div>
                    <div
                      v-for="(img, index) of options.data.icon.name"
                      :key="index"
                    >
                      <v-tooltip
                        top
                        v-if="img.toString().includes('elearning.png')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            src="@/assets/img/out_link_2.svg"
                            class="img-fluid"
                            style="
                              height: 24px;
                              width: 24px;
                              margin-right: 8px;
                              cursor: pointer;
                            "
                            @click="redirect(options.data, 'elearning')"
                          />
                        </template>
                        <span>Take E-Learning Course</span>
                      </v-tooltip>
                      <img
                        src="@/assets/img/book_club.png"
                        class="img-fluid"
                        style="
                          height: 24px;
                          width: 24px;
                          margin-right: 8px;
                          cursor: pointer;
                        "
                        v-else-if="img.toString().includes('book.png')"
                        @click="redirect(options.data, 'book')"
                      />
                      <v-tooltip
                        top
                        v-else-if="img.toString().includes('proficiency.png')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-bind="attrs"
                            v-on="on"
                            src="@/assets/img/input-mag.svg"
                            class="img-fluid"
                            style="
                              height: 24px;
                              width: 24px;
                              margin-right: 8px;
                              cursor: pointer;
                            "
                            @click="redirect(options.data, 'proficiency')"
                          />
                        </template>
                        <span
                          >Inspect Proficiency Checkpoints and available
                          Experts</span
                        >
                      </v-tooltip>

                      <!-- <img
                        src="@/assets/img/input-mag.svg"
                        class="img-fluid"
                        style="
                          height: 24px;
                          width: 24px;
                          margin-right: 8px;
                          cursor: pointer;
                        "
                        v-if="
                          index == 0 &&
                          !img.toString().includes('elearning.png') &&
                          !img.toString().includes('book.png') &&
                          !img.toString().includes('proficiency.png')
                        "
                      /> -->
                    </div>
                  </div>
                </template>
                <template #employeeTemplateWeight="{ data: options }">
                  <div
                    :style="{
                      'margin-left': getMarginForWeight(options.data),
                      'margin-right': '15px',
                    }"
                    v-if="options.data.weight"
                  >
                    {{ (options.data.weight * 100).toFixed(0) }}%
                  </div>
                </template>
              </DxTreeList>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isActingAsAnotherUser"
      content-class="dialog-box-action-as-another"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-body d-flex flex-column align-center">
          <p class="cust-dialog-label">
            You cannot do this because you are acting as another user
          </p>
          <v-btn
            depressed
            rounded
            class="btn-ok"
            @click="closeDialog"
            color="#53a0de"
            >OK</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { DxTreeList, DxColumn } from "devextreme-vue/tree-list";
import { getTecticalReadiness, getReadinessDataPoints } from "@/api";
import roleAssignment from "@/components/readiness/roleAssignment";
import bulletChartWidget from "@/components/readiness/bulletChartWidget";
import dummy1Widget from "@/components/readiness/dummy1Widget";
import dummy2Widget from "@/components/readiness/dummy2Widget";
import { createNamespacedHelpers } from "vuex";
import DxSparkline, { DxTooltip } from "devextreme-vue/sparkline";

const { mapActions } = createNamespacedHelpers("isLoading");
const readinessHelper = createNamespacedHelpers("readiness");
// const alertHelpers = createNamespacedHelpers("alert");
const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");

export default {
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "Your Inspect Proficiency" ||
      from.name == "Employee Inspect Proficiency"
    ) {
      localStorage.setItem("isfromProficiency", true);
      next();
    } else {
      next();
    }
  },
  name: "Readiness",
  components: {
    DxTreeList,
    DxColumn,
    roleAssignment,
    bulletChartWidget,
    dummy1Widget,
    dummy2Widget,
    DxSparkline,
    DxTooltip,
  },
  data() {
    return {
      flag: false,
      expandedRowKeys: [],
      mainChartOption: {
        chartType: "fusion_chart_bullet_chart_widget",
        employeeReadinessValue: "",
        bulletColor: "",
        readinessChartOption: {
          attrs: {
            options: {
              start: 0,
              end: 100,
              color: [
                {
                  minValue: "0",
                  maxValue: "49.99",
                  code: "#979EA8",
                },
                {
                  minValue: "49.99",
                  maxValue: "79.99",
                  code: "#CFD4DB",
                },
                {
                  minValue: "79.99",
                  maxValue: "100",
                  code: "#F2F3F5",
                },
              ],
              bulletColor: [
                {
                  minValue: "0",
                  maxValue: "49.9999",
                  code: "#E81313",
                },
                {
                  minValue: "49.9999",
                  maxValue: "79.9999",
                  code: "#1071E5",
                },
                {
                  minValue: "79.9999",
                  maxValue: "100",
                  code: "#54C45E",
                },
              ],
            },
          },
        },
      },
      dataSource: [],
      dropDownList: [
        { id: "Your_Path_to_Readiness", name: "Your path to readiness" },
        { id: "Your_expert_badges", name: "Your Expert Badges" },
        {
          id: "Your_current_Responsibilities",
          name: "Your Current Responsibilities",
        },
      ],
      selectedFromDropdown: "Your_Path_to_Readiness",
      source: [],
      pronounEmployee: "Your",
      act_as_me: false,
      isActingAsAnotherUser: false,
    };
  },
  computed: {
    ...nanoramicLabsHelpers.mapGetters(["employeeNameJobList"]),
  },
  async created() {
    if (localStorage.getItem("isfromProficiency")) {
      if (localStorage.getItem("expandedRowKeys")) {
        this.expandedRowKeys = JSON.parse(
          localStorage.getItem("expandedRowKeys")
        );
      }
    }
    if (this.$route.params.employeeId) {
      // this.getLoading(true);
      this.pronounEmployee = "";
      this.getReadiness(this.$route.params.employeeId);
      const id_employee = this.$route.params.employeeId;
      const employee_data = this.employeeNameJobList.find(
        (e) => e.id == id_employee
      );
      this.pronounEmployee = employee_data.name;
      this.act_as_me = false;
      this.changeDropdownList();
      await this.getReadinessData(this.$route.params.employeeId);
      //  this.getLoading(false);
    } else {
      this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
      await this.getReadiness(localStorage.getItem("employee_id"));
      await this.getReadinessData(localStorage.getItem("employee_id"));
    }
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...readinessHelper.mapMutations(["setReadinessData"]),
    async getReadiness(employeeId) {
      this.getLoading(true);
      try {
        const result = await getTecticalReadiness(employeeId);
        this.setReadinessData(result.data.data.response.assignments);
        this.mainChartOption.employeeReadinessValue =
          result.data.data.response.readiness;
        this.mainChartOption.bulletColor = this.getBulletColor(
          this.mainChartOption.readinessChartOption,
          result.data.data.response.readiness
        );
        this.flag = true;
        this.dataSource = [];
        this.prepareData(result.data.data.response.assignments, null);
      } catch (error) {
        console.log("getTecticalReadiness==>", error);
      }
      this.getLoading(false);
    },
    prepareData(res, parentData) {
      for (let i = 0; i < res.length; i++) {
        this.formateData(res[i], parentData);
        if (res[i].children.length > 0) {
          res[i].ID =
            (parentData == null ? 0 : parentData.ID) +
            res[i].model.split(".").join("") +
            res[i].res_id;
          this.prepareData(res[i].children, res[i]);
        }
      }
    },
    formateData(rowData, parentData) {
      let formatedData = {
        ID:
          (parentData == null ? 0 : parentData.ID) +
          rowData.model.split(".").join("") +
          rowData.res_id,
        res_id: rowData.res_id,
        name: rowData.fields.find((x) => x.name == "name").value,
        icon: parentData != null ? rowData.icons : this.getIcon(rowData),
        ParentID: parentData == null ? 0 : parentData.ID,
        parentData: parentData,
        employeeReadinessValue: Math.trunc(
          rowData.fields.find((x) => x.name == "employee_readiness").value
        ),
        readinessChartOption: rowData.nodes.employee_readiness,
        bulletColor: this.getBulletColor(
          rowData.nodes.employee_readiness,
          rowData.fields.find((x) => x.name == "employee_readiness").value
        ),
        chartType: rowData.nodes.employee_readiness.attrs.widget,
        weight:
          rowData.values && rowData.values.role_relative_weight >= 0
            ? rowData.values.role_relative_weight
            : rowData.fields.find((x) => x.name == "role_relative_weight")
                ?.value,
        dummy1: rowData.nodes.dummy_1.attrs,
        dummy2: rowData.nodes.dummy_2.attrs,
        fields: rowData.fields,
        model: rowData.model,
      };
      if (
        rowData.model == "tactical.role.assignment.responsibility" &&
        rowData.children.length > 0
      ) {
        formatedData.children = rowData.children[0];
      }
      if (formatedData.ID != formatedData.parentID) {
        this.dataSource.push(formatedData);
      }
    },
    getIcon(rowData) {
      const str =
        "/addon_tactical_readiness/static/src/img/level_" +
        rowData.fields.find((x) => x.name == "level").value +
        ".png";
      rowData.icons.name.push(str);
      return rowData.icons;
    },
    getBulletColor(data, value) {
      if (data.attrs.options.bulletColor) {
        for (const element of data.attrs.options.bulletColor) {
          if (value <= element.maxValue) {
            return element.code;
          }
        }
      }
    },
    redirect(rowData, type) {
      if (type == "elearning") {
        const link =
          "https://employee.nanoramic.com/course-presentation?" +
          rowData.name.split(" ").join("%20");
        window.open(link, "_blank");
      } else if (type == "book") {
        const link =
          "https://employee.nanoramic.com/book-presentation?" +
          rowData.name.split(" ").join("%20");
        window.open(link, "_blank");
      } else if (type == "proficiency") {
        const responsibility_id = rowData.fields.find(
          (x) => x.name == "responsibility_id"
        ).value;

        let indexOfNumeric = [];
        for (let i = 0; i < rowData.ParentID.length; i++) {
          if ("0123456789".includes(rowData.ParentID[i])) {
            if (
              !indexOfNumeric.includes(i - 1) &&
              !indexOfNumeric.includes(i - 2) &&
              !indexOfNumeric.includes(i - 3)
            ) {
              indexOfNumeric.push(i);
            }
          }
        }
        this.expandedRowKeys = [];
        if (indexOfNumeric.length == 3) {
          this.expandedRowKeys.push(
            rowData.ParentID.slice(0, indexOfNumeric[1] + 4)
          );
        }
        this.expandedRowKeys.push(rowData.ParentID);
        localStorage.setItem(
          "expandedRowKeys",
          JSON.stringify(this.expandedRowKeys)
        );
        if (JSON.parse(localStorage.getItem("act_as_me"))) {
          this.pronounEmployee === "Your"
            ? this.$router.push({
                name: "Your Inspect Proficiency",
                params: {
                  id: responsibility_id,
                  data: rowData,
                },
              })
            : this.$router.push({
                name: "Employee Inspect Proficiency",
                params: {
                  id: responsibility_id,
                  data: {
                    ...rowData,
                    employeeId: this.$route.params.employeeId,
                  },
                },
              });
        } else {
          this.isActingAsAnotherUser = true;
        }
      }
    },
    async getReadinessData(employee_id) {
      this.getLoading(true);
      try {
        const result = await getReadinessDataPoints(employee_id, 0);
        console.log(
          "result.data.data.response.data: ",
          result.data.data.response.data
        );
        this.source = result.data.data.response.data.map((x) => ({
          ...x,
          readiness: x.readiness * 100,
        }));
      } catch (error) {
        console.log("getReadinessData==>", error);
      }
      this.getLoading(false);
    },
    setColors(e) {
      // ref to datasource collection
      const dataItems = e.component.getDataSource().items();

      // ref and iterate over sparkline markers
      e.element.querySelectorAll(".dxc-markers rect").forEach((el, i) => {
        // lookup value from chart dataset
        const { readiness: value } = dataItems[i];

        // get color string based on the value from chart dataset
        const color =
          value >= 80 ? "#A7D42E" : value <= 50 ? "#FF6C6C" : "#53A0DE";

        // update "fill" attribute of the marker
        el.setAttribute("fill", color);
      });
    },
    onSelect(value) {
      if (value === "Your_current_Responsibilities") {
        this.pronounEmployee === "Your"
          ? this.$router.push("/your-job/your-current-responsibilities")
          : this.$router.push(
              `/employees/responsibilities/${this.$route.params.employeeId}`
            );
      } else if (value === "Your_expert_badges") {
        this.pronounEmployee === "Your"
          ? this.$router.push("/your-job/your-expert-badges")
          : this.$router.push(
              `/employees/expert-badges/${this.$route.params.employeeId}`
            );
      }
    },
    getMarginForWeight(data) {
      return `${
        (data.readinessChartOption.attrs.options.height == 1
          ? data.readinessChartOption.attrs.options.leftPadding + 1
          : data.readinessChartOption.attrs.options.leftPadding) * 17
      }px`;
    },
    goBack() {
      // this.$router.push(path);
      this.$router.go(-1);
    },
    changeDropdownList() {
      this.dropDownList.forEach((item) => {
        item.name = item.name.replace("Your", this.pronounEmployee);
      });
    },
    closeDialog() {
      this.isActingAsAnotherUser = false;
    },
    // onExpandedRowKeysChange(e) {
    // console.log('e: ', e.parentKey);
    // this.expandedRowKeys.push(e.key);
    // console.log('this.expandedRowKeys: ', this.expandedRowKeys);
    // },
  },
  beforeDestroy() {
    localStorage.removeItem("isfromProficiency");
  },
};
</script>

<style lang="scss" scoped>
// assets/styles/components/shared-distribution.scss

.main_section {
  &__header {
    background: $white;

    .main_section-title {
      padding: 5px;
    }
  }
}

.sparkline {
  margin-left: 30px;
  width: 200px;
  height: 30px;
}

.mainChartOption-readinessValue {
  color: black;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}

.page_router {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .txt_router {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7f868e;
  }

  .text_redirect:hover {
    cursor: pointer;
    color: $blue;
    text-decoration-line: underline;
  }

  .img_arrow {
    background-image: url("~@/assets/img/arrow_right.svg");
    width: 5px;
    height: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.cust-dialog {
  .cust-dialog-header {
    margin-bottom: 1rem;

    .cust-dialog-title {
      color: $blue;
      text-align: center;
    }
  }

  .cust-dialog-body {
    font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
    font-size: 1.08333333rem;
    font-weight: 400;
    line-height: 1.5;

    .cust-dialog-bodyContent {
      margin-top: 25px;
      width: max-content;

      .cust-dialog-labelContent {
        font-weight: bold;
        color: #008784;
        text-align: left;
      }
    }

    .cust-dialog-label {
      font-weight: bold;
      color: #666666;
      text-align: left;
    }

    .btn-ok {
      width: 64px !important;
      float: left !important;
      margin: 20px 10px 10px 10px !important;
      text-transform: none;
      letter-spacing: normal;
      color: $white;
      font-size: 14px !important;
      font-weight: 600;
      font-style: normal;
      line-height: 17px !important;
    }
  }
}

.v-tooltip__content {
  padding: 0px 10px;
}
</style>
<style lang="scss">
.v-dialog.dialog-box-action-as-another {
  padding: 30px 30px 10px 30px;
  border-radius: 30px;
  background-color: $white;
  width: max-content;
}

.cell-text-align {
  text-align: left !important;
}
</style>
