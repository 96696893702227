<template>
  <div class="table1-container">
    <FirstGrid :col="column_names[0]" :row="rows">
      <template v-slot="slotProps">
        <SecondGrid
          :col="column_names[1]"
          :row="slotProps.row['Certifications that require the course']"
        ></SecondGrid>
      </template>
    </FirstGrid>
  </div>
</template>
<script>
import FirstGrid from "./FirstGrid";
import SecondGrid from "./SecondGrid";

export default {
  name: "TableTemplate",
  components: {
    FirstGrid,
    SecondGrid,
  },
  props: ["rows", "column_names"],
  data() {
    return {
      selectedPageNumber: 1,
      eventsPagination: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.table1-container {
  padding: 40px;
}
</style>
