import axios from "./_axios";
import { API } from "./apiEndPoints";
import EditDateTimeToGMT from "@/utils/edit_date_time_to_gmt.js";

export const getTacticalRoleAssignments = () => {
  let result = axios()({
    url: API.TACTICALROLEASSIGNMENT,
    method: "patch",
    params: { action: "get_role_assignments" },
  });
  return result;
};
export const getTacticalRoleAssignmentsEmployee = (_id_employee) => {
  let result = axios()({
    url: API.TACTICALROLEASSIGNMENT,
    method: "patch",
    params: {
      action: "get_role_assignments",
      employee_id: _id_employee,
    },
  });
  return result;
};
export const getResponsibilitiesEmployee = (_id_employee) => {
  let result = axios()({
    url: API.TACTICALROLEASSIGNMENT,
    method: "patch",
    params: {
      action: "get_responsibilities",
      employee_id: _id_employee,
    },
  });
  return result;
};
export const updateAcceptanceRoleDate = (_tacticalRoleAssignmentID, _date) => {
  let result = axios()({
    url: API.TACTICALROLEASSIGNMENT,
    method: "patch",
    params: {
      action: "updates_acceptance_date",
      employee_assign: _tacticalRoleAssignmentID,
      date: EditDateTimeToGMT(_date),
    },
  });
  return result;
};
export const getResponsibilitiesForDirectReport = (
  _id_employee,
  onlyRoleName
) => {
  let result = axios()({
    url: API.TACTICALROLEASSIGNMENT,
    method: "patch",
    params: {
      action: "get_responsibilities",
      employee_id: _id_employee,
      only_role_name: onlyRoleName,
    },
  });
  return result;
};

export const getOrganizationalChartFrom = (employeeId) => {
  let result = axios()({
    url: API.GETREADINESS,
    method: 'patch',
    params: {
      action: "get_organizational_chart_from",
      employee_id: employeeId,
    }
  });
  return result;
}

