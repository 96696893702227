<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__content">
            <div class="main_section__header" style="text-align: left">
              <span class="section_header">Path to Readiness</span>
            </div>
            <!-- <div class="main_section__content"> -->
            <div>
              <DxTreeList
                id="tasks"
                :data-source="rows"
                :show-borders="true"
                :showRowLines="true"
                :column-auto-width="true"
                :word-wrap-enabled="true"
                key-expr="ID"
                parent-id-expr="ParentID"
              >
                <DxColumn
                  caption=""
                  :width="50 + '%'"
                  data-field="name"
                  cell-template="RoleAssignmentTemplate"
                />
                <DxColumn
                  :width="50 + '%'"
                  :allow-sorting="true"
                  caption="Readiness"
                  cell-template="employeeTemplate"
                />

                <template #employeeTemplate="{ data: options }">
                  <bulletChartWidget :data="options.data"></bulletChartWidget>
                </template>
                <template #RoleAssignmentTemplate="{ data: options }">
                  <roleAssignment :data="options.data"></roleAssignment>
                </template>
              </DxTreeList>
              <DxLoadPanel
                :position="{ of: '#loadig-readiness', at: 'bottom' }"
                :visible="loading_roles"
                :show-indicator="true"
                :show-pane="true"
                :shading="false"
                :close-on-outside-click="false"
              />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DxTreeList, DxColumn } from "devextreme-vue/tree-list";
import DxLoadPanel from "devextreme-vue/load-panel";
import roleAssignment from "@/components/readiness/roleAssignment";
import bulletChartWidget from "@/components/readiness/bulletChartWidget";
export default {
  name: "Readiness",
  props: ["rows", "loading"],
  components: {
    DxTreeList,
    DxColumn,
    roleAssignment,
    bulletChartWidget,
    DxLoadPanel,
  },
  computed: {
    loading_roles() {
      return this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
// assets/styles/components/shared-distribution.scss

.main_section {
  &__header {
    background: $white;
    .main_section-title {
      padding: 5px;
    }
  }
}

.sparkline {
  margin-left: 30px;
  width: 200px;
  height: 30px;
}
.mainChartOption-readinessValue {
  color: black;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
}

// .page_router {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: flex-start;
//   align-items: center;

//   .txt_router {
//     font-family: Gilroy;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 17px;
//     color: #7f868e;
//   }
//   .text_redirect:hover {
//     cursor: pointer;
//     color: $blue;
//     text-decoration-line: underline;
//   }
//   .img_arrow {
//     background-image: url("~@/assets/img/arrow_right.svg");
//     width: 5px;
//     height: 9px;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//     margin-left: 10px;
//     margin-right: 10px;
//   }
// }
.main_section {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.section_header {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #323a44;
}
</style>
