<template>
  <div>
    <v-row no-gutters class="mb-5 ml-5 mr-5">
      <v-col class="pa-0">
        <section class="presentation-page ma-0">
          <v-row no-gutters class="presentation-page__content">
            <v-col cols="12" sm="3" class="pa-0">
              <div class="content-img">
                <div class="content-img__img" :style="course.bg"></div>
              </div>
            </v-col>
            <v-col cols="12" sm="9" lg="5" class="d-flex flex-column">
              <h1 class="detail-title">{{ course.title }}</h1>
              <p class="content-info">
                {{ course.points }} Credits for the course completion.
              </p>
              <div class="intro-progress-bar">
                <v-progress-linear
                  class="progress-bar-container"
                  :value="course.completion"
                  :color="progress_bar_color"
                  height="16"
                >
                  <strong class="progress-text">
                    {{
                      Math.ceil(course.completion)
                        ? Math.ceil(course.completion)
                        : 0
                    }}%
                  </strong>
                </v-progress-linear>
              </div>
            </v-col>
            <v-col cols="12" lg="4" class="text-right mt-3">
              <div class="control-container">
                <p>
                  <span v-if="deadline">
                    Deadline:
                    <span class="font-weight-bold">{{ deadlineDate }}</span>
                  </span>
                  <span v-else> No deadline </span>
                </p>
                <v-btn
                  v-if="course.button"
                  :disabled="act_as_me == false"
                  class="btn_txt"
                  depressed
                  rounded
                  :color="
                    course.button == 'enroll'
                      ? '#ddde00'
                      : course.button == 'start'
                      ? '#a7d42e'
                      : course.button == 'continue'
                      ? '#53a0de'
                      : ''
                  "
                  height="48"
                  width="135"
                  @click="onOpenDialog"
                >
                  {{ course.button }}
                </v-btn>
                <CoursePopup
                  v-if="openDialog"
                  :course_id="course.id"
                  class="content-btn"
                  :openDialog="openDialog"
                  :showDescriptionDirectly="false"
                  @exit="completedCourse"
                  @completedCourse="completedCourse"
                />
              </div>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import CoursePopup from "@/components/course/CoursePopup";
import EditDate from "@/utils/edit_date_6.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const takeCourseHelper = createNamespacedHelpers("takeCourse");
import { getCourseDetailWithIDUnpublished } from "@/api";

export default {
  name: "AskedToLearnCard",
  props: {
    type: {
      type: String,
      required: true,
    },
    model_name: {
      type: String,
      required: true,
    },
    deadline: {
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      act_as_me: true,
      progress_bar_color: "#A7D42E",
      openDialog: false,
    };
  },
  computed: {
    deadlineDate() {
      return EditDate(this.deadline);
    },
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  components: {
    CoursePopup,
  },
  methods: {
    ...mapActions(["getLoading"]),
    onOpenDialog() {
      this.openDialog = true;
    },
    closeCourse() {
      this.openDialog = false;
    },
    completedCourse() {
      this.closeCourse();
      this.$emit("reloadCoursesData");
    },
  },
};
</script>
<style lang="scss" scoped>
.presentation-page {
  &__content {
    .content-img {
      padding: 0 1rem;
      &__img {
        width: 125px;
        height: 125px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 1.275rem;
        border: 1px solid transparent;
      }
    }
    .control-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      p {
        font-size: 14px;
        flex-grow: 1;
      }
      .btn_txt {
        .v-btn__content {
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: white;
          text-transform: capitalize;
          position: absolute;
          letter-spacing: normal;
        }
      }
    }
    .content-info {
      padding-left: 0.25rem;
      font-size: 14px;
      font-weight: 500;
      max-width: 18.75rem;
      color: #323a44;
      flex-grow: 1;
    }
    .content-btn {
      margin-left: 0.1875rem;
      width: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.875rem;
      cursor: pointer;
    }
  }
  .detail-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.8125rem;
    text-transform: capitalize;
    color: #323a44;
    margin-bottom: 10px;
  }
  .intro-progress-bar {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    .progress-bar-text {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #323a44;
    }
    .progress-bar-container {
      margin-top: 14px;
      border-radius: 8px;
      .progress-text {
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        text-transform: capitalize;
        color: #323a44;
      }
    }
  }
}
@media screen and (min-width: 1263px) and (max-width: 1470px) {
  .content-img__img {
    width: 100px !important;
    height: 100px !important;
  }
}
@media screen and (max-width: 470px) {
  .content-img {
    padding: 0;
  }
  .control-container {
    align-items: flex-start !important;
  }
}
</style>
