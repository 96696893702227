export default {
  namespaced: true,
  state: {
    isCropped: false,
    croppedImg: "",
    savedImg: false,
  },
  actions: {
    getImg({ commit }, status) {
      commit("setImg", status);
      commit("setIsCropped", true);
    },
    dispatchClearImg({ commit }) {
      commit("setImg", "");
      commit("setIsCropped", false);
    },
  },
  mutations: {
    setImg(state, status) {
      state.croppedImg = status;
    },
    setIsCropped(state, status) {
      state.isCropped = status;
    },
    setImgSaved(state, status) {
      state.savedImg = status;
    },
  },
  getters: {
    getIsCropped(state) {
      return state.isCropped;
    },
    getCroppedImg(state) {
      return state.croppedImg;
    },
    imgSaved(state) {
      return state.savedImg;
    },
  },
};
