export const LAST_DAY_ROLE = "2022-12-31";
export const COACHING_STYLES = [
  { id: "s1-telling", name: "S1 - Telling" },
  { id: "s2-selling", name: "S2 - Selling" },
  { id: "s3-participating", name: "S3 - Participating" },
  { id: "s4-delegating", name: "S4 - Delegating" },
];
export const TARGETS = [
  { id: "novice", name: "Novice" },
  { id: "apprentice", name: "Apprentice" },
  { id: "professional", name: "Professional" },
  { id: "expert", name: "Expert" },
];
export const LEADERSHIP = {
  "s1-telling": { color: "#04b345" },
  "s2-selling": { color: "#f3711a" },
  "s3-participating": { color: "#00adf1" },
  "s4-delegating": { color: "#eb008b" },
};
export const PROFICIENCY = {
  novice: { color: "#ff8f8f" },
  apprentice: { color: "#6db1ff" },
  professional: { color: "#c3f7c8" },
  expert: { color: "#f5f507" },
  super_expert: { color: "#ff383e" }
};
