import axios from "./_axios";
import { API } from "./apiEndPoints";
import moment from "moment";

export const getTecticalReadiness = (employeeId) => {
  // var urlencoded = new URLSearchParams();
  // urlencoded.append("employee_id", localStorage.getItem("employee_id"));
  // urlencoded.append("employee_id", "64");
  let result = axios()({
    method: "patch",
    url: API.GETREADINESS,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: {
      action: "get_employee_path_to_readiness",
      employee_id: employeeId, //localStorage.getItem("employee_id"),
    },
    // data: urlencoded,
  });
  return result;
};

export const getReadinessDataPoints = (employeeId, include_readiness) => {
  let result = axios()({
    url: API.READINESSDATAPOINTS,
    method: "patch",
    params: {
      action: "get_data_points",
      employee_id: employeeId, // 323,
      date_from: moment(new Date()).format("yyyy-MM-DD"),
      // date_to:'',
      dow: 6, //6
      number_of_weeks: -12,
      include_readiness: include_readiness ? 1 : "",
    },
  });
  return result;
};
export const getExpertBadges = (employeeId) => {
  let result = axios()({
    url: API.GETEXPERTSEMPLOYEE,
    method: 'patch',
    params: {
      action: "get_experts_employee",
      employee_id: employeeId
    }
  });
  return result;
}
export const getCheckPoints = (responsibilityId, employeeId) => {
  let result = axios()({
    url: API.GETEXPERTS,
    method: "patch",
    params: {
      action: "get_checkpoints",
      responsibility_id: responsibilityId,
      employee_id: employeeId,
      // state: "active"
    },
  });
  return result;
};

export const getResponsibilityById = (_id) => {
  let result = axios()({
    url: `${API.GETEXPERTS}/${_id}`,
    method: "get",
  });
  return result;
};

export const evaluateCheckpoints = (employeeId, state, checkpointId) => {
  let result = axios()({
    url: API.EVALUATECHECKPOINT,
    method: 'PATCH',
    params: {
      action: 'evaluate_checkpoint',
      employee_id: employeeId,
      new_state: state,
      checkpoint_id: checkpointId
    },
  })
  return result;
};

export const getBadge = (responsibilityId) => {
  let result = axios()({
    url: API.GETEXPERTS,
    method: 'PATCH',
    params: {
      action: 'get_badge',
      responsibility_id: responsibilityId,
    },
  });
  return result;
};

export const grantBadge = (employeeID, responsibilityId, badge) => {
  let result = axios()({
    url: API.GETEXPERTSEMPLOYEE,
    method: 'PATCH',
    params: {
      action: 'grant_badge',
      proficiency: badge,
      employee_id: employeeID,
      responsibility_id: responsibilityId
    }
  })
  return result;
}

export const editExpiration = (badgeId, expirationDate) => {
  let result = axios()({
    url: API.GETEXPERTSEMPLOYEE,
    method: 'patch',
    params: {
      action: 'set_badge_expiration_date',
      badge_id: badgeId,
      expiration_date: expirationDate
    }
  })
  return result;
}
