<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Your Learning Events</h1>
            <p class="text">
              All learning events in which you’re enrolled, including all your
              learning events in progress and already completed.
            </p>
          </div>
          <div class="main_section__content">
            <Filters
              :filters="filters"
              :searchVal="query"
              searchPlaceholder="Search events"
              @selectedTag="filterByTags"
              @search="filterByQuery"
            />

            <SelectedTags
              :selectedTags="selectedTags"
              @removeTag="removeFilter"
              @removeTags="removeFilters"
              :class="{ eventActive: getMobileMenuActive }"
            />

            <!-- <transition
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
            > -->
            <div v-if="!notFound">
              <div class="not-filter" v-show="notFilter">
                <div class="not-filter__img-fluid"></div>
                <div class="not-filter__text">
                  <p class="not-filter-title">
                    No event was found matching your filters
                  </p>
                  <p class="not-filter-subtitle">
                    Please, try to choose other filters or delete some of them.
                  </p>
                </div>
              </div>
              <div v-show="!notFilter" class="eventsTable">
                <!-- <EventsTable
                  :cols="learning_cols"
                  :rows="learning_rows"
                  :courses="courses"
                  :class="{ eventActive: getMobileMenuActive }"
                  :tableType="tableType"
                /> -->
                <TableEvents
                  :cols="learning_cols"
                  :rows="learning_rows"
                  :courses="courses"
                />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-events"></div>
                <div class="not-found__space-text"></div>
                <p class="not-found__text">You have no assigned events yet</p>
              </div>
            </div>
            <!-- </transition> -->
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/learning-events/EventsFilters";
// import EventsTable from "@/components/credit-requests/EventsTable";
import TableEvents from "@/components/learning-events/TableEvents.vue";
import SelectedTags from "@/components/course-catalog/SelectedTags";
import { getLearningEvents, getCourses } from "@/api";
import { createNamespacedHelpers } from "vuex";
import EditPastDate from "@/utils/edit_past_date.js";
import EditFutureDate from "@/utils/edit_future_date.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
export default {
  name: "LearningEvents",
  components: {
    Filters,
    SelectedTags,
    // EventsTable,
    TableEvents,
  },
  data() {
    return {
      filters: {
        status: [],
        type: [],
      },
      tableType: "events",
      show: true,
      notFilter: false,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      learning_cols: [
        "type",
        "name",
        "deadline",
        "credits",
        "status",
        "started on",
        "progress bar",
        "completed on",
        "expiration date",
      ],
      fullLearningRows: [],
      setLearningRows: [],
      learning_rows: [],
      gettingEvents: [],
      courses: [],
    };
  },
  computed: {
    notFound() {
      return this.gettingEvents.length == 0;
    },
    ...setCoursePageInfoHelpers.mapGetters(["getMobileMenuActive"]),
  },
  created() {
    this.getEventsCoursesFromAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getEventsCoursesFromAPI() {
      this.getLoading(true);
      await this.getCourses();
      await this.getLearningEvents();
      this.getLoading(false);
    },
    async getCourses() {
      try {
        let result = await getCourses();
        if (result.status == 200) {
          this.courses = result.data.data.response;
        }
      } catch (error) {
        console.log("getCoursesError==>", error);
      }
    },
    async getLearningEvents() {
      try {
        let result = await getLearningEvents();
        if (result.status == 200) {
          this.gettingEvents = result.data.data.response.data;
          // let ex = example.data.response.data;
          // this.gettingEvents = ex;
          if (this.gettingEvents.length != 0) {
            this.filters = {
              status: ["Continue", "Start", "Completed"],
              type: [
                "eLearning",
                "Classroom",
                "Book",
                "Reading",
                "Coaching",
                "Teaching",
                "Content Creation",
              ],
            };
            for (let index = 0; index < this.gettingEvents.length; index++) {
              const event = this.gettingEvents[index];
              let row = {
                id: 0,
                type: "",
                name: "",
                deadline: "",
                credits: "",
                status: "",
                "started on": "",
                "progress bar": 0,
                "completed on": "",
                "expiration date": "",
                startedDate: 0,
                completedDate: 0,
                deadlineDate: 0,
                expirationDate: 0,
                bg: "",
                channel_id: "",
              };

              switch (event.activity_type.type) {
                case "elearning":
                  row.type = "eLearning";
                  break;
                case "classroom":
                  row.type = "Classroom";
                  break;
                case "book":
                  row.type = "Book";
                  break;
                case "reading":
                  row.type = "Reading";
                  break;
                case "coaching":
                  row.type = "Coaching";
                  break;
                case "teaching":
                  row.type = "Teaching";
                  break;
                case "content_creation":
                  row.type = "Content Creation";
                  break;

                default:
                  break;
              }
              row.id = event.id;
              row.channel_id = event.activity_type.channel_id;
              row.bg = event.activity_type.bg;

              row.name = event.activity_type.name;
              row["progress bar"] = event.activity_type.completion;
              row.credits = event.credits;
              switch (event.state) {
                case "planned":
                  row.status = "Start";
                  break;
                case "done":
                  row.status = "Completed";
                  break;
                case "in_progress":
                  row.status = "Continue";
                  break;
                case "to_do":
                  row.status = "Start";
                  break;
                case "canceled":
                  row.status = "Canceled";
                  break;
                case "voided":
                  row.status = "Voided";
                  break;
                default:
                  break;
              }

              if (event.activity_type.started_on) {
                row["started on"] = EditPastDate(
                  event.activity_type.started_on
                );
                row.startedDate = new Date(event.activity_type.started_on);
              } else {
                row["started on"] = "-";
              }
              if (event.deadline_date) {
                row.deadline = EditFutureDate(event.deadline_date);

                row.deadlineDate = Math.abs(
                  new Date(event.deadline_date) - new Date()
                );
              } else {
                row.deadline = "-";
                row.deadlineDate = new Date() * 10;
              }
              if (event.activity_type.completion == 100) {
                if (event.completion_date) {
                  row["completed on"] = EditPastDate(event.completion_date);
                  row.completedDate = new Date(event.completion_date);
                } else {
                  row["completed on"] = "-";
                }
              } else {
                row["completed on"] = row.status;
              }
              if (event.expiration_date) {
                row["expiration date"] = EditFutureDate(event.expiration_date);
                row.expirationDate = new Date(event.expiration_date);
              } else {
                row["expiration date"] = "-";
              }

              this.fullLearningRows.push(row);
            }
            this.setLearningRows = this.fullLearningRows;
            this.learning_rows = this.fullLearningRows;
          }
        }
      } catch (error) {
        console.log("GetLearningEventsError==>", error);
      }
    },
    removeFilters() {
      this.selectedTags = [];

      this.setLearningRows = this.fullLearningRows;
      this.learning_rows = this.setLearningRows;
    },
    removeFilter(filter) {
      const index = this.selectedTags.findIndex((t) => filter === t);

      this.selectedTags.splice(index, 1);
      if (this.selectedTags.length === 0) {
        this.removeFilters();
      } else {
        this.setLearningRows = this.fullLearningRows.filter((c) =>
          this.filterTags(c, this.selectedTags)
        );
        this.learning_rows = this.setLearningRows;
      }
    },
    filterByTags(tag) {
      this.query = "";

      if (this.selectedTags) {
        if (!this.selectedTags.includes(tag)) this.selectedTags.push(tag);
      } else {
        this.selectedTags.push(tag);
      }

      this.setLearningRows = this.fullLearningRows.filter((c) =>
        this.filterTags(c, this.selectedTags)
      );
      console.log("setLearningRows==>", this.setLearningRows);
      this.learning_rows = this.setLearningRows;
    },
    filterTags(a, b) {
      let sendBack = false;
      if (b.includes(a.type) || b.includes(a.status)) {
        sendBack = true;
      }
      return sendBack;
    },
    filterByQuery(value) {
      this.selectedTags = [];

      this.query = value;
      this.setLearningRows = this.fullLearningRows.filter((item) => {
        return this.query
          .toLowerCase()
          .split(" ")
          .every((v) => item.name.toLowerCase().includes(v));
      });
      this.learning_rows = this.setLearningRows;
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
