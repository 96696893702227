<template>
  <div>
    <div class="comingSoon">
      <img
        class="comingSoonImg"
        src="@/assets/img/coming_soon.svg"
        alt="Coming soon image"
      />
      <h3 class="comingSoonText">Coming soon...</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComingSoon",
};
</script>
<style lang="scss" scoped>
.comingSoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5rem;
  margin-top: 4rem;
  margin-right: 5rem;
  margin-bottom: 3rem;
}
.comingSoonText {
  color: $blue;
  font-size: 2.25rem;
  font-weight: 600;
}
.comingSoonImg {
  width: 100%;
  max-width: 600px;
  height: auto;
}
@media screen and (max-width: 406px) {
  .comingSoonText {
    font-size: 1rem;
  }
}
</style>
