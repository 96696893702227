<template>
  <div
    class="drag-and-drop"
    @drop="handleFileDrop"
    @dragover="dragover"
    @dragleave="dragleave"
  >
    <input
      class="d-none"
      type="file"
      id="assetsFieldHandle"
      @change="handleFileInput"
      ref="file"
      accept=".pdf,.jpg,.jpeg,.png"
      size="10"
    />

    <label for="assetsFieldHandle">
      <div class="label-wrapper">
        <HeadshotIcon class="icon" />
        <span class="label">Upload a headshot picture of you</span>
      </div>
    </label>
  </div>
</template>

<script>
import HeadshotIcon from "@/components/tasks/HeadshotIcon";
export default {
  components: {
    HeadshotIcon,
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleFileDrop(e) {
      let droppedFile = e.dataTransfer.files[0];
      if (!droppedFile) return;
      this.file = droppedFile;
      e.currentTarget.classList.remove("bg-opacity");
      this.$emit("drop-file", this.file);
      e.target.value = "";
    },
    handleFileInput(e) {
      console.log(e.target.files[0], "fileinput");
      let inputFile = e.target.files[0];
      if (!inputFile) return;
      this.file = inputFile;
      this.$emit("drop-file", this.file);
      e.target.value = "";
    },
    dragover(e) {
      if (!e.currentTarget.classList.contains("bg-opacity")) {
        e.currentTarget.classList.add("bg-opacity");
      }
    },
    dragleave(e) {
      e.currentTarget.classList.remove("bg-opacity");
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  width: 300px;
  border: 2px solid red;
}
.drag-and-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed #a7d42e;
  border-radius: 30px;
  background-color: rgba(167, 212, 46, 0.05);

  &.bg-opacity {
    opacity: 0.5;
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .icon {
      margin-bottom: 60px;
    }

    .label {
      text-decoration: underline;
      text-underline-offset: 5px;
      font-size: 36px;
      font-weight: 500;
      line-height: 42px;
      color: #a7d42e;
    }
  }
}
@media screen and (max-width: 450px) {
  .drag-and-drop .label-wrapper .label {
    font-size: 20px;
  }
}
</style>
