<template>
  <div class="table1-container">
    <FirstGrid
      :col="column_names[0]"
      :row="roles"
      :disabled_button="disabled_button"
    >
      <template v-slot="slotProps">
        <SecondGrid
          :col="column_names"
          :row="slotProps.row.childs"
          :disabled_button="disabled_button"
        ></SecondGrid>
      </template>
    </FirstGrid>
  </div>
</template>
<script>
import FirstGrid from "@/components/assignments/FirstGrid";
import SecondGrid from "@/components/assignments/SecondGrid";

export default {
  name: "TableDetail",
  components: {
    FirstGrid,
    SecondGrid,
  },
  props: ["roles", "column_names", "disabled_button"],
  data() {
    return {
      selectedPageNumber: 1,
      eventsPagination: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.table1-container {
  padding: 40px;
}
</style>
