import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import CourseCatalog from "@/views/CourseCatalog.vue";
import AskedToLearn from "@/views/AskedToLearn.vue";
import CoursePresentation from "@/views/CoursePresentation.vue";
import CoursePreview from "@/views/CoursePreview.vue";
import Maintenance from "@/views/Maintenance.vue";
import Login from "@/views/Login.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import EmployeeProfile from "@/views/EmployeeProfile.vue";
import LearningDashboard from "@/views/LearningDashboard.vue";
import Dashboard from "@/views/Dashboard.vue";
import OrgChartView from "@/views/OrgChartView.vue";
import TakeCourse from "@/views/TakeCourse.vue";
import TakeCoursePreview from "@/views/TakeCoursePreview.vue";
import Directory from "@/views/Directory.vue";
import Employees from "@/views/Employees.vue";
import Locations from "@/views/Locations.vue";
import Departments from "@/views/Departments.vue";
import Tasks from "@/views/Tasks.vue";
import LearningEvents from "@/views/LearningEvents.vue";
import CreditRequests from "@/views/CreditRequests.vue";
import YourAssignments from "@/views/YourAssignments.vue";
import CurrentResponsibilities from "@/views/CurrentResponsibilities.vue";
import RequiredCertifications from "@/views/RequiredCertifications.vue";
import ServiceDesk from "@/views/ServiceDesk.vue";
import RequiredElearning from "@/views/RequiredElearning.vue";
import BookClub from "@/views/BookClub.vue";
import BookPresentation from "@/views/BookPresentation";
import YourHRFile from "@/views/YourHRFile.vue";
import Faq from "@/views/Faq.vue";
import DirectReports from "@/views/DirectReports.vue";
import Coaching from "@/views/Coaching.vue";
import EmployeeRoles from "@/views/YourAssignments.vue";
import EmployeeCertifications from "@/views/RequiredCertifications.vue";
import EmployeeResponsibilities from "@/views/CurrentResponsibilities.vue";
import Status from "@/views/Status.vue";
import YourRoadmap from "@/views/YourRoadmap.vue";
import Readiness from "@/views/Readiness.vue";
import YourCharges from "@/views/YourCharges.vue";
import YourDischarges from "@/views/YourDischarges.vue";
import YourCostPerHour from "@/views/YourCostPerHour.vue";
import CurrentvsDeservedSalary from "@/views/CurrentvsDeservedSalary.vue";
import YourExpertBadges from "@/views/YourExpertBadges.vue";
import InspectProficiency from "@/views/InspectProficiency.vue"

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset_password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/employees/profile",
    name: "EmployeeProfile",
    component: EmployeeProfile,
  },
  {
    path: "/employees/roles/:employee",
    name: "Employee Roles",
    component: EmployeeRoles,
  },
  {
    path: "/employees/certifications/:employee",
    name: "Employee Certifications",
    component: EmployeeCertifications,
  },
  {
    path: "/employees/responsibilities/:employee",
    name: "Employee Responsibilities",
    component: EmployeeResponsibilities,
  },
  {
    path: "/home/orgchart",
    name: "Org Chart",
    component: OrgChartView,
  },
  {
    path: "/home/status",
    name: "Status",
    component: Status,
  },
  {
    path: "/home/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/learning/dashboard",
    name: "Learning Dashboard",
    component: LearningDashboard,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/learning/course-catalog",
    name: "Course Catalog",
    component: CourseCatalog,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/learning/asked-to-learn",
    name: "Asked to Learn",
    props: (route) => ({ dash: route.query.dash }),
    component: AskedToLearn,
  },
  {
    path: "/learning/learning-events",
    name: "Learning Events",
    component: LearningEvents,
  },
  {
    path: "/learning/credit-requests",
    name: "Credit Requests",
    component: CreditRequests,
  },
  {
    path: "/learning/book-club",
    name: "Book Club",
    component: BookClub,
  },
  {
    path: "/book-presentation",
    name: "BookPresentation",
    component: BookPresentation,
  },
  {
    path: "/course-presentation",
    name: "CoursePresentation",
    component: CoursePresentation,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/course-preview",
    name: "Course Preview",
    component: CoursePreview,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/take-course",
    name: "Take Course",
    component: TakeCourse,
  },
  {
    path: "/see-course-preview",
    name: "Take Course Preview",
    component: TakeCoursePreview,
  },
  {
    path: "/home/directory",
    name: "Directory",
    component: Directory,
  },
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
  },

  {
    path: "/locations",
    name: "Locations",
    component: Locations,
  },
  {
    path: "/departments",
    name: "Departments",
    component: Departments,
  },
  {
    path: "/your-job/required-certifications",
    name: "Required Certifications",
    component: RequiredCertifications,
  },
  {
    path: "/your-job/your-assignments",
    name: "Your Assignments",
    component: YourAssignments,
  },
  {
    path: "/your-job/your-current-responsibilities",
    name: "Your Current Responsibilities",
    component: CurrentResponsibilities,
  },
  {
    path: "/your-job/required-elearning",
    name: "Required eLearning",
    component: RequiredElearning,
  },
  {
    path: "/your-roadmap",
    name: "Your Roadmap",
    component: YourRoadmap,
  },
  {
    path: "/manage-others/direct-reports",
    name: "Direct Reports",
    component: DirectReports,
  },
  {
    path: "/manage-others/coaching",
    name: "Coaching",
    component: Coaching,
  },
  {
    path: "/people-ops-services/service-desk",
    name: "Service Desk",
    component: ServiceDesk,
  },
  {
    path: "/people-ops-services/your-hr-file",
    name: "Your HR File",
    component: YourHRFile,
  },
  {
    path: "/people-ops-services/faqs",
    name: "FAQs",
    component: Faq,
  },
  {
    path: "/readiness",
    name: "Readiness",
    component: Readiness,
  },
  {
    path: "/compensation/your-charges",
    name: "Your Charges",
    component: YourCharges,
  },
  {
    path: "/compensation/your-discharges",
    name: "Your Discharges",
    component: YourDischarges,
  },
  {
    path: "/compensation/your-cost-per-hour",
    name: "Your Cost Per Hour",
    component: YourCostPerHour,
  },
  {
    path: "/compensation/current-vs-deserved-salary",
    name: "Current vs Deserved Salary",
    component: CurrentvsDeservedSalary,
  },
  {
    path: "/compensation/current-vs-deserved-salary/:employeeId",
    name: "Managing others Current vs Deserved Salary",
    component: CurrentvsDeservedSalary,
  },
  {
    path: "/your-job/your-expert-badges",
    name: "Your Expert Badges",
    component: YourExpertBadges,
  },
  {
    path: "/employees/expert-badges/:employee",
    name: "Employee Expert Badges",
    component: YourExpertBadges,
  },
  {
    path: "/your-proficiency/your-inspect-proficiency/:id",
    name: "Your Inspect Proficiency",
    component: InspectProficiency,
  },
  {
    path: "/employees/inspect-proficiency/:id",
    name: "Employee Inspect Proficiency",
    component: InspectProficiency,
  },
  {
    path: "/readiness/:employeeId",
    name: "EmployeeReadiness",
    component: Readiness,
  },
  { path: "*", redirect: "/home/dashboard" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const currentDate = Date.now();
  const expireDate = localStorage.getItem("expireDate");
  if (expireDate) {
    if (currentDate > expireDate) {
      console.log("======expired token====");
      localStorage.clear();
    }
  } else {
    localStorage.clear();
  }
  const loggedIn = localStorage.getItem("token");
  if (loggedIn) {
    if (to.path == "/reset_password") {
      return next(from.path);
    }
  }
  if (!loggedIn) {
    if (to.path == "/reset_password") {
      return next();
    }
  }

  if (authRequired && !loggedIn && to.path != "/reset_password") {
    if (to.fullPath == "/") {
      return next("/login");
    } else {
      return next({ path: "/login", query: { nextUrl: to.fullPath } });
    }
  }
  if (!authRequired) {
    localStorage.clear();
  }
  next();
});

export default router;
