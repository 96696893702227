import {
  getCourses,
  getCourseDetailWithID,
  getCourseDetailWithIDUnpublished,
} from "@/api";

export default {
  namespaced: true,
  state: {
    course_detail: "",
    courses_List: [],
    courses_to_approve_list: [],
    course_approval_needed: false,
  },
  mutations: {
    setCourseDetail(state, course_detail) {
      state.course_detail = {};
      state.course_detail = course_detail;
      // console.log(state.course_detail)
    },
    setCoursesList(state, status) {
      state.courses_List = status;
    },
    setCoursesToApproveList(state, status) {
      state.courses_to_approve_list = [...status];
    },
    setCourseApprovalNeeded(state, status) {
      state.course_approval_needed = status;
    },
    setCourseDetailUpdate(state, status) {
      status.request_model_name = state.course_detail.request_model_name;
      status.task_id = state.course_detail.task_id;
      state.course_detail = status;
    },
    setCourseButtonStart(state) {
      state.course_detail.button = "start";
    },
    // setCourseDetailState(state, status) {
    //   state.course_detail.completed = status.completed;
    //   state.course_detail.button = status.button;
    // }
  },
  actions: {
    dispatchCourseDetail({ commit }, course_detail) {
      const courseFiltered = new course_filter(course_detail);
      commit("setCourseDetail", courseFiltered);
    },
    async dispatchCourseID({ commit }, _id) {
      try {
        let result_course = await getCourseDetailWithID(_id);
        if (result_course.status == 200) {
          let course = result_course.data.data.response[0];
          const courseFiltered = new course_filter(course);
          commit("setCourseDetailUpdate", courseFiltered);
        }
        // commit("setCoursesToApproveList", course);
      } catch (e) {
        console.error("dispatchCourseIDError==>", e);
      }
    },
    async dispatchCoursesList({ commit }) {
      try {
        let all_course_list = [];
        let result_all_course = await getCourses();
        if (result_all_course.status == 200) {
          let courses = result_all_course.data.data.response;
          all_course_list = [...courses];
        }
        commit("setCoursesList", all_course_list);
      } catch (e) {
        console.error("getCoursesError==>", e);
      }
    },
    async dispatchCourseToApprove(
      { commit },
      { _id, _request_model_name, _task_id }
    ) {
      try {
        let course = [];
        let result_course = await getCourseDetailWithIDUnpublished(_id);
        if (result_course.status == 200) {
          const response = result_course.data.data.response[0];
          course = JSON.parse(JSON.stringify(response));
          course.request_model_name = _request_model_name;
          course.task_id = _task_id;
          const courseFiltered = new course_to_approve_filter(course, true);
          commit("setCourseDetail", courseFiltered);
        }
      } catch (e) {
        console.error("dispatchCourseToApproveError==>", e);
      }
    },
  },
  getters: {
    courseDetail(state) {
      return state.course_detail;
    },
    coursesList(state) {
      return state.courses_List;
    },
    coursesToApproveList(state) {
      return state.courses_to_approve_list;
    },
    courseApprovalNeeded(state) {
      return state.course_approval_needed;
    },
  },
};
function course_filter({ ...data }) {
  this.id = data.id;
  this.bg = data.bg;
  this.title = data.title;
  this.minutes = data.minutes;
  this.steps = data.steps;
  this.completed = data.completed;
  this.completion = data.completion;
  this.points = data.points;
  this.button = data.button;
  this.tags = data.tags;
  this.description = data.description;
  this.content = data.content;
  this.prerequisites = data.prerequisites;
  this.request_model_name = data.request_model_name
    ? data.request_model_name
    : "";
}
function course_to_approve_filter({ ...data }, _datas_exceded) {
  this.id = data.id;
  this.bg = data.bg;
  this.title = data.title;
  this.minutes = data.minutes;
  this.steps = data.steps;
  this.completed = data.completed;
  this.completion = data.completion;
  this.points = data.points;
  this.button = data.button;
  this.tags = data.tags;
  this.description = data.description;
  this.content = data.content;
  this.prerequisites = data.prerequisites;
  this.request_model_name = data.request_model_name
    ? data.request_model_name
    : "";
  this.task_id = data.task_id;

  this.slide_detail = new Array(data.slide_detail.length);
  for (let i = 0; i < data.slide_detail.length; i++) {
    this.slide_detail[i] = {};
  }
  for (const index in data.slide_detail) {
    let _slide = data.slide_detail[index];
    this.slide_detail[index].name = _slide.name;
    this.slide_detail[index].slide_id = _slide.slide_id;
    this.slide_detail[index].completed = _slide.completed;
    this.slide_detail[index].content = {};
    this.slide_detail[index].content.html_content =
      _slide.content?.html_content;
    this.slide_detail[index].content.document_type =
      _slide.content?.document_type;
    this.slide_detail[index].content.document_id = _slide.content?.document_id;
    this.slide_detail[index].content.url = _slide.content?.url;
    this.slide_detail[index].content.datas = _datas_exceded
      ? "EXCEDED"
      : _slide.content?.datas;
    this.slide_detail[index].quiz = _slide.quiz;
    this.slide_detail[index].slide_type = _slide.slide_type;
    this.slide_detail[index].links = _slide.links;
  }
}
