<template>
  <div>
    <div class="asked_page">
      <div class="page_router">
        <span class="txt_router">Home</span>
        <div class="img_arrow"></div>
        <div class="txt_router">Dashboard</div>
      </div>
      <v-row justify="center" no-gutters class="mt-5">
        <v-col cols="12" lg="8" class="pa-0 ml-md-a">
          <section class="course-page">
            <div class="course-page__content">
              <div class="course-page__title size-24">Courses To Take</div>
              <AskedToLearnCard
                v-for="(task, index) in tasksNoIntro"
                :key="index"
                :type="task.task_type"
                :model_name="task.request_model_name"
                :deadline="task.date_deadline"
                :course="task.course"
                class="course-card"
                @reloadCoursesData="getTasksFromAPI"
              />
            </div>
          </section>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AskedToLearnCard from "@/components/course/AskedToLearnCard";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const TasksHelper = createNamespacedHelpers("tasks");

export default {
  name: "CourseCatalog",
  components: {
    AskedToLearnCard,
  },
  props: {
    dash: {
      required: false,
    },
  },
  data() {
    return {
      tasks_courses: [],
    };
  },
  computed: {
    ...TasksHelper.mapGetters(["tasksNoIntro"]),
  },
  created() {
    // if (this.dash != 1 || this.dash != "1" || this.tasksNoIntro.length == 0) this.getTasksFromAPI();

    /*
     * This method now gets called on the Header to make it faster
     */
    // this.dispatchUserTasksByCourse();
    this.getTasksFromAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...TasksHelper.mapActions(["dispatchUserTasksByCourse"]),
    async getTasksFromAPI() {
      this.getLoading(true);
      await this.dispatchUserTasksByCourse();
      this.getLoading(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.asked_page {
  margin: 30px 80px;
  display: flex;
  flex-direction: column;
  .page_router {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .txt_router {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #7f868e;
    }
    .img_arrow {
      background-image: url("~@/assets/img/arrow_right.svg");
      width: 5px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .course-page {
    margin: 30px 80px;
    &__content {
      background: $white;
      border-radius: 30px;
      margin-bottom: 2.0625rem;
      padding-bottom: 10px;
    }
    &__title {
      align-items: center;
      background-color: $blue;
      border-radius: 30px;
      display: flex;
      justify-content: space-between;
      height: 80px;
      color: #ffffff;
      padding-left: 50px;
      font-weight: 700;
    }
    .course-card {
      border-bottom: 1px solid #f6f6fb;
      margin: 30px 0;
      min-height: 140px;
      overflow: auto;
    }
  }
}
@media screen and (max-width: 1000px) {
  .asked_page {
    margin: 30px 40px !important;
  }
  .course-page {
    margin: 30px 0px !important;
  }
}
</style>
