<template>
  <div>
    <div class="page-title">
      <span>Learning Dashboard</span>
    </div>
    <div class="dashboard-content">
      <div class="block-list">
        <TableForDashboard
          v-if="courses_rows.length > 0"
          route_name="Asked to Learn"
          :cols="courses_cols"
          :rows="courses_rows"
          content_title="Courses you are asked to take"
          @reloadCoursesData="reloadCoursesData"
        />
        <div v-else class="no-courses">
          <span>You have no courses to take at this time</span>
        </div>
        <TableForDashboard
          v-if="new_learning_rows.length > 0"
          route_name="Course Catalog"
          :cols="new_learning_cols"
          :rows="new_learning_rows"
          content_title="New E-Learning courses"
          @reloadCoursesData="reloadCoursesData"
        />
        <div v-else class="promote-new-courses">
          <span>
            Promote the Course Catalog when are no New Learning courses
          </span>
        </div>
        <TableForDashboard
          v-if="completed_learning_rows.length > 0"
          route_name="Learning Events"
          :cols="completed_learning_cols"
          :rows="completed_learning_rows"
          content_title="You recently completed learning events"
          @reloadCoursesData="reloadCoursesData"
        />
        <div v-else class="no-completed-courses">
          <span>You have no recently completed learning events</span>
        </div>
        <!-- <div class="continuous-learning">
          <div class="continuous-learning__title">
            <span>Your continuous learning credits</span>
          </div>

          <div class="continuous-learning__charts">
            <highcharts :options="chartOptions" class="charts-content" />
          </div>
        </div>
        <div class="no-completed-courses">
          <span>You have no recently completed learning events</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import TableForDashboard from "@/components/learning-dashboard/TableForDashboard";
import EditFutureDate from "@/utils/edit_future_date.js";
import EditDate from "@/utils/edit_date_1.js";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
import EditPastDate from "@/utils/edit_past_date.js";
import { getCourses, getLearningEvents } from "@/api";
const { mapActions } = createNamespacedHelpers("isLoading");
const TasksHelper = createNamespacedHelpers("tasks");
export default {
  name: "LearningDashboard",
  components: {
    TableForDashboard,
  },
  data() {
    return {
      chartOptions: {
        /*plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },*/
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          gridLineWidth: 0,
          tickWidth: 0,
          //   crosshair: true,
          crosshair: {
            width: 1,
            color: "#9a7e7e",
          },
        },
        /*yAxis: {
          gridLineColor: "white",
        },
        */
        yAxis: {
          crosshair: {
            width: 1,
            color: "#9a7e7e",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          tickWidth: 0,
          title: {
            text: "",
          },
        },
        title: {
          text: "",
          /* align: "left",
            margin: 10,
            x: 30,
            style: {
              color: "#323A44",
              fontsize: 18,
              lineheight: 21,
            },*/
        },

        /*legend: {
          title: {
            text:
              'Credits<br/><span style="font-family: Gilroy; font-size: 10px; color: #666; font-weight: normal">(Click to hide)</span>',
            style: {
              fontStyle: "normal",
            },
          },
          layout: "horizontal",
          //   layout: "vertical",
          align: "right",
          verticalAlign: "bottom",
          //   x: -10,
          //   y: 100,
        },*/

        series: [
          {
            name: "test1",
            data: [
              29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
              194.1, 95.6, 54.4,
            ],
            lineWidth: 2,
            color: "#53a0de",
            // marker: { color: "#53a0de" },
          },
          {
            name: "test2",
            data: [
              216.4, 194.1, 95.6, 54.4, 29.9, 71.5, 106.4, 129.2, 144.0, 176.0,
              135.6, 148.5,
            ],
            lineWidth: 2,
            color: "#A7D42E",
            marker: { fillColor: "#A7D42E", radius: 5 },
            // marker: { fillColor: "#A7D42E" },
          },
        ],
      },
      courses_cols: ["Course", "Status", "Deadline", "Credits"],
      // courses_rows: [],
      new_learning_cols: ["Course", "Status", "Credits"],
      new_learning: [],
      completed_learning_cols: ["Type", "Name", "Completed on", "Credits"],
      completed_learning: [],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  computed: {
    ...TasksHelper.mapGetters(["tasksNoIntro"]),
    courses_rows() {
      /*
       * dispatchUserTasksByCourse now gets called on the Header to make it faster
       * this.getTasksFromAPI();
       */
      let unique = [];
      let _tasks = [];
      for (const _task of this.tasksNoIntro) {
        if (_tasks.length >= 5) break;
        if (!unique.includes(_task.course.id)) {
          unique.push(_task.course.id);
          if (
            _task.request_model_name ==
              "onboarding.request.hremployee.course" &&
            _task.course &&
            _task.course.button
          ) {
            let asked = {
              id: _task.course.id,
              Course: _task.course.title,
              Status: CapitalizeFirstLetter(_task.course.button || "Review"),
              Deadline: _task.date_deadline
                ? EditFutureDate(_task.date_deadline)
                : "No Deadline",
              Credits: _task.course.points,
            };
            _tasks.push(asked);
          }
        }
      }
      return _tasks;
    },
    new_learning_rows() {
      return this.new_learning;
    },
    completed_learning_rows() {
      // let _elearningCompleted = [];
      // this.completed_learning.sort(function (a, b) {
      //   return new Date(a.completion_date) - new Date(b.completion_date);
      // });
      // this.completed_learning.reverse();
      // for (const i of this.completed_learning) {
      //   if (_elearningCompleted.length >= 4) break;
      //   _elearningCompleted.push({
      //     Type: i.activity_type.type,
      //     Name: i.activity_type.name,
      //     "Completed on": i.completion_date ? EditPastDate(i.completion_date) : "-",
      //     Credits: i.credits,
      //     Course: i.activity_type.name,
      //     eLearning: {
      //       ...i.activity_type,
      //       started_on: EditDate(i.activity_type.started_on) || "-",
      //       credits: i.credits,
      //       expiration_date: EditDate(i.expiration_date) || "-",
      //       deadline: i.deadline_date ? EditFutureDate(i.deadline_date) : "No Deadline",
      //       "progress bar": i.activity_type.completion || 0,
      //       completion_date: i.completion_date ? EditPastDate(i.completion_date) : "-",
      //     }
      //   });
      // }
      return this.completed_learning;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...TasksHelper.mapActions(["dispatchUserTasksByCourse"]),
    async getDataFromAPI() {
      this.getLoading(true);
      await this.getNewCourses();
      await this.geteLearnCompleted();
      this.dispatchUserTasksByCourse();
      this.getLoading(false);
    },
    async getNewCourses() {
      try {
        let result = await getCourses();
        if (result.status == 200) {
          let _courses = result.data.data.response;
          let today = new Date();
          let published_30_days = _courses.filter((_c) => {
            const _day = new Date(_c.date_published);
            const days_off = Math.round((_day - today) / (60000 * 60 * 24));
            return days_off <= 0 && days_off > -31;
          });
          published_30_days.sort(function (a, b) {
            return new Date(a.date_published) - new Date(b.date_published);
          });
          published_30_days.reverse();
          let _new_5_elearning = [];
          for (const i of published_30_days) {
            if (_new_5_elearning.length >= 5) break;
            _new_5_elearning.push({
              id: i.id,
              Course: i.title,
              Status: CapitalizeFirstLetter(i.button || "Review"),
              Credits: i.points,
            });
          }
          this.new_learning = _new_5_elearning;
        }
      } catch (error) {
        console.log("getCoursesError==>", error);
      }
    },
    async geteLearnCompleted() {
      try {
        let result = await getLearningEvents();
        if (result.status == 200) {
          let data = result.data.data.response.data;
          let _completedRecently = data.filter(
            (l) => l.completion_date != false
          );
          _completedRecently.sort(function (a, b) {
            return new Date(a.completion_date) - new Date(b.completion_date);
          });
          _completedRecently.reverse();

          for (const i of _completedRecently) {
            if (this.completed_learning.length >= 4) break;
            this.completed_learning.push({
              Type: i.activity_type.type,
              Name: i.activity_type.name,
              "Completed on": i.completion_date
                ? EditPastDate(i.completion_date)
                : "-",
              Credits: i.credits,
              Course: i.activity_type.name,
              eLearning: {
                ...i.activity_type,
                started_on: EditDate(i.activity_type.started_on) || "-",
                credits: i.credits,
                expiration_date: EditDate(i.expiration_date) || "-",
                deadline: i.deadline_date
                  ? EditFutureDate(i.deadline_date)
                  : "No Deadline",
                "progress bar": i.activity_type.completion || 0,
                completion_date: i.completion_date
                  ? EditPastDate(i.completion_date)
                  : "-",
              },
            });
          }
        }
      } catch (error) {
        console.log("geteLearnCompletedError==>", error);
      }
    },
    reloadCoursesData() {
      this.getDataFromAPI();
    },
  },
};
</script>
<style lang="scss">
.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  color: #53a0de;
  margin-top: 4.2rem;
  margin-left: 5rem;
  margin-bottom: 1.875rem;
}
.dashboard-content {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  .block-list {
    // display: flex;
    // flex-flow: row;
    display: grid;
    // grid-auto-columns: 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
    gap: 2.5rem;
    // flex-wrap: wrap;
  }
  .continuous-learning {
    // width: 53.75rem;
    width: 100%;
    // height: 22.75rem;
    height: 100%;
    background: $white;
    border-radius: 1.875rem;
    // margin-top: 2.5rem;
    &__title {
      margin-top: 1.25rem;
      margin-right: 1.875rem;
      margin-left: 1.875rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.3125rem;
      color: #323a44;
    }
    &__charts {
      margin-bottom: 0px;
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      margin-top: 1.875rem;
      .charts-content {
        height: 16.875rem;
      }
      // .highcharts-credits {
      //   display: none !important;
      // }
      .highcharts-root {
        font-family: Gilroy !important;
      }
    }
  }
  .no-courses {
    // width: 53.75rem;
    height: 10rem;
    background: $white;
    border-radius: 1.875rem;
    // margin-right: 2.5rem;
    // margin-top: 2.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .promote-new-courses {
    // width: 53.75rem;
    height: 10rem;
    background: $white;
    border-radius: 1.875rem;
    // margin-top: 2.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .no-completed-courses {
    // width: 53.75rem;
    height: 10rem;
    background: $white;
    border-radius: 1.875rem;
    // margin-right: 2.5rem;
    // margin-top: 2.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1000px) {
  .block-list {
    grid-template-columns: 1fr !important;
  }
  .page-title {
    margin-left: 1rem !important;
  }
  .dashboard-content {
    margin-left: 1rem !important;
  }
}
</style>
