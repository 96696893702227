<template>
  <div class="form-fields-content">
    <div class="header">
      <slot name="header"> </slot>
    </div>

    <v-row class="content pa-0">
      <v-col
        v-for="(field, index) in fields"
        :key="index"
        class="field"
        cols="12"
        :sm="fields.length == 1 ? 12 : 6"
        :md="field.size"
      >
        <v-menu
          v-if="field.type === 'date'"
          v-model="field.datePickerOpen"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          attach
          offset-y
          header-color="#53a0de"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="field.value"
              readonly
              v-bind="attrs"
              v-on="on"
              solo
              rounded
              flat
              class="text-field"
              hide-details="auto"
              :validate-on-blur="true"
              type="text"
              :rules="field.required ? fieldRequired : []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="field.value"
            @input="field.datePickerOpen = false"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          v-if="
            field.type === 'char' ||
            field.type === 'integer' ||
            field.type === 'float'
          "
          :label="field.label"
          v-model="field.value"
          solo
          rounded
          flat
          class="text-field"
          hide-details="auto"
          :validate-on-blur="true"
          type="text"
          :rules="field.required ? fieldRequired : []"
        >
        </v-text-field>
        <v-autocomplete
          v-if="field.type === 'many2one'"
          v-model="field.value"
          :items="field.list ? field.list : []"
          item-text="display_name"
          item-value="id"
          solo
          rounded
          flat
          :label="field.label"
          class="text-field"
          single-line
          :menu-props="{ maxWidth: '300' }"
          @change="emitAction(field.action, $event)"
          hide-details="auto"
          :validate-on-blur="true"
          type="text"
          :rules="field.required ? fieldRequired : []"
        >
        </v-autocomplete>
        <v-autocomplete
          v-if="field.type === 'many2many'"
          v-model="field.value"
          :items="field.list ? field.list : []"
          item-text="display_name"
          item-value="id"
          solo
          rounded
          flat
          chips
          :label="field.label"
          single-line
          multiple
          @input="field.searchInput = null"
          :search-input.sync="field.searchInput"
          class="text-field"
          hide-details="auto"
          :validate-on-blur="true"
          type="text"
          :rules="field.required ? multipleFieldRequired : []"
        >
          <template #selection="{ item }">
            <!-- <v-chip 
              color="#53a0de33"
              text-color="#53a0de"
              class="chip-select"
            > -->
            <v-chip
              color="#53a0de33"
              text-color="#53a0de"
              :close="true"
              close-icon="$closeBlue"
              class="chip-select"
              @click:close="() => deleteChip(field, item.id)"
            >
              {{ item.display_name }}
            </v-chip>
          </template>
        </v-autocomplete>
        <v-textarea
          v-if="field.type === 'long_text'"
          :value="field.value"
          @input="($event) => $emit(`${field.action}`, $event)"
          :label="field.label"
          required
          no-resize
          height="144px"
          solo
          rounded
          flat
          hide-details
          class="text-field"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import moment from "moment";

export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fieldRequired: [(v) => !!v || "You must provide this field"],
      multipleFieldRequired: [
        (v) => v.length > 0 || "You must select at least one option",
      ],
    };
  },
  methods: {
    emitAction(_action, _val) {
      if (_action) {
        this.$emit(_action, _val);
      }
    },
    deleteChip(val, index) {
      const i = val.value.indexOf(index);
      val.value.splice(i, 1);
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-fields-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 30px;
  background-color: $light-blue-1;
  .header {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: capitalize;
  }
  .content {
    .field {
      padding-bottom: 20px;
      &.one-field {
        margin-right: 0;
        min-width: 320px;
        max-width: 320px;
        padding: 0 20px;
      }
    }
  }
}
.chip-select {
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
}
.theme--light.auto-complete {
  ::v-deep {
    .v-input__control {
      padding: 0 24px;
    }
  }
}
.theme--light.text-field {
  ::v-deep {
    .v-input__control {
      padding: 0 12px;
      .v-input__slot {
        .v-label {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .content {
    .field {
      &.one-field {
        min-width: 250px !important;
      }
    }
  }
  .theme--light.auto-complete {
    ::v-deep {
      .v-input__control {
        padding: 0 14px;
      }
    }
  }
  .theme--light.text-field {
    ::v-deep {
      .v-input__control {
        padding: 0 14px;
      }
    }
  }
}
</style>
