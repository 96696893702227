<template>
  <div>
    <v-row class="take-course course-main-container">
      <v-col cols="12" sm="12" md="3" class="course-items">
        <div class="course-intro">
          <v-card
            class="overflow-y-auto course-intro-scroll"
            v-if="takeCourseWithID"
          >
            <!-- <v-card class=" course-intro-scroll"> -->
            <v-card-text>
              <div class="course-intro-container">
                <div class="intro-1">
                  <div class="img-course" :style="takeCourseWithID.bg"></div>
                  <div class="intro-container">
                    <span class="course-title">{{
                      takeCourseWithID.title
                    }}</span>
                    <ul class="meta">
                      <li class="meta__item">
                        <div class="dot bg-red"></div>
                        <span class="min-step-txt">{{
                          takeCourseWithID.minutes
                        }}</span>
                      </li>
                      <li class="meta__item">
                        <div class="dot bg-green"></div>
                        <span class="min-step-txt">{{
                          takeCourseWithID.steps
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tags">
                  <div
                    class="tags__item"
                    v-for="tag in takeCourseWithID.tags"
                    :key="tag.id"
                  >
                    {{ tag }}
                  </div>
                </div>
                <div
                  class="intro-description"
                  v-if="takeCourseWithID.description"
                >
                  <span class="course-description-text"
                    >{{ takeCourseWithID.description }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="course-steps">
          <v-card
            class="overflow-y-auto course-steps-scroll"
            v-if="takeCourseWithID"
          >
            <v-card-text>
              <div v-for="item in takeCourseWithID.content" :key="item.id">
                <span class="step-title-text" v-if="item.is_category">
                  {{ item.name }}</span
                >
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-if="!item.is_category"
                    fill-dot
                    small
                    :color="transparent_bgcolor"
                  >
                    <template v-slot:icon>
                      <v-avatar
                        size="24px"
                        v-if="item.checking"
                        :color="completed_bgcolor"
                      >
                        <img
                          class="img_checking"
                          src="@/assets/img/course_item_checking.svg"
                        />
                      </v-avatar>
                      <v-avatar size="24px" v-else :color="uncompleted_bgcolor">
                        <!-- <img src="https://i.pravatar.cc/64" /> -->
                      </v-avatar>
                    </template>
                    <div class="step-item-container">
                      <div class="step-item">
                        <!-- <div class="img-step-item-1"></div> -->
                        <span
                          class="step-item-text-1"
                          :class="{
                            stepItemText1_active: item.id == selectedSlideID,
                          }"
                          @click="setStepItem(item)"
                        >
                          <!-- {{ Object.values(item)[1] }} -->
                          {{ item.name }}
                        </span>
                      </div>
                    </div>
                    <p
                      v-for="link in hasSlideDetailLink(item.name)"
                      :key="link.id"
                    >
                      <a
                        :href="link.link"
                        class="external-link"
                        target="_blank"
                      >
                        <v-icon size="10" class="link-icon">$linkBlue</v-icon>
                        <span>{{ link.name }}</span>
                      </a>
                    </p>
                  </v-timeline-item>
                </v-timeline>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="9" class="course-content">
        <div class="course-content-header">
          <span class="course-step-title">
            {{ this.slideItem_title }}
          </span>
          <div class="btn-course-exit" @click="setCourseExit">
            <span class="text-exit">Exit</span>
            <div class="btn-exit-arrow"></div>
          </div>
        </div>
        <div class="course-step-content">
          <v-card
            class="overflow-y-auto course-step-content-scroll"
            v-if="takeCourseWithID"
          >
            <v-card-text>
              <div class="html-content" v-if="setSlide_HTMLDetail">
                <div
                  class="html-content-text"
                  v-html="setSlide_HTMLDetail"
                ></div>
              </div>
              <div class="document-content" v-else-if="setSlide_Document">
                <iframe
                  type="application/pdf"
                  name="pdf-frame"
                  :src="openInIFrame ? setSlide_Document : ableToDownload"
                  frameborder="0"
                  class="pdf-frame"
                  :style="{ height: openInIFrame ? '90%' : '0' }"
                >
                </iframe>
                <button
                  v-if="!openInIFrame"
                  @click="onDownload"
                  class="download-btn"
                >
                  Download Content
                </button>
              </div>
              <div v-else-if="setSlide_VideoID" class="youtube-player">
                <youtube
                  :video-id="setSlide_VideoID"
                  ref="youtube"
                  @playing="youtube_playing"
                  @ready="youtube_ready"
                  @ended="youtube_ended"
                ></youtube>
              </div>
              <div v-else-if="slideQuizList" class="slideQuizList">
                <!-- <p>{{ selectedSlideQuizList }}</p> -->
                <div
                  v-for="i in slideQuizList.length"
                  :key="i.index"
                  class="slideQuizList_quiz"
                >
                  <span class="quiz_question_text"
                    >{{ i }}. {{ slideQuizList[i - 1].question }}</span
                  >
                  <span
                    v-if="slideQuizList[i - 1].allow_multiple_answers"
                    class="quiz_option_text"
                    >{{
                      slideQuizList[i - 1].allow_multiple_answers
                        ? "Multiple answers might be correct"
                        : ""
                    }}</span
                  >
                  <div
                    class="quiz_answer"
                    v-for="answer in slideQuizList[i - 1].answers"
                    :key="answer.id"
                    :style="{ background: answer.quizBGColor }"
                  >
                    <v-checkbox
                      v-model="selectedSlideQuizList[i - 1]"
                      :label="answer.text_value"
                      :value="answer.id"
                      :color="checkboxColor"
                      class="quiz_checkbox"
                      :multiple="slideQuizList[i - 1].allow_multiple_answers"
                      @change="changedQuizAnswers"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getCourseDetailWithIDUnpublished,
  submittedQuizAnswers,
  getSlideDetail,
} from "@/api";
import { createNamespacedHelpers } from "vuex";
import _ from "lodash";
import b64toBlob from "@/utils/b64_to_blob.js";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  name: "TakeCoursePreview",
  data() {
    return {
      transparent_bgcolor: "#00000000",
      progress_bar_color: "#A7D42E",
      uncompleted_bgcolor: "#c2c2c5",
      completed_bgcolor: "#a7d42e80",
      checkboxColor: "#7F868E",
      slideItem_title: "",
      takeCourseWithID: "",
      setSlide_HTMLDetail: "",
      setSlide_Document: "",
      setSlide_VideoID: "",
      slideQuizList: "",
      selectedSlideQuizList: [],
      openInIFrame: false,
      ableToDownload: false,
      quizAlert: false,
      quizFailed: false,
      completedScore: 50,
      selectedSlideID: "",
      parameter_course_id: 0,
    };
  },
  created() {
    this.setCheckedCourseItem();
  },
  computed: {
    ...coursePresentationHelper.mapGetters(["courseDetail"]),
    arraySlideDetail() {
      return this.takeCourseWithID.slide_detail.flatMap((_sd) => _sd.name);
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    youtube_playing() {
      // console.log(" we are watching!!!");
    },
    youtube_ready(event) {
      this.player = event.target;
      // console.log("videoready===");
    },
    youtube_ended(event) {
      // console.log("videoend===");
    },
    onDownload() {
      this.ableToDownload = this.setSlide_Document;
      setTimeout(() => {
        this.ableToDownload = "";
      }, 500);
    },
    hasSlideDetailLink(_name) {
      const hasLink = this.arraySlideDetail.indexOf(_name);
      return this.takeCourseWithID.slide_detail[hasLink].links;
    },
    setCourseExit() {
      let url = `/course-preview?${this.parameter_course_id}`;
      this.$router.push(url);
    },
    setStepItem(_item) {
      this.slideItem_title = _item.name;
      this.takeCourseWithID.content.forEach((content) => {
        if (content.id == _item.id) {
          content["checking"] = true;
        } else {
          if (content["checking"]) {
            content["checking"] = false;
          }
        }
      });
      this.getSlideDetailWithID(_item.id);
    },
    async quizAnswersSubmit() {
      if (this.selectedSlideQuizList.length == this.slideQuizList.length) {
        this.quizAlert = false;
        for (
          let index = 0;
          index < this.selectedSlideQuizList.length;
          index++
        ) {
          if (
            !this.selectedSlideQuizList[index] ||
            (typeof this.selectedSlideQuizList[index] !== "string" &&
              this.selectedSlideQuizList[index].length == 0)
          ) {
            this.quizAlert = true;
            break;
          }
        }
        if (!this.quizAlert) {
          let st_selectedSlideQuizList = _.flatten(
            this.selectedSlideQuizList
          ).join(",");

          try {
            this.getLoading(true);
            let result = await submittedQuizAnswers(
              this.selectedSlideID,
              st_selectedSlideQuizList
            );
            if (result.status == 200) {
              let submittedQuizAnswersResult = result.data.data.response;
              this.getQuizAnswerResult(submittedQuizAnswersResult);
            }
          } catch (error) {
            console.log("submittedQuizAnswersError==>", error);
            this.getLoading(false);
          }
        }
      } else {
        this.quizAlert = true;
      }
    },
    getQuizAnswerResult(_result) {
      if (!_result.slide_completed) {
        this.quizFailed = true;
        this.slideQuizList.forEach((quiz) => {
          quiz.answers.forEach((answer) => {
            _result.badAnswers.forEach((bad) => {
              if (bad == answer.id) {
                answer.quizBGColor = "#FFD3D3";
              }
            });
            _result.goodAnswers.forEach((good) => {
              if (good == answer.id) {
                answer.quizBGColor = "#d3e996";
              }
            });
          });
        });
        this.getLoading(false);
      } else {
        this.setCheckedCourseItem();
      }
    },
    changedQuizAnswers() {
      this.quizFailed = false;
      this.quizAlert = false;
    },
    async getSlideDetailWithID(_slideID) {
      this.slideQuizList = "";
      this.setSlide_VideoID = "";
      this.setSlide_HTMLDetail = "";
      this.setSlide_Document = "";
      this.selectedSlideQuizList = [];

      for (const slide of this.takeCourseWithID.slide_detail) {
        if (_slideID === slide.slide_id) {
          this.selectedSlideID = _slideID;
          let _url_docx;
          switch (slide.slide_type) {
            case "webpage":
              this.setSlide_HTMLDetail = slide.content.html_content
                ? slide.content.html_content
                : "";
              break;
            case "video":
              this.setSlide_VideoID = slide.content.document_id
                ? slide.content.document_id
                : "";
              break;
            case "document":
              try {
                const result = await getSlideDetail(this.selectedSlideID);
                const datas = result.data.data.response.content.datas;
                const { openInIFrame, blobURL } = b64toBlob(datas);
                this.setSlide_Document = blobURL;
                this.openInIFrame = openInIFrame;
              } catch (error) {
                console.log("DocumentError==>", error);
              }
              break;
            case "quiz":
              this.slideQuizList = slide.quiz.slide_questions
                ? slide.quiz.slide_questions
                : "";
              if (this.slideQuizList) {
                this.slideQuizList.forEach((quiz) => {
                  quiz.answers.forEach((answer) => {
                    answer["quizBGColor"] = "transparent";
                  });
                  // quiz["quizBGColor"] = "transparent";
                });
              }
              break;
          }
        }
      }
    },
    async setCheckedCourseItem() {
      this.parameter_course_id =
        parseInt(Object.keys(this.$route.query)[0]) || null;
      if (this.parameter_course_id) {
        try {
          this.getLoading(true);
          let result = await getCourseDetailWithIDUnpublished(
            this.parameter_course_id
          );
          if (result.status == 200) {
            this.takeCourseWithID = result.data.data.response[0];
            this.slideItem_title = this.takeCourseWithID.slide_detail[0].name;
            this.getSlideDetailWithID(
              this.takeCourseWithID.slide_detail[0].slide_id
            );
            this.checkingCourseSlide();
          }
        } catch (error) {
          console.log("takeCourseError==>", error);
        } finally {
          this.getLoading(false);
        }
      }
    },
    checkingCourseSlide() {
      this.takeCourseWithID.content.forEach((content) => {
        this.takeCourseWithID.slide_detail.forEach((slide) => {
          if (content.id == slide.slide_id) {
            if (this.selectedSlideID == content.id) {
              content["checking"] = true;
              this.slideItem_title = content["name"];
            }
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//style in src/assets/styles/components/take-course.scss
.course-main-container {
  padding-top: 40px !important;
  padding-left: 80px;
  padding-right: 80px;
  height: calc(100vh - 140px);
}
@media screen and (max-width: 1400px) {
  .course-main-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
</style>
