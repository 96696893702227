<template>
  <div>
    <ComingSoon />
  </div>
</template>

<script>
import ComingSoon from "@/common/ComingSoon.vue";
export default {
  name: "Status",
  components: {
    ComingSoon,
  },
};
</script>
<style lang="scss" scoped></style>
