<template>
  <div class="filters" style="margin-top: 10px; margin-bottom: 5px">
    <nav class="filters__nav">
      <ul class="options">
        <li
          class="item"
          @click.stop="toggleMenu('filters')"
          v-click-outside="hideMenus"
        >
          <span class="text">Filters</span>
          <img class="img-fluid" src="@/assets/img/nav-arrow.svg" />
          <ul
            class="list-elements"
            v-bind:class="{ activeMenu: stateMenus.filters }"
          >
            <li
              v-for="el in filtersTags"
              :key="el.name"
              class="elementList"
              @mouseover="
                () => {
                  hoverSubMenu = el.name;
                }
              "
              @mouseleave="hoverSubMenu = ''"
            >
              <div class="label-list">
                <p class="box" @click="selectTag(el)">
                  {{ el.name }}
                </p>
                <v-icon size="10" v-if="el.child_ids.length > 0"
                  >$arrowRight</v-icon
                >
              </div>
              <div
                v-if="el.child_ids.length > 0 && hoverSubMenu == el.name"
                class="childsSubMenu"
                :class="{ activeSubMenu: hoverSubMenu == el.name }"
              >
                <ul class="subelements">
                  <li class="subbox" @click="selectTag(el.name)">
                    {{ el.name }}
                  </li>
                  <li
                    v-for="ch in el.child_ids"
                    :key="ch.name"
                    class="subbox"
                    @click="selectTag(ch.name)"
                  >
                    {{ ch.name }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- When Mobile, Menu -->
    <nav class="filters__mobile_nav">
      <ul class="options" v-if="filtersTags.length != 0">
        <li class="item">
          <img
            @click.stop="toggleMenu('responsiveMenu')"
            class="mobile_filter_img"
            src="@/assets/img/mobile_filter_img.svg"
          />
          <div
            class="elements"
            v-bind:class="{ activeResposiveMenu: stateMenus.responsiveMenu }"
          >
            <ul
              class="filterTag"
              v-bind:class="{ activeMenu: stateMenus.responsiveMenu }"
            >
              <li class="filterTagName">
                Filters
                <div class="filterTagName_underline"></div>
              </li>
              <li
                class="box"
                v-for="el in filtersTags"
                :key="el.name"
                @click="selectTag(el.name)"
              >
                <p>{{ el.name }}</p>
                <v-icon
                  class="arrow-sub"
                  v-if="el.child_ids.length > 0"
                  @click.stop="toggleSubMenuMobile(el.name)"
                  :class="{ activeSubMenuMobile: hoverSubMenu == el.name }"
                >
                  $arrowDown
                </v-icon>
                <ul
                  v-if="el.child_ids.length > 0 && hoverSubMenu == el.name"
                  class="subelements"
                >
                  <li class="subbox" @click.stop="selectTag(el.name)">
                    {{ el.name }}
                  </li>
                  <li
                    v-for="ch in el.child_ids"
                    :key="ch.name"
                    class="subbox"
                    @click.stop="selectTag(ch.name)"
                  >
                    {{ ch.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { createNamespacedHelpers } from "vuex";
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
export default {
  name: "FilterDepartment",
  props: {
    // filtersTags: {
    //   type: Object,
    //   default: () => {},
    //   required: true,
    // }
    filtersTags: {
      type: Array,
      default: () => new Array(),
      required: true,
    },
  },
  data() {
    return {
      stateMenus: {
        filters: false,
        responsiveMenu: false,
      },
      hoverSubMenu: "",
    };
  },
  methods: {
    // ...setCoursePageInfoHelpers.mapMutations(["setMobileMenuActive"]),
    selectTag(_tags) {
      let tagNamesArray = [];
      if (typeof _tags == "object") {
        tagNamesArray.push(_tags.name);
        _tags.child_ids.forEach((t) => {
          tagNamesArray.push(t.name);
        });
      } else {
        tagNamesArray.push(_tags);
      }
      this.$emit("selectedTag", tagNamesArray);
      this.stateMenus.responsiveMenu = false;
      // this.setMobileMenuActive(false);
    },
    toggleMenu(key) {
      for (const name of Object.keys(this.stateMenus)) {
        if (name === key) {
          this.stateMenus[name] = !this.stateMenus[name];
          if (key == "responsiveMenu") {
            // this.setMobileMenuActive(this.stateMenus[name]);
          }
        } else {
          this.stateMenus[name] = false;
        }
      }
    },
    // Hide all menus when clicking outside of the menus.
    hideMenus() {
      this.stateMenus.filters = false;
      // this.stateMenus.responsiveMenu = false;
    },
    toggleSubMenuMobile(e) {
      if (this.hoverSubMenu == e) this.hoverSubMenu = "";
      else this.hoverSubMenu = e;
    },
  },
  // This is the directive to listen and handle outside click on filters menu.
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.activeResposiveMenu {
  transform: scaleX(1) !important;
  display: block !important;
}
.activeMenu {
  transform: scaleX(1) !important;
}
.activeSubMenu {
  transform: scaleX(1) !important;
}
.activeSubMenuMobile {
  transform: rotate(180deg) !important;
  // transform: rotate(-180deg) !important;
}
.filters {
  align-items: center;
  background-color: $blue;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  &__mobile_nav {
    display: none;
    .options {
      display: flex;
      margin-left: 3.75rem;
      .item {
        margin-right: 3.7625rem;
        position: relative;
        // .text {
        //   color: $white;
        //   font-style: normal;
        //   font-weight: 600;
        //   font-size: 1rem;
        //   line-height: 19px;
        //   margin-right: 0.625rem;
        // }
        .mobile_filter_img {
          width: 100%;
          cursor: pointer;
        }
        .elements {
          position: absolute;
          display: none;
          padding-bottom: 30px;
          .filterTag {
            background-color: $white;
            box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
            border-radius: 16px;
            left: -1.25rem;
            margin-top: 0.6875rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            overflow: hidden;
            // position: absolute;
            transform: scaleX(0);
            transition: all 0.3s ease;
            top: 100%;
            width: 20.4375rem;
            padding-left: 0rem !important;
            .filterTagName {
              font-style: normal;
              font-weight: bold;
              font-size: 1.125rem;
              line-height: 1.375rem;
              text-transform: capitalize;
              color: #53a0de;
              padding: 0.5rem 1rem;
              .filterTagName_underline {
                height: 0.0625rem;
                padding-left: 1rem;
                padding-right: 1rem;
                background-color: #e0e0e0;
                margin-top: 0.75rem;
              }
            }
            .box {
              cursor: pointer;
              color: $black;
              font-style: normal;
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 14px;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              padding-left: 1rem;
              .arrow-sub {
                position: absolute;
                right: 10px;
                width: 15px;
                height: 15px;
                margin-top: -15px;
                transition: all 0.3s ease;
              }
              .subelements {
                position: relative;
                display: flex;
                flex-direction: column;
                margin-top: 5px;
                .subbox {
                  cursor: pointer;
                  color: $black;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.875rem;
                  line-height: 14px;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  padding-left: 2rem;
                }
                .subbox:hover {
                  background-color: $light-blue-1 !important;
                  color: #a7d42e;
                }
              }
            }
            .box:hover {
              background-color: $light-blue-1 !important;
              color: #a7d42e;
            }
          }
        }
      }
      .item {
        .mobile_filter_img:hover {
          color: $yellow;
        }
      }
    }
  }
  &__nav {
    .options {
      display: flex;
      margin-left: 3.75rem;
      .item {
        cursor: pointer;
        margin-right: 3.7625rem;
        .text {
          color: $white;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 19px;
          margin-right: 0.625rem;
        }
        .img-fluid {
          width: 0.55rem;
        }
        .list-elements {
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
          border-radius: 16px;
          left: -1.25rem;
          margin-top: 0.6875rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          position: absolute;
          transform: scaleX(0);
          transition: all 0.3s ease;
          top: 100%;
          padding-left: 0rem !important;
          background-color: $white !important;
          .elementList {
            position: relative;
            display: flex;
            flex-direction: row;
            .label-list {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 9rem;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              padding-left: 1rem;
              padding-right: 0.5rem;
              .box {
                color: $black;
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 14px;
              }
            }
            .label-list:hover {
              background-color: $light-blue-1 !important;
            }
            .childsSubMenu {
              position: absolute;
              top: 0;
              left: 101%;
              width: 9rem;
              background-color: $white;
              box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
              border-radius: 16px;
              transform: scaleX(0);
              transition: all 0.3s ease;
              .subelements {
                left: 100%;
                .subbox {
                  cursor: pointer;
                  color: $black;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.875rem;
                  line-height: 14px;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  padding-left: 2rem;
                }
                .subbox:hover {
                  background-color: $light-blue-1 !important;
                  color: #a7d42e;
                }
              }
            }
          }
        }
        .tag-list-elements {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          background-color: $white;
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
          border-radius: 16px;
          left: -1.25rem;
          margin-top: 0.6875rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          position: absolute;
          transform: scaleX(0);
          transition: all 0.3s ease;
          top: 100%;
          width: 40.125rem;
          .box {
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 14px;
            padding-top: 0.5rem;
            padding-left: 1rem;
            padding-bottom: 0.5rem;
          }
          .box:hover {
            background-color: $light-blue-1 !important;
          }
        }
      }
      .item:hover {
        .text {
          color: $yellow;
        }
      }
    }
  }
}
@media screen and (max-width: 1096px) {
  .filters__nav .options .item {
    margin-right: 1.5rem;
  }
  .filters__nav .options {
    margin-left: 1.8rem;
  }
}
@media screen and (max-width: 900px) {
  .filters__nav {
    display: none;
  }
  .filters__mobile_nav {
    display: block !important;
  }
}
@media screen and (max-width: 500px) {
  .filters .filters__mobile_nav .options .item .list-elements {
    left: 0%;
    margin-left: -1.375rem;
  }
  .filters .filters__mobile_nav .options {
    margin-left: 1rem;
  }
  .filters .filters__mobile_nav .options .item {
    margin-right: 1rem;
  }
}
</style>
