<template>
  <div>
    <v-row no-gutters v-if="!notRequestFound">
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">
              {{ coachingRequestTitle }}
            </h1>
          </div>
          <div class="main_section__content">
            <div class="eventsTable">
              <TableRequest
                :rows="request_fields"
                :column_names="cols_names1"
                @requestResponse="coachingRequestResponse"
              />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">
              {{ rows_fields.length > 0 ? coachingTitle : "Coaching" }}
            </h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <div class="eventsTable">
                <TableTracked
                  :rows="rows_fields"
                  :column_names="cols_names"
                  @deleteRow="deleteTracking"
                  @removeBadge="removeBadge($event)"
                />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
                <!-- <div class="not-found__text"></div> -->
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getResponsibilitiesTrackedByMe,
  getTrackers,
  requestAccept,
  requestDecline,
  removeBadge,
} from "@/api";
import { createNamespacedHelpers } from "vuex";
import TableTracked from "@/components/coaching/TableTracked.vue";
import TableRequest from "@/components/coaching/TableRequest.vue";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);
const alertHelpers = createNamespacedHelpers("alert");

export default {
  name: "Coaching",
  components: {
    TableTracked,
    TableRequest,
  },
  data() {
    return {
      filters: {
        status: [],
      },
      rows_fields: [],
      cols_names: [
        [
          "Coach Employee",
          "For Responsibility",
          "Current Tactical Readiness",
          "Coaching Style",
          "Proficiency",
          "Actions",
        ],
      ],
      cols_names1: [
        [
          "Request to coach",
          "For responsibility",
          "Requested by/on",
          "Actions",
        ],
      ],
      request_fields: [],
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.rows_fields.length == 0;
    },
    notRequestFound() {
      return this.request_fields.length == 0;
    },
    coachingTitle() {
      if (this.rows_fields.length > 1)
        return `You have ${this.rows_fields.length} coaching/evaluation assignments`;
      else return `You have ${this.rows_fields.length} coaching/evaluation assignment`;
    },
    coachingRequestTitle() {
      if (this.request_fields.length > 1)
        return `You have ${this.request_fields.length} coaching/evaluation requests`;
      else
        return `You have ${this.request_fields.length} coaching/evaluation request`;
    },
  },
  created() {
    this.getDataTable();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    async getDataTable() {
      this.getLoading(true);
      await this.getDataFromAPI();
      // await this.getRequestDataFromAPI();
      this.getLoading(false);
    },
    async getDataFromAPI() {
      try {
        // let result = await getResponsibilitiesTrackedByMe();
        let result = await getTrackers();
        if (result.status == 200) {
          this.request_fields = result.data.data.response.filter(
            (request) => request.state === "pending"
          );
          let tracked_by_me = result.data.data.response.filter(
            (request) => request.state === "accepted"
          );
          this.rows_fields = await this.filterData(tracked_by_me);
          this.request_fields = result.data.data.response.filter(
            (request) => request.state === "pending"
          );
          this.request_fields.forEach((element) => {
            element.job_title = this.employeeNameJobList.find((item) => {
              return item.id === element.employee_id;
            }).job_title;
            element.employee_image_128 = this.getCoachImage(
              element.employee_id
            );
          });
        }
      } catch (error) {
        console.error("GetResponsibilityTrackedByMeError==>", error);
      }
    },
    async filterData(_tracked_by_me) {
      let data = [];

      _tracked_by_me.forEach((resp) => {
        if (!resp.terminated_on) {
          let row = {
            responsibility_id: resp.responsibility_id,
            employee_responsibility_id: resp.employee_responsibility_id,
            commitment: resp.commitment,
            "Coach Employee": resp.employee_name || "-",
            coach_image: this.getCoachImage(resp.employee_id),
            employee_id: resp.employee_id,
            "For Responsibility": resp.responsibility_name || "-",
            "Current Tactical Readiness":
              resp.certification_progress_desc || "-",
            responsibility_certification_progress:
              resp.responsibility_certification_progress,
            responsibility_proficiency_progress:
              resp.responsibility_proficiency_progress,
            "Coaching Style": resp.coaching_style_name || "-",
            coaching_id: resp.coaching_style_id,
            badge: CapitalizeFirstLetter(resp.proficiency),
            badge_id: resp.badge_id,
            target: CapitalizeFirstLetter(resp.target),
            target_id: resp.target,
            tracker_id: resp.tactical_responsibility_tracker_id,
            certification_requirements_count:
              resp.certification_requirements_count,
            expiration_date: resp.expiration_date,
            progressbar_stacked_values: JSON.parse(
              resp.progressbar_stacked_values
            ),
            job_title: this.employeeNameJobList.find((item) => {
              return item.id === resp.employee_id;
            }).job_title,
            role_assignment: resp.role_ids,
            responsibility_intensity: `https://erp.nanoramic.com/addon_tactical_readiness/static/src/img/level_${
              resp.intensity == 3
                ? "high"
                : resp.intensity == 2
                ? "medium"
                : "low"
            }.png`,
          };
          data.push(row);
        }
      });
      return data;
    },
    deleteTracking(_id_responsibility_tracker) {
      let indexDelete = this.rows_fields.findIndex(
        (r) => r["tracker_id"] == _id_responsibility_tracker
      );
      this.rows_fields.splice(indexDelete, 1);
    },
    getCoachImage(employee_id) {
      return this.employeeNameJobList.find((e) => {
        return e.id === employee_id;
      }).image_128;
    },
    async removeBadge(event) {
      this.getLoading(true);
      try {
        const result = await removeBadge(
          event.employee_responsibility_id,
          event.removeReason
        );
        let index = this.rows_fields.findIndex(
          (r) =>
            r["employee_responsibility_id"] == event.employee_responsibility_id
        );
        this.rows_fields[index].badge = "";
        this.rows_fields[index].badge_id = false;
        this.getLoading(false);
      } catch (error) {
        this.getLoading(false);
        console.log("error: ", error);
      }
    },

    // async getRequestDataFromAPI() {
    //   try {
    //     let result = await getTrackers();
    //     if (result.status == 200) {
    //       // this.request_fields = result.data.data.response.filter(
    //       //   (request) => request.state == "pending"
    //       // );
    //     }
    //   } catch (error) {
    //     console.error("getTrackersError==>", error);
    //   }
    // },

    async coachingRequestResponse(event) {
      try {
        if (event.action === "accept_coaching") {
          let result = await requestAccept(
            event.rowData.tactical_responsibility_tracker_id
          );
          if (result.data.data.response == true) {
            this.dispatchOpenAlert({
              type: "success",
              message: `You just accepted to coach ${event.rowData.employee_name} for responsibility ${event.rowData.responsibility_name}.`,
            });
            this.getDataTable();
          }
        } else if (event.action === "decline_coaching") {
          let result = await requestDecline(
            event.rowData.tactical_responsibility_tracker_id,
            event.rowData.declineReason
          );
          if (result.data.data.response == null) {
            this.getDataTable();
          }
        }
      } catch (error) {
        console.error("coachingRequestResponseError==>", error);
      }
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
