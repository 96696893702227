<template>
  <paginate
    v-model="selected_page"
    :pageCount="pagecount"
    :click-handler="pageNumber"
    :containerClass="'paginate'"
    :prev-class="'paginate-prev'"
    :next-class="'paginate-next'"
    :page-class="'page-item'"
    :page-link-class="'page-link'"
    :next-link-class="'next-link'"
    :prev-link-class="'prev-link'"
  >
    <template v-slot:prev>
      <div class="navigation-wrapper">
        <v-icon size="9">$arrowLeft</v-icon>
        <span class="text">Prev</span>
      </div>
    </template>
    <template v-slot:next>
      <div class="navigation-wrapper">
        <span class="text">Next</span>
        <v-icon size="9">$vuetify.icons.arrowRight</v-icon>
      </div>
    </template>
  </paginate>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  props: {
    pagecount: {
      type: Number,
      default: 0,
    },
    selectedPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      current_page: 1,
    };
  },
  created() {},
  components: {
    Paginate,
  },
  computed: {
    selected_page: {
      get: function () {
        return this.selectedPage;
      },
      set: function () {
        this.selectedPage;
      },
    },
  },
  methods: {
    pageNumber(pageNum) {
      this.$emit("updateFAQ", pageNum);
    },
  },
};
</script>

<style lang="scss">
ul.paginate {
  display: flex;
  list-style: none;
  padding-left: 0;
}
a {
  color: $green !important;
}

.page-item {
  margin: 0 10px;
  &.active {
    text-decoration-line: none;

    .page-link {
      border-bottom: 1px solid $green;
    }
  }
}

.paginate-prev,
.paginate-next {
  font-weight: 600;
}

.paginate-prev {
  margin-right: auto;
}

.paginate-next {
  margin-left: auto;
}

.page-link,
.next-link,
.prev-link {
  outline: none;
}
.disabledLink {
  pointer-events: none !important;
}
.navigation-wrapper {
  display: flex;
  align-items: center;
  .text {
    padding: 0 6px;
    color: $green;
  }
}
</style>
