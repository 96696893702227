<template>
  <v-chip
    class="chip"
    :color="chipData?.color"
    :outlined="chipData?.isOutlined"
    :text-color="chipData?.textColor"
    large
    @click="onClickChip()"
    :id="chipData?.id"
    :close="chipData?.close"
    @click:close="onClose"
  >
    <v-avatar
      v-if="!!chipData?.image"
      v-bind:style="
        chipData?.image.includes('expiration')
          ? 'border-radius:0%'
          : 'border-radius:50%'
      "
    >
      <img
        :id="'chipImg' + chipData.id"
        :src="chipData.image"
        @mouseenter="showParentTooltip()"
        @mouseleave="showParentTooltip()"
      />

      <DxTooltip
        v-if="!!chipData?.toolTip && chipData?.hasImageToolTip"
        :hide-on-outside-click="false"
        show-event="mouseenter"
        hide-event="mouseleave"
        :target="'#chipImg' + chipData.id"
      >
        {{ chipData?.toolTip }}
      </DxTooltip>
    </v-avatar>
    <div>
      <div
        class="chip_text"
        v-bind:style="
          chipData?.textIsBold ? 'font-weight:600' : 'font-weight:400'
        "
      >
        {{ chipData?.text }}
      </div>
      <div class="progress_bar" v-if="chipData?.hasProgressbar">
        <ChildBulletChartWidget :barData="chipData?.progress_bar" />
      </div>
    </div>
  </v-chip>
</template>
<script>
import { DxTooltip } from "devextreme-vue/tooltip";
import ChildBulletChartWidget from "../components/readiness/childBulletChartWidget.vue";

export default {
  name: "chipComponent",
  props: ["chipData"],
  components: { DxTooltip, ChildBulletChartWidget },
  data() {
    return {};
  },
  created() {
    // console.log("chipData: ", this.chipData);
  },
  methods: {
    onClickChip() {
      this.$emit("clickOnChip");
    },
    onClose() {
      this.$emit("onClickClose");
    },
    showParentTooltip() {
      this.$emit("showBadgeTooltip");
    },
  },
};
</script>
<style scoped lang="scss">
.chip {
  width: max-content;
  /* padding: 0 20px 0 5px; */
  display: flex;
  align-items: center;
}

.chip_image {
  margin-top: 1px;
}

.chip_text {
  margin-left: 8px;
  font-size: 14px;
  white-space: break-spaces;
  line-height: 16px;
  text-align: center;
}

.chip[data-v-b4166e7c] {
  width: -moz-max-content;
  /* width: max-content; */
  padding: 0 20px 0 5px;
}

.v-chip .v-avatar {
  height: 28px !important;
  min-width: 28px !important;
  width: 28px !important;
}
</style>
<style>
.v-chip.v-chip--outlined .v-icon {
  color: black;
}

.v-chip--clickable {
  cursor: default;
}

.v-chip.v-size--large {
  height: 36px !important;
  font-size: 18px !important;
}

.progress_bar {
  margin-left: 12px !important;
  width: 100%;
  padding-top: 15px !important;
  min-width: 200px;
}
</style>
