<template>
  <div class="table1-container">
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows.missing_certifications"
      :columns="column_names"
      key-expr="certification_id"
      :showColumnHeaders="true"
      :show-borders="true"
      :showRowLines="true"
      :showColumnLines="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :column-hiding-enabled="true"
    >
      <DxColumn
        caption="Missing Certifications for this responsibility"
        data-field="certification_name"
      />
      <DxColumn
        caption="Certification Progress"
        data-field="certificates"
        cell-template="progressTemplate"
        :hiding-priority="1"
      />
      <DxColumn
        data-field="certificate"
        cell-template="certificateTemplate"
        :hiding-priority="0"
        alignment="center"
      />
      <template #progressTemplate="{ data: rowData }">
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                class="progress-bar-container"
                :value="
                  priorKnowledge(rowData.data)
                    ? 100
                    : rowData.data.progress_data
                    ? rowData.data.progress_data.certification_progress
                    : 0
                "
                :color="priorKnowledge(rowData.data) ? '#FFD6F5' : '#FCFCCA'"
                height="16"
                v-bind="attrs"
                v-on="on"
              ></v-progress-linear>
            </template>
            <div
              v-if="rowData.data.progress_data"
              v-html="rowData.data.progress_data.certification_progress_desc"
            ></div>
          </v-tooltip>
        </div>
      </template>
      <template #certificateTemplate="{ data: rowData }">
        <span v-if="priorKnowledge(rowData.data)" class="txt-pending"
          >Certified (Prior Knowledge)</span
        >
        <span
          v-else-if="certifiedNotPriorKnowledge(rowData.data.certificates)"
          class="txt-pending"
          >Certified</span
        >
        <span
          v-else-if="
            rowData.data.progress_data &&
            rowData.data.progress_data.submitted_type == 'prior_knowledge'
          "
          class="txt-pending"
          >Pending Certificate</span
        >
        <v-btn
          v-else
          :disabled="act_as_me == false"
          text
          icon
          class="btn-add-prior"
          @click="onSave(rowData.data)"
          color="#D92DAB"
        >
          + Ask for Prior Knowledge Credit
        </v-btn>
      </template>
    </DxDataGrid>
    <OkModal :open="modalOkOpen" message="Coming Soon" @ok="closeModalOk" />
  </div>
</template>
<script>
import DxDataGrid, { DxColumn } from "devextreme-vue/data-grid";
import { createPriorKnowledgeCertificate } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");
import OkModal from "@/common/OkModal";

export default {
  name: "TableRespMissingCert",
  components: {
    DxDataGrid,
    DxColumn,
    OkModal,
  },
  props: ["rows"],
  data() {
    return {
      act_as_me: true,
      modalOkOpen: false,
      column_names: [
        "missing certifications for this responsibility",
        "certification progress",
        "certificate",
      ],
      priorKnowledge(rowData) {
        const _certificated = rowData.certificates.filter(
          (cert) => cert.state == "active" && cert.prior_knowledge
        );
        if (_certificated.length > 0) {
          return true;
        }
        return false;
      },
      certifiedNotPriorKnowledge(rowData) {
        const _certificated = rowData.filter((cert) => cert.state == "active");
        if (_certificated.length > 0) {
          return true;
        }
        return false;
      },
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    async onSave(rowData) {
      this.getLoading(true);
      try {
        let result = await createPriorKnowledgeCertificate(
          null,
          rowData.certification_id
        );
        if (result.status == 200) {
          rowData.progress_data.submitted_type = "prior_knowledge";
          // this.$emit("updateDataCertifications", this.rows);
          this.modalOkOpen = true;
        }
      } catch (error) {
        console.log(error);
        this.dispatchOpenAlert({ type: "error", message: error });
      }
      this.getLoading(false);
    },
    closeModalOk() {
      this.modalOkOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.table1-container {
  // padding: 20px;
}
.tags-sm {
  padding: 6px 14px 4px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px !important;
  line-height: 12.38px;
}
.txt_red {
  color: #ff6c6c !important;
}
.progress-bar-container {
  margin-top: 14px;
  border-radius: 8px;
  .progress-text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: #323a44;
  }
}
.btn-add-prior {
  font-family: Gilroy;
  text-transform: capitalize;
  font-size: 0.75rem;
  font-weight: bold;
}
.txt-pending {
  font-family: Gilroy;
  font-size: 0.75rem;
  font-weight: bold;
  color: $blue;
  text-decoration: underline;
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
