import { getTecticalReadiness } from "@/api";
export default {
    namespaced: true,
    state: {
        readinessData: [],
    },
    mutations: {
        setReadinessData(state, status) {
            state.readinessData = [...status];
        },
    },
    _actions: {
        async dispatchReadinessData({ commit }, employee_id) {
            try {
                const {
                    data: {
                        data: { response },
                    },
                } = await getTecticalReadiness(employee_id);
                commit("setReadinessData", response.assignments);
            } catch (error) {
                console.log("dispatchReadinessDataError==>", error);
                commit("setReadinessData", []);
            }
        },
    },
    get actions() {
        return this._actions;
    },
    set actions(value) {
        this._actions = value;
    },
    getters: {
        getReadinessData(state) {
            return state.readinessData;
        },
    },
};