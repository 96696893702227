import axios from "axios";
import { getToken } from "./getToken";
export const uploadedHeadshot = (_id, _img) => {
  let formData = new FormData();
  let crop_img = _img.split(",")[1];
  formData.append("data", crop_img);

  let result = axios({
    method: "patch",
    url: `${process.env.https://pre-release.prd.nanoramic.com}/api/onboarding.task/${_id}`,
    headers: {
      "access-token": getToken(),
      "Content-Type": "multipart/form-data",
    },
    params: {
      action: "save_onboarded_picture",
    },
    data: formData,
  });
  return result;
};
