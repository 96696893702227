var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-5 ml-5 mr-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('section',{staticClass:"presentation-page ma-0"},[_c('v-row',{staticClass:"presentation-page__content",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"content-img"},[_c('div',{staticClass:"content-img__img",style:(_vm.course.bg)})])]),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"9","lg":"5"}},[_c('h1',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.course.title))]),_c('p',{staticClass:"content-info"},[_vm._v(" "+_vm._s(_vm.course.points)+" Credits for the course completion. ")]),_c('div',{staticClass:"intro-progress-bar"},[_c('v-progress-linear',{staticClass:"progress-bar-container",attrs:{"value":_vm.course.completion,"color":_vm.progress_bar_color,"height":"16"}},[_c('strong',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(Math.ceil(_vm.course.completion) ? Math.ceil(_vm.course.completion) : 0)+"% ")])])],1)]),_c('v-col',{staticClass:"text-right mt-3",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"control-container"},[_c('p',[(_vm.deadline)?_c('span',[_vm._v(" Deadline: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.deadlineDate))])]):_c('span',[_vm._v(" No deadline ")])]),(_vm.course.button)?_c('v-btn',{staticClass:"btn_txt",attrs:{"disabled":_vm.act_as_me == false,"depressed":"","rounded":"","color":_vm.course.button == 'enroll'
                    ? '#ddde00'
                    : _vm.course.button == 'start'
                    ? '#a7d42e'
                    : _vm.course.button == 'continue'
                    ? '#53a0de'
                    : '',"height":"48","width":"135"},on:{"click":_vm.onOpenDialog}},[_vm._v(" "+_vm._s(_vm.course.button)+" ")]):_vm._e(),(_vm.openDialog)?_c('CoursePopup',{staticClass:"content-btn",attrs:{"course_id":_vm.course.id,"openDialog":_vm.openDialog,"showDescriptionDirectly":false},on:{"exit":_vm.completedCourse,"completedCourse":_vm.completedCourse}}):_vm._e()],1)])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }