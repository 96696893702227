import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getEmployeeCharges = (action, quarterId) => {
  let result = axios()({
    url: API.GETEMPLOYEECHARGES,
    method: "patch",
    params: {
      action: action,
      employee_id: localStorage.getItem("employee_id"),
      quarter_id: quarterId,
    },
  });
  return result;
};

export const getEmployeeCostPerHour = (quarterId) => {
  let result = axios()({
    url: API.GETEMPLOYEECOST,
    method: "patch",
    params: {
      action: "get_employee_cost_per_hour",
      employee_id: localStorage.getItem("employee_id"),
      quarter_id: quarterId,
    },
  });
  return result;
};

export const getBudgetPeriodByDate = (date) => {
  let result = axios()({
    url: API.GETBUDGETPERIOD,
    method: "patch",
    params: { action: "get_quarter_from_date", date: date },
  });
  return result;
};

export const getCurrentVsDeservedSalary = (employee_id) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("employee_id", employee_id);
  // urlencoded.append("employee_id", "2");
  let result = axios()({
    method: "patch",
    url: API.GETEMPLOYEECOST,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: {
      action: "compute_current_vs_deserved_salary",
    },
    data: urlencoded,
  });
  return result;
};
