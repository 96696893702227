//   Jan 01, 2000
const EditDate = (_date) => {
  if (!_date) return "";
  let regex_Date = /^\d{4}-([0][0-9]|[1][0-2])-\d{2}$/;
  let setdate;
  let setYear;
  let setMonth;
  let setDate;
  if (regex_Date.test(_date)) {
    setdate = _date.split("-");
    setYear = setdate[0];
    setMonth = parseInt(setdate[1]) - 1;
    setDate = setdate[2];
  } else {
    setdate = new Date(_date);
    setYear = setdate.getFullYear();
    setMonth = setdate.getMonth();
    setDate = setdate.getDate();
    setDate = setDate < 10 ? "0" + setDate : setDate;
  }
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[setMonth] + " " + setDate + ", " + setYear;
};
export default EditDate;
