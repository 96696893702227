<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">
              People reporting to you (directly or indirectly)
            </h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <div class="eventsTable">
                <span class="sub-title weight-700 size-24">Direct Reports</span>
                <span class="box-number weight-700 size-12">{{
                  organizationalChart.length
                }}</span>
                <DxTreeList
                  :data-source="organizationalChart"
                  :show-borders="true"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :word-wrap-enabled="true"
                  items-expr="direct_reports"
                  data-structure="tree"
                >
                  <DxColumn
                    caption="Employee"
                    cell-template="employee"
                    :width="40 + '%'"
                  />
                  <DxColumn
                    caption="Readiness"
                    cell-template="readiness"
                    :width="30 + '%'"
                  />
                  <DxColumn cell-template="pathToReadiness" />
                  <template #employee="{ data: options }">
                    <div>
                      <div class="img-role">
                        <div class="report-img">
                          <!-- <img src="@/assets/img/avatar_01.svg" /> -->
                          <!-- <img :src="report_user_image" /> -->
                          <div
                            class="report-img-container"
                            :style="{
                              backgroundImage: `url(${employeeImage(
                                options.data
                              )})`,
                            }"
                          ></div>
                        </div>
                        <div class="report-name-role">
                          <ul>
                            <li class="report-name">
                              <span>{{ options.data.full_name }}</span>
                            </li>
                            <li class="report-role">
                              <span>{{ options.data.title }}</span>
                            </li>
                            <!-- <li class="report-role" v-if="report_pronoun">
                              <span>{{ report_pronoun }}</span>
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #readiness="{ data: options }">
                    <div class="readiness-container">
                      <div class="chart-container">
                        <DxSparkline
                          v-if="
                            displaySparkline(options.data.readiness_readings)
                          "
                          :data-source="
                            getReadinessData(options.data.readiness_readings)
                          "
                          class="sparkline"
                          value-field="readiness"
                          argument-field="date"
                          type="bar"
                          :show-first-last="false"
                          :column-auto-width="true"
                          :on-drawn="setColors"
                        >
                          <DxTooltip :enabled="false" />
                        </DxSparkline>
                      </div>
                      <div
                        class="last_readiness"
                        v-if="displaySparkline(options.data.readiness_readings)"
                      >
                        <span>
                          <b
                            >{{
                              options.data.readiness_readings[
                                options.data.readiness_readings.length - 1
                              ].readiness
                            }}%</b
                          >
                        </span>
                      </div>
                    </div>
                  </template>
                  <template #pathToReadiness="{ data: options }">
                    <div>
                      <div
                        style="
                          margin: 8px;
                          cursor: pointer;
                          display: flex;
                          justify-content: end;
                        "
                        @click="redirectTo(options.data)"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <img
                                v-bind="attrs"
                                v-on="on"
                                src="../assets/img/path_to_readiness.png"
                                style="height: 24px; width: 24px"
                              />
                            </span>
                          </template>
                          <span style="padding: 5px">Path To Readiness</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                </DxTreeList>
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <p class="not-found__text">You have no Direct Reports</p>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isActingAsAnotherUser"
      content-class="dialog-box-action-as-another"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-body d-flex flex-column align-center">
          <p class="cust-dialog-label">
            You cannot do this because you are acting as another user
          </p>
          <v-btn
            depressed
            rounded
            class="btn-ok"
            @click="closeDialog"
            color="#53a0de"
            >OK</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { DxTreeList, DxColumn } from "devextreme-vue/tree-list";
import { getOrganizationalChartFrom } from "@/api";
import { createNamespacedHelpers } from "vuex";
import DxSparkline, { DxTooltip } from "devextreme-vue/sparkline";

const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxSparkline,
    DxTooltip,
  },
  computed: {
    ...nanoramicLabsHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.organizationalChart.length == 0;
    },
  },
  async created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));

    try {
      this.getLoading(true);
      let result = await getOrganizationalChartFrom(
        localStorage.getItem("employee_id")
      );
      if (result) {
        result.data.data.response.direct_reports.sort(function (a, b) {
          return a.full_name < b.full_name
            ? -1
            : a.full_name > b.full_name
            ? 1
            : 0;
        });
        this.organizationalChart = result.data.data.response.direct_reports;
      }
      this.getLoading(false);
    } catch (error) {
      console.log("error: ", error);
    }
  },
  data() {
    return {
      organizationalChart: [],
      isActingAsAnotherUser: false,
      act_as_me: true,
      test: [],
    };
  },
  methods: {
    ...mapActions(["getLoading"]),
    employeeImage(data) {
      const image = this.employeeNameJobList.find(
        (e) => e.id == data.id
      ).image_128;
      return image;
    },

    setColors(e) {
      // ref to datasource collection
      const dataItems = e.component.getDataSource().items();
      // console.log("dataItems: ", dataItems);

      // ref and iterate over sparkline markers
      e.element.querySelectorAll(".dxc-markers rect").forEach((el, i) => {
        // lookup value from chart dataset
        const { readiness: value } = dataItems[i];
        // console.log("value: ", value);

        // get color string based on the value from chart dataset
        const color =
          value >= 80 ? "#A7D42E" : value <= 50 ? "#FF6C6C" : "#53A0DE";

        // update "fill" attribute of the marker
        el.setAttribute("fill", color);
      });
    },
    redirectTo(employee_data) {
      if (this.act_as_me) {
        const url = "/readiness/" + employee_data.id;
        this.$router.push(url);
      } else {
        this.isActingAsAnotherUser = true;
      }
    },
    closeDialog() {
      this.isActingAsAnotherUser = false;
    },
    getReadinessData(data) {
      return data.map((value) => {
        return {
          date: value.date,
          readiness:
            value.readiness < 1 ? value.readiness * 100 : value.readiness,
        };
      });
    },
    displaySparkline(data) {
      let show = false;
      for (const element of data) {
        if (element.readiness > 0) {
          show = true;
          break;
        }
      }
      return show;
    },
  },
};
</script>
<style scoped>
.img-role {
  display: flex;
  flex-flow: row;
  align-items: center;
  .report-img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 1rem;

    .report-img-container {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 40px !important;
      height: 40px !important;
      border-radius: 0.625rem;
      background-color: #dfebf5;
    }
  }
  .report-name-role {
    .report-name {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #323a44;
      text-transform: capitalize;
    }
    .report-role {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #a1a4ac;
      padding-top: 0.125rem;
      text-transform: capitalize;
    }
  }
}
.cust-dialog-body {
  font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
  font-size: 1.08333333rem;
  font-weight: 400;
  line-height: 1.5;
  .cust-dialog-bodyContent {
    margin-top: 25px;
    width: max-content;

    .cust-dialog-labelContent {
      font-weight: bold;
      color: #008784;
      text-align: left;
    }
  }
  .cust-dialog-label {
    font-weight: bold;
    color: #666666;
    text-align: left;
  }
  .btn-ok {
    width: 64px !important;
    float: left !important;
    margin: 20px 10px 10px 10px !important;
    text-transform: none;
    letter-spacing: normal;
    color: white;
    font-size: 14px !important;
    font-weight: 600;
    font-style: normal;
    line-height: 17px !important;
  }
}
.readiness-container {
  display: flex;
  align-items: center;
}

.last_readiness {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.eventsTable {
  padding: 15px;
}
.box-number {
  padding: 7px 10px;
  background-color: #53a0de4d;
  border-radius: 4px;
  margin-left: 20px;
  vertical-align: super;
  color: blue;
}
</style>
