<template>
  <div class="headshot" @dragover.prevent @drop.prevent>
    <drag-and-drop @drop-file="onChange" v-show="!selectedFile" />
    <cropper :file="selectedFile" @cancel="cancel" v-show="selectedFile" />
  </div>
</template>

<script>
import DragAndDrop from "@/components/tasks/DragAndDrop";
import Cropper from "@/components/tasks/Cropper";
export default {
  components: {
    DragAndDrop,
    Cropper,
  },
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    cancel() {
      console.log("cancel");
      this.selectedFile = null;
    },
    onChange(e) {
      const file = e;
      console.log(file);
      this.mime_type = file.type;
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.headshot {
  padding: 60px 40px;
  height: 80vh;
}
@media screen and (max-width: 450px) {
  .headshot {
    padding: 10px;
    height: 50vh;
  }
}
</style>
