<template>
  <div>
    <div class="directory-page">
      <div class="page_router">
        <span class="txt_router">Home</span>
        <div class="img_arrow"></div>
        <div class="txt_router">Directory</div>
      </div>
      <div class="nanoramicLabs">
        <span class="txt_nanoramic">Nanoramic Labs</span>
        <div class="nanoramic_search">
          <v-autocomplete
            v-model="searchEmployee"
            :items="employeeNameJobList"
            solo
            rounded
            flat
            hide-details
            :label="searchEmployee ? searchEmployee : 'Search Employees'"
            item-text="name"
            item-value="name"
            :menu-props="{ maxWidth: '300' }"
            @change="selectedEmployee()"
            :loading="searchLoading"
            loader-height="0"
          >
            <template v-slot:item="data">
              <v-list-item-avatar rounded>
                <!-- <img :src="data.item.image_128_b64" /> -->
                <div
                  class="selectedImg"
                  :style="{
                    backgroundImage: 'url(' + data.item.image_128 + ')',
                  }"
                ></div>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.job_title"
                ></v-list-item-subtitle>
                <v-list-item-subtitle v-if="data.item.employee_pronoun">
                  {{ data.item.employee_pronoun }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title v-if="searchLoading" class="no-data">
                  <DxLoadIndicator
                    :visible="searchLoading"
                    class="loading-indicator-search"
                  />
                  <span>Loading data...</span>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <div class="nanoramic_info">
          <div
            v-for="(inforItem, index) in nanoramicLabs_list"
            :key="index"
            class="nanormic_infoItem"
            :style="{ borderColor: inforItem.itemColor }"
            @click="directoryDetail(inforItem)"
          >
            <div class="infoItem">
              <div
                class="img_info"
                :style="{
                  backgroundImage: 'url(' + inforItem.imgIcon + ')',
                }"
              ></div>
              <DxLoadIndicator
                :visible="inforItem.countLoading"
                class="loading-indicator"
              />
              <span
                v-if="!inforItem.countLoading"
                class="txt_count"
                :style="{ color: inforItem.itemColor }"
              >
                {{ inforItem.itemCount }}
              </span>
            </div>
            <span class="infoItem_title">{{ inforItem.itemName }}</span>
          </div>
        </div>
      </div>
      <span class="txt_myProfile">Your Directory Profile</span>
      <div class="profile_page">
        <Employee />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");
const { mapActions, mapGetters } = createNamespacedHelpers("isLoading");
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import Employee from "@/components/employee/EmployeeInfo";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";

import { departmentHierarchy } from "@/api";
export default {
  name: "Directory",
  components: {
    Employee,
    DxLoadIndicator,
  },
  data() {
    return {
      searchEmployee: "",
      searchLoading: true,
      nanoramicLabs_list: [
        {
          imgIcon: require("../assets/img/employees_icon.svg"),
          itemColor: "#53A0DE",
          itemName: "Employees",
          itemCount: 0,
          countLoading: true,
        },
        {
          imgIcon: require("../assets/img/departments_icon.svg"),
          itemColor: "#c2c301",
          itemName: "Departments",
          itemCount: 0,
          countLoading: true,
        },
        {
          imgIcon: require("../assets/img/teams_icon.svg"),
          itemColor: "#A7D42E",
          itemName: "Teams",
          itemCount: 0,
          countLoading: false,
        },
        {
          imgIcon: require("../assets/img/locations_icon.svg"),
          itemColor: "#1F166B",
          itemName: "Locations",
          itemCount: 0,
          countLoading: true,
        },
      ],
      employees_List: [],
      // departments_List: [],
    };
  },
  created() {
    this.getLoading(false);
    this.getDATAFromAPI();
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
    ...nanoramicLabsHelpers.mapGetters([
      "employeeNameJobList",
      "employeeRegions",
      "countryTotal",
    ]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...nanoramicLabsHelpers.mapActions([
      "dispatchEmployeeNameJobList",
      "dispatchEmployeeList",
      "dispatchDepartmentList",
      "dispatchEmployeeRegionCountryList",
      "dispatchEmployeeRegions",
    ]),
    selectedEmployee() {
      let _searchEmployeeID = "";
      this.employeeNameJobList.forEach((employee) => {
        if (employee.name == this.searchEmployee) {
          _searchEmployeeID = employee.id;
        }
      });
      let url = `/employees/profile?employee=${_searchEmployeeID}`;
      this.$router.push(url);
    },
    removeEmployee() {
      this.searchEmployee = "";
    },
    async getDATAFromAPI() {
      // this.getLoading(true);
      this.dispatchEmployeeRegionCountryList([]);
      await this.getDirectoryEmployeeList();
      await this.getDepartmentHierarchy();
      // this.getLoading(false);
    },
    async getDirectoryEmployeeList() {
      if (this.employeeNameJobList.length == 0) {
        await this.dispatchEmployeeNameJobList();
      }
      this.searchLoading = false;
      this.nanoramicLabs_list[0].itemCount = this.employeeNameJobList.length;
      this.nanoramicLabs_list[0].countLoading = false;
      this.nanoramicLabs_list[3].itemCount = this.countryTotal;
      this.nanoramicLabs_list[3].countLoading = false;
    },
    async getDepartmentHierarchy() {
      try {
        let result = await departmentHierarchy();
        if (result.status === 200) {
          let departments_list = result.data.data.response;
          // console.log("department==>", departments_list);
          this.dispatchDepartmentList(departments_list);
          this.nanoramicLabs_list[1].itemCount = departments_list.length;
          this.nanoramicLabs_list[1].countLoading = false;
        }
      } catch (error) {
        console.log("DepartmentHierarchyError==>", error);
        this.nanoramicLabs_list[1].countLoading = false;
      }
    },

    directoryDetail(_inforItem) {
      let _name = _inforItem.itemName;
      if (_inforItem.itemCount != 0) {
        let url = `/`;
        switch (_name) {
          case "Employees":
            url = `/employees`;
            break;
          case "Departments":
            url = `/departments`;
            break;
          case "Teams":
            url = `/teams`;
            break;
          case "Locations":
            url = `/locations`;
            break;
          default:
            break;
        }
        this.$router.push(url).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>
<style lang="scss">
@keyframes nanoramicLabsItem {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}
.loading-indicator {
  height: 32px;
  width: 32px;
}
.loading-indicator-search {
  height: 24px;
  width: 24px;
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.selectedImg {
  min-width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #cce3f5;
  border-radius: 12px;
}
.directory-page {
  margin: 30px 80px;
  display: flex;
  flex-direction: column;
  .page_router {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .txt_router {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #7f868e;
    }
    .img_arrow {
      background-image: url("~@/assets/img/arrow_right.svg");
      width: 5px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .nanoramicLabs {
    margin-top: 26px;
    margin-bottom: 30px;
    background-image: url("~@/assets/img/nanoramicLabs_bg.png");
    max-width: 974px;
    // height: 336px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 40px 40px 30px 40px;
    .txt_nanoramic {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: #323a44;
    }
    .nanoramic_search {
      margin-top: 26px;
      margin-bottom: 26px;
      .v-text-field--solo > .v-input__control > .v-input__slot {
        background: #f6f6fb;
        width: 300px;
      }
      .v-text-field--rounded > .v-input__control > .v-input__slot {
        padding: 0 10px;
      }
      .selectedEmployeeInfo {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        min-width: 350px;
        background: white;
        padding: 0px;
        .selectedImg {
          min-width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #cce3f5;
          border-radius: 12px;
        }
        .selectedTxtName {
          display: flex;
          flex-flow: column;
          margin-left: 10px;
          margin-right: 5px;
          color: #323a44;
          .txt_name {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
          }
          .txt_title {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
    .nanoramic_info {
      margin-top: 28px;
      display: grid;
      grid-gap: 1.625rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      .nanormic_infoItem {
        border: solid 1px;
        border-radius: 16px;
        padding: 20px 24px;
        min-width: 204px;
        cursor: pointer;
        .infoItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          .img_info {
            width: 30px;
            height: 30px;
            // margin-right: 45px;
          }
          .txt_count {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            // margin-left: 45px;
          }
        }
        .infoItem_title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;

          color: #323a44;
        }
      }
      .nanormic_infoItem:hover {
        transform: scale(1.1, 1.1);
        // animation: nanoramicLabsItem 1s forwards;
        // animation-fill-mode: forwards;
        // -webkit-animation: nanoramicLabsItem 1s forwards;
      }
    }
  }
  .txt_myProfile {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #323a44;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .profile_page {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1135px) {
  .directory-page {
    margin: 30px 30px 50px 30px;
  }
  .directory-page .nanoramicLabs {
    background-image: none;
  }
  .directory-page .nanoramicLabs .nanoramic_info {
    grid-template-columns: 1fr 1fr !important;
  }
  .nanormic_infoItem:hover {
    transform: scale(1, 1) !important;
  }
}
@media screen and (max-width: 800px) {
  .directory-page .nanoramicLabs {
    padding: 20px 20px 30px 20px;
  }
  .directory-page .nanoramicLabs .nanoramic_info {
    grid-template-columns: 1fr !important;
  }
}
@media screen and (max-width: 400px) {
  .directory-page
    .nanoramicLabs
    .nanoramic_search
    .v-text-field--solo
    > .v-input__control
    > .v-input__slot {
    width: 280px;
  }
}
</style>
