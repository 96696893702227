<template>
  <div>
    <div class="dashboard-page">
      <div class="page_router">
        <span class="txt_router">Home</span>
        <div class="img_arrow"></div>
        <div class="txt_router">Dashboard</div>
      </div>
      <div class="hr_status">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="4">
              <div class="hr-content">
                <div class="hr-header">
                  <span class="title_card txt_hr_title"
                    >People Ops is asking you to do
                  </span>
                  <div class="hr-img"></div>
                </div>
                <div class="hr_detail">
                  <div class="tasks_info">
                    <span class="txt_task">Your Tasks</span>
                    <span class="txt_task_count"
                      >You have {{ tasksCount }} things to do.</span
                    >
                  </div>
                  <button class="txt_goTasks" @click="gotoTasks">View all tasks</button>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4">
              <div class="faqs-content">
                <span class="title_card"
                  >Your Readiness is {{ readinessData.value }}%</span
                >
                <div class="bulletChart mt-3 mb-3" v-if="readinessData.value != 0">
                  <fusioncharts
                    type="hbullet"
                    height="80"
                    dataFormat="json"
                    data-empty-message="Data is loading"
                    :dataSource="getDataSource()"
                  ></fusioncharts>
                </div>
                <div v-if="readinessData.value != 0">
                  <DxSparkline
                    :data-source="source"
                    value-field="readiness"
                    class="sparkline"
                    argument-field="date"
                    type="bar"
                    :show-first-last="false"
                    :on-drawn="setColors"
                  >
                    <DxTooltip :enabled="false" />
                  </DxSparkline>
                  <div class="text_below_chart">Last 12 weeks</div>
                </div>
                <span class="txt_more" @click="onReadinessShowMore">
                  {{ textShowStatus }}
                </span>
              </div>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12" lg="4">
              <div class="status-content">
                <div class="status_img"></div>
                <div class="status_cover">
                  <span class="title_card">Your Status</span>
                  <div
                    class="status_detail"
                    v-for="status in employeeStatusList"
                    :key="status.index"
                  >
                    <span class="subtitle_card">{{
                      status.thisWeekDay ? status.thisWeekDay : ""
                    }}</span>
                    <div class="txt_status_review">
                      <div
                        class="img_dot"
                        v-if="status.color"
                        :style="{ background: status.color }"
                      ></div>
                      <span class="txt_review">{{
                        status.type_id[1] ? status.type_id[1] : ""
                      }}</span>
                    </div>
                  </div>
                </div>
                <span
                  v-if="employeeStatusList.length > 0"
                  class="txt_more"
                  @click="onStatusShowMore"
                >
                  {{ textShowStatus }}
                </span>
              </div>
            </v-col> -->
            <v-col cols="12" sm="12" md="12" lg="4">
              <div class="faqs-content">
                <div class="faqs_img"></div>
                <span class="txt_faqs">Frequently Asked Questions</span>
                <div class="faqs_cover">
                  <v-expansion-panels accordion class="na-panel">
                    <v-expansion-panel v-for="(faq, i) in faq5" :key="i">
                      <v-expansion-panel-header class="na-text--light-gray">
                        {{ faq.question }}
                        <!-- <template v-slot:actions>
                          <v-icon size="26" class="content-arrow"> $expand </v-icon>
                        </template> -->
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="faq.answer">
                          <span class="na-content-text size-14 weight-600">{{
                            faq.answer
                          }}</span>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <router-link v-if="faq5.length > 0" :to="linkToFAQ">
                  <span class="txt_more"> More FAQ's </span>
                </router-link>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="educationalEvent">
            <v-col cols="12" sm="12" md="12" lg="8">
              <div class="events-content">
                <div class="events-header">
                  <span class="title_card">Upcoming Events</span>
                  <span class="subtitle_card"
                    >{{ educationalEvent.from_date }} -
                    {{ educationalEvent.to_date }}</span
                  >
                </div>
                <div class="events-body">
                  <span class="event_name">{{ educationalEvent.name }}</span>
                  <span class="event_description">{{
                    educationalEvent.description
                  }}</span>
                  <span class="subtitle_card">Speaker</span>
                  <EmployeeIdentifier
                    v-if="
                      educationalEvent.speakers && educationalEvent.speakers.length > 0
                    "
                    :name="`${educationalEvent.speakers[0].first_name} ${educationalEvent.speakers[0].last_name}`"
                    :department="educationalEvent.speakers[0].bio"
                    :image="educationalEvent.speakers[0].picture"
                  />
                </div>
                <button class="event-btn">Go to event</button>
                <div class="events-bg"></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="values-content">
        <span class="txt_values">Our values</span>
        <div class="values_list">
          <Value
            v-for="(value, index) in valuesInfo"
            :key="index"
            :index="index"
            :value="value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Value from "@/components/dashboard/Value";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import EditDate from "@/utils/edit_date_2.js";
import EditDateStatus from "@/utils/edit_date_3.js";
import CreateSimpleDate from "@/utils/create_simple_date.js";
import DaysOffset from "@/utils/days_offset.js";
import EmployeeIdentifier from "@/components/dashboard/EmployeeIdentifier.vue";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter";
import {
  getValues,
  getUserTasksByType,
  getStatusForLastWeek,
  getFAQByPageANDSize,
  getEducationalEvents,
  getReadinessDataPoints,
} from "@/api";
import "devextreme/dist/css/dx.light.css";
import DxSparkline, { DxTooltip } from "devextreme-vue/sparkline";

export default {
  name: "Dashboard",
  components: {
    Value,
    EmployeeIdentifier,
    DxSparkline,
    DxTooltip,
  },
  data() {
    return {
      valuesNumberColor: ["#FF6C6C", "#53A0DE", "#A7D42E", "#DDDE00", "#1F166B"],
      valuesInfo: [],
      tasksCount: 0,
      employeeStatusList: [],
      employeeStatusFullList: [],
      statusShowMore: false,
      faq5: [],
      linkToFAQ: { name: "FAQs" },
      textShowStatus: "Show more",
      educationalEvent: false,
      menuItemsList: [
        {
          Home: [],
        },
      ],
      homeSubItems: [
        { name: "Dashboard", to: "/home/dashboard" },
        { name: "Directory", to: "/home/directory" },
        { name: "Org Chart", to: "/home/orgchart" },
        { name: "Status", to: "/home/status" },
      ],
      source: [],
      readinessData: {},
    };
  },
  created() {
    this.menuItemsList[0].Home = this.homeSubItems;
    console.log("gggg", this.menuItemsList[0].Home);
    // this.getLoading(false);
    this.getDashboardDetail();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDashboardDetail() {
      this.getLoading(true);
      // await this.getStatus();
      await this.getFAQs();
      await this.getValues();
      await this.getEduEvents();
      await this.getReadinessData();
      this.getLoading(false);
      await this.getTasks();
    },
    async getTasks() {
      try {
        let result = await getUserTasksByType();
        if (result.status == 200) {
          //   console.log("task==>", result.data.data.response);
          this.tasksCount = result.data.data.response
            ? result.data.data.response.length
            : 0;
        }
      } catch (error) {
        console.log("GetTasksError==>", error);
      }
    },
    async getStatus() {
      try {
        let start_thisWeekDisplayPadded = CreateSimpleDate();
        let end_thisWeekDisplayPadded = CreateSimpleDate("end-week");
        let result = await getStatusForLastWeek(
          start_thisWeekDisplayPadded,
          end_thisWeekDisplayPadded
        );
        if (result.status == 200) {
          this.employeeStatusFullList = result.data.data.response
            ? result.data.data.response
            : "";
          if (this.employeeStatusFullList) {
            this.employeeStatusFullList.forEach((status) => {
              let _date = "";
              if (status.datetime_from) {
                _date = status.datetime_from;
              } else if (status.datetime_to) {
                _date = status.datetime_to;
              } else {
                _date = "";
              }
              if (_date) {
                status.thisWeekDay = EditDateStatus(_date) || "-";
              }
            });

            this.employeeStatusFullList.sort(function (a, b) {
              return a.thisDate - b.thisDate;
            });

            if (this.employeeStatusFullList.length >= 4) {
              for (let index = 0; index < 3; index++) {
                const element = this.employeeStatusFullList[index];
                this.employeeStatusList.push(element);
              }
            } else {
              this.employeeStatusList = [];
              this.employeeStatusList = this.employeeStatusFullList;
            }
          }
        }
      } catch (error) {
        console.log("GetEmployeeStatusError===>", error);
      }
    },
    async getFAQs() {
      try {
        let result = await getFAQByPageANDSize(1, 5);
        if (result.status == 200) {
          this.faq5 = result.data.data;
        }
      } catch (error) {
        console.log("GetFAQsError===>", error);
      }
    },
    async getValues() {
      try {
        let result = await getValues();
        if (result.status == 200) {
          this.valuesInfo = result.data.data;
          let colorCount = 0;
          let colorListIndex = 0;
          for (let index = 0; index < this.valuesInfo.length; index++) {
            const element = this.valuesInfo[index];
            colorCount++;
            element.txtColor = this.valuesNumberColor[index - 5 * colorListIndex];
            if (colorCount >= 5) {
              colorCount = 0;
              colorListIndex++;
            }
          }
        }
      } catch (error) {
        console.log("GetValuesError==>", error);
      }
    },
    async getEduEvents() {
      try {
        let result = await getEducationalEvents();
        if (result.status == 200) {
          let data = result.data.data.response.data;
          if (data.length > 0) {
            let eventEdu = data;
            let indexDateCloser = -1;
            let daysCloser = null;
            eventEdu.forEach((evnt, i) => {
              let daysTo = DaysOffset(evnt.from_date);
              if (daysTo <= 0 && (daysTo > daysCloser || daysCloser == null)) {
                indexDateCloser = i;
                daysCloser = daysTo;
              }
            });
            if (indexDateCloser > -1) {
              this.educationalEvent = eventEdu[indexDateCloser];
              this.educationalEvent.from_date =
                EditDate(eventEdu[indexDateCloser].from_date) || "-";
              this.educationalEvent.to_date =
                EditDate(eventEdu[indexDateCloser].to_date) || "-";
              let bio = eventEdu[indexDateCloser].speakers[0].bio;
              this.educationalEvent.speakers[0].bio = bio ? bio : "";
              this.educationalEvent.speakers[0].first_name = CapitalizeFirstLetter(
                eventEdu[indexDateCloser].speakers[0].first_name
              );
              this.educationalEvent.speakers[0].last_name = CapitalizeFirstLetter(
                eventEdu[indexDateCloser].speakers[0].last_name
              );
            }
          }
        }
      } catch (error) {
        console.log("getEducationalEvents==>", error);
      }
    },
    onStatusShowMore() {
      this.statusShowMore = !this.statusShowMore;
      this.textShowStatus = this.statusShowMore ? "Show less" : "Show more";
      this.employeeStatusList = [];
      if (this.statusShowMore) {
        this.employeeStatusList = this.employeeStatusFullList;
      } else {
        if (this.employeeStatusFullList.length >= 4) {
          for (let index = 0; index < 3; index++) {
            const element = this.employeeStatusFullList[index];
            this.employeeStatusList.push(element);
          }
        } else {
          this.employeeStatusList = this.employeeStatusFullList;
        }
      }
    },
    gotoTasks() {
      let url = `/tasks`;
      this.$router.push(url);
    },
    getDataSource() {
      const dataSource = {
        chart: {
          numbersuffix: "%",
          theme: "fusion",
          // lowerLimit: "0",
          // upperLimit: "120",
          showTickMarks: "0",
          showTickValues: "0",
          showValue: "0",
          upperLimit: this.readinessData.end,
          lowerLimit: this.readinessData.start,
          plotFillColor: this.getBulletColor(
            this.readinessData.bulletColor,
            this.readinessData.value
          ), //this.readinessData.targetColor //"#1071E5",
        },
        colorrange: {
          color: this.readinessData.color,
          // color: [
          //   {
          //     minValue: "0",
          //     maxValue: "49.99",
          //     code: "#979EA8",
          //   },
          //   {
          //     minValue: "49.99",
          //     maxValue: "79.99",
          //     code: "#CFD4DB",
          //   },
          //   {
          //     minValue: "79.99",
          //     maxValue: "100",
          //     code: "#F2F3F5",
          //   },
          // ],
        },
        value: this.readinessData.value, // 20,
      };
      return dataSource;
    },
    onReadinessShowMore() {
      this.$router.push("/readiness");
    },
    async getReadinessData() {
      try {
        const result = await getReadinessDataPoints(localStorage.getItem("employee_id"), 1);
        this.source = result.data.data.response.data.map((x) => ({
          ...x,
          readiness: x.readiness * 100,
        }));
        this.readinessData = result.data.data.response.readiness_data;
      } catch (error) {
        console.log("getReadinessData==>", error);
      }
    },
    setColors(e) {
      // ref to datasource collection
      const dataItems = e.component.getDataSource().items();

      // ref and iterate over sparkline markers
      e.element.querySelectorAll(".dxc-markers rect").forEach((el, i) => {
        // lookup value from chart dataset
        const { readiness: value } = dataItems[i];

        // get color string based on the value from chart dataset
        const color = value >= 80 ? "#A7D42E" : value <= 50 ? "#FF6C6C" : "#53A0DE";

        // update "fill" attribute of the marker
        el.setAttribute("fill", color);
      });
    },
    getBulletColor(bulletColorArray, value) {
      if (bulletColorArray) {
        for (const element of bulletColorArray) {
          if (value <= element.maxValue) {
            return element.code;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.title_card {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 30px;
  text-transform: capitalize;
  color: $black;
  margin-bottom: 5px;
}
.subtitle_card {
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 17.15px;
  text-transform: capitalize;
  color: $black;
  margin-bottom: 5px;
}
.dashboard-page {
  margin: 30px 80px;
  display: flex;
  flex-direction: column;
  .page_router {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .txt_router {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #7f868e;
    }
    .img_arrow {
      background-image: url("~@/assets/img/arrow_right.svg");
      width: 5px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .hr_status {
    margin-top: 26px;
    display: flex;
    flex-flow: row;
    .hr-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //   width: 100%;
      height: 376px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      margin-right: 36px;
      padding: 70px 40px 45px 40px;
      .hr-header {
        display: flex;
        justify-content: space-between;
        .txt_hr_title {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 50px;
          word-wrap: break-word;
          width: 55%;
        }
        .hr-img {
          background-image: url("~@/assets/img/dashboard_HR_bg.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 155px;
          height: 109px;
        }
      }
      .hr_detail {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        // margin-top: 50px;
        .tasks_info {
          display: flex;
          flex-flow: column;
          // margin-right: 50px;
          .txt_task {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: underline;
            text-transform: capitalize;
            color: #549ddc;
            margin-bottom: 6px;
          }
          .txt_task_count {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: $gray;
            margin-top: 6px;
          }
        }
        .txt_goTasks {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          background-color: #a7d42e;
          border-radius: 30px;
          width: 154px;
          height: 48px;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
    .status-content {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      min-height: 376px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      margin-left: 36px;
      margin-right: 36px;
      padding: 40px;
      position: relative;
      .status_img {
        position: absolute;
        background-image: url("~@/assets/img/status_bg.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 130px;
        height: 65px;
        z-index: 1;
        top: 22px;
        right: 32px;
      }
      .status_cover {
        display: flex;
        flex-flow: column;
        position: relative;
        z-index: 2;
        .status_detail {
          display: flex;
          flex-flow: column;
          margin-top: 25px;
          .txt_status_review {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-top: 5px;
            .img_dot {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              //   background: red;
              margin-right: 7px;
            }
            .txt_review {
              font-style: normal;
              font-weight: 400;
              line-height: 19.41px;
              font-size: 16px;
              color: #323a44;
              margin-left: 7px;
              margin-right: 10px;
            }
            .txt_status_hidden {
              font-style: normal;
              font-weight: normal;
              line-height: 15px;
              font-size: 12px;
              color: #a1a4ac;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .faqs-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: white;
      border-radius: 16px;
      // min-height: 350px;
      // max-height: 350px;
      height: 376px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      padding: 40px;
      margin-left: 36px;
      position: relative;
      .faqs_img {
        position: absolute;
        background-image: url("~@/assets/img/question_bg.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 81px;
        height: 78px;
        z-index: 1;
        top: 22px;
        right: 55px;
      }
      .txt_faqs {
        position: relative;
        z-index: 2;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        color: #323a44;
        margin-bottom: 5px;
        // width: calc(100% - 138px);
      }
      .faqs_cover {
        z-index: 2 !important;
        position: relative;
        .v-expansion-panel::before {
          box-shadow: none !important;
        }
        .v-expansion-panel:not(:first-child)::after {
          border-top: none;
        }
        .v-expansion-panel-header {
          padding: 0;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 17px;
          min-height: 40px !important;
        }
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
          padding: 0px 0px 0px 12px;
          border-left: 2px solid $blue;
        }
        .theme--light.v-expansion-panels .v-expansion-panel {
          background-color: transparent;
        }
        .na-panel {
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 240px;
          // ::-webkit-scrollbar {
          //   display: none;
          // }

          .na-content-text {
            line-height: 21px;
          }
        }
      }
    }
    .events-content {
      position: relative;
      height: 315px;
      border-radius: 16px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      margin-right: 36px;
      margin-top: 40px;
      background-color: white;
      padding: 40px 50px 40px 40px;
      .events-bg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-bottom-right-radius: 16px;
        background-image: url("~@/assets/img/upcoming_events_bg.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-color: white;
        width: 661px;
        height: 286px;
        z-index: 1;
      }
      .events-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 2;
      }
      .events-body {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        .event_name {
          font-style: normal;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 30px;
          text-transform: capitalize;
          color: $blue;
          margin-bottom: 16px;
        }
        .event_description {
          // display: inline-block;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 21px;
          color: $black;
          margin-bottom: 20px;
          width: 70%;
          max-height: 42px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          // white-space: pre-line;
          -webkit-line-clamp: 2; /* number of lines to show */
        }
      }
      .event-btn {
        position: absolute;
        background-color: $green;
        bottom: 40px;
        right: 51px;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 17.15px;
        color: $white;
        z-index: 2;
        padding: 14px 40px;
        border-radius: 30px;
      }
    }
    .txt_more {
      // display: flex;
      // flex-direction: row;
      // justify-content: flex-end;
      // align-items: flex-end;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      // line-height: 22px;
      text-align: left;
      text-decoration-line: underline;
      color: #53a0de;
      cursor: pointer;
    }
  }
  .values-content {
    margin-top: 45px;

    display: flex;
    flex-flow: column;
    .txt_values {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #323a44;
      margin-bottom: 25px;
    }
    .values_list {
      display: grid;
      //   grid-gap: 65px;
      grid-column-gap: 65px;
      grid-row-gap: 40px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
}
@media screen and (min-width: 1264px) and (max-width: 1696px) {
  .txt_faqs {
    width: calc(100% - 80px) !important;
  }
  .faqs_img {
    right: 40px !important;
  }
}

@media screen and (max-width: 1650px) {
  .values_list {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 1400px) {
  .hr-content {
    margin-right: 18px !important;
  }
  .status-content {
    margin-left: 18px !important;
    margin-right: 18px !important;
  }
  .faqs-content {
    margin-left: 18px !important;
  }
  .events-content {
    margin-right: 18px !important;
  }
}
@media screen and (max-width: 1263px) {
  .hr-content {
    margin-right: 0px !important;
    margin-bottom: 15px;
  }
  .status-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .faqs-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .events-content {
    margin-right: 0px !important;
    margin-top: 15px !important;
  }
  .values_list {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 900px) {
  .dashboard-page {
    margin: 30px 30px 50px 30px;
  }
}
@media screen and (max-width: 775px) {
  .events-bg {
    background-image: none !important;
    background-color: transparent !important;
  }
  .event_description {
    width: 100% !important;
  }
}
@media screen and (max-width: 680px) {
  .hr-content {
    padding: 40px !important;
    background-image: none !important;
    height: auto !important;
    .txt_hr_title {
      margin-bottom: 25px !important;
    }
    .hr_detail {
      justify-content: space-between;
      margin-top: 30px !important;
      .hr_detail_txt {
        // margin-right: 0px !important;
      }
      .txt_goTasks {
        // margin-left: 0px !important;
      }
    }
  }
  .values_list {
    grid-template-columns: 1fr !important;
  }
}
@media screen and (min-width: 364px) and (max-width: 540px) {
  .txt_faqs {
    width: calc(100% - 80px) !important;
  }
  .faqs_img {
    right: 32px !important;
  }
}
@media screen and (max-width: 500px) {
  .events-content {
    height: 400px !important;
  }
  .event-btn {
    position: static !important;
    margin-top: 12px;
  }
}
@media screen and (max-width: 450px) {
  .dashboard-page {
    margin: 30px 15px 40px 15px;
    .hr-content {
      padding: 30px 15px !important;
      .txt_goTasks {
        padding: 15px 25px !important;
      }
    }
    .status-content {
      padding: 30px 15px !important;
    }
    .faqs-content {
      padding: 30px 15px !important;
    }
    .events-content {
      padding: 30px 15px !important;
    }
  }
}
@media screen and (max-width: 330px) {
  .faqs_img {
    display: none !important;
  }
  .status_img {
    display: none !important;
  }
}
#dataGrid {
  height: 500px;
}
.activeNavMenuItem {
  color: #549ddc !important;
  text-decoration-line: underline !important;
}
.text-item {
  color: black;
  margin-right: 10px;
}
.header_menu {
  // margin: 0px 60px;
  height: 56px;
  padding: 0px 30px;
  background-color: $blue;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .header_menu_txt {
    display: block;
    margin: 0px 30px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    text-decoration: none;
    color: #ffffff80;
    text-align: center;
    span {
      color: #ffffff80;
      cursor: pointer;
    }
    span:hover {
      color: #ffffff;
      text-decoration-line: underline;
    }
    .subMenu {
      display: flex;
      flex-flow: row;
      align-items: center;
      background-color: $white;
      height: 48px;
      // box-shadow: 0px 6px 10px rgb(195 196 198 / 60%);
      border-radius: 30px;
      left: -2rem;
      margin-top: 34px;
      padding: 0 10px !important;
      overflow: hidden;
      position: absolute;
      transform: scaleX(0);
      transition: all 0.3s ease;
      top: 100%;

      z-index: 1;
      white-space: nowrap;
      .box {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        padding: 0 30px;
        span {
          color: $black;
        }
        span:hover {
          color: #549ddc;
          text-decoration-line: underline;
        }
      }
    }
  }
}

.activeHeaderMenuTxt {
  text-decoration-line: underline !important;
  color: #ffffff !important;
  span {
    color: #ffffff !important;
  }
}
.bulletChart {
  width: 100%;
  overflow: hidden;
  margin-bottom: -36px;
  margin-top: -8px;
  padding-left: 0px;
}
// .sparkline {
//   margin-left: 30px;
//   width: 200px;
//   height: 30px;
// }
.text_below_chart {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  // color: $gray;
  color: hsla(242, 88.4%, 66.3%, 1);
  margin-top: 10px;
}
</style>
