<template>
  <div class="report-row">
    <div class="img-role">
      <div class="report-img">
        <!-- <img src="@/assets/img/avatar_01.svg" /> -->
        <!-- <img :src="report_user_image" /> -->
        <div
          class="report-img-container"
          :style="{ 'background-image': 'url(' + report_user_image + ')' }"
        ></div>
      </div>
      <div class="report-name-role">
        <ul>
          <li class="report-name">
            <span>{{ report_name }}</span>
          </li>
          <li class="report-role">
            <span>{{ report_role }}</span>
          </li>
          <li class="report-role" v-if="report_pronoun">
            <span>{{ report_pronoun }}</span>
          </li>
        </ul>
      </div>
    </div>
    <router-link
      class="report-out-link"
      :to="linkToSeeMore(report)"
      target="_blank"
    >
      <img src="@/assets/img/out_link.svg" />
    </router-link>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
// const nanoramicLabsDetailHelpers = createNamespacedHelpers("nanoramicLabsDetail");
export default {
  name: "UserReportRow",
  props: {
    report: {
      type: Object,
      default: new Object(),
    },
  },

  data() {
    return {
      report_name: "",
      report_role: "",
      report_pronoun: "",
      report_user_image: "",
    };
  },
  created() {
    this.report_name = this.report.name;
    this.report_role = this.report.role;
    this.report_pronoun = this.report.employee_pronoun;
    this.report_user_image = this.report.img;
  },
  computed: {},
  methods: {
    linkToSeeMore(_report) {
      let url = this.$router.resolve({
        name: "EmployeeProfile",
        query: { employee: _report.report_id },
      });

      return url.href;
    },
  },
};
</script>
<style lang="scss">
.report-row {
  display: flex;
  flex-flow: row;
  padding-top: 0.875rem !important;
  justify-content: space-between;
  align-items: center;
  .img-role {
    display: flex;
    flex-flow: row;
    align-items: center;
    .report-img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;

      .report-img-container {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background-color: #dfebf5;
      }
    }
    .report-name-role {
      .report-name {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #323a44;
        text-transform: capitalize;
      }
      .report-role {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #a1a4ac;
        padding-top: 0.125rem;
        text-transform: capitalize;
      }
    }
  }

  .report-out-link {
    min-width: 1.875rem;
    text-align: right;
    cursor: pointer;
  }
}
</style>
