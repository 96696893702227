export default {
  namespaced: true,
  state: {
    learningActivityTypes: [],
  },
  mutations: {
    setLearningActivityTypes(state, status) {
      state.learningActivityTypes = status;
    },
  },
  getters: {
    getLearningActivityTypes(state) {
      return state.learningActivityTypes;
    },
  },
};
