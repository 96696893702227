<template>
  <v-dialog content-class="dialog-select" :value="openDialog" persistent>
    <div class="cust-dialog" v-if="rowData.coach && rowData.coach.length != 0">
      <div class="cust-dialog-header">
        <h2 class="cust-dialog-title">
          {{ rowData.title }}
        </h2>
      </div>
      <div class="cust-dialog-body">
        <p class="cust-dialog-label" v-if="rowData.bodyTitle">
          {{ rowData.bodyTitle }}
        </p>
        <div class="cust-dialog-bodyContent">
          <v-row>
            <v-col class="cust-dialog-label" cols="3"> Employee </v-col>
            <v-col class="cust-dialog-labelContent" cols="9">{{
              rowData["employee"]
            }}</v-col>
          </v-row>
          <v-row v-if="rowData.responsibility">
            <v-col class="cust-dialog-label" cols="3">
              Employee Responsibility</v-col>
            <v-col class="cust-dialog-labelContent" cols="9">{{
              rowData["responsibility"]
            }}</v-col>
          </v-row>
          <v-row v-if="rowData.addCoach">
            <v-col class="cust-dialog-label" cols="3">Coach</v-col>
            <v-col cols="9">
              <v-row v-if="rowData.coach.length == 1">
                <v-text-field :value="rowData.coach[0].employee_name" disabled></v-text-field>
              </v-row>
              <v-row v-else>
                <v-select v-model="coach_id" label="Select" :items="rowData.coach" item-text="employee_name"
                  item-value="employee_id" solo flat hide-details single-line>
                </v-select>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-row v-if="rowData.addCoach">
            <v-row v-if="!rowData.coach.length == 1">
              <v-col class="cust-dialog-label" cols="3">Coaching Style</v-col>
              <v-col cols="9">
                <v-select
                  v-model="coachingStyle_id"
                  label="Select"
                  :items="listCoaching"
                  item-text="name"
                  item-value="id"
                  solo
                  flat
                  hide-details
                  single-line
                >
                  <template v-slot:selection="data">
                    <span
                      v-bind="data.attrs"
                      :style="{ color: colorLeadership(data.item.id) }"
                    >
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-row> -->
        </div>
      </div>
      <div class="cust-dialog-footer">
        <v-btn depressed rounded class="btn-accept btn-save" @click="onSave" color="#a7d42e">Request</v-btn>

        <v-btn depressed rounded class="btn-accept btn-save" @click="onCancel" color="#ff6c6c">Discard</v-btn>
      </div>
    </div>
    <div class="cust-dialog" v-if="rowData.coach && rowData.coach.length == 0">
      <div class="cust-dialog-body d-flex flex-column align-center">
        <p class="cust-dialog-label">
          There is no expert available for this responsibility. Please contact
          People and Operations to resolve the issue.
        </p>
        <v-btn depressed rounded class="btn-accept btn-save" @click="onCancel" color="#1c74e2">OK</v-btn>
      </div>
    </div>
    <!-- <div
      class="coach_error"
      v-if="rowData.coach && rowData.coach.length == 0"
    ></div> -->
  </v-dialog>
</template>

<script>
import {
  COACHING_STYLES,
  TARGETS,
  LEADERSHIP,
  PROFICIENCY,
} from "@/utils/constants.js";

export default {
  name: "EditDialogBox",
  props: ["rowData", "open"],
  data() {
    return {
      coach_id: "",
      coachingStyle_id: "s1-telling",
      description: "",
      summary: "",
    };
  },
  computed: {
    openDialog() {
      return this.open;
    },
    listCoaching() {
      return COACHING_STYLES;
    },
    listTargets() {
      return TARGETS;
    },
  },
  methods: {
    colorLeadership(situational_leadership) {
      let _color = LEADERSHIP[situational_leadership].color;
      return _color ? _color : "#000000";
    },
    onSave() {
      //put error message if not selected
      if (this.rowData.coach.length == 1) {
        this.coach_id = this.rowData.coach[0].employee_id;
      }
      if (this.coach_id != "") {
        const coaching_obj = {
          coach_id: this.coach_id,
          coaching_style: "s1-telling",
        };
        console.log("this.rowData: ", coaching_obj);
        this.$emit("save", coaching_obj);
      }
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.cust-dialog {
  .cust-dialog-header {
    margin-bottom: 1rem;

    .cust-dialog-title {
      color: $blue;
      text-align: center;
    }
  }

  .cust-dialog-body {
    font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
    font-size: 1.08333333rem;
    font-weight: 400;
    line-height: 1.5;

    .cust-dialog-bodyContent {
      margin-top: 25px;
      //   width: max-content;

      .cust-dialog-labelContent {
        font-weight: bold;
        color: #008784;
        text-align: left;
      }
    }

    .cust-dialog-label {
      font-weight: bold;
      color: #666666;
      text-align: left;
    }
  }

  .btn-save {
    width: max-content !important;
    float: left !important;
    margin: 20px 10px 10px 10px !important;
  }
}
</style>
<style lang="scss">
.v-dialog.dialog-select {
  padding: 30px 30px 10px 30px;
  border-radius: 30px;
  background-color: $white;
  width: min(70vw, 900px);
}

.dx-scrollview-content {
  padding: 10px;
}

.coach_error {
  background-color: #ffd9d9;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #666666;
}
</style>
