<template>
  <div>
    <!-- Accept -->
    <div
      v-if="
        data.dummy2.widget == 'devexpress_emptyible_date_widget' &&
        data.model == 'tactical.role.assignment' &&
        data.fields.find((x) => x.name == 'dummy_2').value == false
      "
    >
      <p :class="[act_as_me == true ? 'emptyMessage' : 'alignText']">
        <span
          v-if="act_as_me == true"
          @click="onAcceptNow(data)"
          class="c-button"
        >
          Accept Now
        </span>
        <span v-else> Not yet Accepted </span>
      </p>
      <!-- <p v-else class="alignText">{{ dateTimeFormatted }}</p> -->
    </div>

    <!-- Acknowledge -->
    <div
      v-else-if="
        data.dummy2.widget == 'devexpress_emptyible_date_widget' &&
        data.model == 'tactical.role.assignment.responsibility' &&
        data.fields.find((x) => x.name == 'dummy_2').value == false
      "
    >
      <p :class="[act_as_me == true ? 'emptyMessage' : 'alignText']">
        <span
          v-if="act_as_me == true"
          @click="onAcknowledgeNow(data)"
          class="c-button"
        >
          Acknowledge Now
        </span>
        <span v-else> Not yet Acknowledged </span>
      </p>
      <!-- <p v-else class="alignText">{{ dateTimeFormattedDummy1 }}</p> -->
    </div>

    <div
      v-else-if="
        data.model == 'tactical.role.assignment.responsibility' &&
        !!data.children &&
        data.children.nodes.dummy_2.attrs.widget == 'tr_coach_widget' &&
        data.children.fields.find((x) => x.name == 'coach_id').value
      "
    >
      <chipComponent
        :chipData="getChipData()"
        :id="'chip' + data.ID"
        @onClickClose="removeCoach(data)"
      >
      </chipComponent>
      <DxTooltip
        :hide-on-outside-click="false"
        show-event="mouseenter"
        hide-event="mouseleave"
        :target="'#chip' + data.ID"
        v-if="getChipData().toolTip.length > 0"
      >
        {{ getChipData().toolTip }}
      </DxTooltip>
    </div>

    <div
      v-else-if="
        data.model == 'tactical.role.assignment.responsibility' &&
        !!data.children &&
        data.children.nodes.dummy_2.attrs.widget == 'tr_coach_widget' &&
        !data.children.fields.find((x) => x.name == 'coach_id').value &&
        data.readinessChartOption.attrs.chartValues[1].color != '#808080'
      "
    >
      <chipComponent
        @clickOnChip="editDialogBox('addCoach', true)"
        class="requestCoachChip"
        :chipData="requestCoachChip"
        :id="'chip' + data.ID"
      ></chipComponent>
      <DxTooltip
        :hide-on-outside-click="false"
        show-event="mouseenter"
        hide-event="mouseleave"
        :target="'#chip' + data.ID"
        v-if="requestCoachChip.toolTip.length > 0"
      >
        {{ requestCoachChip.toolTip }}
      </DxTooltip>
    </div>

    <!-- Coach -->
    <div
      v-if="data.dummy2.widget == 'tr_coach_widget'"
      class="d-flex justify-center"
    >
      <div
        v-if="data.fields.find((x) => x.name == 'coach_id').value"
        class="c-coach-widget"
        :style="{
          'background-color': getBGColor(data.fields),
        }"
      >
        <v-list-item>
          <v-list-item-avatar rounded="30px">
            <div
              class="selectedImg"
              :style="{ backgroundImage: `url(${getCoachImg(data)}` }"
            ></div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <p>
                {{ data.fields.find((x) => x.name == "coach_name").value }}
              </p>
              <p :style="{ color: getTextColor(data.fields) }">
                <strong>
                  {{
                    data.fields.find((x) => x.name == "tracker_state").value ==
                    "accepted"
                      ? getCoachingStyle(data.fields)
                      : data.fields.find((x) => x.name == "tracker_state").value
                  }}
                </strong>
              </p>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              rounded
              icon
              color="#000000"
              @click="removeCoach(data)"
              class="c-btn-removeCoach"
            >
              <v-icon size="20"> $deleteBlack </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
      <div v-else>
        <button class="addCoach" @click="editDialogBox('addCoach', false)">
          Request Coach
        </button>
      </div>
    </div>

    <!-- Credits -->
    <div
      v-else-if="data.dummy2.widget == 'tr_fulfillment_widget'"
      class="fulfillmentCredit"
    >
      <div
        v-if="data.dummy2.displayCredits && data.dummy1.fulfillmentID == false"
      >
        > {{ data.dummy2.learningActivityCredits }} Credits
      </div>
    </div>

    <!-- empty -->
    <div v-else></div>

    <EditDialogBox
      :open="openEditDialogBox"
      :rowData="editingData"
      @save="saveData"
      @cancel="closeEditDialogBox"
      ref="EditDialogBox"
    ></EditDialogBox>
  </div>
</template>
<script>
import EditDialogBox from "./editDialogbox";
import moment from "moment";
import {
  updateAcceptanceRoleDate,
  updateResponsibilityAcknowledgeDate,
  getExperts,
  requestCoach,
} from "@/api";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");
// const userInfoHelpers = createNamespacedHelpers("userInfo");
import EditDateToUTC from "@/utils/edit_date_to_utc_1.js";
import EditDate from "@/utils/edit_date_2.js";
import { removeCoachRequest } from "@/api";
import chipComponent from "@/common/chips";
import { LEADERSHIP } from "@/utils/constants.js";
import { DxTooltip } from "devextreme-vue/tooltip";

export default {
  name: "Dummy2Widget",
  props: ["data", "actAsMe"],
  components: {
    EditDialogBox,
    chipComponent,
    DxTooltip,
  },
  data() {
    return {
      act_as_me: true,
      openEditDialogBox: false,
      editingData: {},
      coach: [],
      requestCoachChip: {
        id: "chip" + this.data.ID,
        textIsBold: false,
        image: "",
        toolTip: "Request Coach",
        color: "#6f7681",
        text: "+ Request Evaluation",
        isOutlined: false,
        textColor: "#FFFFFF",
        close: false,
      },
    };
  },
  created() {
    console.log(JSON.parse(JSON.stringify(this.data)));
    this.act_as_me = this.actAsMe;
  },
  computed: {
    ...nanoramicLabsHelpers.mapGetters(["employeeNameJobList"]),
    // dateTimeFormatted() {
    //   let time;
    //   if (this.data.dummy2.options) {
    //     time =
    //       this.data.dummy2.noEmptyprefixMessage +
    //       " " +
    //       moment(
    //         this.data.fields.find((x) => x.name == "dummy_2").value
    //       ).format("DD-MMM-yyyy");
    //   }
    //   return time;
    // },
    // dateTimeFormattedDummy1() {
    //   let time;
    //   if (this.data.dummy1.options) {
    //     time =
    //       "Acknowledged on " +
    //       moment(
    //         this.data.fields.find((x) => x.name == "dummy_1").value
    //       ).format("DD-MMM-yyyy");
    //   }
    //   return time;
    // },
    // ...userInfoHelpers.mapGetters(["userInfo"]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    closeEditDialogBox() {
      this.openEditDialogBox = false;
    },
    async editDialogBox(action, isfromResponsibilityLevel) {
      this.getLoading(true);
      if (action == "addCoach") {
        const responsibility_id = isfromResponsibilityLevel
          ? this.data.children.fields.find((x) => x.name == "responsibility_id")
              .value
          : this.data.fields.find((x) => x.name == "responsibility_id").value;
        const result = await getExperts(responsibility_id);
        this.coach = result.data.data.response;
        this.editingData = {
          title: "Request Coach",
          bodyTitle: "",
          employee: JSON.parse(localStorage.getItem("my_name_picture")).name, //this.userInfo.name
          responsibility: isfromResponsibilityLevel
            ? this.data.fields.find((x) => x.name == "name").value
            : this.data.parentData.fields.find((x) => x.name == "name").value,
          addCoach: true,
          coaching_id: "",
          coach: this.coach,
        };
        this.openEditDialogBox = true;
      }
      this.getLoading(false);
    },
    async saveData(coachingData) {
      this.getLoading(true);
      let isfromResponsibilityLevel =
        this.data.model == "tactical.role.assignment.responsibility";
      try {
        if (coachingData.coach_id !== null) {
          const result = await requestCoach(
            isfromResponsibilityLevel
              ? this.data.children.res_id
              : this.data.res_id,
            coachingData.coach_id,
            coachingData.coaching_style
          );

          if (result.status == 200) {
            if (isfromResponsibilityLevel) {
              this.data.children.fields.find(
                (item) => item.name == "coach_id"
              ).value = coachingData.coach_id;
              this.data.children.fields.find(
                (item) => item.name == "coaching_style"
              ).value = coachingData.coaching_style;
              this.data.children.fields.find(
                (item) => item.name == "tracker_state"
              ).value = "pending";
              this.data.children.fields.find(
                (item) => item.name == "coach_name"
              ).value = this.coach.find(
                (element) => element.employee_id == coachingData.coach_id
              ).employee_name;
              this.data.children.fields.find(
                (item) => item.name == "tracker_tooltip"
              ).value =
                "Requested by " +
                this.editingData.employee +
                " on " +
                moment().format("YYY-MM-DD HH:mm:ss");
            } else {
              this.data.fields.find((item) => item.name == "coach_id").value =
                coachingData.coach_id;

              this.data.fields.find(
                (item) => item.name == "coaching_style"
              ).value = coachingData.coaching_style;
              this.data.fields.find(
                (item) => item.name == "tracker_state"
              ).value = "pending";
              this.data.fields.find((item) => item.name == "coach_name").value =
                this.coach.find(
                  (element) => element.employee_id == coachingData.coach_id
                ).employee_name;
              this.data.fields.find(
                (item) => item.name == "tracker_tooltip"
              ).value =
                "Requested by" +
                " " +
                this.editingData.employee +
                " on " +
                moment().format("YYY-MM-DD HH:mm:ss");
            }

            this.openEditDialogBox = false;
          }
        }
      } catch (error) {
        console.log("acceptRoleError==>", error);
      }
      this.getLoading(false);
    },
    async onAcceptNow(_row) {
      this.getLoading(true);
      try {
        const _today = new Date();
        const result = await updateAcceptanceRoleDate(_row.res_id);
        if (result.status == 200) {
          _row.fields.find((x) => x.name == "dummy_2").value =
            EditDateToUTC(_today);
        }
      } catch (error) {
        console.log("acceptRoleError==>", error);
      }
      this.getLoading(false);
    },
    async onAcknowledgeNow(_row) {
      this.getLoading(true);
      try {
        const ackId = this.data.fields.find(
          (x) => x.name == "employee_readiness"
        ).value;
        const _today = new Date();
        const result = await updateResponsibilityAcknowledgeDate(ackId, _today);
        if (result.status == 200) {
          _row.fields.find((x) => x.name == "dummy_2").value = EditDate(_today);
        }
      } catch (error) {
        console.log("acknoledgeRoleError==>", error);
      }
      this.getLoading(false);
    },
    getBGColor(fields) {
      const status = fields.find((x) => x.name == "tracker_state").value;
      if (status == "pending") {
        return "#EDF5FF";
      } else if (status == "declined") {
        return "#FFD9D9";
      } else {
        return "#FFFFFF";
      }
    },
    getTextColor(fields) {
      const status = fields.find((x) => x.name == "tracker_state").value;
      if (status == "pending" || status == "declined") {
        return "#4c535d";
      } else {
        return "#f3711a";
      }
    },
    getCoachingStyle(fields) {
      let style = fields.find((x) => x.name == "coaching_style").value;
      style = style.split("-")[1];
      style = style.charAt(0).toUpperCase() + style.slice(1);
      return style;
    },
    async removeCoach(data) {
      let isfromResponsibilityLevel =
        this.data.model == "tactical.role.assignment.responsibility";

      let employee_id = isfromResponsibilityLevel
        ? data.children.fields.find((item) => item.name == "employee_id").value
        : data.fields.find((item) => item.name == "employee_id").value;

      let coach_id = isfromResponsibilityLevel
        ? data.children.fields.find((item) => item.name == "coach_id").value
        : data.fields.find((item) => item.name == "coach_id").value;

      let responsibility_id = isfromResponsibilityLevel
        ? data.children.fields.find((item) => item.name == "responsibility_id")
            .value
        : data.fields.find((item) => item.name == "responsibility_id").value;

      try {
        const result = await removeCoachRequest(
          employee_id,
          coach_id,
          responsibility_id
        );
        if (result.status == 200) {
          if (isfromResponsibilityLevel) {
            data.children.fields.find(
              (item) => item.name == "coach_id"
            ).value = false;
            data.children.fields.find(
              (item) => item.name == "coaching_style"
            ).value = false;
            data.children.fields.find(
              (item) => item.name == "tracker_state"
            ).value = false;
            data.children.fields.find(
              (item) => item.name == "coach_name"
            ).value = false;
          } else {
            data.fields.find((item) => item.name == "coach_id").value = false;
            data.fields.find(
              (item) => item.name == "coaching_style"
            ).value = false;
            data.fields.find(
              (item) => item.name == "tracker_state"
            ).value = false;

            data.fields.find((item) => item.name == "coach_name").value = false;
          }
        }
        const ref = this.$refs.EditDialogBox;
        Object.assign(ref.$data, ref.$options.data());
      } catch (error) {
        console.log("removeCoach==>", error);
      }
    },
    getCoachImg(data) {
      const coachID = data.fields.find((x) => x.name == "coach_id").value;
      const coachImgUrl = this.employeeNameJobList.find(
        (e) => e.id == coachID
      ).image_128;
      return coachImgUrl;
    },
    getChipData() {
      let ChipData = {
        textIsBold: true,
        image: this.getCoachImg(this.data.children),
        toolTip: "Chip",
        id: "chip" + this.data.ID,
      };
      const status = this.data.children.fields.find(
        (x) => x.name == "tracker_state"
      ).value;

      if (status == "declined") {
        ChipData.color = "#F00B26";
        ChipData.text = "Declined";
        ChipData.isOutlined = false;
        ChipData.textColor = "#FFFFFF";
        ChipData.close = true;

        const coach_name = this.data.children.fields.find(
          (x) => x.name == "coach_name"
        ).value;
        const findDate = this.data.children.fields.find(
          (x) => x.name == "tracker_tooltip"
        ).value;

        ChipData.toolTip =
          coach_name + " declined" + " on " + this.getDate(findDate);
      } else if (status == "pending") {
        ChipData.color = "#006AE3";
        ChipData.text = "Pending";
        ChipData.isOutlined = false;
        ChipData.textColor = "#FFFFFF";
        ChipData.close = true;

        const employeeID = this.data.children.fields.find(
          (x) => x.name == "employee_id"
        ).value;
        const employeeName = this.employeeNameJobList.find(
          (e) => e.id == employeeID
        ).name;
        const coach_name = this.data.children.fields.find(
          (x) => x.name == "coach_name"
        ).value;
        const findDate = this.data.children.fields.find(
          (x) => x.name == "tracker_tooltip"
        ).value;
        ChipData.toolTip =
          coach_name +
          " was requested as coach by " +
          employeeName +
          " on " +
          this.getDate(findDate);
      } else if (status == "accepted") {
        ChipData.color = "#000000";
        ChipData.isOutlined = true;
        ChipData.close = true;

        let style = this.data.children.fields.find(
          (x) => x.name == "coaching_style"
        ).value;
        ChipData.textColor = LEADERSHIP[style].color;

        style = style.split("-")[1];
        style = style.charAt(0).toUpperCase() + style.slice(1);

        ChipData.text = style;

        const coach_name = this.data.children.fields.find(
          (x) => x.name == "coach_name"
        ).value;
        const findDate = this.data.children.fields.find(
          (x) => x.name == "tracker_tooltip"
        ).value;

        ChipData.toolTip =
          coach_name + " accepted on " + this.getDate(findDate);
      }

      return ChipData;
    },
    getDate(date) {
      const dateRegex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/;
      const dateMatch = date.match(dateRegex);
      let formattedDate = "";
      if (dateMatch && dateMatch.length > 1) {
        const dateString = dateMatch[1];
        formattedDate = new Date(dateString);
      }
      return moment(formattedDate).format("DD-MMM-YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.emptyMessage {
  flex-grow: 1;
  color: #ffffff;
  background: #e81313;
  padding: 4px;
  margin-bottom: 0;
  // cursor: pointer;
  text-align: center;
}

.coach_img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50% !important;
}

.addCoach {
  font-size: 13px;
  padding: 5px 10px;
  color: $blue;
}

.fulfillmentCredit {
  font-size: 13px;
  font-weight: bold;
}

.alignText {
  text-align: center !important;
  color: #aba5a5;

  span {
    background: $red;
    color: white;
    padding: 5px;
    display: block;
  }
}

.c-button {
  width: 100%;
  display: block;
  cursor: pointer;
}

.c-coach-widget {
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
}

.c-btn-removeCoach {
  position: absolute;
  top: -6px;
  right: -6px;
}

.chip {
  width: max-content;
  padding: 0 20px 0 5px;
}

.requestCoachChip.v-chip.v-size--large {
  cursor: pointer;
  // height: 40px !important;
  // font-size: 14px !important;
}

.v-chip__content {
  font-size: 14px !important;
  width: 100%;
  margin: 0 auto;
}
</style>
<!--57 v-html="
  data.fields.find((x) => x.name == 'coach_text').value.replace('padding-left: 30px;', '')"
  style="font-size: 13px" -->
