import VuexPersist from "vuex-persist";
import localforage from "localforage";

const vuexPersist = new VuexPersist({
  key: "employee_portal",
  storage: localforage,
  // storage: window.localStorage,
});

export default vuexPersist;
