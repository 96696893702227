<template>
  <!-- Filters container -->
  <div class="filters">
    <!-- Filters option menus -->
    <nav class="filters__nav">
      <ul class="options" v-if="Object.keys(filtersTags).length != 0">
        <li
          class="item"
          @click.stop="toggleMenu('tags')"
          v-click-outside="hideMenuTags"
          v-if="filtersTags.tags.length > 0"
        >
          <span class="text">Tags</span>
          <img class="img-fluid" src="@/assets/img/nav-arrow.svg" />
          <div
            class="tag-elements"
            v-bind:class="{ activeMenu: stateMenus.tags }"
          >
            <span
              class="box"
              v-for="el in tags"
              :key="el"
              @click="selectTag(el)"
              >{{ el }}</span
            >
          </div>
        </li>
        <li
          class="item"
          @click.stop="toggleMenu('filters')"
          v-click-outside="hideMenuFilters"
          v-if="filtersTags.filters.length > 0"
        >
          <span class="text">Filters</span>
          <img class="img-fluid" src="@/assets/img/nav-arrow.svg" />
          <ul
            class="elements"
            v-bind:class="{ activeMenu: stateMenus.filters }"
          >
            <li
              class="box"
              v-for="el in filters"
              :key="el"
              @click="selectTag(el)"
            >
              {{ el }}
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <!-- When Mobile, Menu -->
    <nav class="filters__mobile_nav">
      <ul class="options" v-if="Object.keys(filtersTags).length != 0">
        <li class="item">
          <!-- <span class="text">Menu</span> -->
          <img
            @click.stop="toggleMenu('responsiveMenu')"
            class="mobile_filter_img"
            src="@/assets/img/mobile_filter_img.svg"
          />
          <div
            class="elements"
            v-bind:class="{ activeResposiveMenu: stateMenus.responsiveMenu }"
          >
            <ul
              class="filterTag"
              v-bind:class="{ activeMenu: stateMenus.responsiveMenu }"
              v-for="filterTag in Object.keys(filtersTags)"
              :key="filterTag"
            >
              <li class="filterTagName">
                {{ filterTag }}
                <div class="filterTagName_underline"></div>
              </li>
              <li
                class="box"
                v-for="el in filtersTags[filterTag]"
                :key="el"
                @click="selectTag(el)"
              >
                {{ el }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>

    <!-- Input text to query keyword -->
    <div class="filters__right">
      <!-- Input form -->
      <div class="input-box">
        <form class="search-form">
          <input
            required
            class="text-search"
            type="text"
            :placeholder="searchPlaceholder"
            :value="searchVal"
            @input="setSearch($event)"
            @click="startSearch"
          />
          <button
            class="clear-search"
            type="reset"
            @click="setSearch('')"
          ></button>
        </form>
        <img src="@/assets/img/input-mag.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { createNamespacedHelpers } from "vuex";
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
export default {
  name: "Filters",
  props: {
    filtersTags: {
      type: Object,
      default: new Object(),
    },
    searchVal: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // Object to handle filters menu state (open or closed).
      stateMenus: {
        tags: false,
        filters: false,
        responsiveMenu: false,
      },
    };
  },

  computed: {
    // Tags filters
    tags() {
      return this.filtersTags.tags;
    },
    filters() {
      return this.filtersTags.filters;
    },
    // setMobileFilterArray() {
    //   this.filter;
    // },
  },
  created() {
    this.setMobileMenuActive(false);
  },
  methods: {
    ...setCoursePageInfoHelpers.mapMutations(["setMobileMenuActive"]),
    // Send the tag to add into tags array in parent.
    selectTag(tag) {
      this.$emit("selectedTag", tag);
      this.stateMenus.responsiveMenu = false;
      this.setMobileMenuActive(false);
    },
    // Setsthe search query to send to the parent.
    setSearch($event) {
      this.$emit("search", $event.target?.value ? $event.target.value : "");
    },
    startSearch() {
      this.stateMenus.responsiveMenu = false;
      this.setMobileMenuActive(false);
    },
    // Toggles tags options menu.
    toggleMenu(key) {
      for (const name of Object.keys(this.stateMenus)) {
        if (name === key) {
          this.stateMenus[name] = !this.stateMenus[name];
          if (key == "responsiveMenu") {
            this.setMobileMenuActive(this.stateMenus[name]);
          }
        } else {
          this.stateMenus[name] = false;
        }
      }
    },
    // Hide all menus when clicking outside of the menus.
    hideMenuFilters() {
      this.stateMenus.filters = false;
    },
    hideMenuTags() {
      this.stateMenus.tags = false;
    },
  },
  // This is the directive to listen and handle outside click on filters menu.
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss">
.activeResposiveMenu {
  transform: scaleX(1) !important;
  display: block !important;
}
.activeMenu {
  transform: scaleX(1) !important;
}
.filters {
  align-items: center;
  background-color: $blue;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  .filters__mobile_nav {
    display: none;
    .options {
      display: flex;
      margin-left: 3.75rem;
      .item {
        margin-right: 3.7625rem;
        position: relative;
        // .text {
        //   color: $white;
        //   font-style: normal;
        //   font-weight: 600;
        //   font-size: 1rem;
        //   line-height: 19px;
        //   margin-right: 0.625rem;
        // }
        .mobile_filter_img {
          width: 100%;
          cursor: pointer;
        }
        .elements {
          position: absolute;
          display: none;
          padding-bottom: 30px;
          z-index: 99999;
          .filterTag {
            background-color: $white;
            box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
            border-radius: 16px;
            left: -1.25rem;
            margin-top: 0.6875rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            overflow: hidden;
            // position: absolute;
            transform: scaleX(0);
            transition: all 0.3s ease;
            top: 100%;
            width: 20.4375rem;
            padding-left: 0rem !important;
            .filterTagName {
              font-style: normal;
              font-weight: bold;
              font-size: 1.125rem;
              line-height: 1.375rem;
              text-transform: capitalize;
              color: #53a0de;
              padding: 0.5rem 1rem;
              .filterTagName_underline {
                height: 0.0625rem;
                padding-left: 1rem;
                padding-right: 1rem;
                background-color: #e0e0e0;
                margin-top: 0.75rem;
              }
            }
            .box {
              cursor: pointer;
              color: $black;
              font-style: normal;
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 14px;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              padding-left: 1rem;
              //  text-transform:capitalize;
            }
            .box:hover {
              background-color: $light-blue-1 !important;
              color: #a7d42e;
            }
          }
        }
      }
      .item {
        .mobile_filter_img:hover {
          color: $yellow;
        }
      }
    }
  }
  &__nav {
    .options {
      display: flex;
      margin-left: 3.75rem;
      .item {
        cursor: pointer;
        margin-right: 3.7625rem;
        position: relative;
        .text {
          color: $white;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 19px;
          margin-right: 0.625rem;
        }
        .img-fluid {
          width: 0.55rem;
        }
        .elements {
          z-index: 99999;
          background-color: $white;
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
          border-radius: 16px;
          left: -1.25rem;
          margin-top: 0.6875rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          overflow: hidden;
          position: absolute;
          transform: scaleX(0);
          transition: all 0.3s ease;
          top: 100%;
          width: 9rem;
          padding-left: 0rem !important;
          .box {
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 14px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            padding-right: 0.5rem;
            // text-transform:capitalize;
          }
          .box:hover {
            background-color: $light-blue-1 !important;
          }
        }
        .tag-elements {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          background-color: $white;
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.23);
          border-radius: 16px;
          left: -1.25rem;
          margin-top: 0.6875rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          position: absolute;
          transform: scaleX(0);
          transition: all 0.3s ease;
          top: 100%;
          width: 40.125rem;
          z-index: 9;
          .box {
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 14px;
            padding-top: 0.5rem;
            padding-left: 1rem;
            padding-bottom: 0.5rem;
          }
          .box:hover {
            background-color: $light-blue-1 !important;
          }
        }
      }
      .item:hover {
        .text {
          color: $yellow;
        }
      }
    }
  }
  &__right {
    display: flex;
    .input-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      background-color: $light-blue-1 !important;
      border-radius: 30px;
      height: 3rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
      margin-top: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.02125rem;
      position: relative;
      width: 17.47875rem;
      .search-form {
        display: flex;
        flex-wrap: nowrap;
        .text-search {
          background-color: $light-blue-1 !important;
          border: 0;
          color: $gray;
          flex: 1;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 16px;
          outline: none;
          width: 90%;
        }
        .clear-search {
          position: relative;
          border: 1px solid transparent;
          background-color: $blue;
          display: inline-block;
          vertical-align: middle;
          outline: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 35px;
          cursor: pointer;
        }
        .clear-search:after {
          content: url("~@/assets/img/close_icon.svg");
          display: block;
          position: absolute;
          background-color: transparent;
          left: 5px;
          top: 5px;
          text-align: center;
          line-height: 0;
          cursor: pointer;
        }
        .text-search:not(:valid) ~ .clear-search {
          display: none;
        }
      }
    }
  }
}
// input[type="search"]::-webkit-search-cancel-button {
//   -webkit-appearance: none;
//   height: 24px;
//   width: 24px;
//   border-radius: 50%;
//   border: 14px solid $blue;
//   background-image: url("~@/assets/img/close_icon.svg");
//   background-color: $blue;
//   background-size: contain;
//   transform: scale(0.3);
//   pointer-events: none;
// }

// input[type="search"]:focus::-webkit-search-cancel-button {
//   opacity: .6;
//   pointer-events: all;
// }
@media screen and (max-width: 1096px) {
  .filters__nav .options .item {
    margin-right: 1.5rem;
  }
  .filters__right .input-box {
    max-width: 13rem;
  }
  .filters__nav .options {
    margin-left: 1.8rem;
  }
  .text-search {
    width: 60% !important;
  }
}
@media screen and (max-width: 900px) {
  .filters__nav {
    display: none;
  }
  .filters__mobile_nav {
    display: block !important;
  }
}
@media screen and (max-width: 500px) {
  .filters .filters__mobile_nav .options .item .elements {
    left: 0%;
    margin-left: -1.375rem;
  }
  .filters .filters__mobile_nav .options {
    margin-left: 1rem;
  }
  .filters .filters__mobile_nav .options .item {
    margin-right: 1rem;
  }
}
</style>
