<template>
  <div>
    <DisplayComplete
      v-if="dialogCourseApproved"
      :type_dialog="data_dialog.type_dialog"
      :subtitle1="data_dialog.subtitle1"
      :points="data_dialog.points"
      :subtitle2="data_dialog.subtitle2"
      :closeText="data_dialog.button"
      @close="setCompletedCourse"
    />
    <div v-else-if="!dialogRejectReason" class="containerCourseToApprove">
      <div class="courseToApprove">
        <div class="courseToApprove__container">
          <div class="courseToApprove__container__img"></div>
        </div>
        <h1 class="courseToApprove__title">
          Please approve or reject the content of this course
        </h1>
        <div class="courseToApprove__buttons">
          <div
            @click="acceptCourse(true)"
            class="btn_approve_course"
            style="background-color: #a7d42e"
          >
            <span class="btn_txt">Approve</span>
          </div>
          <div
            @click="showReasonReject"
            class="btn_approve_course"
            style="background-color: #ff6c6c"
          >
            <span class="btn_txt">Reject</span>
          </div>
        </div>
        <span class="courseToApprove__txt"
          >Upon Approval the course will be automatically published</span
        >
      </div>
    </div>
    <div v-else style="height: 100%">
      <div class="reason-container">
        <p class="reason-title">Please Provide Reason for Rejection</p>
        <v-textarea
          v-model="rejectReason"
          placeholder="Please provide a reason for the rejection"
          height="110"
          required
          no-resize
          solo
          rounded
          flat
          hide-details
          class="text-field"
        />
        <v-btn
          :disabled="rejectReason.trim() == ''"
          depressed
          rounded
          color="#53a0de"
          height="47"
          width="165"
          class="btn_send"
          @click="onSubmitReason"
        >
          Send Reason
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { approveRejectCourse } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const takeCourseHelper = createNamespacedHelpers("takeCourse");
const alertHelpers = createNamespacedHelpers("alert");

import DisplayComplete from "@/common/DisplayComplete";

export default {
  components: {
    DisplayComplete,
  },
  props: {
    task_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dialogCourseApproved: false,
      data_dialog: {},
      dialogRejectReason: false,
      rejectReason: "",
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters(["takeCourse"]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    ...takeCourseHelper.mapMutations([
      "setApprovementCompleted",
      "setSlideApprove",
    ]),
    showReasonReject() {
      this.dialogRejectReason = true;
    },
    onSubmitReason() {
      this.rejectCourse(this.rejectReason);
    },
    rejectCourse(e) {
      this.acceptCourse(false, e);
    },
    async acceptCourse(_approved, _reason) {
      this.getLoading(true);
      let result = await approveRejectCourse(this.task_id, _approved, _reason);
      if (result.status == 200) {
        let error = result.data.data.response.error;
        if (error) {
          this.getLoading(false);
          this.dispatchOpenAlert({ type: "error", message: error });
        } else {
          this.getLoading(false);
          if (_approved) {
            this.data_dialog = {
              type_dialog: "approve",
              subtitle1: "You successfully completed the course and earned",
              points: `${this.takeCourse.points}`,
              button: "Back to Tasks",
            };
          } else {
            this.data_dialog = {
              type_dialog: "reject",
              subtitle1: "Content was rejected",
              button: "Back To Tasks",
            };
          }
          this.dialogCourseApproved = true;
          this.setApprovementCompleted(true);
        }
      } else {
        this.getLoading(false);
        this.dispatchOpenAlert({ type: "error", message: "dhaks" });
      }
    },
    setCompletedCourse() {
      this.dialogCourseApproved = false;
      this.setSlideApprove(false);
      this.$emit("setCompletedCourse");
    },
  },
};
</script>
<style lang="scss" scoped>
.reason-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .reason-title {
    font-size: 1.5rem;
    color: $blue;
    font-weight: 700;
    line-height: 29.71px;
  }
  .text-field {
    width: 75%;
    max-height: 120px;
    border: 1px solid $light-gray-2;
    margin: 26px 0 40px 0;
  }
  .btn_send {
    font-weight: 600;
    font-size: 0.875rem;
    color: $white;
    text-transform: none;
  }
}
</style>
