<template>
  <v-card class="overflow-y-auto course-steps-scroll" v-if="takeCourse">
    <v-card-text>
      <div v-for="item in takeCourse.content" :key="item.id">
        <span class="step-title-text" v-if="item.is_category">
          {{ item.name }}
        </span>
        <v-timeline align-top dense>
          <v-timeline-item
            v-if="!item.is_category"
            fill-dot
            small
            :color="transparent_bgcolor"
          >
            <template v-slot:icon>
              <v-avatar
                size="24px"
                v-if="item.checked"
                :color="completed_bgcolor"
              >
                <img
                  class="img_checked"
                  src="@/assets/img/course_item_checked.svg"
                />
              </v-avatar>
              <v-avatar
                size="24px"
                v-else-if="item.checking"
                :color="completed_bgcolor"
              >
                <img
                  class="img_checking"
                  src="@/assets/img/course_item_checking.svg"
                />
              </v-avatar>
              <v-avatar size="24px" v-else :color="uncompleted_bgcolor">
                <!-- <img src="https://i.pravatar.cc/64" /> -->
              </v-avatar>
            </template>
            <div class="step-item-container">
              <div class="step-item">
                <!-- <div class="img-step-item-1"></div> -->
                <span
                  class="step-item-text-1"
                  :class="{
                    stepItemText1_active: item.id == selectedSlideID,
                  }"
                  @click="setStepItem(item)"
                >
                  <!-- {{ Object.values(item)[1] }} -->
                  {{ item.name }}
                </span>
              </div>
            </div>
            <p v-for="link in hasSlideDetailLink(item.name)" :key="link.id">
              <a :href="link.link" class="external-link" target="_blank">
                <v-icon size="10" class="link-icon">$linkBlue</v-icon>
                <span>{{ link.name }}</span>
              </a>
            </p>
          </v-timeline-item>
        </v-timeline>
      </div>
      <div v-if="approvementVisible">
        <v-timeline align-top dense>
          <v-timeline-item fill-dot small :color="transparent_bgcolor">
            <template v-slot:icon>
              <v-avatar
                size="24px"
                v-if="slideApprove"
                :color="completed_bgcolor"
              >
                <img
                  class="img_checking"
                  src="@/assets/img/course_item_checking.svg"
                />
              </v-avatar>
              <v-avatar
                size="24px"
                v-else-if="approvementCompleted"
                :color="completed_bgcolor"
              >
                <img
                  class="img_checked"
                  src="@/assets/img/course_item_checked.svg"
                />
              </v-avatar>
              <v-avatar size="24px" v-else :color="uncompleted_bgcolor">
              </v-avatar>
            </template>
            <div class="step-item-container">
              <div class="step-item">
                <span
                  class="step-item-text-1"
                  :class="{
                    stepItemText1_active: selectedSlideID == '',
                  }"
                  @click="selectApprovement"
                >
                  Approve content
                </span>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const takeCourseHelper = createNamespacedHelpers("takeCourse");
export default {
  name: "CourseSteps",
  data() {
    return {
      transparent_bgcolor: "#00000000",
      completed_bgcolor: "#a7d42e80",
      uncompleted_bgcolor: "#c2c2c5",
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters([
      "takeCourse",
      "slideApprove",
      "selectedSlideID",
      "approvementVisible",
      "approvementCompleted",
    ]),
    arraySlideDetail() {
      return this.takeCourse.slide_detail.flatMap((_sd) => _sd.name);
    },
  },
  methods: {
    ...takeCourseHelper.mapActions(["dispatchTakeCourse"]),
    ...takeCourseHelper.mapMutations([
      "setSlideApprove",
      "setSlideItemTitle",
      "setSelectedSlideID",
      "setApprovementVisible",
      "setSlideChecked",
    ]),
    hasSlideDetailLink(_name) {
      const hasLink = this.arraySlideDetail.indexOf(_name);
      return this.takeCourse.slide_detail[hasLink].links;
    },
    setStepItem(_item) {
      this.setSlideApprove(false);
      this.setSlideItemTitle(_item.name);
      this.setSlideChecked(_item.checked ? _item.checked : false);
      let takeCourse_copy = JSON.parse(JSON.stringify(this.takeCourse));
      takeCourse_copy.content.forEach((content) => {
        content["checking"] = false;
        takeCourse_copy.slide_detail.forEach((slide) => {
          if (content.id == slide.id) {
            if (slide.completed) {
              content["checked"] = true;
            } else {
              content["checked"] = false;
            }
          }
        });
      });
      if (!_item.checked) {
        takeCourse_copy.content.forEach((content) => {
          if (content.id == _item.id) {
            content["checking"] = true;
          } else {
            if (content["checking"]) {
              content["checking"] = false;
            }
          }
        });
      }
      this.dispatchTakeCourse(takeCourse_copy);
      // this.getSlideDetailWithID(_item.id);
      this.$emit("getSlideDetailWithID", _item.id);
    },
    selectApprovement() {
      if (!this.approvementCompleted) {
        this.setSlideApprove(true);
      }
      this.setSelectedSlideID("");
      this.setApprovementVisible(true);
      // this.getSlideDetailWithID(-1);
      this.$emit("getSlideDetailWithID", -1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
