<template>
  <DxDataGrid
    id="gridContainer2"
    :data-source="row"
    :columns="col[1]"
    key-expr="INDEX"
    :show-borders="true"
    :showRowLines="true"
    @cell-prepared="onCellPrepared"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :word-wrap-enabled="true"
  >
    <DxColumn data-field="Responsibility" />
    <!-- <DxColumn data-field="Current Version" /> -->
    <DxColumn
      data-field="Required Certifications"
      cell-template="certificationTemplate"
    />
    <DxColumn
      data-field="certification_progress"
      cell-template="readinessTemplate"
    />
    <DxColumn
      data-field="Acknowledged On"
      cell-template="acknowledgeOnTemplate"
    />
    <DxSelection mode="single" />
    <!-- <DxMasterDetail :enabled="true" template="detailTemplate" /> -->
    <template #certificationTemplate="{ data: rowData }">
      <div>
        <v-chip
          v-for="(chip, index) in rowData.data['Required Certifications']"
          :key="index"
          class="tags-sm"
          :color="colorCertificate(chip)"
          text-color="white"
        >
          {{ chip.certification_name ? chip.certification_name : "-" }}
        </v-chip>
      </div>
    </template>
    <template #readinessTemplate="{ data: rowData }">
      <BulletChartResponsibilities :barData="readinessValues(rowData.data)" />
      <!-- <div class="intro-progress-bar">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-progress-linear
              class="progress-bar-container"
              :value="rowData.data['certification_progress'] * 100"
              color="#A7D42E"
              height="16"
              v-bind="attrs"
              v-on="on"
            ></v-progress-linear>
          </template>
          <span>{{ rowData.data.readiness_description }}</span>
        </v-tooltip>
      </div> -->
    </template>
    <template #acknowledgeOnTemplate="{ data: rowData }">
      <div>
        <span v-if="rowData.data['Acknowledged On']">{{
          rowData.data["Acknowledged On"]
        }}</span>
        <v-btn
          v-else
          :disabled="act_as_me == false || disabled_button"
          depressed
          rounded
          color="#ddde00"
          class="btn-accept"
          @click.stop="onAcknowledgeResp(rowData.data)"
        >
          Acknowledge now
        </v-btn>
      </div>
    </template>
    <!-- <template #detailTemplate="{ data: rowData }">
      <ThirdGrid
        :cols="col[2]"
        :role_ids="rowData.data.role_ids"
      />
    </template> -->
  </DxDataGrid>
</template>
<script>
import { updateResponsibilityAcknowledgeDate } from "@/api";
import EditDate from "@/utils/edit_date_2.js";
import ThirdGrid from "@/components/assignments/ThirdGrid";
import BulletChartResponsibilities from "@/common/DevExtreme/BulletChartResponsibilities";
import DxDataGrid, {
  DxSelection,
  // DxMasterDetail,
  DxColumn,
} from "devextreme-vue/data-grid";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  components: {
    DxDataGrid,
    DxSelection,
    // DxMasterDetail,
    DxColumn,
    // ThirdGrid,
    BulletChartResponsibilities,
  },
  props: ["col", "row", "disabled_button"],
  data() {
    return {
      act_as_me: true,
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  methods: {
    ...mapActions(["getLoading"]),
    onCellPrepared(e) {
      if (e.rowType == "header" && e.columnIndex == 0) {
        e.cellElement.style.paddingLeft = "50px";
      } else if (e.rowType == "data" && e.columnIndex == 0) {
        if (e.row.cells[0].rowType == "data") {
          e.row.cells[0].cellElement.style.paddingLeft = "50px";
        }
      }
    },
    readinessValues(rowData) {
      let arrayBar = new Array();
      arrayBar.push({
        value: rowData.responsibility_certification_progress,
      });
      arrayBar.push({
        value: rowData.responsibility_proficiency_progress,
      });
      return arrayBar;
    },
    async onAcknowledgeResp(_row) {
      this.getLoading(true);
      try {
        const _today = new Date();
        const result = await updateResponsibilityAcknowledgeDate(
          _row.id,
          _today
        );
        if (result.status == 200) {
          _row["Acknowledged On"] = EditDate(_today);
        }
      } catch (error) {
        console.log("acceptRoleError==>", error);
      }
      this.getLoading(false);
    },
    colorCertificate(_cert) {
      if (_cert) {
        const _certificates = _cert.certificates;
        if (_certificates.length > 0) {
          for (const c of _certificates) {
            if (c.active_role) {
              if (c.prior_knowledge) {
                return "#d222a7";
              }
              return "#22b14c";
            }
          }
        }
      }
      return "#ed1c24";
    },
  },
};
</script>
<style scoped>
.adaptiveRowStyle {
  padding: 40px !important;
}
</style>
<style lang="scss" scoped>
.last-margin {
  padding-left: 28px;
  // padding-right: 28px;
}
.tags-sm {
  padding: 2px 14px;
  margin-right: 10px;
  // font-style: normal;
  font-family: Gilroy-Bold;
  font-weight: 700;
  font-size: 0.625rem !important;
  line-height: 12.38px;
}
.btn-accept {
  display: block;
  padding: 5px 14px;
  color: $white;
  border-radius: 30px;
  margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
  // font-size: 14px !important;
  // font-weight: 600;
  // line-height: 17px !important;
}
.progress-bar-container {
  margin-top: 14px;
  border-radius: 8px;
  .progress-text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: #323a44;
  }
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
