<template>
  <div class="slideQuizList">
    <!-- <p>{{ selectedSlideQuizList }}</p> -->
    <div
      v-for="i in slideQuizList.length"
      :key="i.index"
      class="slideQuizList_quiz"
    >
      <span class="quiz_question_text">
        {{ i }}. {{ slideQuizList[i - 1].question }}
      </span>
      <span
        v-if="slideQuizList[i - 1].allow_multiple_answers"
        class="quiz_option_text"
        >{{
          slideQuizList[i - 1].allow_multiple_answers
            ? "Multiple answers might be correct"
            : ""
        }}</span
      >
      <div
        class="quiz_answer"
        v-for="answer in slideQuizList[i - 1].answers"
        :key="answer.id"
        :style="{ background: answer.quizBGColor }"
      >
        <v-checkbox
          v-model="selectedSlideQuizList[i - 1]"
          :label="answer.text_value"
          :value="answer.id"
          :color="checkboxColor"
          class="quiz_checkbox"
          :multiple="slideQuizList[i - 1].allow_multiple_answers"
          @change="changedQuizAnswers"
        ></v-checkbox>
      </div>
    </div>
    <div class="quiz_submit">
      <span
        class="quiz_submit_text"
        v-if="!slideChecked"
        @click="quizAnswersSubmit"
      >
        Check Your Answers
      </span>
      <span class="quiz_answer_alert" v-if="quizAlert"
        >All questions must be answered</span
      >
      <span class="quiz_answer_alert" v-if="quizFailed"
        >All correct answers must be selected</span
      >
      <span class="quiz_answer_alert" v-if="quizCompleted"
        >Quiz already completed</span
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
const takeCourseHelper = createNamespacedHelpers("takeCourse");
const { mapActions } = createNamespacedHelpers("isLoading");
import { submittedQuizAnswers } from "@/api";
export default {
  data() {
    return {
      checkboxColor: "#7F868E",
      quizAlert: false,
      quizFailed: false,
      quizCompleted: false,
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters([
      "slideChecked",
      "selectedSlideID",
      "selectedSlideQuizList",
      "slideQuizList",
    ]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...takeCourseHelper.mapMutations(["setSlideQuizList"]),
    changedQuizAnswers() {
      this.quizFailed = false;
      this.quizAlert = false;
    },
    async quizAnswersSubmit() {
      if (this.selectedSlideQuizList.length == this.slideQuizList.length) {
        this.quizAlert = false;
        for (
          let index = 0;
          index < this.selectedSlideQuizList.length;
          index++
        ) {
          if (
            !this.selectedSlideQuizList[index] ||
            (typeof this.selectedSlideQuizList[index] !== "string" &&
              this.selectedSlideQuizList[index].length == 0)
          ) {
            this.quizAlert = true;
            break;
          }
        }
        if (!this.quizAlert) {
          let st_selectedSlideQuizList = _.flatten(
            this.selectedSlideQuizList
          ).join(",");

          try {
            this.getLoading(true);
            let result = await submittedQuizAnswers(
              this.selectedSlideID,
              st_selectedSlideQuizList
            );
            if (result.status == 200) {
              // console.log("quiz_result==>", result.data.data.response);
              let submittedQuizAnswersResult = result.data.data.response;
              console.log(result);
              this.getQuizAnswerResult(submittedQuizAnswersResult);
            }
          } catch (error) {
            console.log("submittedQuizAnswersError==>", error);
            this.getLoading(false);
          }
        }
      } else {
        this.quizAlert = true;
      }
    },
    getQuizAnswerResult(_result) {
      console.log(_result);
      if (_result.error == "slide_quiz_done") {
        this.quizCompleted = true;
      } else if (!_result.slide_completed) {
        this.quizFailed = true;
        let slideQuizList_copy = JSON.parse(JSON.stringify(this.slideQuizList));
        slideQuizList_copy.forEach((quiz) => {
          quiz.answers.forEach((answer) => {
            _result.badAnswers.forEach((bad) => {
              if (bad == answer.id) {
                answer.quizBGColor = "#FFD3D3";
              }
            });
            _result.goodAnswers.forEach((good) => {
              if (good == answer.id) {
                answer.quizBGColor = "#d3e996";
              }
            });
          });
        });
        this.setSlideQuizList(slideQuizList_copy);
        this.getLoading(false);
      } else {
        this.$emit("setCheckedCourseItem");
      }
    },
  },
};
</script>
