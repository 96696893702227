import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getResponsibilitiesTrackedByMe = (employee_id) => {
  let result = axios()({
    url: `${API.GETTRACKERS}/`,
    method: "patch",
    params: {
      action: "get_trackers",
      employee_id: employee_id || localStorage.getItem("employee_id"),
    },
  });
  return result;
};
export const createTracker = ({ ...params }) => {
  let result = axios()({
    url: `${API.RESPONSIBILITYTRACKER}/`,
    method: "patch",
    params: {
      action: "add_tracker",
      vals: {
        employee_id: params.employee_id,
        responsibility_id: params.responsibility_id,
        coach_id: params.coach_id
          ? params.coach_id
          : localStorage.getItem("employee_id"),
        situation_leadership: params.situation_leadership,
      },
    },
  });
  return result;
};
export const editTracker = ({ ...params }) => {
  let result = axios()({
    url: `${API.RESPONSIBILITYTRACKER}/${params.tracker_id}`,
    method: "put",
    params: {
      situation_leadership: params.coaching_id,
    },
  });
  return result;
};
export const updateTracker = (params) => {
  let result = axios()({
    url: `${API.RESPONSIBILITYTRACKER}/`,
    method: "patch",
    params: {
      action: "update_tracker",
      id: params.tracker_id,
      vals: {
        situation_leadership: params.situation_leadership,
      },
    },
  });
  return result;
};
export const deleteTracker = (tracker_id) => {
  let result = axios()({
    url: `${API.RESPONSIBILITYTRACKER}/${tracker_id}`,
    method: "delete",
  });
  return result;
};
export const terminateTracker = (tracker_id) => {
  let result = axios()({
    url: `${API.RESPONSIBILITYTRACKER}/`,
    method: "patch",
    params: {
      action: "action_delete",
      id: tracker_id,
    },
  });
  return result;
};

export const removeBadge = (employee_resp, reasonToRemove) => {
  let result = axios()({
    url: `${API.EMPLOYEERESPONSIBILITY}/`,
    method: "patch",
    params: {
      action: "remove_proficiency_badge",
      employee_resp: employee_resp,
      reason: reasonToRemove
    },
  });
  return result;
};

export const updatesBadge = (params) => {
  let result = axios()({
    url: `${API.EMPLOYEERESPONSIBILITY}/`,
    method: "patch",
    params: params,
  });
  return result;
};
