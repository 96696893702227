import axios from "./_axios";
import { API } from "./apiEndPoints";

export const checkedSlideCompleted = (_slideID) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: {
      action: "slide_set_completed",
      slide_id: _slideID,
      include_unpublished: 1,
    },
  });
  return result;
};
export const checkedSlideViewed = (_slideID) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: {
      action: "slide_set_viewed",
      slide_id: _slideID,
    },
  });
  return result;
};
export const submittedQuizAnswers = (_slideID, _answers) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: { action: "finish_quiz", slide_id: _slideID, answer_ids: _answers },
  });
  return result;
};
export const getSlideDetail = (_slideID) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: { action: "get_slide_detail", slide_id: _slideID },
  });
  return result;
};
