<template>
  <div>
    <v-dialog
      v-model="dialog"
      content-class="dialog"
      persistent
      scrollable
      fullscreen
      :hide-overlay="!dialog"
      overlay-opacity="0.46"
      overlay-color="#212121"
    >
      <v-card class="card">
        <v-card-title class="headline">
          <span class="title-text">{{ title_top }}</span>
        </v-card-title>
        <div class="form-wrapper">
          <v-card
            v-if="courseDetail"
            class="overflow-y-auto taking-course-scroll"
          >
            <div v-if="showDescription && showDescriptionDirectly">
              <div class="header-crs">
                <div class="btn-exit-crs" @click="exitCourseDialog">
                  <span class="text-exit-crs">Exit</span>
                  <div class="btn-exit-arrow-crs"></div>
                </div>
              </div>
              <ElearningDescription
                :courseDetail="courseDetail"
                @setEnrollCourse="setEnrollCourse"
                @setStartCourse="setStartCourse"
              />
            </div>
            <CourseMain
              v-else
              class="take-course-modal"
              :courseDetail="courseDetail"
              :task_id="task_id"
              @exitCourse="exitCourseDialog"
              @completedCourse="completedCourse"
            />
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CourseMain from "@/components/take-course/CourseMain";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const taskHelper = createNamespacedHelpers("tasks");
import { uploadedHeadshot, getCourses, getCourseDetailWithID } from "@/api";
import ElearningDescription from "@/components/course/ElearningDescription";
import { enrollingCourse, getCourseDetailWithIDUnpublished } from "@/api";

export default {
  name: "CoursePopup",
  props: {
    openDialog: {
      type: Boolean,
      required: true,
    },
    course_id: {
      type: Number,
      required: true,
    },
    task_id: {
      type: Number,
      required: false,
    },
    showDescriptionDirectly: {
      type: Boolean,
      default: true,
      required: false,
    },
    title_top: {
      type: String,
      default: "Please complete this task",
      required: false,
    },
  },
  components: {
    CourseMain,
    ElearningDescription,
  },
  data() {
    return {
      act_as_me: true,
      dialog: false,
      showDescription: true,
      courseDetail: null,
    };
  },
  async created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    if (this.openDialog) {
      this.dialog = true;
    }
    if (!this.showDescriptionDirectly) {
      this.showDescription = false;
    }
    await this.getDataCourse();
  },
  watch: {
    openDialog: function (_val, _oldVal) {
      this.dialog = _val;
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...taskHelper.mapMutations(["setTasksNoIntro"]),
    ...coursePresentationHelper.mapMutations(["setCourseButtonStart"]),
    async getDataCourse() {
      try {
        this.getLoading(true);
        let result = await getCourseDetailWithIDUnpublished(this.course_id);
        if (result.status == 200) {
          const response = result.data.data.response[0];
          this.courseDetail = response;
        }
      } catch (error) {
        console.log("getCourseToApproveError===>", error);
      } finally {
        this.getLoading(false);
      }
    },
    exitCourseDialog() {
      this.dialog = false;
      if (this.openDialog) this.$emit("exit");
    },
    async completedCourse() {
      this.setTasksNoIntro([]);
      this.$emit("completedCourse");
      this.dialog = false;
      if (this.openDialog) this.$emit("exit");
    },
    async setEnrollCourse() {
      try {
        let result = await enrollingCourse(this.courseDetail.id);
        if (result.status == 200) {
          this.getDataCourse();
        }
      } catch (error) {
        console.log("enrollError=>", error);
      }
    },
    setStartCourse() {
      this.showDescription = false;
    },
  },
};
</script>
<style lang="scss">
//@import "~@styles/variables/_variables.scss";
.take-course-modal {
  height: 90vh;
  padding: 20px 20px 40px 20px;
  margin: 0 !important;
}
.btn {
  height: 40px;
}
.btn_txt {
  .v-btn__content {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.0625rem;
    color: white;
    text-transform: capitalize;
    position: absolute;
    letter-spacing: normal;
  }
}
.dialog.v-dialog--fullscreen {
  top: auto;
  left: auto;
  width: 85%;
  height: 95%;
}
.v-dialog.dialog {
  position: absolute;
  // margin: 80px;
  // //height: calc(100% - 160px);
  // border-radius: $default-br;
  // width: 85%;

  .v-card__title.headline {
    padding: 20px 30px;
    justify-content: center;
    background-color: #53a0de;

    .icon--close {
      margin-right: 8px;
    }
  }
  .card {
    height: 100%;
    overflow: hidden;

    .title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $white;
    }

    .form-wrapper {
      height: 100%;

      .popup-form-wrapper {
        padding: 20px 30px 10px 30px;
      }

      .headshot-wrapper {
        height: 100%;
      }

      .form {
        margin-bottom: 24px;
        background: #e5e5e5;
        &.fit-form {
          width: fit-content;
        }
      }

      .icon {
        margin-left: 10px;
      }
      .taking-course-scroll {
        height: 100%;
      }
    }
  }
}
.header-crs {
  margin: 20px;
  background: #a7d42e;
  border-radius: 30px;
  // width: 100%;
  padding: 15px 60px;
  display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  // align-content: center;
  .btn-exit-crs {
    border: 1px solid #323a44;
    box-sizing: border-box;
    border-radius: 36px;
    padding: 15px 20px 15px 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    .text-exit-crs {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #323a44;
    }
    .btn-exit-arrow-crs {
      margin-left: 14px;
      background-image: url("~@/assets/img/btn_exit_arrow.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 16px;
      height: 7.75px;
    }
  }
}
@media screen and (max-width: 700px) {
  .v-dialog.dialog {
    margin: 0 10px;
  }
}
@media screen and (max-width: 450px) {
  .v-card__title.headline {
    padding: 20px 10px !important;
    .icon--close {
      margin-right: 2px !important;
    }
  }
}
</style>
