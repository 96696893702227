const FieldsData = (
  type = "char",
  name = "",
  label = "",
  placeholder = "",
  size = 12,
  list = [],
  action = "",
  value = ""
) => {
  // char, integer, float, many2one, many2many, date, long_text
  let field_data = {
    type: type,
    name: name,
    label: label,
    placeholder: placeholder,
    size: size,
    list: list,
    action: action,
    value: value,
    searchInput: null,
    datePickerOpen: false,
  };
  return field_data;
};
export default FieldsData;
