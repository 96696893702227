<template>
  <div class="text-center">
    <v-dialog v-model="dialog" content-class="dialog" persistent scrollable>
      <v-card class="card">
        <v-card-title class="headline">
          <base-btn :onClick="closeDialog">
            <v-icon class="icon--close">$close</v-icon>
            Close
          </base-btn>
          <span class="title-text">Ticket {{ dialogInfo.reference }}</span>
        </v-card-title>

        <div class="form-wrapper">
          <div class="popup-form-wrapper">
            <div class="principal">
              <div class="ticket-description">
                <p class="weight-400 size-16" style="line-height: 19.41px">
                  Ticket {{ dialogInfo.reference }}
                </p>
                <br />
                <p class="weight-700 size-24" style="line-height: 29.71px">
                  {{ dialogInfo.summary }}
                </p>
                <br />
                <p class="weight-600 size-16" style="line-height: 19.41px">
                  Description
                </p>
                <br />
                <div class="container-outline">
                  <span
                    class="weight-400 size-14"
                    style="line-height: 16.98px"
                    >{{ dialogInfo.description }}</span
                  >
                </div>
              </div>
            </div>

            <div class="side-box">
              <div class="ticket-status">
                <p class="weight-600 size-16">Status</p>
                <p class="weight-400 size-14">{{ dialogInfo.status }}</p>
                <p class="weight-600 size-16">Severity Level</p>
                <p class="weight-400 size-14">
                  {{ dialogInfo["severity level"] }}
                </p>
                <p class="weight-600 size-16">Assignee</p>
                <p class="weight-400 size-14">{{ dialogInfo.assignee }}</p>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import BaseBtn from "@/components/tasks/BaseBtn";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  props: ["openDialog", "dialogInfo"],
  components: {
    BaseBtn,
  },
  created() {
    this.getLoading(false);
    this.dialog = this.openDialog;
    console.log(this.dialog);
  },
  computed: {
    dialog: {
      get() {
        return this.openDialog;
      },
      set(value) {
        this.$emit("input");
      },
    },
  },
  data() {
    return {
      // dialog: false,
    };
  },
  methods: {
    ...mapActions(["getLoading"]),
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog.dialog {
  margin: 80px;
  // height: calc(100% - 160px);
  border-radius: 30px;

  .card {
    height: 100%;
    overflow: hidden;
    .v-card__title.headline {
      padding: 20px 30px;
      justify-content: space-between;
      background-color: #53a0de;

      .icon--close {
        margin-right: 8px;
      }
    }
    .title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $white;
      margin: 0 auto;
    }
    .form-wrapper {
      height: calc(100% - 64px);
      .popup-form-wrapper {
        padding: 47px 209px 60px 209px;
        background: white;
        display: flex;
        column-gap: 42px;
        .principal {
          flex-grow: 3;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          .ticket-description {
            .container-outline {
              border: 1px solid $light-gray-2;
              border-radius: 16px;
              padding: 20px;
            }
          }
        }
        .side-box {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          .ticket-status {
            background: $light-gray-3;
            border-radius: 16px;
            padding: 24px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1378px) {
  .popup-form-wrapper {
    flex-direction: column;
    column-gap: 0px;
    padding: 47px 50px 60px 50px !important;
  }
}
@media screen and (max-width: 700px) {
  .v-dialog__content {
    ::v-deep {
      .v-dialog {
        margin: 0 10px !important;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .v-card__title.headline {
    padding: 20px 10px !important;
    justify-content: start;
    background-color: #53a0de;
    .icon--close {
      margin-right: 2px !important;
    }
  }
  .popup-form-wrapper {
    padding: 20px !important;
  }
}
</style>
