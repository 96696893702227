<template>
  <div class="table1-container">
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows"
      :columns="cols"
      key-expr="id"
      :show-borders="true"
      :showRowLines="true"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :onRowClick="selectionChanged"
    >
      <DxColumn data-field="type" />
      <DxColumn data-field="name" />
      <DxColumn data-field="deadline" cell-template="deadlineTemplate" />
      <DxColumn data-field="credits" />
      <DxColumn data-field="status" />
      <DxColumn data-field="started on" cell-template="startedOnTemplate" />
      <DxColumn data-field="progress bar" cell-template="progressBarTemplate" />
      <DxColumn data-field="completed on" cell-template="completedOnTemplate" />
      <DxColumn
        data-field="expiration date"
        cell-template="expirationTemplate"
      />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #deadlineTemplate="{ data: rowData }">
        <span
          :class="
            rowData.data['deadline'] == 'Today'
              ? 'txt_orange'
              : rowData.data['deadline'] == 'Overdue'
              ? 'txt_red'
              : ''
          "
          >{{ rowData.data["deadline"] }}</span
        >
      </template>
      <template #startedOnTemplate="{ data: rowData }">
        <span
          :class="rowData.data['started on'] == 'Today' ? 'txt_orange' : ''"
          >{{ rowData.data["started on"] }}</span
        >
      </template>
      <template #completedOnTemplate="{ data: rowData }">
        <span
          :class="rowData.data['completed on'] == 'Today' ? 'txt_orange' : ''"
          >{{ rowData.data["completed on"] }}</span
        >
      </template>
      <template #progressBarTemplate="{ data: rowData }">
        <div class="btn_container">
          <v-progress-linear
            class="progress-bar-container"
            :value="rowData.data['progress bar']"
            :color="
              rowData.data['progress bar'] == 100 ? color_green : color_yellow
            "
            height="16"
          >
            <strong class="progress-text">
              {{
                rowData.data["progress bar"] ? rowData.data["progress bar"] : 0
              }}%
            </strong>
          </v-progress-linear>
        </div>
      </template>
      <template #expirationTemplate="{ data: rowData }">
        <span
          :class="
            rowData.data['expiration date'] == 'Today'
              ? 'txt_orange'
              : rowData.data['expiration date'] == 'Overdue'
              ? 'txt_red'
              : ''
          "
          >{{ rowData.data["expiration date"] }}</span
        >
      </template>
    </DxDataGrid>
    <v-menu
      v-model="menu_visible"
      absolute
      offset-y
      :nudge-width="menuContentWidth"
      :position-y="menu_position_y"
      :position-x="menu_position_x"
      :close-on-content-click="false"
      rounded="xl"
    >
      <v-card>
        <div class="menu-content">
          <div class="events-detail">
            <div class="detail-1">
              <div
                class="img-event"
                :style="selectedRow.bg ? selectedRow.bg : ''"
                :class="{ noEventImg: !selectedRow.bg }"
              ></div>
              <div class="detail-info">
                <span class="txt_name">{{ selectedRow.name }}</span>
                <span class="txt_type">{{ selectedRow.type }}</span>
                <span class="txt_credits">{{ selectedRow.credits }} XP</span>
              </div>
            </div>
            <div class="detail-2">
              <div class="detail-txt">
                <span class="txt-detail-name">Start Date: </span>
                <span class="txt-detail-info">{{
                  selectedRow["started on"]
                }}</span>
              </div>
              <div class="detail-txt">
                <span class="txt-detail-name">Due Date: </span>
                <span class="txt-detail-info">{{
                  selectedRow["expiration date"]
                }}</span>
              </div>
              <div class="detail-txt">
                <span class="txt-detail-name">Finish Date: </span>
                <span class="txt-detail-info">{{ selectedRow.deadline }}</span>
              </div>
            </div>
          </div>
          <div class="events-status">
            <div class="progress">
              <div v-if="selectedRow['started on'] == '-'">
                <span class="txt_pending">Pending Start</span>
              </div>

              <div v-else-if="selectedRow['progress bar'] == 100">
                <span class="txt_completed">Completed successfully!</span>
              </div>
              <div v-else>
                <span class="txt_progress">Event Progress</span>
                <div class="progress-bar">
                  <v-progress-linear
                    class="progress-bar-container"
                    v-model="selectedRow['progress bar']"
                    :color="color_green"
                    height="16"
                    ><strong class="progress-text"
                      >{{ selectedRow["progress bar"] }}%</strong
                    ></v-progress-linear
                  >
                </div>
              </div>
            </div>
            <span
              class="txt_gotoEvent"
              @click="goToEvent(selectedRow)"
              v-if="
                (selectedRow.channel_id != false &&
                  selectedRow.type == 'eLearning') ||
                selectedRow.type == 'Book'
              "
            >
              Go to Event
            </span>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
import DxDataGrid, {
  DxSelection,
  DxColumn,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxColumn,
    DxPager,
    DxPaging,
  },
  props: ["cols", "rows", "courses"],
  data() {
    return {
      color_green: "#A7D42E",
      color_yellow: "#ddde00",
      selectedRow: {},
      selectedId: 0,
      menu_visible: false,
      menuContentWidth: 760,
      menu_position_x: 0,
      menu_position_y: 0,
    };
  },
  methods: {
    ...coursePresentationHelper.mapActions(["dispatchCourseDetail"]),
    selectionChanged(e) {
      this.menu_visible = true;
      this.selectedId = e.key;
      this.selectedRow = {};
      this.selectedRow = e.data;
      const row_rect = e.rowElement.getBoundingClientRect();
      this.menu_position_y = row_rect.y + 50;
      this.menu_position_x = window.innerWidth / 2 - this.menuContentWidth / 2;
    },
    goToEvent(_row) {
      let url = "";
      if (_row.type == "eLearning") {
        for (let index = 0; index < this.courses.length; index++) {
          const course = this.courses[index];
          if (course.id == _row.channel_id) {
            this.dispatchCourseDetail(course);
            url = `/course-presentation?${course.title}`;
            this.$router.push(url);
            break;
          }
        }
      } else if (_row.type == "Book") {
        url = `/book-presentation?${_row.name}`;
        this.$router.push(url);
      }
    },
  },
};
</script>
<style lang="scss">
// assets/components/dev-express-grid.scss
.table1-container {
  padding: 40px;
}
.txt_orange {
  color: #f2994a !important;
}
.txt_red {
  color: #ff6c6c !important;
}
.txt_green {
  color: #a7d42e !important;
}
.txt_grey {
  color: #8a929b !important;
}
.txt_pending {
  color: #800080 !important;
}
.txt_refused {
  color: #c205c5 !important;
}
.btn_container {
  display: flex;
  justify-content: center;
  .btn_txt {
    .v-btn__content {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.0625rem;
      color: white;
      text-transform: capitalize;
    }
  }
}
.progress-bar-container {
  margin-top: 14px;
  border-radius: 8px;
  .progress-text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: #323a44;
  }
}
.menu-content {
  padding: 40px;
  display: flex;
  flex-flow: column;
  .events-detail {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .detail-1 {
      display: flex;
      flex-flow: row;
      .img-event {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .txt_type {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          margin-bottom: 12px;
          text-transform: capitalize;
        }
        .txt_credits {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          color: #53a0de;
          padding: 0px 10px;
          border: solid 1px #53a0de;
          border-radius: 15px;
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 360px;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
    .txt_gotoEvent {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      padding: 15px 40px;
      border-radius: 30px;
      background-color: #a7d42e;
      border: solid 1px #a7d42e;
      cursor: pointer;
    }
  }
}
</style>
