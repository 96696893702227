<template src="vue2-simplert-core/simplert.html"></template>

<script>
import simplertMixin from "vue2-simplert-core";

export default {
  name: "Simplert",
  mixins: [simplertMixin],
};
</script>

<style src="vue2-simplert-core/simplert.css"></style>
