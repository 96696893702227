<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div class="parent">
        <div
          class="blue-chart"
          :style="{
            color: blue,
            'background-color': blue,
            width: `${barProgress}%`,
            height: '6px',
          }"
          v-bind="attrs"
          v-on="on"
        ></div>
      </div>
    </template>
    <span>{{ Caption }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    countMissing: {
      type: Number,
      default: 0,
    },
    barProgress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      blue: "#1c74e2",
    };
  },
  computed: {
    Caption() {
      if (this.countMissing == 1)
        return `Your certification Level (${this.barProgress}% with ${this.countMissing} missing certification)`;
      return `Your certification Level (${this.barProgress}% with ${this.countMissing} missing certifications)`;
    },
  },
};
</script>
<style lang="scss" scoped>
.parent {
  display: flex;
  position: relative;
  top: 11px;
}
.blue-chart {
  position: relative;
  z-index: 2;
  border-radius: 3px;
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  white-space: nowrap !important;
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
