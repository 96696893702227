<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="page_router">
            <span
              v-if="isFromManageOther"
              class="txt_router text_redirect"
              @click="
                redirect(
                  responsibility_data.fields
                    ? '/manage-others/direct-reports'
                    : '/manage-others/coaching'
                )
              "
              >Managing others</span
            >
            <div v-if="isFromManageOther" class="img_arrow"></div>
            <span
              class="txt_router text_redirect"
              @click="redirect('/readiness')"
              >{{ pronounEmployee }}
              {{ responsibility_data.fields ? "Readiness" : "" }}</span
            >
            <div class="img_arrow"></div>
            <div class="txt_router">
              <!-- {{ pronounEmployee }} Inspect Proficiency -->
              Proficiency for responsibility {{ proficiencyName }}
            </div>
          </div>
          <div class="main_section__header">
            <h1 class="main_section-title">
              <!-- {{ pronounEmployee }} Inspect Proficiency -->
            </h1>
          </div>
          <div class="main_section__content">
            <div>
              <div class="eventsTable">
                <DxTabPanel
                  :data-source="Proficiency"
                  v-model="selectedIndex"
                  :loop="loop"
                  :animation-enabled="animationEnabled"
                  :swipe-enabled="swipeEnabled"
                >
                  <template #title="{ data: proficiency }">
                    <span>{{ proficiency.title }}</span>
                  </template>
                  <template #item="{ data: proficiency }">
                    <div class="tabpanel-item">
                      <template v-if="proficiency.ID == 1">
                        <v-checkbox
                          v-model="notPassed"
                          @click="notPassedCheckpoints()"
                          label="Show only not passed"
                        ></v-checkbox>
                        <div
                          v-if="
                            (!isFromManageOther && isSuperExpert) ||
                            (isFromManageOther && inspectUserIsSuperExpert)
                          "
                          class="lightred-bg"
                        >
                          {{
                            !isFromManageOther
                              ? "you are"
                              : pronounEmployee + " is"
                          }}
                          super expert of this responsibility and are not
                          required to pass checkpoints
                        </div>
                        <div
                          v-if="
                            isFromManageOther &&
                            !(isExpert || isSuperExpert) &&
                            !inspectUserIsSuperExpert
                          "
                          class="lightyellow-bg"
                        >
                          Because you are not an expert of this responsibility,
                          you are not allowed to evaluate its checkpoints
                        </div>
                        <div
                          v-else-if="
                            !isFromManageOther && !isSuperExpert && isExpert
                          "
                          class="lightyellow-bg"
                        >
                          You cannot evaluate your own checkpoints
                        </div>
                        <div
                          v-else-if="
                            !isFromManageOther && !isSuperExpert && !isExpert
                          "
                          class="lightyellow-bg"
                        >
                          You are not an expert for this responsibility and you
                          are looking at yourself. You can only see what others
                          evaluated for you
                        </div>
                        <div
                          v-if="
                            !isSuperExpert ||
                            (isFromManageOther && !inspectUserIsSuperExpert)
                          "
                          class="progress_bar"
                        >
                          <v-row style="align-items: center">
                            <v-col class="col-lg-1 col-sm-2">
                              <div
                                :style="{
                                  'border-left': `30px solid ${colorCheckpoints(
                                    'apprentice'
                                  )}`,
                                  paddingLeft: '5px',
                                }"
                              >
                                Apprentice
                              </div>
                            </v-col>
                            <v-col class="col-lg-8 col-sm-6">
                              <div class="progress_section" style="width: 100%">
                                <ChildBulletChartWidget
                                  :barData="
                                    progressBarData.apprentice
                                      ? progressBarData.apprentice
                                      : progressBarData.undefined
                                  "
                                ></ChildBulletChartWidget>
                              </div>
                            </v-col>
                            <v-col
                              class="col-lg-3 col-sm-4"
                              v-if="
                                isFromManageOther && (isExpert || isSuperExpert)
                              "
                            >
                              <v-row>
                                <v-col
                                  col="6"
                                  v-if="
                                    progressbar_stacked_values['apprentice']
                                      ?.has_badge
                                  "
                                >
                                  <div
                                    class="d-flex position-relative tempate_bg"
                                    v-on:dblclick="onDoubleClick()"
                                  >
                                    <!-- <div class="d-flex position-relative">
                                      <div
                                        :style="{
                                          'border-left': `10px solid ${colorCheckpoints(
                                            'Apprentice'
                                          )}`,
                                        }"
                                      ></div>
                                      <div class="template-img">
                                        <img
                                          src="../assets/img/badgeIcon.png"
                                        />
                                      </div>
                                    </div>
                                    <div class="d-flex align-center">
                                      <div class="badge_text">Apprentice</div>
                                      <div>
                                        <v-btn
                                          depressed
                                          rounded
                                          icon
                                          color="#000000"
                                          @click="removeDialog()"
                                        >
                                          <v-icon size="20">
                                            $deleteBlack
                                          </v-icon>
                                        </v-btn>
                                      </div>
                                    </div> -->
                                    <chipComponent
                                      :chipData="getBadgeChipData('Apprentice')"
                                      @onClickClose="removeDialog()"
                                    ></chipComponent>
                                  </div>
                                </v-col>
                                <v-col col="6">
                                  <v-btn
                                    @click="onGrant('apprentice')"
                                    class="grant-button"
                                    v-if="
                                      progressbar_stacked_values['apprentice']
                                        ?.can_grant
                                    "
                                    :width="100 + 'px'"
                                    >Grant</v-btn
                                  >
                                </v-col>
                                <!-- <v-col col="6" class="target-text">
                                  <span
                                    v-if="
                                      badge.target == 'apprentice' &&
                                      !progressbar_stacked_values['apprentice']
                                        ?.has_badge
                                    "
                                  >
                                    &lt; Target
                                  </span>
                                </v-col> -->
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row style="align-items: center">
                            <v-col class="col-lg-1 col-sm-2">
                              <div
                                :style="{
                                  'border-left': `30px solid ${colorCheckpoints(
                                    'professional'
                                  )}`,
                                  paddingLeft: '5px',
                                }"
                              >
                                Professional
                              </div>
                            </v-col>
                            <v-col class="col-lg-8 col-sm-6">
                              <div class="progress_section" style="width: 100%">
                                <ChildBulletChartWidget
                                  :barData="
                                    progressBarData.professional
                                      ? progressBarData.professional
                                      : progressBarData.undefined
                                  "
                                ></ChildBulletChartWidget>
                              </div>
                            </v-col>

                            <v-col
                              class="col-lg-3 col-sm-4"
                              v-if="
                                isFromManageOther && (isExpert || isSuperExpert)
                              "
                            >
                              <v-row>
                                <v-col
                                  col="6"
                                  v-if="
                                    progressbar_stacked_values['professional']
                                      ?.has_badge
                                  "
                                >
                                  <div
                                    class="d-flex position-relative tempate_bg"
                                    v-on:dblclick="onDoubleClick()"
                                  >
                                    <!-- <div class="d-flex position-relative">
                                      <div
                                        :style="{
                                          'border-left': `10px solid ${colorCheckpoints(
                                            'Professional'
                                          )}`,
                                        }"
                                      ></div>
                                      <div class="template-img">
                                        <img
                                          src="../assets/img/badgeIcon.png"
                                        />
                                      </div>
                                      
                                    </div>
                                    <div class="d-flex align-center">
                                      <div class="badge_text">Professional</div>
                                      <div>
                                        <v-btn
                                          depressed
                                          rounded
                                          icon
                                          color="#000000"
                                          @click="removeDialog()"
                                        >
                                          <v-icon size="20">
                                            $deleteBlack
                                          </v-icon>
                                        </v-btn>
                                      </div>
                                    </div> -->
                                    <chipComponent
                                      :chipData="
                                        getBadgeChipData('Professional')
                                      "
                                      @onClickClose="removeDialog()"
                                    ></chipComponent>
                                  </div>
                                </v-col>
                                <v-col col="6">
                                  <v-btn
                                    @click="onGrant('professional')"
                                    class="grant-button"
                                    v-if="
                                      progressbar_stacked_values['professional']
                                        ?.can_grant
                                    "
                                    :width="100 + 'px'"
                                    >Grant</v-btn
                                  >
                                </v-col>
                                <!-- <v-col class="target-text">
                                  <span
                                    v-if="
                                      badge.target == 'professional' &&
                                      !progressbar_stacked_values[
                                        'professional'
                                      ]?.has_badge
                                    "
                                  >
                                    &lt; Target
                                  </span>
                                </v-col> -->
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row style="align-items: center">
                            <v-col class="col-lg-1 col-sm-2">
                              <div
                                :style="{
                                  'border-left': `30px solid ${colorCheckpoints(
                                    'expert'
                                  )}`,
                                  paddingLeft: '5px',
                                }"
                              >
                                Expert
                              </div>
                            </v-col>
                            <v-col class="col-lg-8 col-sm-6">
                              <div class="progress_section" style="width: 100%">
                                <ChildBulletChartWidget
                                  :barData="
                                    progressBarData.expert
                                      ? progressBarData.expert
                                      : progressBarData.undefined
                                  "
                                ></ChildBulletChartWidget>
                              </div>
                            </v-col>
                            <v-col
                              class="col-lg-3 col-sm-4"
                              v-if="
                                isFromManageOther && (isExpert || isSuperExpert)
                              "
                            >
                              <v-row>
                                <v-col
                                  col="6"
                                  v-if="
                                    progressbar_stacked_values['expert']
                                      ?.has_badge
                                  "
                                >
                                  <div
                                    class="d-flex position-relative tempate_bg"
                                    v-on:dblclick="onDoubleClick()"
                                  >
                                    <!-- <div
                                        :style="{
                                          'border-left': `10px solid ${colorCheckpoints(
                                            'Expert'
                                          )}`,
                                        }"
                                      ></div>
                                      <div class="template-img">
                                        <img
                                          src="../assets/img/badgeIcon.png"
                                        />
                                      </div> -->
                                    <chipComponent
                                      :chipData="getBadgeChipData('Expert')"
                                      @onClickClose="removeDialog()"
                                    ></chipComponent>
                                    <!-- <div class="d-flex align-center">
                                      <div class="badge_text">Expert</div>
                                      <div>
                                        <v-btn
                                          depressed
                                          rounded
                                          icon
                                          color="#000000"
                                          @click="removeDialog()"
                                        >
                                          <v-icon size="20">
                                            $deleteBlack
                                          </v-icon>
                                        </v-btn>
                                      </div>
                                    </div> -->
                                  </div>
                                </v-col>
                                <v-col col="6">
                                  <v-btn
                                    @click="onGrant('expert')"
                                    class="d-flex position-relative grant-button"
                                    v-if="
                                      progressbar_stacked_values['expert']
                                        ?.can_grant
                                    "
                                    :width="100 + 'px'"
                                    >Grant</v-btn
                                  >
                                </v-col>
                                <!-- <v-col col="6" class="target-text">
                                  <span
                                    v-if="
                                      badge.target == 'expert' &&
                                      !progressbar_stacked_values['expert']
                                        ?.has_badge
                                    "
                                  >
                                    &lt; Target</span
                                  ></v-col
                                > -->
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="!inspectUserIsSuperExpert">
                          <div v-if="!isSuperExpert || isFromManageOther">
                            <DxDataGrid
                              id="gridContainer"
                              :ref="gridRefName"
                              key-expr="checkpoint_id"
                              :data-source="filteredCheckpoints"
                              :columns="column_names"
                              :column-auto-width="true"
                              :show-borders="true"
                              :showRowLines="true"
                            >
                              <DxScrolling mode="virtual" />
                              <DxSorting mode="multiple" />
                              <DxColumn
                                caption="checkpoints"
                                data-field="criteria"
                                cell-template="checkpoints"
                                :calculate-sort-value="valueBadgeAndsequence"
                                :sorting-method="sortBadgeAndsequence"
                                sort-order="asc"
                              />
                              <template #checkpoints="{ data: rowData }">
                                <div class="row">
                                  <div
                                    :style="{
                                      'border-left': `30px solid ${colorCheckpoints(
                                        rowData.data['badge_type']
                                      )}`,
                                      paddingLeft: '5px',
                                      justifyContent: 'center',
                                    }"
                                    class="col-7"
                                  >
                                    <v-list-item>
                                      <v-list-item-content>
                                        <div
                                          class="text-block"
                                          :style="{
                                            color: `${statusColor(
                                              rowData.data['evaluation_state']
                                            )}`,
                                            fontWeight:
                                              rowData.data[
                                                'evaluation_state'
                                              ] == false
                                                ? 'bold'
                                                : 'normal',
                                          }"
                                        >
                                          {{ rowData.data.criteria }}
                                          ({{
                                            rowData.data.instructions
                                              ? rowData.data.instructions
                                              : "N/A"
                                          }})
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </div>
                                  <div class="col-5">
                                    <div class="row checkPoint-status">
                                      <div class="col-3 button-group">
                                        <!-- Passed check icon -->
                                        <button
                                          class="btn"
                                          v-if="
                                            isFromManageOther &&
                                            (isExpert || isSuperExpert) &&
                                            (rowData.data.evaluation_state ==
                                              false ||
                                              rowData.data.evaluation_state ==
                                                'reset')
                                          "
                                          @click="
                                            EvaluateCheckpoints(
                                              rowData.data.checkpoint_id,
                                              'passed'
                                            )
                                          "
                                        >
                                          <span
                                            class="btn fa fa-check text-success"
                                          ></span>
                                        </button>
                                        <!-- Failed icon -->
                                        <button
                                          class="btn"
                                          v-if="
                                            isFromManageOther &&
                                            (isExpert || isSuperExpert) &&
                                            (rowData.data.evaluation_state ==
                                              false ||
                                              rowData.data.evaluation_state ==
                                                'reset')
                                          "
                                          @click="
                                            EvaluateCheckpoints(
                                              rowData.data.checkpoint_id,
                                              'failed'
                                            )
                                          "
                                        >
                                          <span class="btn fa text-error"
                                            >&#xf00d;</span
                                          >
                                        </button>
                                        <!-- Not applicable icon -->
                                        <button
                                          class="btn"
                                          v-if="
                                            isFromManageOther &&
                                            (isExpert || isSuperExpert) &&
                                            (rowData.data.evaluation_state ==
                                              false ||
                                              rowData.data.evaluation_state ==
                                                'reset')
                                          "
                                          @click="
                                            EvaluateCheckpoints(
                                              rowData.data.checkpoint_id,
                                              'not_applicable'
                                            )
                                          "
                                        >
                                          <img
                                            src="https://erp.nanoramic.com/web_widget_tr_checkpoint/static/src/img/na_icon.png"
                                            name="evaluate_na"
                                            style="max-width: 20px"
                                          />
                                        </button>
                                        <!-- reset icon -->
                                        <button
                                          class="btn"
                                          v-if="
                                            isFromManageOther &&
                                            (isExpert || isSuperExpert) &&
                                            (rowData.data.evaluation_state ==
                                              'passed' ||
                                              rowData.data.evaluation_state ==
                                                'failed' ||
                                              rowData.data.evaluation_state ==
                                                'not_applicable')
                                          "
                                          @click="
                                            EvaluateCheckpoints(
                                              rowData.data.checkpoint_id,
                                              'reset'
                                            )
                                          "
                                        >
                                          <span class="btn fa text-warning"
                                            >&#xf021;</span
                                          >
                                        </button>
                                      </div>
                                      <div
                                        v-if="
                                          rowData.data.evaluation_state == false
                                        "
                                        class="not-evaluated-text col-9"
                                      >
                                        Not evaluated yet
                                      </div>
                                      <div
                                        v-if="
                                          rowData.data.evaluation_state ==
                                            'passed' ||
                                          rowData.data.evaluation_state ==
                                            'failed' ||
                                          rowData.data.evaluation_state ==
                                            'reset'
                                        "
                                        :style="{
                                          color: `${statusColor(
                                            rowData.data['evaluation_state']
                                          )}`,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }"
                                        class="col-9"
                                      >
                                        <v-avatar size="35" class="me-2">
                                          <img :src="rowData.data.image" />
                                        </v-avatar>
                                        {{
                                          rowData.data.evaluation_state
                                            .charAt(0)
                                            .toUpperCase() +
                                          rowData.data.evaluation_state.slice(1)
                                        }}
                                        by
                                        {{
                                          rowData.data.last_evaluated_by_name
                                        }}
                                        on
                                        {{ rowData.data.last_evaluated_on }}
                                      </div>
                                      <div
                                        v-if="
                                          rowData.data.evaluation_state ==
                                          'not_applicable'
                                        "
                                        :style="{
                                          color: `${statusColor(
                                            rowData.data['evaluation_state']
                                          )}`,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }"
                                        class="col-9"
                                      >
                                        <v-avatar size="35" class="me-2">
                                          <img :src="rowData.data.image" />
                                        </v-avatar>
                                        Not applicable said
                                        {{
                                          rowData.data.last_evaluated_by_name
                                        }}
                                        on
                                        {{ rowData.data.last_evaluated_on }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </DxDataGrid>
                          </div>
                        </div>
                      </template>
                      <template v-if="proficiency.ID == 2"> </template>
                      <template v-if="proficiency.ID == 3">
                        <div
                          v-if="description"
                          v-html="description"
                          aria-readonly="true"
                          class="description"
                        ></div>
                      </template>
                    </div>
                  </template>
                </DxTabPanel>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-dialog
      v-model="removeBadges"
      persistent
      content-class="dialog-box-decline-request"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-header">
          <h2 class="cust-dialog-title">Remove badge</h2>
        </div>
        <div class="cust-dialog-body">
          <v-row>
            <v-col cols="3"> Please provide a reason for remove badge </v-col>
            <v-col cols="8" style="margin-left: 10px">
              <v-form ref="reason">
                <v-text-field
                  :rules="rules"
                  hide-details="auto"
                  v-model="removeReason"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <div class="cust-dialog-footer d-flex pa-2">
          <v-btn
            depressed
            rounded
            class="btn-decline me-4"
            @click="onRemoveBadge"
            color="#a7d42e"
            >Remove</v-btn
          >

          <v-btn
            depressed
            rounded
            class="btn-accept"
            @click="onCancel"
            color="#ff6c6c"
            >Discard</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="editExpirationDateDialog"
      persistent
      content-class="dialog-box-decline-request"
    >
      <div class="cust-dialog">
        <div class="cust-dialog-header">
          <h2 class="cust-dialog-title">Edit Expiration Date</h2>
        </div>
        <div class="cust-dialog-body">
          <v-row>
            <v-col cols="3"> Expiration Date </v-col>
            <v-col cols="8" style="margin-left: 10px">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="expiration_date"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expiration_date"
                  no-title
                  @input="menu1 = false"
                  :min="expiration_date"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="cust-dialog-footer d-flex pa-2">
          <v-btn
            depressed
            rounded
            class="btn-decline me-4"
            @click="editExpirationDate()"
            color="#a7d42e"
            >Save</v-btn
          >

          <v-btn
            depressed
            rounded
            class="btn-accept"
            @click="onCancel"
            color="#ff6c6c"
            >Discard</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DxTabPanel from "devextreme-vue/tab-panel";
import {
  getCheckPoints,
  getResponsibilityById,
  evaluateCheckpoints,
  getBadge,
  grantBadge,
  removeBadge,
  editExpiration,
} from "@/api";
import { PROFICIENCY } from "@/utils/constants.js";
import DxDataGrid, {
  DxColumn,
  // DxPager,
  // DxPaging,
  DxSorting,
  DxScrolling,
} from "devextreme-vue/data-grid";
import moment from "moment";
import ChildBulletChartWidget from "../components/readiness/childBulletChartWidget";
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);
const { mapActions } = createNamespacedHelpers("isLoading");
import chipComponent from "@/common/chips";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";

export default {
  name: "InspectProficiency",
  components: {
    DxTabPanel,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    ChildBulletChartWidget,
    chipComponent,
  },
  data() {
    return {
      pronounEmployee: "Your",
      not_current_user: false,
      rows: [],
      selectedIndex: 0,
      loop: false,
      animationEnabled: true,
      swipeEnabled: true,
      gridRefName: "dataGrid",
      Proficiency: [
        {
          ID: 1,
          title: "Proficiency Checkpoints",
        },
        {
          ID: 2,
          title: "Available Experts",
        },
        {
          ID: 3,
          title: "Description",
        },
      ],
      checkPoints: [],
      column_names: ["checkpoints"],
      displayMode: "full",
      pageSizes: [5, 10, "all"],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      proficiencyName: "",
      responsibility_data: {},
      isSuperExpert: false,
      isExpert: false,
      isFromManageOther: false,
      inspectUserIsSuperExpert: false,
      progressbar_stacked_values: {},
      progressBarData: [],
      valueBadgeAndsequence(rowData) {
        return [rowData.badge_type, rowData.sequence];
      },
      filteredCheckpoints: [],
      notPassed: true,
      badge: {},
      removeReason: "",
      removeBadges: "",
      editExpirationDateDialog: false,
      rules: [
        (value) => !!value || "Reason is required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      chipData: {},
      expiration_date: "",
      menu1: "",
      description: "",
    };
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    if (this.$route.name == "Your Inspect Proficiency") {
      this.getLoading(true);
      const id_employee = Object.values(this.$route.params);
      this.responsibility_data = id_employee[1];
      this.expiration_date = this.responsibility_data?.fields?.find(
        (x) => x.name == "expiration_date"
      ).value;
      if (id_employee.length > 1) {
        if (this.responsibility_data.fields) {
          this.isSuperExpert = this.responsibility_data.fields.find(
            (x) => x.name == "is_super_expert"
          ).value;
          this.isExpert =
            this.responsibility_data.fields.find((x) => x.name == "proficiency")
              .value == "Expert";
          this.badge.target = this.responsibility_data.fields.find(
            (x) => x.name == "target"
          ).value;
        }

        await this.getCheckPoints(
          this.$route.params.id,
          localStorage.getItem("employee_id")
        );

        this.getResponsibility(this.$route.params.id);
      } else {
        this.responsibility_data;
        this.$router.go(-1);
      }
      this.getLoading(false);
    } else if (this.$route.name == "Employee Inspect Proficiency") {
      this.getLoading(true);
      this.pronounEmployee = "";
      this.isFromManageOther = true;
      const id_employee = Object.values(this.$route.params);
      const getBadgeRes = await getBadge(id_employee[0]);
      if (getBadgeRes.data.data.response.badge) {
        this.isExpert = getBadgeRes.data.data.response.badge == "expert";
        this.isSuperExpert = getBadgeRes.data.data.response.badge == "expert";
      }
      if (id_employee.length > 1) {
        const employee_data = this.employeeNameJobList.find(
          (e) => e.id == id_employee[1].employeeId
        );
        this.pronounEmployee = employee_data.name;
        this.responsibility_data = id_employee[1];
        this.expiration_date = this.responsibility_data?.fields?.find(
          (x) => x.name == "expiration_date"
        ).value;
        if (!this.expiration_date) {
          this.expiration_date = this.responsibility_data["expiration_date"];
        }
        this.inspectUserIsSuperExpert =
          this.responsibility_data.fields?.find((x) => x.name == "proficiency")
            .value == "Expert" &&
          this.responsibility_data.fields?.find(
            (x) => x.name == "is_super_expert"
          ).value;
        this.badge.target = this.responsibility_data?.fields?.find(
          (x) => x.name == "target"
        ).value;
        if (!this.badge.target) {
          this.badge.target = this.responsibility_data.target.toLowerCase();
        }

        await this.getCheckPoints(id_employee[0], id_employee[1].employeeId);
        this.getResponsibility(id_employee[0]);
      } else {
        this.$router.go(-1);
      }

      this.getLoading(false);
    }
    this.notPassedCheckpoints();
  },

  methods: {
    ...mapActions(["getLoading"]),
    redirect(path) {
      if (path == "/readiness") {
        this.$router.go(-1);
      } else {
        this.$router.push(path);
      }
    },
    /* get all checkpoints */
    async getCheckPoints(responsibility_id, employee_id) {
      try {
        const res = await getCheckPoints(responsibility_id, employee_id);
        this.checkPoints = res.data.data.response.data;
        this.progressbar_stacked_values = JSON.parse(
          res.data.data.response.progressbar_stacked_values
        );
        this.description = res.data.data.response.responsibility.description;
        Object.keys(this.progressbar_stacked_values).forEach((key) => {
          this.progressBar(key);
        });
        this.checkPoints.forEach((ele) => {
          if (ele.last_evaluated_on) {
            ele.last_evaluated_on = moment
              .utc(ele.last_evaluated_on)
              .local()
              .format("MMMM D YYYY, H:mm");
          }
          if (ele.last_evaluated_by) {
            ele.image = this.employeeNameJobList.find(
              (e) => e.id == ele.last_evaluated_by
            )?.image_128;
          }
        });
      } catch (error) {
        console.log("error: ", error);
      }
    },
    async getResponsibility(id) {
      try {
        const res = await getResponsibilityById(id);
        if (res) {
          this.proficiencyName = res.data.data[0].display_name;
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
    /* get checkpoints on badge type*/
    colorCheckpoints(Checkpoints) {
      let _color = PROFICIENCY[Checkpoints.toLowerCase()].color;
      return _color ? _color : "#000000";
    },
    sortBadgeAndsequence(value1, value2) {
      if (value1[0] == value2[0]) {
        if (value1[1] > value2[1]) {
          return 1;
        } else {
          return -1;
        }
      } else if (value1[0] == "apprentice") {
        return -1;
      } else if (value2[0] == "apprentice") {
        return 1;
      } else if (value1[0] == "professional" && value2[0] == "expert") {
        return -1;
      } else if (value1[0] == "expert" && value2[0] == "professional") {
        return 1;
      }
    },
    statusColor(status) {
      if (status == "passed") {
        return "#4BB543";
      } else if (status == "not_applicable") {
        return "#808080";
      } else if (status == "failed") {
        return "#ff0e0e";
      } else {
        return "#000000";
      }
    },
    async EvaluateCheckpoints(checkpoint_id, state) {
      this.getLoading(true);
      let employeeId;
      if (this.responsibility_data?.fields) {
        employeeId = this.responsibility_data.fields.find(
          (x) => x.name == "employee_id"
        ).value;
      } else {
        employeeId = this.responsibility_data.employeeId;
      }
      try {
        const res = await evaluateCheckpoints(employeeId, state, checkpoint_id);
        if (res) {
          await this.getCheckPoints(this.$route.params.id, employeeId);
          this.notPassedCheckpoints();
        }
        this.getLoading(false);
      } catch (error) {
        this.getLoading(false);
        console.log("error: ", error);
      }
    },
    async notPassedCheckpoints() {
      if (this.notPassed == true) {
        return (this.filteredCheckpoints = this.checkPoints.filter(
          (element) => element.evaluation_state != "passed"
        ));
      } else {
        return (this.filteredCheckpoints = this.checkPoints);
      }
    },
    progressBar(badge) {
      if (badge != undefined) {
        let barData = {
          readinessChartOption: {
            attrs: {
              chartValues: [
                {
                  value: this.progressbar_stacked_values[badge].passed,
                  // color: "#4BB543",
                  color: "#A3D977",
                },
                {
                  value: this.progressbar_stacked_values[badge].failed,
                  // color: "#ff0e0e",
                  color: "#C92D39",
                },
                {
                  value: this.progressbar_stacked_values[badge].not_applicable,
                  color: "#000000",
                },
                {
                  value: this.progressbar_stacked_values[badge].not_evaluated,
                  color: "#BFBFBF",
                },
              ],
              options: {
                leftPadding: 0,
                height: 3,
              },
              label: "",
            },
          },
        };
        this.progressBarData[badge] = barData;
      } else {
        let barData = {
          readinessChartOption: {
            attrs: {
              chartValues: [
                {
                  value: 100,
                  color: "#4BB543",
                },
              ],
              options: {
                leftPadding: 0,
                height: 3,
              },
              label: "",
            },
          },
        };
        this.progressBarData[badge] = barData;
      }
    },
    async onGrant(badge) {
      this.getLoading(true);
      let employeeId;
      if (this.responsibility_data?.fields) {
        employeeId = this.responsibility_data?.fields?.find(
          (x) => x.name == "employee_id"
        ).value;
      } else {
        employeeId = this.responsibility_data.employeeId;
      }
      try {
        const result = await grantBadge(
          employeeId,
          this.$route.params.id,
          badge
        );
        if (result) {
          this.expiration_date = moment().add(1, "years").subtract(1, "days");
          await this.getCheckPoints(this.$route.params.id, employeeId);
        }
        this.getLoading(false);
      } catch (error) {
        this.getLoading(false);

        console.log("error: ", error);
      }
    },
    removeDialog() {
      this.removeBadges = true;
    },
    onCancel() {
      this.removeBadges = false;
      this.editExpirationDateDialog = false;
      if (this.responsibility_data?.fields) {
        this.expiration_date =
          this.responsibility_data.fields["expiration_date"].value;
      } else {
        this.expiration_date = this.responsibility_data["expiration_date"];
      }
      this.$refs.reason?.reset();
    },
    async onRemoveBadge() {
      if (this.$refs.reason.validate()) {
        this.getLoading(true);
        let employee_id;
        let employee_resp;
        if (this.responsibility_data?.fields) {
          employee_resp = this.responsibility_data?.fields?.find(
            (x) => x.name == "employee_responsibility_id"
          ).value;
          employee_id = this.responsibility_data?.fields?.find(
            (x) => x.name == "employee_id"
          ).value;
        } else {
          employee_resp = this.responsibility_data.employee_responsibility_id;
          employee_id = this.responsibility_data.employeeId;
        }
        try {
          const result = await removeBadge(employee_resp, this.removeReason);
          if (result) {
            await this.getCheckPoints(this.$route.params.id, employee_id);
            this.$refs.reason.reset();
            this.removeBadges = false;
          }
          this.getLoading(false);
        } catch (error) {
          this.getLoading(false);
          console.log("error: ", error);
        }
      }
    },
    getBadgeChipData(badgeName) {
      let target = CapitalizeFirstLetter(this.badge.target);
      let ChipData = {
        textColor: "black",
        hasImageToolTip: true,
      };
      let badge = badgeName;

      let days = moment(this.expiration_date).diff(moment(), "days");
      ChipData.close = days > 30 ? false : true;

      ChipData.image = require(days > 30
        ? "../assets/img/expiration_date.png"
        : days <= 30 && days != 0
        ? "../assets/img/expiration_date_less.png"
        : "../assets/img/badgeIcon.png");

      ChipData.toolTip = ChipData.image.includes("expiration_date")
        ? `Badge will expire in ${days} days (${moment(
            this.expiration_date
          ).format("DD-MMM-YYYY")})`
        : ChipData.image.includes("expiration_date_less")
        ? `Badge will expire in ${days} days (${moment(
            this.expiration_date
          ).format("DD-MMM-YYYY")})`
        : "Badge does not have expiration date";

      if (
        badge == "Apprentice" &&
        this.progressbar_stacked_values["apprentice"].has_badge
      ) {
        ChipData.text =
          target != "Apprentice"
            ? `${badge} > ${
                target == "Professional" ? "50%" : "33%"
              } to ${target}`
            : badge;
        ChipData.color = "#CFE4FF";
      } else if (
        badge == "Professional" &&
        this.progressbar_stacked_values["professional"].has_badge
      ) {
        ChipData.text =
          target == "Apprentice"
            ? `${badge} > ${target}`
            : target == "Expert"
            ? `${badge} > 66% to ${target}`
            : badge;
        ChipData.color = "#E3FAE3";
      } else if (
        badge == "Expert" &&
        this.progressbar_stacked_values["expert"].has_badge
      ) {
        ChipData.text =
          target == "Apprentice" || target == "Professional"
            ? `${badge}  >  ${target}`
            : badge;
        ChipData.color = "#FCFCCA";
      } else {
        ChipData.text = `> 0% to ${target}`;
        ChipData.color = "black";
        ChipData.isOutlined = true;
      }

      this.chipData = ChipData;
      return ChipData;
    },
    onDoubleClick() {
      if (this.chipData.image.includes("expiration_date_less")) {
        this.editExpirationDateDialog = true;
      } else {
        return false;
      }
    },
    async editExpirationDate() {
      try {
        this.getLoading(true);
        let badge_id;
        if (this.responsibility_data?.fields) {
          badge_id = this.responsibility_data?.fields?.find(
            (x) => x.name == "badge_id"
          ).value;
        } else {
          badge_id = this.responsibility_data.badge_id;
        }
        const result = await editExpiration(badge_id, this.expiration_date);
        if (result) {
          this.editExpirationDateDialog = false;
          this.responsibility_data["expiration_date"] = this.expiration_date;
        }
        this.getLoading(false);
      } catch (error) {
        console.log("error: ", error);
        this.getLoading(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabpanel-item {
  padding: 25px;
}

.checkpoints {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 20px;
  background-color: #ffcccb;
}

#gridContainer {
  height: 450px;
}

.main_section__header {
  padding: 10px;
}

.v-list-item__title {
  color: #4bb543;
}

.text-block {
  white-space: pre-line;
  height: max-content;
}

.not-evaluated-text {
  color: #000000;
  font-weight: bold;
}

.text-success {
  color: #00863e !important;
}

.text-error {
  color: red;
}

.text-warning {
  color: orange !important;
}

.status-passed-text {
  color: #34aa4a;
}

.lightred-bg {
  background: #ffd9d9;
  padding: 5px;
  text-align: center;
  font-size: 15px;
}

.lightyellow-bg {
  background: #fcfcca;
  padding: 5px;
  text-align: center;
  font-size: 15px;
}

.checkPoint-status {
  align-items: center;
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.progress_bar {
  margin: 10px 0;
  .row {
    // width: fit-content;
  }
}

.grant-button {
  height: 24px !important;
  // width: 100% !important;
  color: white !important;
  // border: 1px solid black;
  background-color: #00c3a7 !important;
}

.target-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.template-img {
  padding-right: 0px;
}

.template-img img {
  height: 26px;
  width: 26px;
  object-fit: cover;
}

.tempate_bg {
  width: 100%;
  height: 30px;
  align-items: center;
  // background: #f2f3f5;
}

.badge_text {
  padding-right: 10px;
  font-weight: bold;
  font-size: 13px;
  color: black;
}
.progress_section {
  margin-left: 10px;
  width: 950px;
}
.cust-dialog {
  .cust-dialog-header {
    margin-bottom: 1rem;
    .cust-dialog-title {
      color: $blue;
      text-align: center;
    }
  }
  .cust-dialog-body {
    font-family: "Roboto", "Odoo Unicode Support Noto", sans-serif;
    font-size: 1.08333333rem;
    font-weight: 400;
    line-height: 1.5;
    .cust-dialog-bodyContent {
      margin-top: 25px;
      width: max-content;

      .cust-dialog-labelContent {
        font-weight: bold;
        color: #008784;
        text-align: left;
      }
    }
    .cust-dialog-label {
      font-weight: bold;
      color: #666666;
      text-align: left;
    }
  }
}
.v-chip__content {
  font-size: 14px !important;
  width: 100%;
  margin: 0 auto;
}
.chip {
  width: max-content;
  padding: 0 20px 0 5px;
}
.v-chip.v-size--large {
  height: 30px !important;
  font-size: 18px !important;
}
.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.btn-decline {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  // margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.description {
  font-size: 16px;
}
</style>
<style>
.dx-tabpanel .dx-multiview-wrapper {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
</style>
