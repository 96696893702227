<template>
  <div>
    <div class="preview-container">
      <!-- Root section -->
      <v-row no-gutters>
        <v-col class="pa-0">
          <ElearningDescription
            :preview="true"
            :courseDetail="courseDetail"
            @setPreviewCourse="setPreviewCourse"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { getCourseDetailWithIDUnpublished } from "@/api";
import { createNamespacedHelpers } from "vuex";
import ElearningDescription from "@/components/course/ElearningDescription";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  name: "CoursePreview",
  components: {
    ElearningDescription,
  },
  computed: {
    ...coursePresentationHelper.mapGetters(["courseDetail"]),
  },
  created() {
    this.getLoading(true);
    this.getChangedCourse();
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...coursePresentationHelper.mapActions(["dispatchCourseDetail"]),
    async getChangedCourse() {
      let parameter_id = parseInt(Object.keys(this.$route.query)[0]) || null;
      if (parameter_id) {
        try {
          let result_all_course = await getCourseDetailWithIDUnpublished(
            parameter_id
          );
          if (result_all_course.status == 200) {
            let course = result_all_course.data.data.response[0];
            this.dispatchCourseDetail(course);
          }
        } catch (error) {
          console.log("getChangedCourseError==>", error);
        } finally {
          this.getLoading(false);
        }
      }
      this.getLoading(false);
    },
    setPreviewCourse() {
      let url = `/see-course-preview?${this.courseDetail.id}`;
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
// ON CoursePresentation.vue
.preview-container {
  padding: 0 40px 40px 40px;
}
</style>
