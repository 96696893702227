import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./assets/styles/css-customized-devexp/dx.generic.custom-scheme.css";
import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment-timezone";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Button, Input, Dialog, Form, Alert, FormItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Grid from "gridjs-vue";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import HighchartsVue from "highcharts-vue";
import GAuth from "vue-google-oauth2";
import VueYouTubeEmbed from "vue-youtube-embed";
import vuetify from "./plugins/vuetify";
// import "vue-lazy-youtube-video/dist/style.simplified.css";
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

FusionCharts.options.license({
  // key: "<YOUR KEY>",
  key: "RjD2fA-21qC3E4A1H4D3B2C8C8B5A4F6B3H5vaiD8A1nhfxiI4A7A-16teB3E4F2B3D3C10B6C3B4A5F3G3B3G1A7C8poiC4D1I4iC8B7B5C-11C-9jE3E3G2tpgC2A1E2tllB3B7D1A3F3H3A2A15A32B13B9ckeG4H3PB2kmA-16A3C1E3ui1A1KA2B2B-13E-11oF1B3F1B7D5C4E4F4B2E1F3I2B6C6w==",
  creditLabel: false,
});

Vue.use(VueFusionCharts, FusionCharts, Charts, Widgets, FusionTheme);
Charts(FusionCharts);

const gauthOption = {
  clientId:
    "694466881684-mv3hd2clor1q5hb6dmtil5ajqbogl8ap.apps.googleusercontent.com",
  scope: "email",
  prompt: "consent",
};
// import faker from "vue-faker";
library.add(faCoffee);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(Grid);

// Vue.use(faker);
Vue.use(ElementUI);
Vue.use(HighchartsVue);
Vue.use(GAuth, gauthOption);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("grid", Grid);
Vue.component("Modal", VueModal);
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.component(Dialog.name, Dialog);
Vue.component(Form.name, Form);
Vue.component(Alert.name, Alert);
Vue.component(FormItem.name, FormItem);
Vue.use(VueYouTubeEmbed);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
