<template>
  <div class="personal-page">
    <div class="page_router">
      <span class="txt_router">People Ops Services</span>
      <div class="img_arrow"></div>
      <div class="txt_router">My personal information</div>
    </div>
    <div class="grid-container">
      <div class="profile-photo-content">
        <div class="paragraph_custom">
          <span class="title-card">Profile Photo</span>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="profile_photo_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>
        <div class="picture">
          <div
            class="image-container"
            :style="{
              'background-image': 'url(' + getUserImg64 + ')',
            }"
          ></div>
        </div>
      </div>
      <div class="personal-info-content">
        <span class="title-card">Personal Information</span>
        <div class="fields-grid">
          <div class="item_group">
            <p>First Name</p>
            <input
              :value="employeeInfo.first_name || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="item_group">
            <p>Middle Name</p>
            <input
              :value="employeeInfo.middle_name || ''"
              class="item_input input-customized"
              :disabled="true"
            />
          </div>
          <div class="item_group">
            <p>Last Name</p>
            <input
              :value="employeeInfo.last_name || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="item_group">
            <p>Nickname</p>
            <input
              :value="employeeInfo.nickname || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="names_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>

        <v-divider class="divider" />

        <div class="fields-grid">
          <div class="item_group">
            <p>Date of birth</p>
            <input
              type="date"
              :value="employeeInfo.birthday || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="item_group item_no_display" />
          <div class="item_group">
            <p>City of Birth</p>
            <input
              :value="employeeInfo.place_of_birth || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="item_group">
            <p>Country of Birth</p>
            <input
              :value="employeeInfo.country_of_birth || ''"
              class="input-customized"
              :disabled="true"
            />
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="birthday_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>

        <v-divider class="divider" />

        <div class="paragraph_custom">
          <div class="item_group">
            <p>Citizenship</p>
            <v-chip
              v-for="(citizenship, index) in employeeInfo.citizenships_ids"
              :key="index"
              class="tags-sm"
              color="#53A0DE33"
              text-color="#53A0DE"
            >
              {{ citizenship }}
            </v-chip>
            <!-- <div class="user-flag">
              <div
                class="user-flag__image"
                :style="{'background-image': 'url(' + getUserFlag + ')'}"
              ></div>
            </div> -->
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="citizenship_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>

        <v-divider class="divider" />

        <div class="paragraph_custom">
          <div class="item_group">
            <p>Gender</p>
            <input
              :value="employeeInfo.gender || ''"
              class="input-customized"
              style="text-transform: capitalize"
              :disabled="true"
            />
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="gender_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>

        <v-divider class="divider" />

        <div class="paragraph_custom">
          <div class="item_group">
            <p>Marital Status</p>
            <input
              :value="employeeInfo.marital || ''"
              class="input-customized"
              style="text-transform: capitalize"
              :disabled="true"
            />
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="marital_status_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>

        <v-divider class="divider" />

        <div class="paragraph_custom">
          <div class="item_group">
            <p>Languages</p>
            <!-- <div :style="{ 'margin-top': '8px' }"> -->
            <v-chip
              v-for="(language, index) in employeeInfo.languages"
              :key="index"
              class="tags-sm"
              color="#53A0DE33"
              text-color="#53A0DE"
              @click:close="() => deleteLanguage(index)"
            >
              {{ language }}
            </v-chip>
            <!-- </div> -->
          </div>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="language_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>
      </div>
      <div class="contact-info-content">
        <div class="paragraph_custom">
          <span class="title-card">Contact Information</span>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="contact_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>
        <p>Address</p>
        <input
          :value="employeeInfo.address || ''"
          class="input-customized"
          :disabled="true"
        />
        <v-divider class="divider" />
        <p>Mobile</p>
        <input
          :value="employeeInfo.mobile || ''"
          class="input-customized"
          :disabled="true"
        />
        <v-divider class="divider" />
        <p>Email</p>
        <input
          :value="employeeInfo.work_email || ''"
          class="input-customized"
          :disabled="true"
        />
      </div>
      <div class="emergency-contact-content">
        <div class="paragraph_custom">
          <span class="title-card">Emergency Contact</span>
          <div class="button-container">
            <button
              class="request-changes-button"
              id="emergency_button"
              v-if="act_as_me"
            >
              Request Changes
            </button>
          </div>
        </div>
        <input
          :value="employeeInfo.emergency_contact || ''"
          class="input-customized"
          style="margin-bottom: 20px"
          :disabled="true"
        />
        <p>Mobile</p>
        <input
          :value="employeeInfo.emergency_phone || ''"
          class="input-customized"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("isLoading");
const userInfoHelpers = createNamespacedHelpers("userInfo");

export default {
  name: "PersonalInfo",
  data() {
    return {
      act_as_me: true,
      employeeInfo: [],
    };
  },
  computed: {
    ...userInfoHelpers.mapGetters(["userInfo", "getUserImg64", "getUserFlag"]),
    nickname() {
      return `@${this.employeeInfo.slack_username}`;
    },
  },
  created() {
    this.getLoading(true);
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    this.getEmployeeInfo();
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),

    getEmployeeInfo() {
      this.employeeInfo = this.userInfo;
    },
    // hoverButton(id) {
    //   document.getElementById(id).style.background = "#53A0DE";
    // },
    // inputToTags(e) {
    //   if (e.target.value.trim() != "") {
    //     this.employeeInfo.languages.push(e.target.value);
    //   }
    //   e.target.value = "";
    // },
    deleteLanguage(index) {
      this.employeeInfo.languages.splice(index, 1);
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
%shared-containers {
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  border-radius: 30px;
  background: white;
  box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
}
%shared-text-input {
  font-weight: normal;
  color: $gray-1;
  line-height: 1.0625rem;
  font-size: 0.875rem;
}
.title-card {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  text-transform: capitalize;
  color: $black;
  margin-bottom: 34px;
}
p {
  color: $black;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}
.tags-sm {
  padding: 6px 14px;
  margin: 2px;
}
.divider {
  background: $light-blue-1;
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.fields-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .item_group {
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    &:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    &:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    &:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
  .button-container {
    grid-column: 3/4;
    grid-row: 1/3;
  }
}
.input-customized {
  @extend %shared-text-input;
  border-style: none;
  box-shadow: none;
  border-bottom: none;
  outline: none;
  outline-style: none;
  &:disabled {
    --b: var(--disabled);
  }
}
input::-webkit-calendar-picker-indicator {
  display: none;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.request-changes-button {
  text-decoration: none;
  line-height: 1.0625rem;
  font-weight: 600;
  font-size: 0.875rem;
  background-color: transparent;
  color: transparent;
  border: none;
  padding: 11px 23px;
  text-align: center;
  border-radius: 30px;
  transition: 0.3s;
  user-select: none;
}
.paragraph_custom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user-flag {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  &__image {
    background: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-top: 0;
    margin-left: 0;
    width: 26px;
    height: 26px;
  }
}
.profile-photo-content:hover #profile_photo_button {
  background-color: $blue;
  color: white;
}
.fields-grid:hover #names_button {
  background-color: $blue;
  color: white;
}
.fields-grid:hover #birthday_button {
  background-color: $blue;
  color: white;
}
.paragraph_custom:hover #citizenship_button {
  background-color: $blue;
  color: white;
}
.paragraph_custom:hover #gender_button {
  background-color: $blue;
  color: white;
}
.paragraph_custom:hover #marital_status_button {
  background-color: $blue;
  color: white;
}
.paragraph_custom:hover #language_button {
  background-color: $blue;
  color: white;
}
.contact-info-content:hover #contact_button {
  background-color: $blue;
  color: white;
}
.emergency-contact-content:hover #emergency_button {
  background-color: $blue;
  color: white;
}
.personal-page {
  margin: 26px 80px;
  font-family: Gilroy;
  font-style: normal;
  .page_router {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 26px;
    .txt_router {
      @extend %shared-text-input;
    }
    .img_arrow {
      background-image: url("~@/assets/img/arrow_right.svg");
      width: 5px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .grid-container {
    display: grid;
    grid-gap: 40px;
    width: calc(100% - 80px);
    grid-template-columns: 27% 40% 33%;
    // grid-template-rows: 45% 17% 11% 23%;
    grid-template-rows: repeat(4, min(100px, 1fr));
    // grid-template-rows: 300px 50px 90px 90px;
    .profile-photo-content {
      @extend %shared-containers;
      grid-column: 1/2;
      grid-row: 1/3;
      .picture {
        margin-top: 5px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        align-self: center;
        .image-container {
          background-size: cover;
          border-radius: 50%;
          background-repeat: no-repeat;
          align-self: center;
          width: 20vw;
          height: 20vw;
          max-width: 250px;
          max-height: 250px;
        }
      }
    }
    .personal-info-content {
      @extend %shared-containers;
      grid-column: 2/3;
      grid-row: 1/5;
      flex-grow: 1;
    }
    .contact-info-content {
      @extend %shared-containers;
      grid-column: 3/4;
      grid-row: 1/2;
    }
    .emergency-contact-content {
      @extend %shared-containers;
      grid-column: 3/4;
      grid-row: 2/4;
    }
  }

  @media screen and (max-width: 1680px) {
    .grid-container {
      grid-template-columns: 45% 55%;
      grid-template-rows: repeat(4, min(100px, 1fr));
      grid-gap: 30px !important;
      width: 100%;
      .profile-photo-content {
        grid-column: 1/2;
        grid-row: 1/2;
      }
      .personal-info-content {
        grid-column: 2/3;
        grid-row: 1/3;
      }
      .contact-info-content {
        grid-column: 1/2;
        grid-row: 2/4;
      }
      .emergency-contact-content {
        grid-column: 2/3;
        grid-row: 3/4;
      }
    }
  }
  @media screen and (max-width: 1213px) {
    margin: 26px 30px !important;
    .request-changes-button {
      background-color: $blue;
      color: white;
    }
    .grid-container {
      grid-template-columns: 100%;
      grid-template-rows: minmax(100px, max-content);
      grid-gap: 20px !important;
      width: 100%;
      .profile-photo-content {
        grid-column: 1;
        grid-row: 1/2;
      }
      .personal-info-content {
        grid-column: 1;
        grid-row: 2/3;
      }
      .contact-info-content {
        grid-column: 1;
        grid-row: 3/4;
      }
      .emergency-contact-content {
        grid-column: 1;
        grid-row: 4/5;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .paragraph_custom {
      flex-direction: column;
      align-items: flex-start;
      .button-container {
        align-self: center !important;
      }
    }
    .fields-grid {
      grid-template-columns: 100%;
      grid-template-rows: repeat(5, 1fr);
      .item_no_display {
        grid-column: 0 !important;
        grid-row: 0 !important;
      }
      .item_group {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1/2;
        }
        &:nth-child(2) {
          grid-column: 1;
          grid-row: 2/3;
        }
        &:nth-child(3) {
          grid-column: 1;
          grid-row: 3/4;
        }
        &:nth-child(4) {
          grid-column: 1;
          grid-row: 4/5;
        }
      }
      .button-container {
        align-items: center !important;
        grid-column: 1;
        grid-row: 5/6;
      }
    }
  }
}
</style>
