<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Required Certifications</h1>
          </div>
          <div class="main_section__content">
            <div v-if="!notFound">
              <div class="eventsTable">
                <TableReqCert :cols="role_cols" :rows="rows" />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-events"></div>
                <div class="not-found__space-text"></div>
                <p class="not-found__text">
                  You have no required certifications yet
                </p>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableReqCert from "@/components/required-certifications/TableReqCert.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import { tableCertifications } from "@/utils/table_your_jobs.js";

export default {
  name: "LearningEvents",
  components: {
    TableReqCert,
  },
  data() {
    return {
      filters: {
        status: [],
      },
      tableType: "req_cer",
      show: true,
      notFilter: false,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      role_cols: [
        "Certification Program",
        "Responsibilities",
        "Status",
        "Expires On",
      ],
      rows: [],
      fullRows: [],
      gettingCertifications: [],
    };
  },
  computed: {
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    this.getLoading(true);
    await this.getDataAPI();
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataAPI() {
      if (this.$route.name == "Required Certifications") {
        this.rows = await tableCertifications();
      } else if (this.$route.name == "Employee Certifications") {
        this.rows = await tableCertifications(
          Object.values(this.$route.params)[0]
        );
      }
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
</style>
