<template>
  <div class="chart-container">
    <div
      v-for="(bar, i) in dataJoin"
      :key="i"
      :style="{
        color: bar.color,
        'background-color': bar.color,
        width: `${bar.value * 2.3}px`,
      }"
      class="status-bar status-bar__center"
      :class="personalizeClass(bar, i)"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div class="div-tooltip" v-bind="attrs" v-on="on"></div>
        </template>
        <span>{{ bar.name }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
// import {
//   DxChart,
//   DxSeries,
//   DxTooltip,
// } from 'devextreme-vue/chart';
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";

export default {
  props: {
    barData: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {
      lightGreen: "#E3FAE3",
      green: "#54C45E",
      lightYellow: "#FCFCCA",
      yellow: "#FFE342",
      grey: "#E5E5E5",
    };
  },
  computed: {
    Name1() {
      return this.barData[0].name
        ? CapitalizeFirstLetter(this.barData[0].name)
        : "Certification";
    },
    Name2() {
      return this.barData[1].name
        ? CapitalizeFirstLetter(this.barData[1].name)
        : "Proficiency";
    },
    Name3() {
      if (this.barData[2] && this.barData[2].name)
        return CapitalizeFirstLetter(this.barData[2].name);
      return "";
    },
    Value1() {
      let val1 = this.barData[0].value ? this.barData[0].value : 0;
      let val2 = this.barData[1].value ? this.barData[1].value : 0;
      if (this.barData[0] && this.barData[0].value) {
        if (val1 > 1 || val2 > 1) {
          return this.barData[0].value * 0.5;
        } else {
          return this.barData[0].value * 0.5 * 100;
        }
      }
      return 0;
    },
    Value2() {
      let val1 = this.barData[0].value ? this.barData[0].value : 0;
      let val2 = this.barData[1].value ? this.barData[1].value : 0;
      if (this.barData[1] && this.barData[1].value) {
        if (val1 > 1 || val2 > 1) {
          return this.barData[1].value * 0.5;
        } else {
          return this.barData[1].value * 0.5 * 100;
        }
      }
      return 0;
    },
    Value3() {
      return 100 - (this.Value1 + this.Value2);
    },
    Color1() {
      if (this.barData[0].color) return this.barData[0].color;
      if (this.barData[0].value == 1 || this.barData[0].value == 100) {
        return this.lightGreen;
      } else {
        return this.lightYellow;
      }
    },
    Color2() {
      if (this.barData[1].color) return this.barData[1].color;
      if (this.barData[1].value == 1 || this.barData[1].value == 100) {
        return this.green;
      } else {
        return this.yellow;
      }
    },
    Color3() {
      if (this.barData[2] && this.barData[2].color)
        return this.barData[2].color;
      return this.grey;
    },
    dataJoin() {
      return [
        {
          value: this.Value1,
          name: this.Name1,
          color: this.Color1,
        },
        {
          value: this.Value2,
          name: this.Name2,
          color: this.Color2,
        },
        {
          value: this.Value3,
          name: this.Name3,
          color: this.Color3,
        },
      ];
    },
  },
  methods: {
    customizeTooltip(barInfo) {
      return {
        text: `${barInfo.value}`,
      };
    },
    personalizeClass(bar, i) {
      let firstNotNull = false;
      let lastNotNull = false;
      for (let index = 0; index < i; index++) {
        if (this.dataJoin[index].value == 0) {
          firstNotNull = true;
        } else {
          firstNotNull = false;
          break;
        }
      }
      for (let index = this.dataJoin.length - 1; index > i; index--) {
        if (this.dataJoin[index].value == 0) {
          lastNotNull = true;
        } else {
          lastNotNull = false;
          break;
        }
      }
      if (bar.value == 100) return "bar";
      if (i == 0 || firstNotNull) return "rounded-left";
      if (i == this.dataJoin.length - 1 || lastNotNull) return "rounded-right";
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  display: flex;
  .status-bar {
    display: flex;
    height: 16px;
    color: $white;
    font-weight: 500;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    white-space: nowrap !important;
    position: relative;
    &__left {
      justify-content: left !important;
    }
    &__center {
      justify-content: center;
    }
  }
  .div-tooltip {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .rounded-left {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .rounded-right {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .bar {
    border-radius: 8px;
    // border-radius: 16px;
  }
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
