<template>
  <div class="table1-container">
    <FirstGrid :col="column_names" :row="charges" :type="type">
      <!-- <template v-slot="slotProps"> -->
      <!-- <SecondGrid :col="column_names" :row="slotProps.row.childs" :disabled_button="disabled_button"></SecondGrid> -->
      <!-- </template> -->
    </FirstGrid>
  </div>
</template>
<script>
import FirstGrid from "@/components/compensation/FirstGrid";
// import SecondGrid from "@/components/compensation/SecondGrid";

export default {
  name: "TableDetail",
  components: {
    FirstGrid,
    // SecondGrid,
  },
  props: ["charges", "column_names", "type"],
  data() {
    return {
      selectedPageNumber: 1,
      eventsPagination: 1,
    };
  },
  mounted() {
    // console.log("uuuuuuuu",this.charges)
  },
};
</script>
<style lang="scss" scoped>
.table1-container {
  padding: 40px;
}
</style>
