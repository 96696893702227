import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getEducationalEvents = () => {
  let result = axios()({
    url: API.EDUCATIONEVENTS,
    method: "patch",
    params: {
      action: "get_education_events",
      state: "confirmed",
    },
  });
  return result;
};
