import axios from "axios";
import { getToken } from "./getToken";

/**
 * Create Axios Instance for API Call
 *
 * @param {boolean} withToken
 * @param { { headers: Object, baseURL: string } } options
 */
export const createAxiosInstance = (withToken = true, options = {}) => {
  // const { headers = {}, baseURL = `${process.env.https://pre-release.prd.nanoramic.com}/api` } =  options;
  const { headers = {}, baseURL = `${process.env.https://pre-release.prd.nanoramic.com}` } = options;

  const instanceConfig = {
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      ...(withToken ? { "access-token": getToken() } : {}),
      ...headers,
    },
  };

  const axiosInstance = axios.create(instanceConfig);

  // axiosInstance.interceptors.request.use((config) => {
  //   / ----------------------------- API Call Start ----------------------------- /;
  //   console.log("[===== Started API Call =====]", config);
  //   return config;
  // });

  // axiosInstance.interceptors.response.use(
  //   (response) => {
  //     / ------------------------------ API Call End ------------------------------ /;
  //     console.log("[===== Ended API Call with Success =====] =>", response);
  //     return response;
  //   },
  //   (error) => {
  //     console.log("[===== Ended API Call with Error =====] =>", error);
  //     throw error;
  //   }
  // );
  return axiosInstance;
};

export default createAxiosInstance;
