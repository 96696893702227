<template>
  <div>
    <div class="header-container"></div>
    <DxDataGrid
      v-if="type != 'costPerHour'"
      id="gridContainer1"
      :data-source="row"
      :columns="col"
      key-expr="name"
      :show-borders="true"
      :showRowLines="true"
      @selection-changed="selectionChanged"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :grouping="groupingOption"
    >
      <DxSorting mode="multiple" />
      <DxGrouping :auto-expand-all="false" />
      <DxColumn
        data-field="date"
        cell-template="dateTemplate"
        sort-order="asc"
      />
      <DxColumn data-field="name" caption="Description" alignment="center" />

      <DxColumn
        :group-index="0"
        data-field="category"
        alignment="center"
        :showWhenGrouped="true"
      />
      <DxColumn
        data-field="partner"
        alignment="center"
        :customize-text="priceColumn_customizeText"
        data-type="string"
        v-if="type != 'discharges'"
      />
      <DxColumn data-field="amount" format="currency" />
      <DxSummary>
        <DxTotalItem column="amount" summary-type="sum" value-format="currency">
        </DxTotalItem>
        <DxGroupItem
          :align-by-column="true"
          column="amount"
          summary-type="sum"
          display-format="{0}"
          value-format="currency"
        >
        </DxGroupItem>
        <DxGroupItem />
      </DxSummary>

      <DxSelection mode="single" />
      <!-- <DxMasterDetail :enabled="true" template="detailTemplate" /> -->
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #dateTemplate="{ data: rowData }">
        <div>
          <span>{{ rowData.data["date"] }}</span>
        </div>
      </template>
    </DxDataGrid>

    <DxDataGrid
      v-if="type == 'costPerHour'"
      id="gridContainer1"
      :data-source="row"
      :columns="col"
      key-expr="analytic_account_id"
      :show-borders="true"
      :showRowLines="true"
      @selection-changed="selectionChanged"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :grouping="groupingOption"
    >
      <DxSorting mode="multiple" />
      <DxColumn
        data-field="quarter_cost_per_hour"
        format="currency"
        caption="Your cost per hour In Quarter"
      />
      <DxColumn
        data-field="theoretical_cost_per_hour"
        format="currency"
        caption="Theoretical Cost Per Hour In Quarter"
      />
      <DxColumn
        data-field="actual_cost_per_hour"
        format="currency"
        caption="Actual Cost Per Hour In Quarter"
      />

      <DxSelection mode="single" />
      <!-- <DxMasterDetail :enabled="true" template="detailTemplate" /> -->
      <DxPaging :page-size="11" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #dateTemplate="{ data: rowData }">
        <div>
          <span>{{ rowData.data["date"] }}</span>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import DxDataGrid, {
  DxSelection,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxColumn,
  DxSorting,
  DxSummary,
  DxGroupItem,
  DxTotalItem,
  DxGrouping,
  DxValueFormat,
} from "devextreme-vue/data-grid";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  components: {
    DxDataGrid,
    DxSelection,
    // DxMasterDetail,
    DxPager,
    DxPaging,
    DxColumn,
    DxSorting,
    DxGroupItem,
    DxSummary,
    DxTotalItem,
    DxGrouping,
    // DxValueFormat
  },
  props: ["col", "row", "type"],
  data() {
    return {
      act_as_me: true,
      groupingOption: {
        texts: {
          groupContinuesMessage: "",
          groupContinuedMessage: "",
        },
      },
    };
  },
  created() {
    // console.log("iiii", this.type);
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  computed: {},

  methods: {
    ...mapActions(["getLoading"]),
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    sortCommitmentAndName(value1, value2) {
      if (value1[0] == value2[0]) {
        return value1[1].localeCompare(value2[1]);
      }
      if (value1[0] == "primary") {
        return -1;
      }
      if (value2[0] == "primary") {
        return 1;
      }
      if (value1[0] == "backup") {
        return -1;
      }
      if (value2[0] == "backup") {
        return 1;
      }
      return 1;
    },
    priceColumn_customizeText(cellInfo) {
      let text = cellInfo.value == false ? " " : cellInfo.value;
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .txt-current-assign-count {
    color: $gray-3;
    font-family: Gilroy-Bold;
    width: 700;
    font-size: 24px;
  }
}
.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.red-txt {
  color: $red;
  font-family: Gilroy-Bold;
  // width: 700;
}
</style>
