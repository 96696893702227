const DetectMimeType = (_base64) => {
  /*
  MAGIC NUMBERS    ----->    https://en.wikipedia.org/wiki/List_of_file_signatures
  HEXADECIMAL TO BASE64  ----->  https://base64.guru/converter/encode/hex
  MIME LIST    ----->    https://www.viainternet.com.mx/clientes/index.php?rp=%2Fknowledgebase%2F22%2FTipos-MIME.html&language=estonian
  */
  //  console.log(_binary.codePointAt(0))
  // console.log(Buffer.from(_base64.toString('utf8'), 'base64').toString('ascii'));
  let openInIFrame = true;
  var signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg",
    UEs: "MicrosoftX",
    "0M8R4KGxGuEA": "OldMicrosoft",
    // WORD
    // : "application/msword"
    // : "application/doc"
    // : "application/ms-doc"
    // : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // EXCEL
    // : "application/vnd.ms-excel",
    // : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // : "application/excel"
    // : "application/x-excel"
    // : "application/x-msexcel"
    // POWER POINT
    // : "application/powerpoint",
    // : "application/vnd.ms-powerpoint",
    // : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    // : "application/mspowerpoint"
    // : "application/x-mspowerpoint"
  };
  let mime = "";
  for (var s in signatures) {
    if (_base64.indexOf(s) === 0) {
      mime = signatures[s];
    }
  }
  if (mime == "MicrosoftX") {
    mime = _isWordX(_base64);
    openInIFrame = false;
  } else if (mime == "OldMicrosoft") {
    mime = _isWord(_base64);
    openInIFrame = false;
  }
  return {
    mimeType: mime,
    openInIFrame: openInIFrame,
  };
};

const _isWordX = (_base64) => {
  let _binary = window.atob(_base64);
  let isWord = _binary.indexOf("word/document.xml");
  if (isWord != -1)
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  let isExcel = _binary.indexOf("xl/workbook.xml");
  if (isExcel != -1)
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  let isPP = _binary.indexOf("ppt/presentation.xml");
  if (isPP != -1)
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  return "application/doc";
};
const _isWord = (_base64) => {
  let _binary = window.atob(_base64);
  let isWord = _binary.indexOf("MSWordDoc");
  if (isWord != -1) return "application/msword";
  let isExcel = _binary.indexOf("Microsoft Excel");
  if (isExcel != -1) return "application/msexcel";
  let isPP = _binary.indexOf("MS PowerPoint");
  if (isPP != -1) return "application/mspowerpoint";
  return "application/doc";
};

export default DetectMimeType;
