import { render, staticRenderFns } from "./dummy1Widget.vue?vue&type=template&id=0c862210&scoped=true&"
import script from "./dummy1Widget.vue?vue&type=script&lang=js&"
export * from "./dummy1Widget.vue?vue&type=script&lang=js&"
import style0 from "./dummy1Widget.vue?vue&type=style&index=0&id=0c862210&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c862210",
  null
  
)

export default component.exports