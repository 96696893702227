<template>
  <div class="card-container">
    <v-tabs v-model="tabSelected" show-arrows>
      <v-tabs-slider color="#53a0de"></v-tabs-slider>
      <v-tab v-for="item in tabsTitles" :key="item" class="tab-cell">
        <span class="title_tab">
          {{ item }}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabSelected" flat>
      <v-tab-item v-for="item in tabsTitles" :key="item">
        <v-card v-if="tabSelected == 0" class="table-container" flat>
          <TableRespMissingCert :rows="rows" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import TableRespMissingCert from "@/components/current-responsibilities/TableRespMissingCert";
export default {
  name: "MenuCurrentResp",
  props: ["rows"],
  components: {
    TableRespMissingCert,
  },
  data() {
    return {
      tabsTitles: ["missing certifications"],
      tabSelected: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  padding: 15px 50px 15px 50px;
  background-color: $white;
}
.tab-cell {
  padding-right: 15px;
  padding-left: 15px;
}
.title_tab {
  text-transform: capitalize;
  color: $blue;
}
.table-container {
  padding-top: 20px;
}
</style>
