import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getFAQByPageANDSize = (_page, _size) => {
  let result = axios()({
    method: "get",
    url: API.GETFAQBYPAGEANDSIZE,
    params: {
      fields: "id,question,answer,tags_ids",
      page: _page,
      size: _size,
    },
  });
  return result;
};

export const getTagList = () => {
  let result = axios()({
    method: "get",
    url: API.GETTAGLIST,
  });
  return result;
};

export const getFAQByTags = (_tags, _page, _size) => {
  let result = axios()({
    method: "get",
    url: API.GETFAQBYPAGEANDSIZE,
    params: {
      fields: "id,question,answer,tags_ids",
      page: _page,
      size: _size,
      filter_tags_ids: _tags,
    },
  });
  return result;
};

export const getFAQByFilter = (_filter, _page, _size) => {
  let result = axios()({
    method: "get",
    url: API.GETFAQBYPAGEANDSIZE,
    // headers: authHeader(),
    params: {
      fields: "id,question,answer,tags_ids",
      page: _page,
      size: _size,
      filter: _filter,
    },
  });
  return result;
};
