import axios from "./_axios";
import { API } from "./apiEndPoints";

export const enrollingCourse = (_courseID) => {
  let result = axios()({
    url: API.ENROLL_COURSE,
    method: "patch",
    params: { action: "enroll_employee", channel_id: _courseID },
  });
  return result;
};
