<template>
  <div v-if="course_detail">
    <CourseMain
      class="course-main-container"
      :courseDetail="course_detail"
      @exitCourse="setCourseExit"
      @completedCourse="setCompletedCourse"
    />
  </div>
</template>

<script>
import { getCourses } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import CourseMain from "@/components/take-course/CourseMain";

export default {
  name: "TakeCourse",
  data() {
    return {
      course_detail: null,
    };
  },
  components: {
    CourseMain,
  },
  created() {
    this.getDataAPI();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataAPI() {
      this.getLoading(true);
      await this.getChangedCourse();
    },
    setCompletedCourse() {
      this.setCourseExit();
    },
    setCourseExit() {
      let url = `/course-presentation?${this.course_detail.title}`;
      this.$router.push(url);
    },
    async getChangedCourse() {
      const courseTitleUrl = decodeURIComponent(
        window.location.search.slice(1)
      );
      try {
        let result_all_course = await getCourses();
        if (result_all_course.status == 200) {
          let courses = result_all_course.data.data.response;
          const current_course = courses.find(
            (course) => course.title == courseTitleUrl
          );
          this.course_detail = current_course;
        }
      } catch (error) {
        console.log("getChangedCourseError==>", error);
        this.getLoading(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//style in src/assets/styles/components/take-course.scss
.course-main-container {
  padding-top: 40px !important;
  padding-left: 80px;
  padding-right: 80px;
  height: calc(100vh - 140px);
}
@media screen and (max-width: 1400px) {
  .course-main-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
</style>
