import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getSeverityLevel = () => {
  let result = axios()({
    url: API.GETSEVERITYLEVEL,
    method: "patch",
    params: { action: "get_severity_level" },
  });
  return result;
};
export const createServiceTicket = (params) => {
  const vals = getValues(params);
  let result = axios()({
    url: API.GETPEOPLESERVICETICKET,
    method: "patch",
    params: {
      action: "create_service_ticket",
      reporter_user_id: localStorage.getItem("user_id"),
      assignee_user_id: localStorage.getItem("user_id"),
      name: vals.name,
      severity_level_id: vals.severity_level_id,
      description: vals.description,
    },
  });
  return result;
};
export const getServiceTickets = () => {
  let result = axios()({
    url: API.GETPEOPLESERVICETICKET,
    method: "patch",
    params: { action: "get_service_ticket" },
  });
  console.log(result);
  return result;
};
function getValues(formValues) {
  const arr = formValues
    .map((form) => form.fields)
    .flat()
    .map((obj) => {
      return { [obj.name]: obj.value.toString() };
    });

  let b = {};
  arr.forEach((c) => {
    b = { ...b, ...c };
  });
  // return JSON.stringify(b);
  return b;
}
