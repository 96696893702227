import { directoryEmployeeList } from "@/api";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
export default {
  namespaced: true,
  state: {
    employee_list: [],
    department_list: [],
    employeeNameJob_list: [],
    employeeRegionCountry_list: [],
    selected_department: "",
    employee_regions: [],
    country_total: 0,
  },
  mutations: {
    setEmployeeList(state, status) {
      state.employee_list = status;
    },
    setDepartmentList(state, status) {
      state.department_list = status;
    },
    setEmployeeNameJobList(state, status) {
      state.employeeNameJob_list = status;
    },
    setEmployeeRegionCountryList(state, status) {
      state.employeeRegionCountry_list = status;
    },
    setSelectedDepartment(state, status) {
      state.selected_department = status;
    },
    setEmployeeRegions(state, status) {
      state.employee_regions = status;
    },
    setCountryTotal(state, status) {
      state.country_total = status;
    },
  },
  actions: {
    dispatchEmployeeList({ commit }, status) {
      commit("setEmployeeList", status);
    },
    dispatchDepartmentList({ commit }, status) {
      commit("setDepartmentList", status);
    },
    async dispatchEmployeeNameJobList({ commit }) {
      try {
        let result = await directoryEmployeeList();
        if (result.status === 200) {
          this.employees_List = result.data;
          let region_list = [];
          let employeeCountryList = [];
          let employeeNameJobList = this.employees_List.map(function (
            employee
          ) {
            if (employee.region) {
              if (!region_list.includes(employee.region)) {
                region_list.push(employee.region);
              }
            }

            if (employee.country_name) {
              if (!employeeCountryList.includes(employee.country_name)) {
                employeeCountryList.push(employee.country_name);
              }
            }
            return {
              id: employee.id || "",
              image_128: employee.image_128 || "",
              name: employee.name || "",
              job_title: employee.job_title || "",
              region: employee.region || "",
              country_name: employee.country_name,
              flag: employee.flag || "",
              department_name: employee.department_name,
              employee_pronoun: employee.employee_pronoun
                ? CapitalizeFirstLetter(employee.employee_pronoun)
                : false,
            };
          });
          commit("setEmployeeNameJobList", employeeNameJobList);
          region_list.sort();
          commit("setEmployeeRegions", region_list);

          let countryCount = employeeCountryList.length;
          commit("setCountryTotal", countryCount);
        }
      } catch (error) {
        console.log("DispatchEmployeeListError==>", error);
        commit("setEmployeeNameJobList", []);
        commit("setEmployeeRegions", []);
        commit("setCountryTotal", 0);
      }
    },
    dispatchEmployeeRegionCountryList({ commit }, status) {
      commit("setEmployeeRegionCountryList", status);
    },
    dispatchSelectedDepartment({ commit }, status) {
      commit("setSelectedDepartment", status);
    },
    dispatchEmployeeRegions({ commit }, status) {
      commit("setEmployeeRegions", status);
    },
  },
  getters: {
    employeeList(state) {
      return state.employee_list;
    },
    departmentList(state) {
      return state.department_list;
    },
    employeeNameJobList(state) {
      return state.employeeNameJob_list;
    },
    employeeRegionCountryList(state) {
      return state.employeeRegionCountry_list;
    },
    selectedDepartment(state) {
      return state.selected_department;
    },
    employeeRegions(state) {
      return state.employee_regions;
    },
    countryTotal(state) {
      return state.country_total;
    },
  },
};
