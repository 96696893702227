<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="book-presentation-page">
          <v-row no-gutters>
            <v-col class="pa-0">
              <div class="back-item">
                <img
                  class="back-item__img"
                  src="@/assets/img/page-arrow-black.svg"
                />
                <span class="back-item__text" @click="backToAllCourse()"
                  >&nbsp;Back to Book Club</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="book-presentation-page__content">
            <v-col cols="12" md="6" class="pa-0">
              <div class="content-img">
                <div
                  class="content-img__img"
                  :style="{
                    'background-image': 'url(' + bookData.cover_url + ')',
                  }"
                  @click="goToBookAmazon"
                >
                  <div class="see_amazon_img"></div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <div class="page-content">
                <span class="txt_name">{{ bookData.name }}</span>
                <span class="txt_subtitle">{{ bookData.subtitle }}</span>
                <span class="txt_author">By {{ bookData.author }}</span>
                <span class="txt_description_title">Description</span>
                <span class="txt_description_content">{{
                  bookData.description
                }}</span>
              </div>
            </v-col>
          </v-row>

          <!-- Content -->
        </section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getBookClubList } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  name: "BookPresentation",
  data() {
    return {
      bookData: {},
    };
  },
  created() {
    this.getLoading(true);
    this.getBookList();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getBookList() {
      try {
        let result = await getBookClubList();
        if (result.status == 200) {
          const bookTitleUrl = decodeURIComponent(
            window.location.search.slice(1)
          );
          let booksList = result.data.data.response;
          this.bookData = booksList.find((_b) => _b.name == bookTitleUrl);
        }
      } catch (error) {
        console.log("getBookClubError==>", error);
      } finally {
        this.getLoading(false);
      }
    },
    goToBookAmazon() {
      window.open(this.bookData.amazon_link, "_blank");
    },
    backToAllCourse() {
      let url = `/learning/book-club`;
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.book-presentation-page {
  // width: 110rem;
  margin: 3.6rem 5rem 5rem 5rem;

  .back-item {
    margin-left: 0.125rem;
    margin-bottom: 1.875rem;
    vertical-align: middle;
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      margin-left: 5px;
    }
  }

  &__content {
    background: $white;
    border-radius: 30px;
    padding: 40px;
    .content-img {
      cursor: pointer;
      margin-right: 50px;
      background-color: #f6f6fb;
      border-radius: 30px;
      padding-top: 58px;
      padding-bottom: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__img {
        width: 423px;
        height: 690px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 30px;
        border: 1px solid #f6f6fb;
        background-color: #f6f6fb;
        position: relative;
        .see_amazon_img {
          position: absolute;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 95px;
          width: 100%;
          background-image: url("~@/assets/img/see_on_amazon_big.svg");
          bottom: -1px;
        }
      }
    }
    .page-content {
      display: flex;
      flex-flow: column;
      margin-top: 25px;
      color: #323a44;
      .txt_name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
      }
      .txt_subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        margin-top: 10px;
      }
      .txt_author {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-top: 15px;
      }
      .txt_description_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-decoration-line: underline;
        color: #53a0de;
        margin-top: 30px;
      }
      .txt_description_content {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        margin-top: 15px;
        margin-right: 100px;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .book-presentation-page {
    margin: 3.6rem 3.75rem 3.75rem 3.75rem;
  }
  .book-presentation-page__content {
    .page-content {
      .txt_description_content {
        margin-right: 0px;
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .book-presentation-page {
    margin: 1.5rem 1.5rem 2.75rem 1.5rem;
  }
  .book-presentation-page__content {
    .content-img {
      margin-right: 0px;
    }
  }
}
@media screen and (max-width: 500px) {
  .book-presentation-page__content {
    padding: 15px;
    .content-img {
      padding-top: 0px;
      padding-bottom: 0px;

      &__img {
        height: 515px;
        .see_amazon_img {
          bottom: -12px;
        }
      }
    }

    .page-content {
      margin-top: 45px;
      .txt_description_content {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
