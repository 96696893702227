<template>
  <div class="header">
    <div class="header_up">
      <div class="navDrawer_logo">
        <div class="navDrawer" :class="{ showDrawer: isAdmin }">
          <div class="img_navDrawer" @click.stop="drawer = !drawer"></div>
          <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list-item class="navHeader_search_content">
              <div class="navHeader_search">
                <form v-on:submit.prevent="sendSearch_Header" class="input-box">
                  <input
                    class="input-box__search"
                    type="text"
                    placeholder="What do you want to do today?"
                  />
                  <!-- Submit button -->
                  <button type="submit" class="input-box__button">
                    <img src="@/assets/img/input-mag.svg" class="img-fluid" />
                  </button>
                </form>
              </div>
              <!-- </v-list-item-content> -->
            </v-list-item>
            <v-list-item v-if="isAdmin" class="navHeader_change_content">
              <ChangeLogin />
            </v-list-item>
            <v-divider
              class="navHeader_divider"
              :class="{ divider_changeLogin: isAdmin }"
            ></v-divider>
            <v-list rounded expand>
              <div v-for="menuItem in menuItemsList" :key="menuItem.id">
                <div v-if="Object.keys(menuItem).length == 1">
                  <v-list-group
                    :value="checkMenuRoute == Object.keys(menuItem)[0]"
                  >
                    <template v-slot:activator>
                      <v-list-item-title
                        ><span
                          class="group_list_item_title"
                          :class="{
                            activeNavMenuItem:
                              checkMenuRoute == Object.keys(menuItem)[0],
                          }"
                          >{{ Object.keys(menuItem)[0] }}</span
                        ></v-list-item-title
                      >
                    </template>
                    <div
                      v-for="subMenuItem in Object.values(menuItem)[0]"
                      :key="subMenuItem.id"
                    >
                      <div v-if="subMenuItem.childs">
                        <v-list-group
                          :value="checkMenuRoute == subMenuItem.name"
                        >
                          <template v-slot:activator>
                            <v-list-item-title>
                              <span
                                class="sub_group_list_item_title"
                                :class="{
                                  activeNavMenuItem:
                                    checkMenuRoute == subMenuItem.name,
                                }"
                              >
                                {{ subMenuItem.name }}
                              </span>
                            </v-list-item-title>
                          </template>
                          <v-list-item
                            v-for="el in subMenuItem.childs"
                            :key="el.id"
                            link
                            class="navMenuItem"
                            :to="{ path: el.to }"
                            active-class="activeNavMenuItem"
                            exact-active-class="activeNavMenuItem"
                          >
                            <span class="sub_subMenu">{{ el.name }}</span>
                          </v-list-item>
                        </v-list-group>
                      </div>
                      <div v-else>
                        <v-list-item
                          link
                          class="navMenuItem"
                          :to="{ path: subMenuItem.to }"
                          active-class="activeNavMenuItem"
                          exact-active-class="activeNavMenuItem"
                        >
                          <span class="subMenu">{{ subMenuItem.name }}</span>
                        </v-list-item>
                      </div>
                    </div>
                  </v-list-group>
                </div>
                <div v-else>
                  <div v-if="Object.keys(menuItem)[1] == 'to'">
                    <v-list-item
                      link
                      class="navMenuItem"
                      :to="{ path: menuItem.to }"
                      active-class="activeNavMenuItem"
                      exact-active-class="activeNavMenuItem"
                    >
                      <span>{{ menuItem.name }}</span>
                    </v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item
                      link
                      class="navMenuItem"
                      @click="redirect(menuItem)"
                      active-class="activeNavMenuItem"
                      exact-active-class="activeNavMenuItem"
                    >
                      <span>{{ menuItem.name }}</span>
                    </v-list-item>
                  </div>
                </div>
              </div>
            </v-list>
          </v-navigation-drawer>
        </div>
        <div class="img_logo" @click="gotoFirstPage"></div>
      </div>

      <div class="header_menu" :class="{ hideHeader: isAdmin }">
        <div v-for="menuItem in menuItemsList" :key="menuItem.id">
          <div v-if="Object.keys(menuItem).length == 1">
            <div
              @click.stop="toggleHeaderMenu(Object.keys(menuItem)[0])"
              class="header_menu_txt"
            >
              <span
                @click="gotoSubMenu(Object.values(menuItem)[0][0].to)"
                :class="{
                  activeHeaderMenuTxt:
                    stateHeaderMenus[Object.keys(menuItem)[0]] ||
                    checkMenuRoute == Object.keys(menuItem)[0],
                }"
                >{{ Object.keys(menuItem)[0] }}</span
              >
              <ul
                v-if="checkMenuRoute != ''"
                class="subMenu"
                :class="{
                  activeHeaderMenu:
                    stateHeaderMenus[Object.keys(menuItem)[0]] ||
                    checkMenuRoute == Object.keys(menuItem)[0],
                }"
              >
                <li
                  class="box"
                  v-for="el in Object.values(menuItem)[0]"
                  :key="el.id"
                >
                  <span
                    @click="gotoSubMenu(el.to)"
                    :class="{
                      activeSubMenuTxt: el.name == checkSubMenuRoute,
                    }"
                    >{{ el.name }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <router-link
              class="header_menu_txt"
              :to="{ path: menuItem.to }"
              active-class="activeHeaderMenuTxt"
              exact-active-class="activeHeaderMenuTxt"
              ><span>{{ menuItem.name }}</span></router-link
            >
          </div>
        </div>
      </div>
      <div class="search_logout">
        <ChangeLogin v-if="isAdmin" class="change_login" />
        <div class="header_search">
          <form v-on:submit.prevent="sendSearch_Header" class="input-box">
            <!-- <input
            class="input-box__search"
            type="text"
            placeholder="Search courses"
            :value="searchVal"
            @input="setSearch($event)"
            @click="startSearch"
          /> -->
            <input
              class="input-box__search"
              type="text"
              placeholder="What do you want to do today?"
            />
            <!-- Submit button -->
            <button type="submit" class="input-box__button">
              <img src="@/assets/img/input-mag.svg" class="img-fluid" />
            </button>
          </form>
        </div>
        <div class="header_userInfo">
          <ul>
            <li
              class="item-menu"
              v-click-outside="hideHeaderMenus"
              @click.stop="toggleHeaderMenu('isLogout')"
            >
              <div
                v-if="my_name_picture.picture"
                class="img-fluid"
                :style="{
                  'background-image': 'url(' + my_name_picture.picture + ')',
                }"
              ></div>
              <div v-else class="img-user-empty"></div>
              <span class="user-menu">
                <i class="name">{{
                  my_name_picture.name ? my_name_picture.name : "No Name"
                }}</i>
                <img
                  class="img-fluid-1"
                  src="@/assets/img/nav-arrow-black.svg"
                />
              </span>
            </li>
            <li
              v-if="stateHeaderMenus.isLogout"
              class="item-logout"
              @click="logOut"
            >
              Log Out
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="act_as_me == false && isAdmin" class="banner-acting">
      You are currently acting as {{ getUserName }}
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ClickOutside from "vue-click-outside";
import ChangeLogin from "@/components/header/ChangeLogin";
const userInfoHelpers = createNamespacedHelpers("userInfo");
const salaryWidgetInfoHelpers = createNamespacedHelpers(
  "CurrentVsDeservedSalary"
);

export default {
  name: "Header",
  components: {
    ChangeLogin,
  },
  data() {
    return {
      isAdmin: false,
      my_name_picture: "",
      act_as_me: true,
      drawer: null,
      responsiveHomeMenu: [],
      stateHeaderMenus: {
        Home: false,
        Learning: false,
        "Your Job": false,
        "People Ops Services": false,
        FAQs: false,
        isLogout: false,
      },
      menuItemsList: [
        {
          Home: [],
        },
        { name: "Your Readiness", to: "/readiness" },
        {
          Learning: [
            // { name: "Learning Dashboard", to: "/learning/dashboard" },
            { name: "Course Catalog", to: "/learning/course-catalog" },
            // {
            //   name: "Asked to Learn",
            //   to: "/learning/asked-to-learn",
            //   childs: [
            //     {
            //       name: "Courses you are asked to Complete",
            //       to: "/learning/asked-to-learn",
            //     },
            //   ],
            // },
            // { name: "Learning Events", to: "/learning/learning-events" },
            // { name: "Credit Requests", to: "/learning/credit-requests" },
            { name: "Book Club", to: "/learning/book-club" },
          ],
        },
        // {
        //   "Your Job": [
        //     {
        //       name: "Your Assignments",
        //       to: "/your-job/your-assignments",
        //     },
        //     {
        //       name: "Your Current Responsibilities",
        //       to: "/your-job/your-current-responsibilities",
        //     },
        //     {
        //       name: "Required Certifications",
        //       to: "/your-job/required-certifications",
        //     },
        //     {
        //       name: "Required eLearning",
        //       to: "/your-job/required-elearning",
        //     },
        //   ],
        // },
        // { name: "Your Roadmap", to: "/your-roadmap" },
        {
          "Manage Others": [
            { name: "Reporting to you", to: "/manage-others/direct-reports" },
            { name: "Coaching", to: "/manage-others/coaching" },
          ],
        },
        {
          "People Ops Services": [
            { name: "Your HR File", to: "/people-ops-services/your-hr-file" },
            { name: "FAQs", to: "/people-ops-services/faqs" },
            { name: "Service Desk", to: "/people-ops-services/service-desk" },
          ],
        },
        {
          Compensation: [
            { name: "Your Charges", to: "/compensation/your-charges" },
            { name: "Your Discharges", to: "/compensation/your-discharges" },
            {
              name: "Your cost per hour",
              to: "/compensation/your-cost-per-hour",
            },
            // {
            //   name: "Current vs Deserved Salary",
            //   to: "/compensation/current-vs-deserved-salary",
            // },
          ],
        },
        {
          name: "Branded Apparel",
          redirect: "https://shop.inkdstores.com/nanoramic/shop/home",
        },
      ],
      navHomeSubItems: [
        { name: "Dashboard", to: "/home/dashboard" },
        { name: "Directory", to: "/home/directory" },
        { name: "Status", to: "/home/status" },
      ],
      homeSubItems: [
        { name: "Dashboard", to: "/home/dashboard" },
        { name: "Directory", to: "/home/directory" },
        // { name: "Org Chart", to: "/home/orgchart" },
        // { name: "Status", to: "/home/status" },
      ],
    };
  },
  computed: {
    ...userInfoHelpers.mapGetters(["getUserName"]),
    // ...salaryWidgetInfoHelpers.mapGetters(["getSalaryWidgetAttribute"]),
    ...salaryWidgetInfoHelpers.mapState(["salaryWidgetAttribute"]),
    checkMenuRoute() {
      let routeName = "";
      switch (true) {
        case this.$route.name == "Directory" ||
          this.$route.name == "Org Chart" ||
          this.$route.name == "Status" ||
          this.$route.name == "Dashboard":
          routeName = "Home";
          break;
        case this.$route.name == "Learning Dashboard" ||
          this.$route.name == "Course Catalog" ||
          this.$route.name == "Asked to Learn" ||
          this.$route.name == "Learning Events" ||
          this.$route.name == "Credit Requests" ||
          this.$route.name == "Book Club":
          routeName = "Learning";
          break;
        case this.$route.name == "Your Assignments" ||
          this.$route.name == "Your Current Responsibilities" ||
          this.$route.name == "Required eLearning" ||
          this.$route.name == "Required Certifications":
          routeName = "Your Job";
          break;
        case this.$route.name == "Direct Reports" ||
          this.$route.name == "Coaching":
          routeName = "Manage Others";
          break;
        case this.$route.name == "Your HR File" ||
          this.$route.name == "FAQs" ||
          this.$route.name == "Service Desk":
          routeName = "People Ops Services";
          break;
        case this.$route.name == "Your Charges" ||
          this.$route.name == "Your Discharges" ||
          this.$route.name == "Your Cost Per Hour":
          routeName = "Compensation";
          break;
        default:
          break;
      }
      return routeName;
    },
    checkSubMenuRoute() {
      return this.$route.name;
    },
  },
  watch: {
    salaryWidgetAttribute: function () {
      this.isPublishDeservedSalary();
    },
  },

  created() {
    this.dispatchSalaryWidgetAttribute(localStorage.getItem("employee_id"));
    this.isAdmin = localStorage.getItem("user1") ? true : false;
  },
  mounted() {
    const datamyuser = localStorage.getItem("my_name_picture");
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    this.my_name_picture = JSON.parse(datamyuser);
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...salaryWidgetInfoHelpers.mapActions(["dispatchSalaryWidgetAttribute"]),
    onResize() {
      if (window.innerWidth < 1200) {
        this.responsiveHomeMenu = this.navHomeSubItems;
        this.menuItemsList[0].Home = this.navHomeSubItems;
      } else {
        this.responsiveHomeMenu = this.homeSubItems;
        this.menuItemsList[0].Home = this.homeSubItems;
      }
    },
    gotoFirstPage() {
      let url = `/home/dashboard`;
      this.$router.push(url).catch((err) => {
        console.log(err);
      });
    },
    gotoSubMenu(_path) {
      this.$router.push(_path).catch((err) => {
        console.log(err);
      });
    },
    hideHeaderMenus() {
      // this.stateHeaderMenus.home = false;
      // this.stateHeaderMenus.learning = false;
      this.stateHeaderMenus.isLogout = false;
    },
    toggleHeaderMenu(st_txt) {
      for (const name of Object.keys(this.stateHeaderMenus)) {
        if (name === st_txt) {
          this.stateHeaderMenus[name] = !this.stateHeaderMenus[name];
        } else {
          this.stateHeaderMenus[name] = false;
        }
      }
    },
    sendSearch_Header() {
      console.log("headersearch");
    },
    logOut() {
      localStorage.clear();
      this.$router.push({
        name: "Login",
        query: { nextUrl: `${this.$route.path}` },
      });
    },
    redirect(menuItem) {
      window.open(menuItem.redirect, "_blank");
    },
    isPublishDeservedSalary() {
      if (
        Object.keys(this.salaryWidgetAttribute).length != 0 &&
        !("error" in this.salaryWidgetAttribute) &&
        this.salaryWidgetAttribute.current_salary != 0 &&
        this.salaryWidgetAttribute.employee_readiness != 0
      ) {
        for (let item of this.menuItemsList) {
          if ("Compensation" in item) {
            item.Compensation.push({
              name: "Current vs Deserved Salary",
              to: "/compensation/current-vs-deserved-salary",
            });
            return;
          }
        }
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.showDrawer {
  display: block !important;
}
.hideHeader {
  display: none !important;
}
.activeHeaderMenu {
  transform: scaleX(1) !important;
}
.activeHeaderMenuTxt {
  text-decoration-line: underline !important;
  color: #ffffff !important;
  span {
    color: #ffffff !important;
  }
}
.activeSubMenuTxt {
  color: $blue !important;
  text-decoration-line: underline;
}

/////////////// When responsive, NavigationDrawer Class

.group_list_item_title {
  padding: 0px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}
.sub_group_list_item_title {
  padding-left: 25px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}
.navMenuItem {
  padding-left: 15px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black !important;
}

.activeNavMenuItem {
  color: #549ddc;
  text-decoration-line: underline;
}

//////Main CSS////////
.header {
  display: flex;
  flex-direction: column;
  .header_up {
    margin: 30px 80px 0px 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ///// NavigationDrawer

    .navDrawer_logo {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      .navDrawer {
        display: none;
        margin-right: 40px;
        .img_navDrawer {
          min-width: 42px;
          height: 42px;
          background-image: url("~@/assets/img/onNavDrawer.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .v-navigation-drawer {
          border-radius: 15px;
          width: 340px !important;
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
          padding: 25px;
          .navHeader_search_content {
            display: none;
            .navHeader_search {
              .input-box {
                align-items: center;
                background-color: $light-violet !important;
                border-radius: 30px;
                display: flex;
                height: 3rem;
                justify-content: space-between;
                margin-bottom: 0.8rem;
                // margin-right: 1rem;
                margin-top: 1rem;
                padding-left: 10px;
                padding-right: 10px;
                position: relative;
                width: 290px;
                &__search {
                  background-color: $light-violet !important;
                  border: 0;
                  color: $gray;
                  flex: 1;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 0.875rem;
                  line-height: 16px;
                  outline: none;
                }
                &__button {
                  background-color: $light-violet !important;
                  border: 0;
                  border-radius: 50%;
                  cursor: pointer;
                  height: 1.91375rem;
                  width: 1.91375rem;
                  .img-fluid {
                    width: 0.956875rem;
                  }
                }
              }
            }
          }
          .navHeader_change_content {
            display: none;
          }
          .navHeader_divider {
            display: none;
          }

          .subMenu {
            padding-left: 25px;
          }

          .sub_subMenu {
            padding-left: 50px;
          }
        }
      }
      .img_logo {
        background-image: url("~@/assets/img/logo.svg");
        min-width: 136px;
        height: 36px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }

    ///////////////////////////

    .header_menu {
      // margin: 0px 60px;
      height: 56px;
      padding: 0px 30px;
      background-color: $blue;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_menu_txt {
        display: block;
        margin: 0px 30px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        position: relative;
        text-decoration: none;
        color: #ffffff80;
        text-align: center;
        span {
          color: #ffffff80;
          cursor: pointer;
        }
        span:hover {
          color: #ffffff;
          text-decoration-line: underline;
        }
        .subMenu {
          display: flex;
          flex-flow: row;
          align-items: center;
          background-color: $white;
          height: 48px;
          // box-shadow: 0px 6px 10px rgb(195 196 198 / 60%);
          border-radius: 30px;
          left: -2rem;
          margin-top: 34px;
          padding: 0 10px !important;
          overflow: hidden;
          position: absolute;
          transform: scaleX(0);
          transition: all 0.3s ease;
          top: 100%;

          z-index: 1;
          white-space: nowrap;
          .box {
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            padding: 0 30px;
            span {
              color: $black;
            }
            span:hover {
              color: #549ddc;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
    .change_login {
      margin-right: 46px;
      margin-left: 46px;
      // display: flex;
      // flex-flow: row;
      // justify-content: flex-end;
    }
    .search_logout {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;

      .header_search {
        // margin-right: 45px;
        // margin-left: 0px;
        .input-box {
          align-items: center;
          background-color: white !important;
          border-radius: 30px;
          display: flex;
          height: 3rem;
          justify-content: space-between;
          margin-bottom: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          padding-left: 24px;
          padding-right: 16px;
          position: relative;
          width: 320px;
          &__search {
            background-color: white !important;
            border: 0;
            color: $gray;
            flex: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 16px;
            outline: none;
          }
          &__button {
            background-color: white !important;
            border: 0;
            border-radius: 50%;
            cursor: pointer;
            height: 1.91375rem;
            width: 1.91375rem;
            .img-fluid {
              width: 0.956875rem;
            }
          }
        }
      }
      .header_userInfo {
        display: flex;
        justify-content: flex-end;

        .item-menu {
          cursor: pointer;
          display: flex;
          align-items: center;

          .img-fluid {
            width: 2rem;
            height: 2rem;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
          }
          .user-menu {
            margin-left: 1rem;
            display: flex;
            .name {
              font-style: normal;
              font-weight: 500;
              font-size: 1rem;
              line-height: 19px;
              margin-right: 0.75rem;
            }
            .img-fluid-1 {
              width: 0.55rem;
            }
          }
        }
        .item-menu:hover {
          color: $blue;
        }
        .item-logout {
          border: 1px solid white;
          background-color: $white;
          width: 9.375rem;
          height: 2.375rem;
          margin-top: 0.5rem;
          text-align: center;
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 1rem;
          cursor: pointer;
          position: absolute;
          margin-left: 0rem;
          box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.6);
        }
        .item-logout:hover {
          color: #c70909;
        }
      }
    }
  }
  .banner-acting {
    // display: block;
    flex-grow: 1;
    background-color: $orange;
    margin: 0 80px 0 268px;
    padding: 14px 0 14px 0;
    border-radius: 30px;
    text-align: center;
    color: $white;
    font-size: 0.875rem; //14px;
    font-style: normal;
    font-weight: 600;
  }
}
@media screen and (max-width: 1765px) {
  .navDrawer {
    display: block !important;
  }
  .header_menu {
    display: none !important;
  }
}
@media screen and (max-width: 1370px) {
  // .navHeader_change_content {
  //   display: block !important;
  // }
  // .change_login {
  //   display: none;
  // }
  .divider_changeLogin {
    display: block !important;
  }
}
@media screen and (max-width: 900px) {
  .header_up {
    margin: 30px;
    .navHeader_search_content {
      display: block !important;
    }
    .navHeader_divider {
      display: block !important;
    }
    .header_search {
      display: none;
    }
    .user-menu {
      .name {
        display: none;
      }
    }
    .item-logout {
      right: 20px;
    }
  }
  .banner-acting {
    margin: 28px 0 !important;
    border-radius: 0 !important;
  }
}
</style>
