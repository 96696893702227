import axios from "./_axios";
import { API } from "./apiEndPoints";
// import { getUID } from "./getUID";

export const getTagGroup = () => {
  let result = axios()({
    url: API.GET_TAGS,
    method: "patch",
    params: { action: "get_tags" },
  });
  return result;
};

/*export const getCourses_UID = () => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: { action: "get_channels", user_id: getUID() },
  });
  return result;
};*/

export const getCourses = () => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: { action: "get_channels" },
  });
  return result;
};

export const getCourseDetailWithID = (_courseID) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: { action: "get_channels", channel_id: _courseID },
  });
  return result;
};
export const getCourseDetailWithIDUnpublished = (_courseID) => {
  let result = axios()({
    url: API.GET_COURSES,
    method: "patch",
    params: {
      action: "get_channels",
      channel_id: _courseID,
      include_unpublished: 1,
    },
  });
  return result;
};
