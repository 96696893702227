export default {
  namespaced: true,
  state: {
    pageNumber: 1,
    filterTagList: [],
    filterTag: "",
    searchKey: "",
    mobileMenuActive: false,
  },
  mutations: {
    setValuesDefault(state, status) {
      state.pageNumber = 1;
      state.filterTagList = [];
      state.filterTag = "";
      state.searchKey = "";
    },
    setPageNumber(state, status) {
      state.pageNumber = status;
    },
    setFilterTagList(state, status) {
      state.filterTagList = status;
    },
    setFilterTag(state, status) {
      state.filterTag = status;
    },
    setSearchKey(state, status) {
      state.searchKey = status;
    },
    setMobileMenuActive(state, status) {
      state.mobileMenuActive = status;
    },
  },
  getters: {
    getPageNumber(state) {
      return state.pageNumber;
    },
    getFilterTagList(state) {
      return state.filterTagList;
    },
    getFilterTag(state) {
      return state.filterTag;
    },
    getSearchkey(state) {
      return state.searchKey;
    },
    getMobileMenuActive(state) {
      return state.mobileMenuActive;
    },
  },
};
