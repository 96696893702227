import axios from "./_axios";
import { API } from "./apiEndPoints";
export const getStatusForLastWeek = (start_date, end_date) => {
  let result = axios()({
    url: API.GETEMPLOYEESTATUS,
    method: "patch",
    params: {
      action: "_employee_statuses",
      employee_id: localStorage.getItem("employee_id"),
      start_date: start_date,
      end_date: end_date,
    },
  });
  return result;
};
