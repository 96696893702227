import axios from "./_axios";
import { API } from "./apiEndPoints";

export const loginEmailPW = (params) => {
  let result = axios(false).get(API.Login, {
    params: {
      login: params.email,
      password: params.password,
      db: `${process.env.odoo_pre_release}`,
    },
  });
  return result;
};

export const loginWithGoogleToken = (_token) => {
  let result = axios(false)({
    method: "post",
    url: API.GOOGLETOKEN,
    params: {
      google_token: _token,
    },
  });
  return result;
};

export const getEmployeeInfo = () => {
  let result = axios()({
    url: API.EMPLOYEE_INFO,
    method: "patch",
    params: { action: "get_employee_info" },
  });
  return result;
};
export const getEmployeeInfoAndToken = (_id_employee) => {
  let result = axios()({
    url: API.EMPLOYEE_INFO,
    method: "patch",
    params: {
      action: "get_employee_info",
      employee_id: _id_employee,
      get_access_token: 1,
    },
  });
  return result;
};

export const getUserInfo = (_employee_id) => {
  let result = axios()({
    url: API.GET_DIRECTORY_EMPLOYEE,
    method: "patch",
    params: { action: "get_directory_employees", employee_id: _employee_id },
  });
  return result;
};
export const getChildsTacticalReadiness = (_employee_id) => {
  let result = axios()({
    url: API.GET_DIRECTORY_EMPLOYEE,
    method: "patch",
    params: {
      action: "get_employee_child_ids",
      employee_id: _employee_id
        ? _employee_id
        : localStorage.getItem("employee_id"),
      skip_responsibilities: 0,
    },
  });
  return result;
};
