//   Jan 01, 2000
//   Today
const EditDate = (_date) => {
  if (_date) {
    const today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth();
    let currentDay = today.getDate();
    let started_date = _date;
    let split_date = started_date.split("-", 3);
    let _year = split_date[0];
    let _month = split_date[1];
    let _day = split_date[2];
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let selectedMonthName = months[parseInt(_month) - 1];

    if (
      currentYear == _year &&
      currentMonth + 1 == _month &&
      currentDay == _day
    ) {
      return "Today";
    }
    return selectedMonthName + " " + _day + ", " + _year;
  }
  return "";
};
export default EditDate;
