<template>
  <div class="table1-container">
    <div class="header-container">
      <CommitmentSigns />
      <v-checkbox
        v-if="notAcknowledgedResp"
        v-model="showNotAcknowledged"
        label="Show only not acknowledged yet"
        color="#53a0de"
        hide-details
      ></v-checkbox>
    </div>
    <DxDataGrid
      id="gridContainer1"
      :ref="gridRefKey"
      :data-source="rows"
      :columns="column_names"
      key-expr="INDEX"
      :show-borders="true"
      :showRowLines="true"
      :showColumnLines="false"
      @selection-changed="selectionChanged"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
      :column-hiding-enabled="true"
    >
      <DxSorting mode="multiple" />
      <DxColumn
        data-field="Commitment / Responsibility"
        cell-template="responsibilityTemplate"
        :calculate-sort-value="valuesCommitmentAndName"
        :sorting-method="sortCommitmentAndName"
        sort-order="asc"
      />
      <DxColumn
        data-field="Your current roles including this responsibility"
        cell-template="tagsTemplate"
        :hiding-priority="3"
      />
      <DxColumn
        data-field="Tactical Readiness"
        cell-template="readinessTemplate"
        :hiding-priority="2"
      />
      <DxColumn
        data-field="Proficiency Badge"
        cell-template="proficiencyTemplate"
        :hiding-priority="1"
      />
      <DxColumn
        data-field="Acknowledged On"
        cell-template="acknowledgeOnTemplate"
        :hiding-priority="0"
      />
      <DxSelection mode="single" />
      <DxMasterDetail :enabled="true" template="detailTemplate" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #responsibilityTemplate="{ data: rowData }">
        <div>
          <v-icon v-if="rowData.data.commitment == 'primary'" size="15"
            >$levelHigh</v-icon
          >
          <v-icon v-else-if="rowData.data.commitment == 'backup'" size="15"
            >$levelMedium</v-icon
          >
          <v-icon v-else-if="rowData.data.commitment == 'preparing'" size="15"
            >$levelLow</v-icon
          >
          <span class="ml-2">{{
            rowData.data["Commitment / Responsibility"]
          }}</span>
        </div>
      </template>
      <template #tagsTemplate="{ data: rowData }">
        <div>
          <v-chip
            v-for="(chip, index) in rowData.data[
              'Your current roles including this responsibility'
            ]"
            :key="index"
            class="tags-sm"
            color="#53A0DE33"
            text-color="#53A0DE"
          >
            {{ chip }}
          </v-chip>
        </div>
      </template>
      <template #readinessTemplate="{ data: rowData }">
        <BulletChartResponsibilities :barData="readinessValues(rowData.data)" />
      </template>
      <template #proficiencyTemplate="{ data: rowData }">
        <div>
          <v-chip
            v-if="rowData.data.badge"
            :color="colorProficiency(rowData.data.badge)"
            class="badge-tag"
            label
            :outlined="rowData.data.badge == 'novice'"
          >
            {{ rowData.data.badge }}
          </v-chip>
          <p v-if="rowData.data.target" class="target-label">
            Target > {{ rowData.data.target }}
          </p>
          <v-list-item v-if="rowData.data.tracker_data">
            <v-list-item-avatar rounded="30px">
              <div
                class="selectedImg"
                :style="{
                  backgroundImage:
                    'url(' + rowData.data.tracker_data.tracker_image_128 + ')',
                }"
              ></div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-html="`Coached by ${rowData.data.tracker_data.tracker_name}`"
              ></v-list-item-title>
              <v-list-item-subtitle
                class="situational-leadership-label"
                :style="{
                  color: colorLeadership(
                    rowData.data.tracker_data.situation_leadership_id
                  ),
                }"
              >
                {{ rowData.data.tracker_data.situation_leadership_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
      <template #acknowledgeOnTemplate="{ data: rowData }">
        <div>
          <v-btn
            v-if="!rowData.data['Acknowledged On']"
            :disabled="act_as_me == false || disabled_button"
            depressed
            rounded
            color="#ddde00"
            class="btn-accept"
            @click.stop="onAcknowledgeResp(rowData.data)"
          >
            Acknowledge now
          </v-btn>
          <span v-else>{{ rowData.data["Acknowledged On"] }}</span>
        </div>
      </template>
      <template #detailTemplate="{ data: rowData }">
        <MenuCurrentResp :rows="rowData.data" />
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxSelection,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxSorting,
} from "devextreme-vue/data-grid";
import EditDate from "@/utils/edit_date_2.js";
import MenuCurrentResp from "@/components/current-responsibilities/MenuCurrentResp";
import { updateResponsibilityAcknowledgeDate } from "@/api";
import { createNamespacedHelpers } from "vuex";
import BulletChartResponsibilities from "@/common/DevExtreme/BulletChartResponsibilities";
import CommitmentSigns from "@/common/CommitmentSigns.vue";
const { mapActions } = createNamespacedHelpers("isLoading");
import { LEADERSHIP, PROFICIENCY } from "@/utils/constants";

const gridRefKey = "data-grid";

export default {
  name: "TableDetail",
  components: {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxSorting,
    CommitmentSigns,
    BulletChartResponsibilities,
    MenuCurrentResp,
  },
  props: ["rows", "column_names", "disabled_button"],
  data() {
    return {
      act_as_me: true,
      gridRefKey,
      showNotAcknowledged: false,
      selectedPageNumber: 1,
      eventsPagination: 1,
      valuesCommitmentAndName(rowData) {
        return [rowData.commitment, rowData["Commitment / Responsibility"]];
      },
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    if (this.notAcknowledgedResp > 0) this.showNotAcknowledged = true;
  },
  computed: {
    dataGrid: function () {
      return this.$refs[gridRefKey].instance;
    },
    notAcknowledgedResp() {
      const _pending_ack = this.rows.filter((a) => a["Acknowledged On"] == "");
      return _pending_ack.length;
    },
  },
  watch: {
    showNotAcknowledged: function (current, prev) {
      // filtering API (DEVEXTREME)
      if (current) {
        this.dataGrid.filter([["Acknowledged On", "=", ""]]);
      } else {
        this.dataGrid.filter([
          ["Acknowledged On", "<>", ""],
          "or",
          ["Acknowledged On", "=", ""],
        ]);
      }
    },
    notAcknowledgedResp: function (current) {
      if (current == 0) {
        this.showNotAcknowledged = false;
      }
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    sortCommitmentAndName(value1, value2) {
      if (value1[0] == value2[0]) {
        return value1[1].localeCompare(value2[1]);
      }
      if (value1[0] == "primary") {
        return -1;
      }
      if (value2[0] == "primary") {
        return 1;
      }
      if (value1[0] == "backup") {
        return -1;
      }
      if (value2[0] == "backup") {
        return 1;
      }
      return 1;
    },
    editAcknowledgment() {
      this.dataGrid.filter([["Acknowledged On", "<>", ""]]);
      this.dataGrid.filter([["Acknowledged On", "=", ""]]);
    },
    async onAcknowledgeResp(_row) {
      this.getLoading(true);
      try {
        const _today = new Date();
        const result = await updateResponsibilityAcknowledgeDate(
          _row.id,
          _today
        );
        if (result.status == 200) {
          _row["Acknowledged On"] = EditDate(_today);
          this.editAcknowledgment();
        }
      } catch (error) {
        console.log("acceptRoleError==>", error);
      }
      this.getLoading(false);
    },
    colorLeadership(situational_leadership) {
      if (situational_leadership) {
        let _color = LEADERSHIP[situational_leadership].color;
        return _color ? _color : "#000000";
      }
      return "#000000";
    },
    colorProficiency(proficiency) {
      if (proficiency) {
        let _color = PROFICIENCY[proficiency].color;
        return _color ? _color : "#000000";
      }
      return "#000000";
    },
    readinessValues(rowData) {
      let arrayBar = new Array();
      arrayBar.push({
        value: rowData.responsibility_certification_progress,
      });
      arrayBar.push({
        value: rowData.responsibility_proficiency_progress,
      });
      return arrayBar;
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.table1-container {
  padding: 40px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tags-sm {
  padding: 6px 14px 4px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px !important;
  line-height: 12.38px;
}
.badge-tag {
  display: block;
  padding: 6px 14px 4px;
  text-align: center;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.target-label {
  text-align: center;
  text-transform: capitalize;
}
.situational-leadership-label {
  font-weight: 600;
}
.btn-accept {
  display: block;
  padding: 5px 14px !important;
  color: $white;
  border-radius: 30px;
  margin: auto !important;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  line-height: 17px !important;
}
.dxg-detail-container {
  margin: 24px 60px;
  .dxg-detail-title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19.6px;
    color: #53a0de;
  }
  .dxg-detail-content {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16.8px;
    ul {
      list-style: none;
      list-style-position: inside;
      li::before {
        content: "\2022";
        font-weight: bold;
        display: inline-block;
        width: 6px;
        color: #53a0de;
        margin-right: 12px;
        // margin-bottom: 12px;
      }
    }
  }
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
