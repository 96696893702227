<template>
  <div class="text-center">
    <v-menu offset-y content-class="drop-down">
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" class="btn-menu">
          <span class="btn-text">{{ name }}</span>
          <menu-arrow color="white" />
        </button>
      </template>

      <ul class="list">
        <li
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(item)"
          class="list-item"
        >
          {{ item }}
        </li>
      </ul>
    </v-menu>
  </div>
</template>

<script>
import MenuArrow from "@/components/icons/MenuArrow.vue";
export default {
  name: "drop-down",
  props: {
    items: {
      type: Array,
    },
    name: {
      default: "",
      type: String,
    },
  },
  components: {
    MenuArrow,
  },
  methods: {
    handleClick(item) {
      console.log("selectingTags====Tagmenu", item);
      this.$emit("select-item", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;

  .btn-text {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: $white;
  }
}

.drop-down {
  padding: 16px 20px;
  background-color: $white;
  border-radius: $default-br;

  .list {
    column-count: 3;
    column-gap: 70px;
    padding: 0;
    list-style: none;

    .list-item {
      padding: 8px 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
      cursor: pointer;
    }
    .list-item:hover {
      color: yellowgreen;
    }
  }
}
@media screen and (max-width: 600px) {
  .drop-down {
    left: 30px !important;
    .list {
      column-count: 2;
      column-gap: 60px;
    }
  }
}
</style>
