<template>
  <div>
    <div class="locations-page">
      <div class="back-item">
        <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
        <span class="back-item__text" @click="backToPages"
          >&nbsp;Back to Directory</span
        >
      </div>
      <span class="txt_pageTitle">Locations</span>
      <div class="filters-container">
        <Filters :filtersTags="departmentList" @selectedTag="filterByTag" />
        <span class="countries-count">{{ totalCountriesCount }}</span>
      </div>
      <SelectedTags
        :selectedTags="selectedTags"
        :selectedTag="selectedTag"
        @removeTagOne="removeFilterOne"
        @removeTag="removeFilter"
        @removeTags="removeFilters"
      />
      <div class="location-list">
        <div v-for="country in employeeRegionCountryList" :key="country.region">
          <div class="list_container">
            <div class="list_item">
              <div v-if="country.region" class="txt_region">
                <span>{{ country.region }}</span>
                <span>{{ country.region_country_count }}</span>
              </div>
              <div
                v-for="country in country.countries"
                :key="country.country_name"
                class="location_info"
                @click="gotoEmployees(country.country_name)"
              >
                <div class="countryName-image">
                  <span class="txt_country">{{ country.country_name }}</span>
                  <div
                    class="img_country"
                    :style="{
                      backgroundImage: 'url(' + country.flag + ')',
                    }"
                  ></div>
                </div>

                <span class="txt_count">{{ country.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Filters from "@/components/locations/Filters";
import SelectedTags from "@/components/course-catalog/SelectedTags";
import FilterLocation from "@/utils/filter_location.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsDetailHelpers = createNamespacedHelpers(
  "nanoramicLabsDetail"
);
export default {
  name: "Locations",
  data() {
    return {
      selectedTags: [], // This is the array that saves all the selected tags from the filters menu.
      selectedTag: "",
      employeeRegionCountryList: [],
    };
  },
  created() {
    this.getLoading(false);
    this.filterEmployees();
    this.setSortArray();
  },
  components: {
    Filters,
    SelectedTags,
  },
  computed: {
    ...nanoramicLabsDetailHelpers.mapGetters([
      "departmentList",
      "employeeNameJobList",
      "employeeRegions",
    ]),
    totalCountriesCount() {
      return this.employeeRegionCountryList.reduce(
        (sum, val) => sum + val.countries.length,
        0
      );
    },
  },
  methods: {
    ...mapActions(["getLoading"]),
    filterEmployees() {
      this.employeeRegionCountryList = FilterLocation(
        this.employeeNameJobList,
        this.employeeRegions,
        this.selectedTags
      );
    },
    setSortArray() {
      this.employeeRegionCountryList.sort(function (a, b) {
        return b.length - a.length;
      });
    },
    gotoEmployees(_countryName) {
      let url = `/employees?locations=${_countryName}`;
      url =
        this.selectedTags.length > 0
          ? `${url}&departments=${JSON.stringify(this.selectedTags)}`
          : url;
      this.$router.push(url);
    },
    backToPages() {
      let url = `/home/directory`;
      this.$router.push(url);
    },
    filterByTag(_tags) {
      this.selectedTag = "";
      this.query = "";
      if (this.selectedTags) {
        _tags.forEach((t) => {
          if (!this.selectedTags.includes(t)) this.selectedTags.push(t);
        });
      } else {
        _tags.forEach((t) => {
          this.selectedTags.push(t);
        });
      }
      this.filterEmployees();
    },
    removeFilterOne(filter) {
      this.removeFilters();
    },
    removeFilter(filter) {
      const index = this.selectedTags.findIndex((t) => filter === t);
      this.selectedTags.splice(index, 1);
      this.filterEmployees();
      // Calculate pages from filtered courses.
      this.getLoading(false);
    },
    // Removes all the filters.
    removeFilters() {
      this.selectedTags = [];
      this.selectedTag = "";
      this.filterEmployees();
    },
  },
};
</script>
<style lang="scss" scoped>
.locations-page {
  margin: 30px 80px;
  .back-item {
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      margin-left: 5px;
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      text-transform: capitalize;
    }
  }
  .txt_pageTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #323a44;
  }
  .filters-container {
    position: relative;
    .countries-count {
      position: absolute;
      top: 0;
      right: 0;
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 19px;
      margin: 30px 50px;
    }
  }
  .location-list {
    display: grid;
    grid-gap: 30px 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 30px;
    .list_container {
      border-radius: 30px;
      background: white;
      padding: 40px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      .list_item {
        width: 100%;
        .txt_region {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
          color: #53a0de;
          padding-bottom: 15px;
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
        }
        .location_info {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          padding: 15px 0px;
          border-bottom: 1px solid #e6e6e6;
          cursor: pointer;
          .countryName-image {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            .txt_country {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #323a44;
            }
            .img_country {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              margin-left: 5px;
            }
          }
          .txt_count {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            color: #53a0de;
            width: 24px;
            height: 24px;
            background: #53a0de4d;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 7px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1650px) {
  .location-list {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media screen and (max-width: 1280px) {
  .location-list {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 900px) {
  .locations-page {
    margin: 30px 30px 50px 30px;
    .location-list {
      grid-template-columns: 1fr !important;
      grid-gap: 20px;
    }
  }
}
</style>
