<template>
  <div class="employeeInfo-page-content">
    <div class="user-info-1">
      <div class="user-info-card">
        <div class="user-info-card__summary">
          <div class="user-info-status">
            <div class="user-img-flag-status">
              <div class="user-img">
                <!-- <img class="user-img__image" :src="user_image" /> -->
                <!-- <img class="user-img__image" src="@/assets/img/avatar.svg" /> -->
                <div
                  class="user-img__image"
                  :style="{
                    'background-image':
                      'url(' + employeeInfo.image_128_base64 + ')',
                  }"
                ></div>
              </div>
              <div class="user-flag">
                <!-- <img class="user-flag__image" :src="flag_image" /> -->
                <!-- <img class="user-flag__image" src="@/assets/img/US_flag.svg" /> -->
                <div
                  class="user-flag__image"
                  :style="{
                    'background-image': 'url(' + employeeInfo.flag_base64 + ')',
                  }"
                ></div>
              </div>

              <div class="user-status">
                <span class="user-status__text">{{
                  employeeInfo.employee_status
                }}</span>
              </div>
            </div>

            <div class="user-experience">
              <ul>
                <li class="user-experience__name">
                  <span>{{ employeeInfo.name }}</span>
                </li>
                <li class="user-experience__role">
                  <span>{{ employeeInfo.job_title }}</span>
                </li>
                <li v-if="employeeInfo.employee_pronoun">
                  <span class="user-experience__started">{{
                    employeeInfo.employee_pronoun
                  }}</span>
                </li>
                <li v-if="employee_startedDate">
                  <img
                    class="started_icon"
                    src="@/assets/img/started_icon.svg"
                  />
                  <span class="user-experience__started">{{
                    employee_startedDate
                  }}</span>
                </li>
              </ul>
            </div>
            <div v-if="flag" class="readiness_chart">
              <!-- style="margin-left:15px" -->
              <span class="mainChartOption-readinessValue">
                Readiness {{ mainChartOption.employeeReadinessValue }}%
              </span>

              <div style="margin-top: 5px; margin-left: -15px">
                <bulletChartWidget :data="mainChartOption"> </bulletChartWidget>
              </div>
            </div>
          </div>
          <div class="user-activate">
            <ul>
              <li class="user-activate__items">
                <img class="items-img" src="@/assets/img/activate_flag.svg" />
                <span class="items-txt">{{
                  employeeInfo.department_name
                }}</span>
              </li>
              <li class="user-activate__items">
                <img class="items-img" src="@/assets/img/activate_home.svg" />
                <span class="items-txt">{{
                  employeeInfo.is_remote
                    ? "Works from home"
                    : "Works from office"
                }}</span>
              </li>
              <li class="user-activate__items">
                <img class="items-img" src="@/assets/img/activate_time.svg" />
                <span class="items-txt">{{ timestamp }}</span>
              </li>
              <li class="user-activate__items">
                <img
                  class="items-img"
                  src="@/assets/img/activate_location.svg"
                />
                <!-- <span class="items-txt">{{ this.employee_activate_location = this.employeeInfo.country_name
          ? this.employeeInfo.country_name
          : "" + "/" + this.employeeInfo.city_name
          ? this.employeeInfo.city_name
          : ""; }}</span> -->
                <span
                  class="items-txt"
                  v-if="employeeInfo.country_work_location"
                >
                  {{ employeeInfo.country_work_location }}
                  {{
                    !employeeInfo.state_work_location ||
                    employeeInfo.state_work_location === "Blank"
                      ? ""
                      : `/ ${employeeInfo.state_work_location}`
                  }}
                  {{
                    employeeInfo.city_work_location
                      ? `/ ${employeeInfo.city_work_location}`
                      : ""
                  }}
                </span>
              </li>
            </ul>
            <div>
              <span class="readiness_sparkline">Last 12 Weeks</span>
              <div>
                <DxSparkline
                  v-if="source.length != 0"
                  :data-source="source"
                  value-field="readiness"
                  class="sparkline"
                  argument-field="date"
                  type="bar"
                  :show-first-last="false"
                  :on-drawn="setColors"
                  :column-auto-width="true"
                >
                  <DxTooltip :enabled="false" />
                </DxSparkline>
              </div>
            </div>
          </div>
        </div>
        <div class="user-bio">
          <div class="user-bio__title">
            <span>Bio</span>
          </div>
          <div
            v-if="employeeInfo.bio"
            class="user-bio__content"
            v-html="employeeInfo.bio"
          ></div>
        </div>
        <div class="user-birthday">
          <div class="user-birthday__info">
            <ul>
              <li class="user-birthday-title">
                <span>Birthday</span>
              </li>
              <li class="user-birthday-date" v-if="employee_birthday">
                <span>{{ employee_birthday }}</span>
              </li>
            </ul>
          </div>
          <div class="user-birthday__gift">
            <img @click="onSent_Gift" src="@/assets/img/gift.svg" />
          </div>
        </div>
        <div class="user-languages">
          <div class="user-languages__title">
            <span>Spoken languages</span>
          </div>
          <div class="user-languages__show">
            <div
              class="user-languages-items"
              v-for="user_language in employeeInfo.languages"
              :key="user_language.id"
              :style="'background:' + employee_language_bgColor"
            >
              {{ user_language }}
            </div>
          </div>
        </div>
      </div>
      <div class="user-info-card-2">
        <div class="user-contact-info">
          <div class="user-contact-info__row">
            <div class="user-contact-info-spec">
              <div class="user-contact-info-show">
                <ul>
                  <li class="user-contact-info-kind">
                    <span>Email</span>
                  </li>
                  <li class="user-contact-info-data">
                    <span>{{ employeeInfo.work_email }}</span>
                  </li>
                </ul>
              </div>
              <div class="user-contact-to-send">
                <a
                  class="contact-send-img user-email"
                  :href="`mailto:${
                    employeeInfo.work_email ? employeeInfo.work_email : ''
                  }`"
                ></a>
              </div>
            </div>
            <div class="under-line">
              <!-- <img src="@/assets/img/user_contact_info_underline.svg" /> -->
            </div>
          </div>
          <!-- User Mobile -->
          <div class="user-contact-info__row">
            <div class="user-contact-info-spec">
              <div class="user-contact-info-show">
                <ul>
                  <li class="user-contact-info-kind">
                    <span>Mobile</span>
                  </li>
                  <li class="user-contact-info-data">
                    <span>{{
                      employeeInfo.mobile ? employeeInfo.mobile : ""
                    }}</span>
                  </li>
                </ul>
              </div>
              <div class="user-contact-to-send" v-if="employeeInfo.mobile">
                <!-- <a class="contact-send-img user-message" /> -->
                <a
                  class="contact-send-img user-phone"
                  :href="`tel:${
                    employeeInfo.mobile ? employeeInfo.mobile : ''
                  }`"
                />
              </div>
            </div>
            <div class="under-line">
              <!-- <img src="@/assets/img/user_contact_info_underline.svg" /> -->
            </div>
          </div>
          <div class="user-contact-info__row">
            <div class="user-contact-info-spec">
              <div class="user-contact-info-show">
                <ul>
                  <li class="user-contact-info-kind">
                    <span>Office-phone</span>
                  </li>
                  <li class="user-contact-info-data">
                    <span>{{
                      employeeInfo.mobile_phone ? employeeInfo.mobile_phone : ""
                    }}</span>
                  </li>
                </ul>
              </div>
              <div
                class="user-contact-to-send"
                v-if="employeeInfo.mobile_phone"
              >
                <!-- <a class="contact-send-img user-message" /> -->
                <a
                  class="contact-send-img user-phone"
                  :href="`tel:${
                    employeeInfo.mobile_phone ? employeeInfo.mobile_phone : ''
                  }`"
                />
              </div>
            </div>
            <div class="under-line">
              <!-- <img src="@/assets/img/user_contact_info_underline.svg" /> -->
            </div>
          </div>
          <div class="user-contact-info__row">
            <div class="user-contact-info-spec">
              <div class="user-contact-info-show">
                <ul>
                  <li class="user-contact-info-kind">
                    <span>Slack</span>
                  </li>
                  <li class="user-contact-info-data">
                    <span>{{
                      employeeInfo.slack_username
                        ? "@" + employeeInfo.slack_username
                        : ""
                    }}</span>
                  </li>
                </ul>
              </div>
              <!-- <div
                class="user-contact-to-send"
                v-if="employeeInfo.slack_username"
              >
                <a class="contact-send-img user-message" />
              </div> -->
            </div>
          </div>
        </div>
        <div class="user-graph">
          <div
            v-if="employeeInfo.pi_placard_base64"
            class="user-graph__img"
            :style="{ 'background-image': 'url(' + m_placard + ')' }"
          ></div>
          <div v-else class="user-graph_no">{{ m_placard }}</div>
        </div>
      </div>
      <div class="user-info-card-3">
        <!-- readiness -->
        <div id="loadig-readiness" class="employee_readiness">
          <EmployeeReadiness :loading="loading_roles" :rows="dataSource" />
        </div>
      </div>
    </div>
    <div class="user-info-2">
      <div class="user-report">
        <div class="user-report__container">
          <div class="report-to-user" v-if="employeeInfo.parent_id">
            <span class="report-kind-text">Reports to</span>
            <UserReportRow
              v-for="report in reportsTo"
              :key="report.id"
              :report="report"
            />
          </div>
          <div class="report-divider" v-if="employeeInfo.parent_id"></div>
          <div class="user-direct-reports" v-if="employeeInfo.child_ids">
            <div class="report-row">
              <span class="report-kind-text">Direct Reports</span>
              <span class="report-kind-text__length">{{
                direct_reports_length
              }}</span>
            </div>
            <UserReportRow
              v-for="report in direct_reports"
              :key="report.id"
              :report="report"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("isLoading");
import moment from "moment";
import UserReportRow from "@/components/user-profile/UserReportRow";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
import EditDateStarted from "@/utils/edit_date_4.js";
import EditDateBirthday from "@/utils/edit_date_5.js";
import { LAST_DAY_ROLE } from "@/utils/constants.js";
import DaysOffset from "@/utils/days_offset.js";
import EmployeeReadiness from "@/components/employee/EmployeeReadiness.vue";
import { getTecticalReadiness, getReadinessDataPoints } from "@/api";
const readinessHelper = createNamespacedHelpers("readiness");
import bulletChartWidget from "@/components/readiness/bulletChartWidget";
import DxSparkline, { DxTooltip } from "devextreme-vue/sparkline";

import {
  getUserInfo,
  getResponsibilitiesEmployee,
  getTacticalRoleAssignmentsEmployee,
  getTacticalCertificationEmployee,
} from "@/api";
export default {
  name: "EmployeeInfo",
  components: {
    UserReportRow,
    EmployeeReadiness,
    bulletChartWidget,
    DxSparkline,
    DxTooltip,
  },
  data() {
    return {
      timestamp: "",
      employee_startedDate: "",
      employee_birthday: "",
      direct_reports: [],
      reportsTo: [],
      m_placard: "",
      employeeInfo: [],
      employeeId: null,
      employee_language_bgColor: "#53A0DE;",
      rows_Roles: [],
      rows_Certifications: [],
      rows_Responsibilities: [],
      loading_roles: true,
      loading_responsibilities: true,
      loading_certifications: true,
      flag: false,
      source: [],
      dataSource: [],
      EmployeeReadiness: false,
      mainChartOption: {
        chartType: "fusion_chart_bullet_chart_widget",
        employeeReadinessValue: "",
        bulletColor: "",
        readinessChartOption: {
          attrs: {
            options: {
              start: 0,
              end: 100,
              color: [
                {
                  minValue: "0",
                  maxValue: "49.99",
                  code: "#979EA8",
                },
                {
                  minValue: "49.99",
                  maxValue: "79.99",
                  code: "#CFD4DB",
                },
                {
                  minValue: "79.99",
                  maxValue: "100",
                  code: "#F2F3F5",
                },
              ],
              bulletColor: [
                {
                  minValue: "0",
                  maxValue: "49.9999",
                  code: "#E81313",
                },
                {
                  minValue: "49.9999",
                  maxValue: "79.9999",
                  code: "#1071E5",
                },
                {
                  minValue: "79.9999",
                  maxValue: "100",
                  code: "#54C45E",
                },
              ],
            },
          },
        },
      },
    };
  },
  created() {
    this.getDataAPI();
    if (this.$route.query.employee) {
      this.getReadiness(this.$route.query.employee);
      const id_employee = this.$route.query.employee;
      this.getReadinessData(this.$route.query.employee);
      this.act_as_me = false;
    } else {
      this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
      this.getReadiness(localStorage.getItem("employee_id"));
      this.getReadinessData(localStorage.getItem("employee_id"));
    }
  },
  computed: {
    direct_reports_length() {
      return this.direct_reports.length ? this.direct_reports.length : "";
    },
  },
  methods: {
    async getDataAPI() {
      await this.getEmployeeInfo();
      await this.getCurrentRoles();
      await this.getCurrentResponsibilities();
      await this.getRequiredCertifications();
    },
    async getEmployeeInfo() {
      try {
        if (this.$route.name == "Directory") {
          this.employeeId = localStorage.getItem("employee_id");
        } else {
          this.employeeId = Object.values(this.$route.query)[0];
        }
        let result = await getUserInfo(this.employeeId);
        if (result.status == 200) {
          this.employeeInfo = result.data.data.response.data[0];
          this.employeeInfo.image_128_base64 = this.employeeInfo
            .image_128_base64
            ? `"data:image/jpeg;base64, ${this.employeeInfo.image_128_base64}"`
            : "";
          this.employeeInfo.flag_base64 = this.employeeInfo.flag_base64
            ? `"data:image/jpeg;base64, ${this.employeeInfo.flag_base64}"`
            : "";
          this.employeeInfo.employee_pronoun = this.employeeInfo
            .employee_pronoun
            ? CapitalizeFirstLetter(this.employeeInfo.employee_pronoun)
            : false;
          setInterval(() => {
            this.getNowTime();
          }, 1000);
          this.employee_birthday = EditDateBirthday(this.employeeInfo.birthday);
          this.placard();
          this.employee_startedDate = EditDateStarted(
            this.employeeInfo.start_date
          );
          await this.setParentChildren();
        }
      } catch (error) {
        console.log("EmployeeInfoError==>", error);
      }
    },
    getNowTime() {
      this.timestamp =
        moment().tz(this.employeeInfo.work_location_tz).format("h:mm a") +
        " local time";
    },
    placard() {
      this.m_placard = this.employeeInfo.pi_placard_base64
        ? `"data:image/jpeg;base64, ${this.employeeInfo.pi_placard_base64}"`
        : "No Placard";
    },
    async setParentChildren() {
      if (this.employeeInfo.parent_id) {
        let result_parent = await getUserInfo(this.employeeInfo.parent_id);
        if (result_parent.status == 200) {
          const _r_t = result_parent.data.data.response.data[0];
          let _reportsTo = { name: "", img: "", role: "", report_id: "" };
          _reportsTo.name = _r_t.name;
          _reportsTo.role = _r_t.job_title;
          _reportsTo.img = `"data:image/jpeg;base64, ${_r_t.image_128_base64}"`;
          _reportsTo.report_id = _r_t.id;
          _reportsTo.employee_pronoun = _r_t.employee_pronoun
            ? CapitalizeFirstLetter(_r_t.employee_pronoun)
            : false;
          this.reportsTo.push(_reportsTo);
        }
      }
      if (this.employeeInfo.child_ids) {
        for (let i = 0; i < this.employeeInfo.child_ids.length; i++) {
          const _id = this.employeeInfo.child_ids[i];
          let result_child = await getUserInfo(_id);
          if (result_child.status == 200) {
            let _direct_reports = {
              name: "",
              img: "",
              role: "",
              report_id: "",
              employee_pronoun: false,
            };
            let _d_r = result_child.data.data.response.data[0];
            _direct_reports.name = _d_r.name;
            _direct_reports.role = _d_r.job_title;
            _direct_reports.img = _d_r.image_128_base64
              ? `"data:image/jpeg;base64, ${_d_r.image_128_base64}"`
              : "";
            _direct_reports.report_id = _d_r.id;
            _direct_reports.employee_pronoun = _d_r.employee_pronoun
              ? CapitalizeFirstLetter(_d_r.employee_pronoun)
              : false;
            this.direct_reports.push(_direct_reports);
          }
        }
      }
    },
    async getCurrentRoles() {
      let roles = [];
      try {
        let result = [];
        result = await getTacticalRoleAssignmentsEmployee(this.employeeId);
        if (result.status == 200) {
          let gettingRoles = result.data.data.response.assignments;
          for (const [i, role] of gettingRoles.entries()) {
            const role_list = role.role_id.data[0].role_id[0];
            const roleRelieved = role.date_to
              ? DaysOffset(role.date_to)
              : DaysOffset(LAST_DAY_ROLE);

            if (roleRelieved <= 0) {
              roles.push({
                id: role.id,
                Role: role_list?.display_name ? role_list.display_name : "-",
                Commitment: CapitalizeFirstLetter(role.commitment) || "-",
              });
            }
          }
        }
      } catch (e) {
        console.log("GetCurrentRolesError===>", e);
      } finally {
        this.rows_Roles = roles.slice(0, 3);
        this.loading_roles = false;
      }
    },
    async getCurrentResponsibilities() {
      let resp = [];
      try {
        let result = [];
        result = await getResponsibilitiesEmployee(this.employeeId);
        if (result.status == 200) {
          let gettingResp = result.data.data.response.responsibilities;
          for (const index in gettingResp) {
            const _resp = gettingResp[index];
            resp.push({
              id: _resp.id,
              Responsibility: _resp.name ? _resp.name : "-",
              Detail: _resp.description
                ? _resp.description
                : "<p>Description not provided<br></p>",
            });
          }
        }
      } catch (e) {
        console.log("GetResponsibilitiesError===>", e);
      } finally {
        this.rows_Responsibilities = resp.slice(0, 3);
        this.loading_responsibilities = false;
      }
    },
    setColors(e) {
      // ref to datasource collection
      const dataItems = e.component.getDataSource().items();

      // ref and iterate over sparkline markers
      e.element.querySelectorAll(".dxc-markers rect").forEach((el, i) => {
        // lookup value from chart dataset
        const { readiness: value } = dataItems[i];

        // get color string based on the value from chart dataset
        const color =
          value >= 80 ? "#A7D42E" : value <= 50 ? "#FF6C6C" : "#53A0DE";

        // update "fill" attribute of the marker
        el.setAttribute("fill", color);
      });
    },
    async getRequiredCertifications() {
      let cert = [];
      try {
        let result = [];
        result = await getTacticalCertificationEmployee(this.employeeId);
        if (result.status == 200) {
          let gettingCertifications = result.data.data.response;
          if (Object.keys(gettingCertifications).length !== 0) {
            for (const index in gettingCertifications) {
              const _cert = gettingCertifications[index];
              cert.push({
                "Certification Program": _cert.name || "-",
                Status: _cert.active ? "Certified" : "Missing",
              });
            }
          }
        }
      } catch (e) {
        console.log("GetResponsibilitiesError===>", e);
      } finally {
        this.rows_Certifications = cert.slice(0, 3);
        this.loading_certifications = false;
      }
    },
    onSent_Gift() {
      console.log("getemployeeInfo====>", this.employeeInfo);
    },

    ...readinessHelper.mapMutations(["setReadinessData"]),
    async getReadiness(employeeId) {
      try {
        const result = await getTecticalReadiness(employeeId);
        this.setReadinessData(result.data.data.response.assignments);
        this.mainChartOption.employeeReadinessValue =
          result.data.data.response.readiness;
        this.mainChartOption.bulletColor = this.getBulletColor(
          this.mainChartOption.readinessChartOption,
          result.data.data.response.readiness
        );
        this.flag = true;
        this.dataSource = [];
        this.prepareData(result.data.data.response.assignments, 0);
      } catch (error) {
        console.log("getTecticalReadiness==>", error);
      } finally {
        this.loading_roles = false;
      }
    },
    async getReadinessData(employeeId) {
      try {
        const result = await getReadinessDataPoints(employeeId, 0);
        this.source = result.data.data.response.data.map((x) => ({
          ...x,
          readiness: x.readiness * 100,
        }));
      } catch (error) {
        console.log("getReadinessData==>", error);
      }
    },
    prepareData(res, parentID) {
      for (let i = 0; i < res.length; i++) {
        this.formateData(res[i], parentID);
        if (res[i].children.length > 0) {
          this.prepareData(
            res[i].children,
            parentID + res[i].model.split(".").join("") + res[i].res_id
          );
        }
      }
    },
    formateData(rowData, parentID) {
      let formatedData = {
        ID: parentID + rowData.model.split(".").join("") + rowData.res_id,
        res_id: rowData.res_id,
        name: rowData.fields.find((x) => x.name == "name").value,
        icon: rowData.icons,
        ParentID: parentID,
        employeeReadinessValue: rowData.fields.find(
          (x) => x.name == "employee_readiness"
        ).value,
        readinessChartOption: rowData.nodes.employee_readiness,
        bulletColor: this.getBulletColor(
          rowData.nodes.employee_readiness,
          rowData.fields.find((x) => x.name == "employee_readiness").value
        ),
        chartType: rowData.nodes.employee_readiness.attrs.widget,
        fields: rowData.fields,
      };
      if (formatedData.ID != parentID) {
        this.dataSource.push(formatedData);
      }
    },
    getBulletColor(data, value) {
      if (data.attrs.options.bulletColor) {
        for (const element of data.attrs.options.bulletColor) {
          if (value <= element.maxValue) {
            return element.code;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.employeeInfo-page-content {
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 73% 27%;

  .user-info-1 {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 70% 30%;
    .user-info-card {
      padding: 2.5rem;
      background: #ffffff;
      border-radius: 1.875rem;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      grid-column: 1/2;
      height: fit-content;

      &__summary {
        // margin-left: 2.5rem;
        // margin-right: 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        // display: grid;

        .user-img-flag-status {
          margin-left: 0rem;
          margin-top: 0rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          .user-img {
            &__image {
              // background: #aeabe6;
              background-color: white;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              border-radius: 50%;
              width: 7.5rem;
              height: 7.5rem;
            }
          }
          .user-flag {
            position: absolute;
            width: 1.625rem;
            height: 1.625rem;
            border-radius: 50%;
            top: 3px;
            right: -4px;
            &__image {
              background: white;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              border-radius: 50%;
              margin-top: 0rem;
              margin-left: 0rem;
              width: 1.625rem;
              height: 1.625rem;
            }
          }
          .user-status {
            width: 100%;
            height: 1.8125rem;
            border-radius: 1.25rem;
            background: #ddde00;
            margin-top: -1.8125rem;
            text-align: center;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            //   vertical-align: middle;
            &__text {
              // vertical-align: sub;
              vertical-align: -webkit-baseline-middle;
              font-family: Gilroy;
              font-style: normal;
              font-weight: bold;
              font-size: 0.875rem;
              line-height: 0.875rem;
              color: #ffffff;
            }
          }
        }

        .user-experience {
          // min-width: 11.125rem;
          // min-height: 5.875rem;
          // backgrosund: yellow;
          // margin-right: 1.6875rem;
          margin-top: 0.875rem;
          font-family: Gilroy;
          text-align: center;
          margin-left: 30px;

          &__name {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.8125rem;
            color: #323a44;
            padding-bottom: 0.5rem;
          }
          &__role {
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            color: #5d646d;
            padding-bottom: 0.5rem;
          }
          .started_icon {
            padding-right: 0.5rem;
            vertical-align: middle;
          }
          &__started {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1rem;
            color: #5d646d;
          }
        }
        .user-activate {
          margin-top: 0.875rem;
          // min-width: 9.375rem;
          // min-height: 6.25rem;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1rem;
          color: #5d646d;
          ul li {
            vertical-align: text-bottom;
          }

          &__items {
            padding-bottom: 0.75rem;
            display: flex;
            .items-img {
              padding-right: 0.5rem;
              vertical-align: middle;
              display: inline-block;
            }
            .items-txt {
              // width: 7.625rem;
              vertical-align: middle;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
          }
        }
      }
      .user-bio {
        margin-top: 2.15rem;
        height: auto;
        &__title {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: #323a44;
          padding-bottom: 0.1875rem;
        }
        &__content {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1rem;

          color: #7f868e;
        }
      }
      .user-birthday {
        margin-top: 2.351875rem;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        &__info {
          .user-birthday-title {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            color: #323a44;
            padding-bottom: 0.205625rem;
            // min-width: 245px;
          }
          .user-birthday-date {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 1rem;
            color: #7f868e;
          }
        }
        &__gift {
          // min-width: 245px;
          // justify-content: space-between;
          text-align: right;
          cursor: pointer;
        }
      }
      .user-languages {
        // margin: 2.5rem;
        margin: 2.5rem 0rem;
        margin-bottom: 0rem;
        min-height: 3.5625rem;
        &__title {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: #323a44;
          padding-bottom: 0.609375rem;
          margin-top: 0rem;
          margin-left: 0rem;
        }
        &__show {
          margin-top: 0rem;
          margin-left: 0rem;
          display: inline-flex;
          flex-direction: row;
          // width: 30.625rem;
          flex-wrap: wrap;
          .user-languages-items {
            border-radius: 1.875rem;
            margin-right: 0.375rem;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            font-weight: bold;
            padding: 0.4375rem 1.0625rem;
            margin-bottom: 0.625rem;
            color: white;
          }
        }
      }
    }

    .user-info-card-2 {
      display: flex;
      flex-direction: column;
      gap: 24px;
      // grid-column: 2/3;
      .user-contact-info {
        padding-bottom: 0.90875rem;
        padding-top: 0.90875rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        // width: 23.375rem;
        height: auto;
        background: #ffffff;
        border-radius: 1.875rem;
        box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
        &__row {
          padding-top: 0.96625rem;

          .under-line {
            height: 1;
            padding-top: 0.0625rem;
            // padding-bottom: 0.96625rem;
            background-color: #f1f0f0;
          }

          .user-contact-info-spec {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.96625rem;
            .user-contact-info-show {
              // min-width: 12.75rem;
              .user-contact-info-kind {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                color: #323a44;
              }
              .user-contact-info-data {
                padding-top: 0.25rem;
                font-style: normal;
                font-weight: normal;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #7f868e;
                word-break: break-word;
              }
            }
            .user-contact-to-send {
              // min-width: 5rem;
              // text-align: right;
              display: flex;
              .contact-send-img {
                margin-left: 0.75rem;
                // cursor: pointer;
                width: 1.5rem;
                height: 1.5rem;
              }
              .user-email {
                background-image: url("~@/assets/img/email_img.svg");
              }
              .user-message {
                background-image: url("~@/assets/img/chat_img.svg");
              }
              .user-phone {
                background-image: url("~@/assets/img/phone_call_img.svg");
              }
            }
          }
        }
      }
      .user-graph {
        // display: inline-block;
        // width: 23.375rem;
        max-height: 18.75rem;
        background: #ffffff;
        border-radius: 1.875rem;
        vertical-align: top;
        box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        &__img {
          // width: 20.625rem;
          width: 320px;
          height: 13.75rem;
          margin: 2.5rem 1.625rem;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        &_no {
          // width: 23.375rem;
          width: 100%;
          height: 18.75rem;
          text-align: center;
          font-size: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .user-info-2 {
    // grid-column: 3/4;
    display: flex;
    flex-direction: column;
    // gap: 24px;
    .user-report {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      //   display: inline-block;
      // width: 23.375rem;
      // height: 18.75rem;
      height: auto;
      background: #ffffff;
      border-radius: 1.875rem;
      vertical-align: top;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      &__container {
        // margin-top: 2.5rem;
        margin-left: 2.5rem;
        margin-right: 2.75rem;
        // margin-bottom: 2.5rem;
        .report-to-user,
        .user-direct-reports {
          .report-kind-text {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            color: #323a44;
            &__length {
              font-size: 1.275rem;
            }
          }
        }
        .report-divider {
          padding-top: 1.875rem;
        }
      }
    }
  }

  .user-info-card-3 {
    grid-column: 1 / span 2;
    grid-row: third-line / 3;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    //   display: inline-block;
    // width: 23.375rem;
    // height: 18.75rem;
    height: auto;
    background: #ffffff;
    border-radius: 1.875rem;
    vertical-align: top;
    box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
  }
}
@media screen and (max-width: 1510px) {
  .employeeInfo-page-content {
    grid-template-columns: 73% 27%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: minmax(100px, max-content);
    .user-info-1 {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    .user-info-2 {
      grid-column: 1/2;
      // grid-row: 2/3;
    }
  }
}
@media screen and (max-width: 1263px) {
  .employeeInfo-page-content {
    .user-info-card-2 {
      .user-contact-info {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      .user-report__container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .employeeInfo-page-content {
    .user-info-card {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .user-info-card-2 .user-contact-info {
      margin-left: 0rem;
    }
    .user-info-card-2 .user-graph {
      margin-left: 0rem;
    }
    .user-info-card-3 .user-report {
      margin-left: 0rem;
    }
  }
}
@media screen and (max-width: 800px) {
  .employeeInfo-page-content {
    .user-info-1 {
      grid-column: 1/2;
      grid-row: 1/2;
      grid-template-columns: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: minmax(100px, max-content);
      //  grid-template-rows: 100%;
      grid-template-rows: repeat(2, 1fr);
      grid-template-rows: minmax(100px, max-content);
      .user-info-card-2 {
        grid-column: 1/2;
        grid-row: 2/3;
      }

      .user-info-card-3 {
        grid-column: 1/2;
        grid-row: 3/4;
      }
    }
    // .user-info-2 {
    //   grid-column: 1/2;
    //   grid-row: 2/3;
    // }
    .user-info-2 {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }
}
.mainChartOption-readinessValue,
.readiness_sparkline {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #323a44;
}
.readiness_chart {
  grid-column: 1 / span 2;
  grid-row: second-line / 2;
}
// .user-info-card__summary {
//   display: grid;
//   // margin-left: 15px;
//   // grid-gap: 48px;
//   // grid-template-columns: 30% 20%
// }
.user-info-status {
  display: grid;
  // grid-gap: 5px;
  grid-template-columns: 25% 75%;
}
</style>
