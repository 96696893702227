const CreateSimpleDate = (simpleDate) => {
  let today = new Date();
  let _year = today.getFullYear();
  let _month = today.getMonth() + 1;
  let _day = today.getDate();

  let _date_week = new Date();
  switch (simpleDate) {
    case "start-week":
      _date_week.setDate(today.getDate() - today.getDay() + 1);
      _year = _date_week.getFullYear();
      _month = _date_week.getMonth() + 1;
      _day = _date_week.getDate();
      break;
    case "end-week":
      _date_week.setDate(today.getDate() + 7 - today.getDay());
      _year = _date_week.getFullYear();
      _month = _date_week.getMonth() + 1;
      _day = _date_week.getDate();
      break;
    default:
      break;
  }
  _day = _day < 10 ? "0" + _day : _day;
  _month = _month < 10 ? "0" + _month : _month;
  return _year + "-" + _month + "-" + _day;
};
export default CreateSimpleDate;
