//   01/January/2000 13:00
const EditDateTime = (_date) => {
  let today = new Date();
  let thisTimeZone = today.getTimezoneOffset();

  let setdate = new Date(_date);
  let set_date = new Date(setdate - thisTimeZone * 60 * 1000);

  let setYear = set_date.getFullYear();
  let setMonth = set_date.getMonth();
  let setDate = set_date.getDate();
  setDate = setDate < 10 ? "0" + setDate : setDate;
  let setHours = set_date.getHours();
  setHours = setHours < 10 ? "0" + setHours : setHours;
  let setMinutes = set_date.getMinutes();
  setMinutes = setMinutes < 10 ? "0" + setMinutes : setMinutes;
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    setDate +
    "/" +
    months[setMonth] +
    "/" +
    setYear +
    " " +
    setHours +
    ":" +
    setMinutes
  );
};
export default EditDateTime;
