export default {
  namespaced: true,
  state: {
    FAQ_size: 20,
    FAQ_pagination: 0,
  },
  actions: {
    setFAQPagination({ commit }, _count) {
      commit("setFAQPagination", _count);
    },
  },
  mutations: {
    setFAQPagination(state, _count) {
      state.FAQ_pagination = _count;
    },
  },
  getters: {
    faqSize(state) {
      return state.FAQ_size;
    },
    faqPagination(state) {
      return state.FAQ_pagination;
    },
  },
};
