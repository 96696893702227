<template>
  <div class="text-center">
    <v-dialog v-model="dialog" content-class="dialog" persistent scrollable>
      <template v-slot:activator="{ on, attrs }">
        <div class="request-button-container">
          <v-btn
            depressed
            rounded
            color="#53a0de"
            v-bind="attrs"
            v-on="on"
            height="47"
            width="237"
            class="btn-popup mt-3"
          >
            Create a Credit Request
          </v-btn>
        </div>
      </template>

      <v-card class="card">
        <v-card-title class="headline">
          <base-btn :onClick="closeDialog">
            <v-icon class="icon--close">$close</v-icon>
            Close
          </base-btn>
          <span class="title-text">Create a Credit Request</span>
        </v-card-title>

        <v-form ref="myForm" v-model="validForm" class="form-wrapper">
          <div class="popup-form-wrapper text-center">
            <FormCreditRequest
              class="form"
              v-for="(section, index) in requestForm"
              :key="index"
              :fields="section.fields"
              @input="changeDescription"
            />
            <v-btn
              :disabled="act_as_me == false"
              depressed
              rounded
              color="#53a0de"
              class="btn-popup mt-16 mb-7"
              @click="onRequestCredits"
            >
              Submit
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseBtn from "@/components/tasks/BaseBtn";
import FormCreditRequest from "@/components/credit-requests/FormCreditRequest";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("isLoading");
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
const dialogCompleteHelpers = createNamespacedHelpers("dialogComplete");
const tacticalReadinesHelper = createNamespacedHelpers("tacticalReadines");

import {
  requestCredits,
  getLearningActivities,
  submitCreditsRequest,
} from "@/api";
export default {
  components: {
    BaseBtn,
    FormCreditRequest,
  },
  created() {
    this.getLoading(true);
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
    this.requestForm[0].fields[2].value = new Date();
    this.getLATypes();
    this.getLoading(false);
  },

  data() {
    return {
      act_as_me: true,
      dialog: false,
      // learning_activity_types: [],
      validForm: false,
      requestForm: [
        {
          fields: [
            {
              value: "",
              type: "many2one",
              label: "Learning activity type",
              name: "la_type",
              required: true,
            },
            {
              value: "",
              type: "integer",
              label: "CLE Credits",
              name: "cle_credits",
              required: false,
            },
            {
              value: "",
              type: "date_time",
              label: "Requested on",
              name: "requested_on",
              required: false,
            },
          ],
        },
        {
          fields: [
            {
              value: "",
              type: "long_text",
              label: "Description",
              name: "description",
              required: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...dialogCompleteHelpers.mapMutations([
      "setDialogComplete",
      "setDataCompleted",
    ]),
    ...tacticalReadinesHelper.mapMutations(["setLearningActivityTypes"]),
    async getLATypes() {
      try {
        const result = await getLearningActivities();
        let learningActivityTypes = result.data.data.response;
        if (result.status == 200) {
          let la_types_list = learningActivityTypes.map((la_Type) => {
            return {
              text: la_Type.name,
              value: la_Type.id,
              credits: la_Type.credits,
            };
          });
          this.setLearningActivityTypes(la_types_list);
        }
      } catch (error) {
        console.log(error);
      }
    },

    changeDescription(new_val) {
      this.requestForm[1].fields[0].value = new_val;
    },
    closeDialog() {
      this.dialog = false;
    },

    async onRequestCredits() {
      if (this.validForm) {
        let _id_la = this.requestForm[0].fields[0].value;
        let _description = this.requestForm[1].fields[0].value;
        let _this = this;
        let _data = {
          type_dialog: "error1",
          title: "Your request submission was failed",
          subtitle1: "An error was found, please try again later.",
        };
        try {
          this.getLoading(true);
          const result = await requestCredits(_description, _id_la);
          if (result.status == 200) {
            let submitted = await this.submitCredits(result.data.data.id);
            if (submitted) {
              _data = {
                type_dialog: "success1",
                title: "Your request was submitted successfully",
                subtitle1: "It could be like a toast",
              };
            }
          }
        } catch (error) {
          console.log(error.message);
        } finally {
          this.dialog = false;
          _this.setDataCompleted(_data);
          _this.setDialogComplete(true);
          this.getLoading(false);
        }
      } else {
        this.$refs.myForm.validate();
      }
    },
    async submitCredits(id_req_credits_draft) {
      try {
        const result = await submitCreditsRequest(id_req_credits_draft);
        if (result.status == 200) {
          return true;
        }
      } catch (error) {
        console.log("SubmitCreditsError===>", error);
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  height: 40px;
}
.request-button-container {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  padding-right: 40px;
}
.btn-popup {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  text-transform: none;
  padding: 14px 40px !important;
  letter-spacing: normal;
}

.v-dialog.dialog {
  margin: 80px;
  border-radius: 30px;

  .v-card__title.headline {
    padding: 20px 30px;
    justify-content: start;
    background-color: #53a0de;

    .icon--close {
      margin-right: 8px;
    }
  }

  .card {
    height: 100%;
    overflow: hidden;

    .title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $white;
      margin: 0 auto;
    }

    .form-wrapper {
      height: calc(100% - 64px);

      .popup-form-wrapper {
        padding: 20px 30px 10px 30px;
        background: white;
      }

      .headshot-wrapper {
        height: 100%;
      }

      .form {
        margin-bottom: 24px;
        // background: #f6f6fb;
        background: #e5e5e5;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .v-dialog__content {
    ::v-deep {
      .v-dialog {
        margin: 0 10px !important;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .v-card__title.headline {
    padding: 20px 10px !important;
    .icon--close {
      margin-right: 2px !important;
    }
  }
  .popup-form-wrapper {
    padding: 20px !important;
  }
  .btn-popup {
    font-size: 12px;
  }
}
</style>
