<template>
  <v-card class="course-intro-scroll" v-if="takeCourse">
    <v-card-text>
      <div class="course-intro-container">
        <div class="intro-1">
          <div class="img-course" :style="takeCourse.bg"></div>
          <div class="intro-container">
            <span class="course-title">{{ takeCourse.title }}</span>
            <ul class="meta">
              <li class="meta__item">
                <div class="dot bg-red"></div>
                <span class="min-step-txt">{{ takeCourse.minutes }}</span>
              </li>
              <li class="meta__item">
                <div class="dot bg-green"></div>
                <span class="min-step-txt">{{ takeCourse.steps }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="tags">
          <div class="tags__item" v-for="tag in takeCourse.tags" :key="tag.id">
            {{ tag }}
          </div>
        </div>
        <div class="intro-description" v-if="takeCourse.description">
          <span class="course-description-text"
            >{{ takeCourse.description }}
          </span>
        </div>
        <div class="intro-progress-bar">
          <span class="progress-bar-text">Course progress</span>
          <v-progress-linear
            class="progress-bar-container"
            :value="takeCourse.completion"
            :color="progress_bar_color"
            height="16"
          >
            <strong class="progress-text">
              {{ Math.ceil(takeCourse.completion) }}%
            </strong>
          </v-progress-linear>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const takeCourseHelper = createNamespacedHelpers("takeCourse");
export default {
  name: "CourseIntro",
  data() {
    return {
      progress_bar_color: "#A7D42E",
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters(["takeCourse"]),
  },
};
</script>
<style lang="scss" scoped></style>
