<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="course-selected-page">
          <ElearningDescription
            v-if="courseDetail"
            :courseDetail="courseDetail"
            @setEnrollCourse="setEnrollCourse"
            @setStartCourse="setStartCourse"
          />
          <v-row v-else>
            <span>Course not available</span>
          </v-row>
          <CoursePopup
            v-if="openDialog"
            :course_id="course_id"
            class="content-btn"
            :task_id="task_id"
            :openDialog="openDialog"
            :showDescriptionDirectly="false"
            :title_top="
              courseDetail.completed
                ? `Approve for publishing course ${courseDetail.title}`
                : 'Please complete this task'
            "
            @exit="closeCourse"
            @completedCourse="completedCourse"
          />
        </section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import CoursePopup from "@/components/course/CoursePopup";
import ElearningDescription from "@/components/course/ElearningDescription";
import { enrollingCourse, getCourseDetailWithIDUnpublished } from "@/api";
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  name: "CourseSelected",
  props: {
    course_id: {
      type: Number,
      required: true,
    },
    task_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      openDialog: false,
      courseDetail: {},
    };
  },
  components: {
    CoursePopup,
    ElearningDescription,
  },
  created() {
    this.getDataCourse();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getDataCourse() {
      try {
        this.getLoading(true);
        let result = await getCourseDetailWithIDUnpublished(this.course_id);
        if (result.status == 200) {
          const response = result.data.data.response[0];
          this.courseDetail = response;
        }
      } catch (error) {
        console.log("getCourseToApproveError===>", error);
      } finally {
        this.getLoading(false);
      }
    },
    closeCourse() {
      this.openDialog = false;
      this.getDataCourse();
    },
    async completedCourse() {
      this.closeCourse();
      this.$emit("completedCourse");
    },
    setStartCourse() {
      this.openDialog = true;
    },
    async setEnrollCourse() {
      try {
        let result = await enrollingCourse(this.courseDetail.id);
        if (result.status == 200) {
          this.courseDetail.button = "start";
        }
      } catch (error) {
        console.log("enrollError=>", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0px;
}
.v-application ul,
.v-application ol {
  padding: 0px;
}
</style>
