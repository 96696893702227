<template>
  <div>
    <DxDataGrid
      id="gridContainer1"
      :data-source="row"
      :columns="col"
      key-expr="id"
      :show-borders="true"
      :showRowLines="true"
      @selection-changed="selectionChanged"
      :allow-column-resizing="true"
    >
      <DxColumn data-field="Course" />
      <DxColumn data-field="Description" />
      <DxColumn data-field="View" cell-template="buttonTemplate" />
      <DxSelection mode="single" />
      <DxMasterDetail :enabled="true" template="detailTemplate" />
      <DxPaging :page-size="10" />
      <DxPager :visible="true" :show-navigation-buttons="true" />
      <template #buttonTemplate="{ data: rowData }">
        <div class="btn_container">
          <v-btn
            :disabled="act_as_me == false"
            class="btn_txt"
            depressed
            rounded
            :color="rowData.data['View'] == 'to_do' ? '#a7d42e' : '#53a0de'"
            height="40"
            width="135"
            @click.stop="openCourse(rowData.data.id)"
          >
            {{ rowData.data["View"] == "to_do" ? "Start" : "Continue" }}
          </v-btn>
        </div>
      </template>
      <template #detailTemplate="{ data: rowData }">
        <slot :row="rowData.data"></slot>
      </template>
    </DxDataGrid>
    <CoursePopup
      v-if="openDialog"
      :course_id="course_id"
      :openDialog="openDialog"
      :showDescriptionDirectly="false"
      title_top="Please complete this task"
      @exit="closeCourse"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { getCourseDetailWithIDUnpublished } from "@/api";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const takeCourseHelper = createNamespacedHelpers("takeCourse");
const { mapActions } = createNamespacedHelpers("isLoading");
import CoursePopup from "@/components/course/CoursePopup";

import DxDataGrid, {
  DxSelection,
  DxMasterDetail,
  DxColumn,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxMasterDetail,
    DxColumn,
    DxPager,
    DxPaging,
    CoursePopup,
  },
  props: ["col", "row"],
  data() {
    return {
      act_as_me: true,
      course_id: null,
      openDialog: false,
    };
  },
  created() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  methods: {
    ...mapActions(["getLoading"]),
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    openCourse(_id) {
      this.course_id = _id;
      this.openDialog = true;
    },
    closeCourse() {
      this.openDialog = false;
    },
  },
};
</script>
<style lang="scss">
// assets/components/dev-express-grid.scss
.btn_container {
  display: flex;
  justify-content: center;
  .btn_txt {
    .v-btn__content {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.0625rem;
      color: white;
      text-transform: capitalize;
    }
  }
}
</style>
