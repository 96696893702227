import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import Close from "@/components/icons/Close";
import Address from "@/components/icons/Address";
import Birthday from "@/components/icons/Birthday";
import CloseChips from "@/components/icons/CloseChips";
import Crop from "@/components/icons/Crop";
import ZoomIn from "@/components/icons/ZoomIn";
import ZoomOut from "@/components/icons/ZoomOut";
import Search from "@/components/icons/Search";
import ArrowL from "@/components/icons/ArrowL";
import ArrowR from "@/components/icons/ArrowR";
import ArrowDownBlue from "@/components/icons/ArrowDownBlue";
import CloseBlue from "@/components/icons/CloseBlue";
import InfoCircle from "@/components/icons/InfoCircle";
import ClockBlue from "@/components/icons/ClockBlue";
import CheckBlue from "@/components/icons/CheckBlue";
import ClearGrey from "@/components/icons/ClearGrey.vue";
import LinkBlue from "@/components/icons/LinkBlue.vue";
import LevelLow from "@/components/icons/LevelLow.vue";
import LevelMedium from "@/components/icons/LevelMedium.vue";
import LevelHigh from "@/components/icons/LevelHigh.vue";
import DeleteBlack from "@/components/icons/DeleteBlack.vue";
import EditBlack from "@/components/icons/EditBlack.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      close: {
        component: Close,
      },
      address: {
        component: Address,
      },
      birthday: {
        component: Birthday,
      },
      closeChips: {
        component: CloseChips,
      },
      crop: {
        component: Crop,
      },
      zoomIn: {
        component: ZoomIn,
      },
      zoomOut: {
        component: ZoomOut,
      },
      search: {
        component: Search,
      },
      arrowLeft: {
        component: ArrowL,
      },
      arrowRight: {
        component: ArrowR,
      },
      arrowDown: {
        component: ArrowDownBlue,
      },
      closeBlue: {
        component: CloseBlue,
      },
      infoCircle: {
        component: InfoCircle,
      },
      clockBlue: {
        component: ClockBlue,
      },
      checkBlue: {
        component: CheckBlue,
      },
      clearGrey: {
        component: ClearGrey,
      },
      linkBlue: {
        component: LinkBlue,
      },
      levelLow: {
        component: LevelLow,
      },
      levelMedium: {
        component: LevelMedium,
      },
      levelHigh: {
        component: LevelHigh,
      },
      deleteBlack: {
        component: DeleteBlack,
      },
      editBlack: {
        component: EditBlack,
      },
    },
  },
});
