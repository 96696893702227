<template>
  <v-row class="take-course" flex-wrap flex-column fill-height>
    <v-col cols="12" sm="12" md="3" class="course-items">
      <div class="course-intro">
        <CourseIntro />
      </div>
      <div class="course-steps">
        <CourseSteps @getSlideDetailWithID="getSlideDetail" />
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="9" class="course-content">
      <div class="course-content-header">
        <span class="course-step-title">
          {{ slideItemTitle }}
        </span>
        <div class="btn-course-exit" @click="setCourseExit">
          <span class="text-exit">Exit</span>
          <div class="btn-exit-arrow"></div>
        </div>
      </div>
      <div class="course-step-content">
        <v-card
          class="overflow-y-auto course-step-content-scroll"
          v-if="takeCourse"
        >
          <v-card-text>
            <Approval
              v-if="slideApprove"
              :task_id="task_id"
              @setCompletedCourse="setCompletedCourse"
            />
            <Document
              v-else-if="content_showed == 'document'"
              @completeSlide="checked_HTMLContent"
            />
            <Html
              v-else-if="content_showed == 'webpage'"
              @completeSlide="checked_HTMLContent"
            />
            <Quiz
              v-else-if="content_showed == 'quiz'"
              @setCheckedCourseItem="setCheckedCourseItem"
            />
            <Video
              v-else-if="content_showed == 'video'"
              @completeSlide="checked_HTMLContent"
            />
            <v-dialog
              v-if="dialogCompletedCourse"
              transition="dialog-transition"
              max-width="1200"
              :value="dialogCompletedCourse"
              persistent
            >
              <template>
                <DisplayComplete
                  type_dialog="complete"
                  subtitle1="You successfully completed the course and earned"
                  :points="`${points_gained}`"
                  subtitle2="Continuous Learning and Education Credits"
                  @close="setCompletedCourse"
                />
              </template>
            </v-dialog>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");
const takeCourseHelper = createNamespacedHelpers("takeCourse");
import {
  getCourseDetailWithIDUnpublished,
  checkedSlideCompleted,
  checkedSlideViewed,
  getSlideDetail,
} from "@/api";

import CourseIntro from "@/components/take-course/CourseIntro";
import CourseSteps from "@/components/take-course/CourseSteps";
import Document from "@/components/take-course/CourseContent/Document";
import Html from "@/components/take-course/CourseContent/Html";
import Quiz from "@/components/take-course/CourseContent/Quiz";
import Video from "@/components/take-course/CourseContent/Video";
import Approval from "@/components/take-course/CourseContent/Approval";
import DisplayComplete from "@/common/DisplayComplete";

export default {
  name: "CourseMain",
  props: {
    courseDetail: {
      type: Object,
      required: true,
    },
    task_id: {
      type: Number,
      required: false,
    },
  },
  components: {
    CourseIntro,
    CourseSteps,
    Document,
    Html,
    Quiz,
    Video,
    Approval,
    DisplayComplete,
  },
  created() {
    this.dispatchInitialState();
    this.setCheckedCourseItem();
  },
  data() {
    return {
      dialogCompletedCourse: false,
      content_showed: "",
      points_gained: 0,
    };
  },
  computed: {
    ...takeCourseHelper.mapGetters([
      "takeCourse",
      "slideItemTitle",
      "approvementCompleted",
      "slideApprove",
      "selectedSlideID",
    ]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...takeCourseHelper.mapMutations([
      "setSelectedSlideID",
      "setSlideQuizList",
      "setSlideVideoID",
      "setSlideHTMLDetail",
      "setSelectedSlideQuizList",
      "setSlideApprove",
      "setApprovementVisible",
      "setSlideChecked",
      "setSlideItemTitle",
    ]),
    ...takeCourseHelper.mapActions([
      "dispatchInitialState",
      "dispatchTakeCourse",
    ]),
    async getSlideDetail(_slideID) {
      this.setSlideQuizList("");
      this.setSlideVideoID("");
      this.setSlideHTMLDetail("");
      this.setSelectedSlideQuizList([]);
      // if (_slideID != -1) {
      this.takeCourse.slide_detail.forEach((slide) => {
        if (_slideID === slide.slide_id) {
          this.setSelectedSlideID(_slideID);
          let slideQuizList_copy;

          switch (slide.slide_type) {
            case "webpage":
              this.setSlideHTMLDetail(
                slide.content.html_content ? slide.content.html_content : ""
              );
              this.content_showed = "webpage";
              break;
            case "video":
              this.setSlideVideoID(
                slide.content.document_id ? slide.content.document_id : ""
              );
              this.content_showed = "video";
              break;
            case "document":
              this.content_showed = "document";
              break;
            case "quiz":
              slideQuizList_copy = slide.quiz.slide_questions
                ? slide.quiz.slide_questions
                : "";
              if (slideQuizList_copy) {
                slideQuizList_copy.forEach((quiz) => {
                  quiz.answers.forEach((answer) => {
                    answer["quizBGColor"] = "transparent";
                  });
                });
              }
              this.setSlideQuizList(slideQuizList_copy);
              this.content_showed = "quiz";
              break;
            default:
              break;
          }
        }
      });
      // }
    },
    async checked_HTMLContent() {
      try {
        this.getLoading(true);
        let result = await checkedSlideViewed(this.selectedSlideID);
      } catch (error) {
        console.log("checked_HTMLContentError==>", error);
      } finally {
        await this.completeSlide();
      }
    },
    async completeSlide() {
      try {
        let result = await checkedSlideCompleted(this.selectedSlideID);
        if (result.status == 200) {
          this.getLoading(false);
          this.setCheckedCourseItem();
        }
      } catch (error) {
        console.log("completeSlideError==>", error);
        this.getLoading(false);
      }
    },
    setCourseExit() {
      if (this.approvementCompleted) {
        this.dispatchInitialState();
        this.completedCourse();
      } else {
        this.dispatchInitialState();
        this.$emit("exitCourse");
      }
    },
    completedCourse() {
      this.$emit("completedCourse");
    },
    checkingCourseSlide(takeCourse_copy) {
      for (const _tc of takeCourse_copy.slide_detail) {
        if (!_tc.completed) {
          this.setSelectedSlideID(_tc.slide_id); //Selects the first incompleted step
          break;
        }
      }
      takeCourse_copy.content.forEach((content) => {
        takeCourse_copy.slide_detail.forEach((slide) => {
          if (content.id == slide.slide_id) {
            if (slide.completed) {
              content["checked"] = true;
            } else {
              if (this.selectedSlideID == content.id) {
                content["checking"] = true;
                this.setSlideItemTitle(content["name"]);
              } else {
                content["checked"] = false;
              }
            }
          }
        });
      });
      this.dispatchTakeCourse(takeCourse_copy);
      this.getSlideDetail(this.selectedSlideID);
      this.getLoading(false);
    },
    async setCheckedCourseItem() {
      try {
        this.getLoading(true);
        let result = await getCourseDetailWithIDUnpublished(
          this.courseDetail.id
        );
        if (result.status == 200) {
          let takeCourse_copy = result.data.data.response[0];
          if (takeCourse_copy.completed) {
            if (takeCourse_copy.button == "continue") {
              this.dialogCompletedCourse = false;
              this.checkingCourseSlide(takeCourse_copy);
            } else if (this.courseDetail.state == "pending_approval") {
              //The user has to approve the course for its publishing
              takeCourse_copy.content.forEach((content) => {
                content["checked"] = true;
              });
              this.dispatchTakeCourse(takeCourse_copy);
              if (!this.approvementCompleted) {
                this.setApprovementVisible(true);
                this.setSlideApprove(true);
                this.getSlideDetail(-1);
                this.setSelectedSlideID("");
                this.setSlideChecked(true);
              }
              this.getLoading(false);
            } else if (this.courseDetail.completed) {
              //The user selects a course already completed
              takeCourse_copy.content.forEach((content) => {
                content["checked"] = true;
              });
              this.setSlideItemTitle(takeCourse_copy.slide_detail[0].name);
              this.dialogCompletedCourse = false;
              this.dispatchTakeCourse(takeCourse_copy);
              this.getSlideDetail(takeCourse_copy.slide_detail[0].slide_id);
              this.setSlideChecked(true);
              this.getLoading(false);
            } else {
              //The user completes the course successfully
              takeCourse_copy.content.forEach((content) => {
                content["checked"] = true;
              });
              this.setSlideItemTitle("Your course was completed.");
              this.points_gained = takeCourse_copy.points;
              this.dialogCompletedCourse = true;
              this.getSlideDetail(-1);
              this.setSlideChecked(true);
              this.getLoading(false);
            }
          } else {
            this.checkingCourseSlide(takeCourse_copy);
          }
        }
      } catch (error) {
        console.log("setCheckedCourseItemError==>", error);
        this.getLoading(false);
      }
    },
    setCompletedCourse() {
      this.dialogCompletedCourse = false;
      this.dispatchInitialState();
      this.completedCourse();
    },
  },
};
</script>
<style lang="scss">
//style in src/assets/styles/components/take-course.scss
</style>
