const FilterLocation = (
  employee_list = [],
  region_list = [],
  department_list = []
) => {
  const regionInfo = region_list.map((region) => {
    const region_employees = employee_list.filter(
      (employee) => employee.region == region
    );
    let department_region_employees = [];
    if (department_list.length > 0) {
      department_region_employees = region_employees.filter(function (e) {
        return this.indexOf(e.department_name) >= 0;
      }, department_list);
    } else {
      department_region_employees = region_employees;
    }

    const country_set = new Set();
    const flag_set = new Set();
    let country_counts = {};
    department_region_employees.forEach((e) => {
      country_set.add(e.country_name);
      flag_set.add(e.flag);
      country_counts[e.country_name] =
        (country_counts[e.country_name] || 0) + 1;
    });

    const country_array = [...country_set];
    const flag_array = [...flag_set];
    let countriesByRegion = country_array.map((c, index) => {
      return {
        country_name: c,
        flag: flag_array[index],
        count: country_counts[c],
      };
    });

    countriesByRegion.sort(function (a, b) {
      return a.country_name.localeCompare(b.country_name);
    });

    return {
      region: region,
      region_country_count: department_region_employees.length,
      countries: countriesByRegion,
    };
  });
  return regionInfo;
};
export default FilterLocation;
