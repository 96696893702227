import { render, staticRenderFns } from "./Headshot.vue?vue&type=template&id=51e146b9&scoped=true&"
import script from "./Headshot.vue?vue&type=script&lang=js&"
export * from "./Headshot.vue?vue&type=script&lang=js&"
import style0 from "./Headshot.vue?vue&type=style&index=0&id=51e146b9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e146b9",
  null
  
)

export default component.exports