let initialState = {
  // return {
  // takeCourse: "",
  takeCourse: {},
  modelName: "",
  slideApprove: false,
  slideItemTitle: "",
  slideChecked: false,
  selectedSlideID: "",
  approvementVisible: false,
  approvementCompleted: false,
  slideQuizList: "",
  slideVideoID: "",
  slideHTMLDetail: "",
  slideDocument: "",
  selectedSlideQuizList: [],
  // }
};
export default {
  namespaced: true,
  state: initialState,
  actions: {
    dispatchInitialState({ commit }) {
      commit("setInitialState");
    },
    dispatchTakeCourse({ commit }, status) {
      let courseFiltered;
      try {
        courseFiltered = new course_filter(status);
        commit("setTakeCourse", courseFiltered);
      } catch (error) {
        courseFiltered = new course_filter(status, true);
        commit("setTakeCourse", courseFiltered);
        console.log("DispatchtakeCourseError==>", error);
      }
    },
  },
  mutations: {
    setInitialState(state) {
      // state = initialState;
      Object.assign(state, initialState);
    },
    setTakeCourse(state, status) {
      state.takeCourse = JSON.parse(JSON.stringify(status));
    },
    setSlideApprove(state, status) {
      state.slideApprove = status;
    },
    setSlideItemTitle(state, status) {
      state.slideItemTitle = status;
    },
    setSlideChecked(state, status) {
      state.slideChecked = status;
    },
    setSelectedSlideID(state, status) {
      state.selectedSlideID = status;
    },
    setApprovementVisible(state, status) {
      state.approvementVisible = status;
    },
    setApprovementCompleted(state, status) {
      state.approvementCompleted = status;
    },
    setSlideQuizList(state, status) {
      state.slideQuizList = status;
    },
    setSlideVideoID(state, status) {
      state.slideVideoID = status;
    },
    setSlideHTMLDetail(state, status) {
      state.slideHTMLDetail = status;
    },
    setSlideDocument(state, status) {
      state.slideDocument = status;
    },
    setSelectedSlideQuizList(state, status) {
      state.selectedSlideQuizList = status;
    },
    setModelName(state, status) {
      state.modelName = status;
    },
  },
  getters: {
    takeCourse(state) {
      return state.takeCourse;
    },
    slideApprove(state) {
      return state.slideApprove;
    },
    slideItemTitle(state) {
      return state.slideItemTitle;
    },
    slideChecked(state) {
      return state.slideChecked;
    },
    selectedSlideID(state) {
      return state.selectedSlideID;
    },
    approvementVisible(state) {
      return state.approvementVisible;
    },
    approvementCompleted(state) {
      return state.approvementCompleted;
    },
    slideQuizList(state) {
      return state.slideQuizList;
    },
    slideVideoID(state) {
      return state.slideVideoID;
    },
    slideHTMLDetail(state) {
      return state.slideHTMLDetail;
    },
    slideDocument(state) {
      return state.slideDocument;
    },
    selectedSlideQuizList(state) {
      return state.selectedSlideQuizList;
    },
    modelName(state) {
      return state.modelName;
    },
  },
};
function course_filter({ ...data }, _datas_exceded) {
  this.id = data.id;
  this.bg = data.bg;
  this.title = data.title;
  this.minutes = data.minutes;
  this.steps = data.steps;
  this.completed = data.completed;
  this.completion = data.completion;
  this.points = data.points;
  this.button = data.button;
  this.tags = data.tags;
  this.description = data.description;
  this.content = data.content;

  this.slide_detail = new Array(data.slide_detail.length);
  for (let i = 0; i < data.slide_detail.length; i++) {
    this.slide_detail[i] = {};
  }
  for (const index in data.slide_detail) {
    let _slide = data.slide_detail[index];
    this.slide_detail[index].name = _slide.name;
    this.slide_detail[index].slide_id = _slide.slide_id;
    this.slide_detail[index].completed = _slide.completed;
    this.slide_detail[index].content = {};
    this.slide_detail[index].content.html_content =
      _slide.content?.html_content;
    this.slide_detail[index].content.document_type =
      _slide.content?.document_type;
    this.slide_detail[index].content.document_id = _slide.content?.document_id;
    this.slide_detail[index].content.url = _slide.content?.url;
    this.slide_detail[index].content.datas = _datas_exceded
      ? "EXCEDED"
      : _slide.content?.datas;
    this.slide_detail[index].quiz = _slide.quiz;
    this.slide_detail[index].slide_type = _slide.slide_type;
    this.slide_detail[index].links = _slide.links;
  }
}
