import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getTacticalCertificationEmployee = (_id_employee) => {
  let result = axios()({
    url: API.TACTICALCERTIFICATON,
    method: "patch",
    params: {
      action: "get_required_certifications",
      employee_id: _id_employee,
    },
  });
  return result;
};
