import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getDisplayCreditRequest = () => {
  let result = axios()({
    url: API.CREDITREQUEST,
    method: "get",
  });
  return result;
};

export const getUserCreditRequest = () => {
  let result = axios()({
    url: API.CREDITREQUEST,
    method: "patch",
    params: { action: "get_credit_request" },
  });
  return result;
};
export const submitCreditsRequest = (_id) => {
  let result = axios()({
    url: `${API.CREDITREQUEST}/`,
    method: "patch",
    params: {
      action: "submit",
      id: _id,
    },
  });
  return result;
};

export const requestCredits = (_description, _learning_activity_type_id) => {
  let result = axios()({
    url: API.CREDITREQUEST,
    method: "post",
    params: {
      description: `${_description}`,
      learning_activity_type_id: _learning_activity_type_id,
      employee_id: localStorage.getItem("employee_id"),
    },
  });
  return result;
};
