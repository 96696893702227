const EditDateTimeToGMT = (_date) => {
  let setdate;
  if (!_date) setdate = new Date();
  else setdate = new Date(_date);

  let setYear = setdate.getUTCFullYear();
  let setMonth = setdate.getUTCMonth() + 1;
  setMonth = setMonth < 10 ? "0" + setMonth : setMonth;
  let setDate = setdate.getUTCDate();
  setDate = setDate < 10 ? "0" + setDate : setDate;
  let setHour = setdate.getUTCHours();
  setHour = setHour < 10 ? "0" + setHour : setHour;
  let setMinutes = setdate.getUTCMinutes();
  setMinutes = setMinutes < 10 ? "0" + setMinutes : setMinutes;
  let setSeconds = setdate.getUTCSeconds();
  setSeconds = setSeconds < 10 ? "0" + setSeconds : setSeconds;
  return `${setYear}-${setMonth}-${setDate} ${setHour}:${setMinutes}:${setSeconds}`;
};
export default EditDateTimeToGMT;
