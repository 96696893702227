//   Started on January 1, 2000 (0 yrs 11 mos)
const EditDate = (_date) => {
  const today = new Date();
  let currentYear = today.getFullYear();
  let currentMonth = today.getMonth();
  if (_date) {
    let started_date = _date;
    let split_date = started_date.split("-", 3);
    let _year = split_date[0];
    let _month = split_date[1];
    let _day = split_date[2];
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let selectedMonthName = months[parseInt(_month) - 1];
    let sofarYear = parseInt(currentYear) - parseInt(_year);
    let sofarMonth = 0;

    if (sofarYear >= 1) {
      sofarYear = sofarYear - 1;
      sofarMonth = parseInt(currentMonth) + 1 + 12 - parseInt(_month);
      if (sofarMonth >= 12) {
        sofarYear++;
        sofarMonth = sofarMonth - 12;
      }
    } else {
      sofarMonth = parseInt(currentMonth) + 1 - parseInt(_month);
    }

    return (
      "Started on " +
      selectedMonthName +
      " " +
      _day +
      ", " +
      _year +
      " (" +
      sofarYear +
      " yrs " +
      sofarMonth +
      " mos)"
    );
  }
  return "";
};
export default EditDate;
