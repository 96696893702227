<template>
  <button @click="onClick" class="base-btn">
    <div class="content-wrapper">
      <slot>Button</slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-btn {
  outline: none;
}
.content-wrapper {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
}
</style>
