import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getLearningActivities = () => {
  let result = axios()({
    url: `${API.GETLEARNINGACTIVITY}/`,
    method: "patch",
    params: {
      action: "get_learning_activity_type",
      // state: "planned"
    },
  });
  return result;
};
