import { getCurrentVsDeservedSalary } from "@/api";
export default {
  namespaced: true,
  state: {
    salaryWidgetAttribute: {},
  },
  mutations: {
    setSalaryWidgetAttribute(state, status) {
      state.salaryWidgetAttribute = { ...status };
    },
  },
  actions: {
    async dispatchSalaryWidgetAttribute({ commit }, employee_id) {
      try {
        let {
          data: {
            data: { response },
          },
        } = await getCurrentVsDeservedSalary(employee_id);
        commit("setSalaryWidgetAttribute", response);
      } catch (error) {
        console.log("dispatchWidgetAttributeError==>", error);
        commit("setSalaryWidgetAttribute", {});
      }
    },
  },
  getters: {
    getSalaryWidgetAttribute(state) {
      return state.salaryWidgetAttribute;
    },
  },
};
