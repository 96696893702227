var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table1-container"},[_c('DxDataGrid',{attrs:{"id":"gridContainer1","data-source":_vm.rows,"columns":_vm.cols,"key-expr":"Certification Program","showColumnHeaders":true,"show-borders":true,"showRowLines":true,"showColumnLines":true,"allow-column-resizing":true,"column-auto-width":true,"word-wrap-enabled":true,"selected-row-keys":_vm.selectedItemKeys},on:{"selection-changed":_vm.selectionChanged},scopedSlots:_vm._u([{key:"tagsTemplate",fn:function({ data: rowData }){return [_c('div',_vm._l((rowData.data['Responsibilities']),function(chip,index){return _c('v-chip',{key:index,staticClass:"tags-sm",attrs:{"color":"#53A0DE33","text-color":"#53A0DE"}},[_vm._v(" "+_vm._s(chip)+" ")])}),1)]}},{key:"statusTemplate",fn:function({ data: rowData }){return [_c('div',{staticClass:"status-container"},_vm._l((rowData.data.status),function(bar,i){return _c('div',{key:i,staticClass:"status-bar status-bar__center",class:bar.size == 100
              ? 'bar'
              : i == rowData.data.status.length - 1
              ? 'bar-right'
              : i == 0
              ? 'bar-left'
              : '',style:({
            color:
              bar.color == '#fff200' || bar.color == '#e5e5e5'
                ? '#000000'
                : '#ffffff',
            'background-color': bar.color,
            width: `${bar.size * 2.3}px`,
          })},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"div-tooltip"},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(bar.label))])])],1)}),0)]}},{key:"detailTemplate",fn:function({ data: rowData }){return [_c('TableDetail',{attrs:{"rows":rowData.data.childs,"column_names":_vm.cols_table_la}})]}}])},[_c('DxColumn',{attrs:{"data-field":"Certification Program"}}),_c('DxColumn',{attrs:{"data-field":"Responsibilities","cell-template":"tagsTemplate"}}),_c('DxColumn',{attrs:{"data-field":"Status","cell-template":"statusTemplate"}}),_c('DxColumn',{attrs:{"data-field":"Expires On"}}),_c('DxPaging',{attrs:{"page-size":10}}),_c('DxPager',{attrs:{"visible":true,"show-navigation-buttons":true}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxMasterDetail',{attrs:{"enabled":true,"template":"detailTemplate"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }