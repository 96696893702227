import DetectMimeType from "@/utils/detect_mime_type.js";
const b64toBlob = (b64Data, sliceSize = 512) => {
  const { mimeType, openInIFrame } = DetectMimeType(b64Data);
  const byteCharacters = window.atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return {
    openInIFrame: openInIFrame,
    blobURL: URL.createObjectURL(blob),
    mimeType: mimeType,
  };
};
export default b64toBlob;
