export default {
  props: {
    color: {
      type: String,
      default: "currentColor",
      required: false,
    },
    size: {
      type: Number,
      default: 16,
      required: false,
    },
  },
};
