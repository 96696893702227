import {
  getResponsibilitiesEmployee,
  getTacticalRole,
  getTacticalRoleAssignments,
  getTacticalRoleAssignmentsEmployee,
  getTacticalCertificationEmployee,
  getLearningEvent,
} from "@/api";
import EditDate from "@/utils/edit_date_1.js";
import EditDate2 from "@/utils/edit_date_2.js";
import EditDateTimeToUTC from "@/utils/edit_date_time_to_utc.js";
import EditDateToUTC from "@/utils/edit_date_to_utc_1.js";
import DaysOffset from "@/utils/days_offset.js";
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
import { LAST_DAY_ROLE } from "@/utils/constants.js";

export const tableResponsibilities = async (id_employee) => {
  try {
    let result = [];
    result = await getResponsibilitiesEmployee(id_employee);
    let yourResp = [];
    if (result.status == 200) {
      let gettingResp = result.data.data.response.responsibilities;
      let _assignments = result.data.data.response.assignments.assignments;
      for (const resp_id in gettingResp) {
        for (let i = 0; i < _assignments.length; i++) {
          let _assign = _assignments[i];
          let _break = false;
          if (_assign.active_role && _assign.state == "active") {
            let _responsibilities = _assign.role_id.data[0].responsibility_ids;
            let _certifications = _assign.role_id.data[0].certification_data;
            for (let j = 0; j < _responsibilities.length; j++) {
              let _resp = _responsibilities[j];
              let cert = _certifications[j];
              if (
                _resp.id == resp_id &&
                _resp.active_responsibility &&
                _resp.state == "active"
              ) {
                const resp = gettingResp[resp_id];
                const _certArray = Object.values(cert).map((_c) =>
                  Object.values(_c)
                );
                let row = {
                  id: _resp.id,
                  INDEX: `${_resp.id}`,
                  "Commitment / Responsibility": resp.name ? resp.name : "-",
                  commitment: _resp.commitment,
                  "Current Version": _resp.version ? _resp.version : "-",
                  "Your current roles including this responsibility": [],
                  responsibility_certification_progress:
                    _resp.responsibility_certification_progress
                      ? _resp.responsibility_certification_progress
                      : 0,
                  responsibility_proficiency_progress:
                    _resp.responsibility_proficiency_progress
                      ? _resp.responsibility_proficiency_progress
                      : 0,
                  tracker_data: resp.tracker_data,
                  target: _resp.target,
                  badge: _resp.badge_id[1],
                  "Acknowledged On": _resp.acknowledge_date
                    ? EditDateToUTC(_resp.acknowledge_date)
                    : "",
                  Detail: resp.description
                    ? resp.description
                    : "<p>Description not provided<br></p>",
                  missing_certifications: _certArray[0],
                };
                resp.role_ids.forEach((_assign) => {
                  row["Your current roles including this responsibility"].push(
                    _assign.name
                  );
                });
                yourResp.push(row);
                _break = true;
              }
            }
            if (_break) {
              break;
            }
          }
        }
      }
    }
    return yourResp;
  } catch (error) {
    console.error("TableResponsibilitiesError==>", error);
    return [];
  }
};
export const tableRoles = async (id_employee) => {
  try {
    let result = [];
    if (id_employee) {
      result = await getTacticalRoleAssignmentsEmployee(id_employee);
    } else {
      result = await getTacticalRoleAssignments();
    }
    let yourRoles = [];
    if (result.status == 200) {
      let gettingRoles = result.data.data.response.assignments;
      for (const [i, role] of gettingRoles.entries()) {
        const role_list = role.role_id.data[0].role_id[0];
        const responsibility_list = role.role_id.data[0].responsibility_ids;
        const certification_list = role.role_id.data[0].certification_data;
        const roleRelieved = role.date_to
          ? DaysOffset(role.date_to)
          : DaysOffset(LAST_DAY_ROLE);
        if (
          roleRelieved <= 0 &&
          (role.state == "relieved" ||
            (role.state == "active" && role.active_role))
        ) {
          const _childs = await get_responsibilities(
            responsibility_list,
            certification_list,
            i,
            role.state
          );
          let row = {
            id: role.id,
            INDEX: `${i}`,
            Role: role_list?.display_name ? role_list.display_name : "-",
            Commitment: role.commitment,
            "Effective Date": role.date_from ? EditDate2(role.date_from) : "-",
            "Relieve Date": role.date_to ? EditDate2(role.date_to) : false,
            Status: role.state ? CapitalizeFirstLetter(role.state) : "-",
            active_role: role.active_role,
            "Accepted On": EditDateToUTC(role.acceptance_date),
            // Responsibilities: role.responsibility_count ? role.responsibility_count : "-",
            Responsibilities: _childs.countActiveResponsibilities,
            childs: _childs.respList,
            childs_resp_ack_cert: _childs.respAckCert,
            childs_resp_ack_no_cert: _childs.respAckNoCert,
            childs_resp_not_ack: _childs.respNotAck,
            childs_resp_not_ack_count: _childs.respNotAckCount,
            childs_cert_achie: _childs.certAchieved,
            childs_miss_cert: _childs.countMissingCert,
          };
          yourRoles.push(row);
        }
      }
    }
    return yourRoles;
  } catch (error) {
    console.error("GetRoleError==>", error);
    return [];
  }
};
export const tableCertifications = async (id_employee) => {
  try {
    let result = [];
    result = await getTacticalCertificationEmployee(id_employee);
    let certifications = [];
    if (result.status == 200) {
      let gettingCertifications = result.data.data.response;
      if (Object.keys(gettingCertifications).length !== 0) {
        for (const index in gettingCertifications) {
          const cert = gettingCertifications[index];
          if (cert.state == "retired") continue;
          let expirationDate = cert.expiration_date
            ? EditDate(cert.expiration_date)
            : "-";
          // let daysToExpire = cert.expiration_date
          //   ? DaysOffset(cert.expiration_date)
          //   : cert.expiration_date;
          let row = {
            sort_effective_date: 0,
            "Certification Program": cert.name ? cert.name : "-",
            Responsibilities: cert.responsibilites ? cert.responsibilites : [],
            Status: cert.active ? "Certified" : "Missing",
            "Expires On": expirationDate,
            childs: [],
            status: false,
            credits_total: 0,
            credits_granted: 0,
            credits_prior_knowledge: 0,
          };
          let childs_events = await get_learning_evts(cert.requirements);
          row.childs = childs_events;
          row.childs.forEach((ch) => {
            if (ch.completion == 100) {
              row.credits_granted += ch.credits;
            }
            if (ch.prior_knowledge) {
              row.credits_prior_knowledge += ch.credits;
            }
            row.credits_total += ch.credits;
          });

          row.status = getStatusInfo(
            cert.expiration_date,
            expirationDate,
            row.credits_granted,
            row.credits_prior_knowledge,
            row.credits_total
          );
          certifications.push(row);
        }
      }
    }
    return certifications;
  } catch (error) {
    console.log("GetRoleError==>", error);
    return [];
  }
};

async function get_learning_evts(requirements) {
  let coursesList = [];
  for (const requirement of requirements) {
    if (requirement.state == "active") {
      for (const l_a of requirement.learning_events) {
        let result = await getLearningEvent(l_a.id);
        if (result.data.data.response.data.length > 0) {
          const course = result.data.data.response.data[0];
          coursesList.push({
            "Learning Activity": course.activity_type
              ? course.activity_type.name
              : "-",
            Status: l_a.state
              ? CapitalizeFirstLetter(l_a.state).replace("_", " ")
              : "-",
            completion: course.activity_type?.completion,
            credits: course.credits ? course.credits : 0,
            prior_knowledge: course.prior_knowledge,
          });
          break;
        }
      }
    }
  }
  return coursesList;
}

function getStatusInfo(_date, _dateFormatted, granted, prior_knowledge, total) {
  let green = "#22b14c";
  let red = "#ed1c24";
  let magenta = "#d222a7";
  let yellow = "#fff200";
  let gray = "#e5e5e5";
  const percent_total =
    total > 0 ? ((granted + prior_knowledge) * 100) / total : 0;
  if (percent_total == 100) {
    if (_date) {
      let dateOff = DaysOffset(_date);
      if (dateOff < -91) {
        let monthYear = _dateFormatted.split("/");
        return [
          {
            label: `Certified until ${monthYear[1]}/${monthYear[2]}`,
            color: green,
            size: 100,
          },
        ];
      } else if (dateOff <= 0 && dateOff > -90) {
        if (dateOff == 1) {
          return [
            {
              label: `Certificate expires in ${Math.abs(dateOff)} day`,
              color: yellow,
              size: 100,
            },
          ];
        }
        return [
          {
            label: `Certificate expires in ${Math.abs(dateOff)} days`,
            color: yellow,
            size: 100,
          },
        ];
      }
    } else {
      return [
        {
          label: "Certified",
          color: green,
          size: 100,
        },
      ];
    }
  } else if (percent_total == 0) {
    return [
      {
        label: "Not started",
        color: red,
        size: 100,
      },
    ];
  } else {
    if (prior_knowledge > 0) {
      const percent_granted = total > 0 ? (granted * 100) / total : 0;
      const percent_prior_knowledge = (prior_knowledge * 100) / total || 0;
      return [
        {
          label: "Prior Knowledge",
          color: magenta,
          size: percent_prior_knowledge,
        },
        {
          label: "Completed",
          color: green,
          size: percent_granted,
        },
        {
          label: "Missing",
          color: gray,
          size: 100 - percent_granted - percent_prior_knowledge,
        },
      ];
    }
    return [
      {
        label: "Completed",
        color: green,
        size: percent_total,
      },
      {
        label: "Missing",
        color: gray,
        size: 100 - percent_total,
      },
    ];
  }
}

async function get_responsibilities(
  responsibility_list,
  certification_list,
  i,
  state_role
) {
  let resp_array = [];
  let _count_active_responsibilities = 0;
  let _resp_ack_no_cert = 0;
  let _resp_ack_cert = 0;
  let _resp_not_ack = 0;
  let _resp_not_ack_count = 0;
  let _cert_achieved = 0;
  let _missing_cert_count = 0;
  let _resp_can_cert = 0;
  for (const [index, responsibility] of responsibility_list.entries()) {
    if (
      responsibility.active_responsibility &&
      responsibility.state == "active"
    ) {
      _count_active_responsibilities++;
      const _cert = Object.values(certification_list[index])[0];
      let resp = {
        id: responsibility.id,
        INDEX: `${i}+${index}`,
        Responsibility: responsibility.name
          ? CapitalizeFirstLetter(responsibility.name)
          : "-",
        "Current Version": responsibility.version
          ? responsibility.version
          : "-",
        "Required Certifications": _cert,
        Certification: _cert,
        certification_progress: responsibility.certification_progress,
        readiness_description: responsibility.certification_progress_desc
          ? responsibility.certification_progress_desc
          : "",
        responsibility_certification_progress:
          responsibility.responsibility_certification_progress,
        responsibility_proficiency_progress:
          responsibility.responsibility_proficiency_progress,
        "Acknowledged On": EditDateToUTC(responsibility.acknowledge_date),
        role_ids: responsibility.role_ids ? responsibility.role_ids : [],
        state_role: state_role,
        // childs: await get_roles_for_responsibilities(
        //   responsibility.role_ids,
        //   i,
        //   index
        // ),
      };
      let _certificated = [];
      if (
        responsibility.certification_progress_desc !=
        "No Requirements for this Responsibility"
      ) {
        _resp_can_cert++;
      }
      if (_cert.length > 0) {
        _certificated = _cert[0].certificates.filter(
          (cert) => cert.state == "active"
        );
      }
      if (_certificated.length > 0) {
        _cert_achieved++;
      }
      if (responsibility.acknowledge_date) {
        if (
          responsibility.certification_progress_desc !=
          "No Requirements for this Responsibility"
        ) {
          if (_certificated.length > 0) {
            _resp_ack_cert++;
          } else {
            _resp_ack_no_cert++;
          }
        }
      } else {
        if (
          responsibility.certification_progress_desc !=
          "No Requirements for this Responsibility"
        ) {
          _resp_not_ack++;
        }
        _resp_not_ack_count++;
      }
      resp_array.push(resp);
    }
  }
  const _total_resp = _resp_ack_cert + _resp_ack_no_cert + _resp_not_ack;
  if (_total_resp > 0) {
    _resp_ack_cert = Math.trunc((_resp_ack_cert * 100) / _total_resp);
    _resp_ack_no_cert = Math.trunc((_resp_ack_no_cert * 100) / _total_resp);
    _resp_not_ack = Math.trunc((_resp_not_ack * 100) / _total_resp);
  }
  _missing_cert_count = _resp_can_cert - _cert_achieved;
  if (_resp_can_cert > 0) {
    _cert_achieved = Math.trunc((_cert_achieved * 100) / _resp_can_cert);
  }
  return {
    respList: resp_array,
    respAckCert: _resp_ack_cert,
    respAckNoCert: _resp_ack_no_cert,
    respNotAck: _resp_not_ack,
    respNotAckCount: _resp_not_ack_count,
    certAchieved: _cert_achieved,
    countActiveResponsibilities: _count_active_responsibilities,
    countRespCanCertificate: _resp_can_cert,
    countMissingCert: _missing_cert_count,
  };
}
async function get_roles_for_responsibilities(role_ids, i, j) {
  let role_array = [];
  for (const k of role_ids) {
    let result_role = await getTacticalRole(k);
    let role_info_name = result_role.data.data.response.data[0].role_id[0].name;
    let r_by_r = {
      INDEX: `${i}+${j}+${k}`,
      "Tactical Roles having this responsibility": role_info_name,
    };
    role_array.push(r_by_r);
  }
  return role_array;
}
