<template>
  <div>
    <div class="employeeProfil-page">
      <div class="back-item">
        <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
        <span
          class="back-item__text"
          @click="backToDirectory(Object.keys($route.query)[0])"
          >&nbsp;Back to {{ Object.keys($route.query)[0] }}</span
        >
      </div>
      <div class="employeeProfile-content">
        <EmployeeInfo @gotoReportEmployee="gotoReportEmployee" />
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeInfo from "@/components/employee/EmployeeInfo";
export default {
  name: "EmployeeProfile",
  components: {
    EmployeeInfo,
  },
  methods: {
    gotoReportEmployee(_url) {
      // this.$router.go(_url);
      window.open(_url.href, "_blank");
    },
    backToDirectory(_pageName) {
      let url = ``;
      if (_pageName == "directory") {
        url = `/home/directory`;
      } else {
        url = `/employees`;
      }
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss">
.employeeProfil-page {
  margin: 30px 80px;
  display: flex;
  flex-direction: column;
  .back-item {
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      margin-left: 5px;
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      text-transform: capitalize;
    }
  }
  .employeeProfile-content {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1280px) {
  .employeeProfil-page {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}
</style>
