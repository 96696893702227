import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getValues = () => {
  let result = axios()({
    url: API.GETVALUES,
    method: "get",
    params: { fields: "name,description" },
  });
  return result;
};
