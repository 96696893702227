var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-content1"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.cols),function(col){return _c('th',{key:col.id},[_vm._v(_vm._s(col))])}),0)]),(_vm.tableType == 'select_row')?_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id,staticClass:"tr-click",class:{ relievedRow: row.status == 'Relieved' },on:{"click":function($event){return _vm.emitAction(row)}}},_vm._l((_vm.cols),function(col){return _c('td',{key:col.id},[_c('span',[_vm._v(_vm._s(row[col]))])])}),0)}),0):_vm._e(),(_vm.tableType == 'req_cer')?_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id},_vm._l((_vm.cols),function(col){return _c('td',{key:col.id,class:row[col] == 'Missing' && col == 'status' ? 'txt_red' : ''},[(Array.isArray(row[col]))?_c('div',_vm._l((row[col]),function(chip,index){return _c('v-chip',{key:index,staticClass:"tags-sm",attrs:{"color":"#53A0DE33","text-color":"#53A0DE"}},[_vm._v(" "+_vm._s(chip)+" ")])}),1):_c('span',[_vm._v(_vm._s(row[col]))])])}),0)}),0):_vm._e()])]),_c('div',{staticClass:"mobile_events"},_vm._l((_vm.rows),function(row){return _c('div',{key:row.id},[_c('div',{staticClass:"detail_row"},_vm._l((_vm.cols),function(col){return _c('div',{key:col.id},[_c('div',{staticClass:"detail_col"},[_c('span',{staticClass:"txt_col"},[_vm._v(_vm._s(col))]),(Array.isArray(row[col]))?_c('div',_vm._l((row[col]),function(chip,index){return _c('v-chip',{key:index,staticClass:"tags-sm",attrs:{"color":"#53A0DE33","text-color":"#53A0DE"}},[_vm._v(" "+_vm._s(chip)+" ")])}),1):_c('span',{staticClass:"txt_row_col",class:row[col] == 'Today' && col == 'deadline'
                  ? 'txt_orange'
                  : row[col] == 'Overdue' && col == 'deadline'
                  ? 'txt_red'
                  : row[col] == 'Today' && col == 'expiration date'
                  ? 'txt_orange'
                  : row[col] == 'Overdue' && col == 'expiration date'
                  ? 'txt_red'
                  : row[col] == 'Today' && col == 'started on'
                  ? 'txt_orange'
                  : row[col] == 'Today' && col == 'completed on'
                  ? 'txt_orange'
                  : row[col] == 'Approved' && col == 'status'
                  ? 'txt_green'
                  : row[col] == 'Submitted' && col == 'status'
                  ? 'txt_orange'
                  : row[col] == 'Rejected' && col == 'status'
                  ? 'txt_red'
                  : row[col] == 'Draft' && col == 'status'
                  ? 'txt_grey'
                  : row[col] == 'Missing' && col == 'status'
                  ? 'txt_red'
                  : ''},[_vm._v(" "+_vm._s(row[col])+" ")])])])}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }