<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="main_section">
          <div class="main_section__content">
            <div class="toolbar">
              <div class="cust-filter">
                <DxDateBox
                  type="date"
                  :calendarOptions="calendarOptions"
                  :use-mask-behavior="true"
                  picker-type="calendar"
                  display-format="'Q'Q yyyy"
                  :value="date"
                  @value-changed="getValue"
                />

                <button
                  class="btn btn-secondary quarter-select-prev"
                  @click="prevQuarter"
                >
                  <span class="fa fa-arrow-left"></span>
                </button>
                <button
                  class="btn btn-secondary quarter-select-next"
                  @click="nextQuarter"
                >
                  <span class="fa fa-arrow-right"></span>
                </button>
              </div>
              <a class="external-link">
                <span> Your Charges </span>
              </a>
              <div class="link" @click="gotopage('your-discharges')">
                Your Discharges
              </div>
              <div class="link" @click="gotopage('your-cost-per-hour')">
                Your Cost Per Hour
              </div>
            </div>
            <div v-if="!notFound">
              <div class="eventsTable">
                <TableTemplate
                  :charges="rows"
                  :column_names="charges_cols"
                  :type="type"
                />
              </div>
            </div>
            <div v-else>
              <div class="not-found">
                <div class="not-found__img-fluid-credit"></div>
              </div>
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableTemplate from "@/components/compensation/TableTemplate";
import { createNamespacedHelpers } from "vuex";
import { tableRoles } from "@/utils/table_your_jobs.js";
import { getEmployeeCharges, getBudgetPeriodByDate } from "@/api";
import { DxDateBox } from "devextreme-vue/date-box";
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");

export default {
  name: "YourCharges",
  components: {
    DxDateBox,
    TableTemplate,
  },
  data() {
    return {
      date: new Date(),
      pronounEmployee: "Your",
      not_current_user: false,
      filters: {
        status: [],
      },
      show: true,
      selectedPageNumber: 1,
      eventsPagination: 1,
      query: "",
      selectedTags: [],
      charges_cols: ["Date", "Description", "Category", "Partner", "Amount"],
      type: "Charges",
      rows: [],
      quarterId: "",
      calendarOptions: {
        maxZoomLevel: "year",
        minZoomLevel: "decade",
        cellTemplate: function (cellData, cellIndex, cellElement) {
          if (
            cellData.view == "decade" ||
            cellData.text == "Jan" ||
            cellData.text == "Jun" ||
            cellData.text == "Jul" ||
            cellData.text == "Dec"
          ) {
            if (cellData.text == "Jan") {
              return "Q1";
            } else if (cellData.text == "Jun") {
              return "Q2";
            } else if (cellData.text == "Jul") {
              return "Q3";
            } else if (cellData.text == "Dec") {
              return "Q4";
            } else {
              return cellData.text;
            }
          } else {
            cellElement.style.display = "none";
            return;
          }
        },
      },
    };
  },
  computed: {
    notFound() {
      return this.rows.length == 0;
    },
  },
  async created() {
    this.getLoading(true);
    await this.getQuarterId(new Date().toISOString().split("T")[0]);
    await this.getDataAPI(this.quarterId);
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    async getQuarterId(date) {
      try {
        let result = await getBudgetPeriodByDate(date);
        if (result.status == 200) {
          this.quarterId = result.data.data.response.quarter_id;
        }
      } catch (error) {
        this.quarterId = "";
        this.dispatchOpenAlert({
          type: "error",
          message: "No quarter registered, please contact system administrator",
        });
        console.log("getCoursesError==>", error);
      }
    },
    async getDataAPI(quarter) {
      try {
        let result = await getEmployeeCharges("get_employee_charges", quarter);
        if (result.status == 200) {
          this.rows = [];
          if (typeof result.data.data.response === "string") {
            this.dispatchOpenAlert({
              type: "error",
              message: "There is not data for selected quarter",
            });
          } else {
            this.rows = result.data.data.response;
          }
        }
      } catch (error) {
        this.rows = [];
        console.log("getCoursesError==>", error);
      }
    },
    gotopage(path) {
      let url = "/Compensation/" + path;
      this.$router.push(url);
    },
    async getValue(event) {
      const offset = event.value.getTimezoneOffset();
      const selectedDate = new Date(event.value.getTime() - offset * 60 * 1000);
      this.getLoading(true);
      await this.getQuarterId(selectedDate.toISOString().split("T")[0]);
      await this.getDataAPI(this.quarterId);
      this.getLoading(false);
    },
    nextQuarter() {
      let quarter = Math.floor(this.date.getMonth() / 3);
      this.date = new Date(this.date.getFullYear(), quarter * 3 + 3, 1);
    },
    prevQuarter() {
      let quarter = Math.floor(this.date.getMonth() / 3);
      this.date = new Date(this.date.getFullYear(), quarter * 3 - 3, 1);
    },
  },
};
</script>
<style lang="scss">
// assets/styles/components/shared-distribution.scss
.toolbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}

.external-link {
  // font-size: 0.75rem;
  color: $blue !important;
  text-decoration-line: underline;
  line-height: 0.9rem;
  text-transform: capitalize;
}

.link {
  cursor: pointer;
}

.dx-calendar-view-year tbody {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.dx-calendar-cell {
  width: 55px;
  line-height: 55px;
  padding: 0;
}

.dx-calendar-empty-cell {
  display: none;
}
.cust-filter {
  display: flex;
  gap: 15px;

  .dx-texteditor.dx-editor-outlined {
    border-color: #cccccc;
    border-radius: 16px;
  }
  .dx-datebox-calendar .dx-dropdowneditor-icon {
    border-radius: 16px;
  }
}
.dx-show-invalid-badge.dx-widget.dx-visibility-change-handler.dx-calendar.dx-swipeable.dx-calendar-view-year {
  height: 115px;
  min-height: 115px;
}
</style>
