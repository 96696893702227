<template>
  <div class="table1-container">
    <DxDataGrid
      id="gridContainer1"
      :data-source="rows"
      :columns="column_names"
      :showColumnHeaders="true"
      :show-borders="true"
      :showRowLines="true"
      :showColumnLines="true"
      :allow-column-resizing="true"
    >
    </DxDataGrid>
  </div>
</template>
<script>
import DxDataGrid from "devextreme-vue/data-grid";
import UserInfo from "@/components/direct-reports/UserInfo";
export default {
  name: "TableDetail",
  components: {
    DxDataGrid,
  },
  props: ["rows", "column_names"],
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.table1-container {
  // padding: 20px;
}
.tags-sm {
  padding: 6px 14px 4px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px !important;
  line-height: 12.38px;
}
.txt_red {
  color: #ff6c6c !important;
}
</style>
