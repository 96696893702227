<template>
  <!-- <v-card>
    <v-card-text> -->
  <div class="completedCourseDialog_content">
    <img
      runat="server"
      :src="require('../assets/img/' + image)"
      class="img_completedCourse"
    />
    <!-- <div
          runat="server"
          :style="{'background-image': 'url(' + image + ')'}"
          class="img_completedCourse"
        ></div> -->
    <!-- <div class="progress_score">
          <span class="progress_getScore_txt">2000</span>
          <v-progress-linear
            class="progress_score_content"
            v-model="completedScore"
            :color="progress_bar_color"
            height="16"
          ></v-progress-linear>
          <span class="progress_totalScore_txt">100000</span>
        </div> -->
    <div class="completed_contentTxt">
      <span v-if="title" class="completed_titleTxt">
        {{ title }}
      </span>
      <br />
      <span v-if="subtitle1" class="completed_subtitleTxt">
        {{ subtitle1 }}
      </span>
      <span v-if="points" class="gotScore"> {{ points }} XP </span>
      <br />
      <span v-if="subtitle2" class="completed_subtitleTxt">
        {{ subtitle2 }}
      </span>
    </div>
    <div
      v-if="closeText != 'OK'"
      @click="closeDialogCompleted"
      class="completed_Btn"
    >
      <span class="completed_btn_txt">{{ closeText }}</span>
    </div>
    <div v-else @click="closeDialogCompleted" class="completed_Btn_OK">
      <span class="completed_btn_txt">OK</span>
    </div>
  </div>
  <!-- </v-card-text>
  </v-card> -->
</template>
<script>
export default {
  name: "DisplayComplete",
  props: {
    type_dialog: {
      type: String,
      default: "success1",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle1: {
      type: String,
      default: "",
    },
    points: {
      type: String,
      default: "",
    },
    subtitle2: {
      type: String,
      default: "",
    },
    closeText: {
      type: String,
      default: "OK",
    },
  },
  computed: {
    image() {
      switch (this.type_dialog) {
        case "success":
          return "success_img.svg";
        case "error":
          return "fail_triangle.svg";
        case "complete":
          return "success_people.svg";
        case "approve":
          return "success_approved.svg";
        case "reject":
          return "fail_rejected.svg";
        default:
          return "success_people.svg";
      }
    },
  },
  methods: {
    closeDialogCompleted() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
// ::v-deep {
//   .card {
//     // padding: 10px !important;
//     height: 100%;
//   }
// }
.completedCourseDialog_content {
  padding: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $white;

  .img_completedCourse {
    margin-top: 40px;
    max-height: 40vh;
  }
  .progress_score {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .progress_getScore_txt {
      margin: 0 10px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #a7d42e;
    }
    .progress_score_content {
      margin: 0 10px;
      width: 400px;
    }
    .progress_totalScore_txt {
      margin: 0 10px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
  }
  .completed_contentTxt {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    // max-width: 520px;
    .completed_titleTxt {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 21px;
      color: $blue;
    }
    .completed_subtitleTxt {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 21px;
      color: $black;
    }
  }
  .completed_Btn {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 257px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $blue;
    border-radius: 30px;
    cursor: pointer;
    .completed_btn_txt {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
  .completed_Btn:hover {
    transform: scale(1.1, 1.1);
  }
  .completed_Btn_OK {
    margin-top: 20px;
    margin-bottom: 40px;
    background-image: url("~@/assets/img/completed_courseBtn_bg.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 140px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .completed_btn_txt {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
  .completed_Btn_OK:hover {
    transform: scale(1.1, 1.1);
  }
  .gotScore {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #a7d42e;
    padding: 1px 10px;
    border-radius: 15px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    color: #a7d42e;
  }
}
</style>
