<template>
  <div class="filters">
    <nav class="filters__nav">
      <ul class="options">
        <li
          v-for="filterMenu in Object.keys(filters)"
          :key="filterMenu"
          class="item"
          @click.stop="toggleMenu(filterMenu)"
          v-click-outside="
            filterMenu == 'status' ? hideStatusMenu : hideTypeMenu
          "
        >
          <span class="text text-capitalize">{{ filterMenu }}</span>
          <img class="img-fluid" src="@/assets/img/nav-arrow.svg" />
          <ul
            class="elements"
            v-bind:class="{ activeMenu: stateMenus[filterMenu] }"
          >
            <li
              class="box"
              v-for="el in filters[filterMenu]"
              :key="el"
              @click="selectTag(el)"
            >
              {{ el }}
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <!-- When Mobile, Menu -->
    <nav class="filters__mobile_nav">
      <ul class="options">
        <li class="item">
          <!-- <span class="text">Menu</span> -->
          <img
            @click.stop="toggleMenu('responsiveMenu')"
            class="mobile_filter_img"
            src="@/assets/img/mobile_filter_img.svg"
          />
          <div
            class="elements"
            v-bind:class="{ activeResposiveMenu: stateMenus.responsiveMenu }"
          >
            <ul
              class="filterTag"
              v-bind:class="{ activeMenu: stateMenus.responsiveMenu }"
              v-for="filterMenu in Object.keys(filters)"
              :key="filterMenu"
            >
              <li class="filterTagName">
                {{ filterMenu }}
                <div class="filterTagName_underline"></div>
              </li>
              <li
                class="box"
                v-for="el in filters[filterMenu]"
                :key="el"
                @click="selectTag(el)"
              >
                {{ el }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>

    <!-- Input text to query keyword -->
    <div class="filters__right">
      <!-- Input form -->
      <div class="input-box">
        <form class="search-form">
          <input
            required
            class="text-search"
            type="text"
            :placeholder="searchPlaceholder"
            :value="searchVal"
            @input="setSearch($event)"
            @click="startSearch"
          />
          <button
            class="clear-search"
            type="reset"
            @click="setSearch('')"
          ></button>
        </form>
        <img src="@/assets/img/input-mag.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const setCoursePageInfoHelpers = createNamespacedHelpers("setCoursePageInfo");
import ClickOutside from "vue-click-outside";

export default {
  name: "EventsFilters",
  props: {
    filters: {
      type: Object,
      default: new Object(),
    },
    searchVal: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      stateMenus: {
        status: false,
        type: false,
        responsiveMenu: false,
      },
    };
  },

  computed: {
    status() {
      return this.filters.status;
    },

    type() {
      return this.filters.type;
    },
  },
  created() {
    this.setMobileMenuActive(false);
  },
  methods: {
    ...setCoursePageInfoHelpers.mapMutations(["setMobileMenuActive"]),
    selectTag(tag) {
      this.$emit("selectedTag", tag);
      this.stateMenus.responsiveMenu = false;
      this.setMobileMenuActive(false);
    },
    setSearch($event) {
      this.$emit("search", $event.target?.value ? $event.target.value : "");
    },
    startSearch() {
      this.stateMenus.responsiveMenu = false;
      this.setMobileMenuActive(false);
    },
    toggleMenu(key) {
      for (const name of Object.keys(this.stateMenus)) {
        if (name === key) {
          this.stateMenus[name] = !this.stateMenus[name];
          if (key == "responsiveMenu") {
            this.setMobileMenuActive(this.stateMenus[name]);
          }
        } else {
          this.stateMenus[name] = false;
        }
      }
    },

    hideMenus(menuName) {
      console.log(menuName);
      if (menuName == "status" && this.stateMenus.status) {
        this.stateMenus.status = false;
      } else if (menuName == "type" && this.stateMenus.type) {
        this.stateMenus.type = false;
      }
    },
    hideStatusMenu() {
      this.stateMenus.status = false;
    },
    hideTypeMenu() {
      this.stateMenus.type = false;
    },
  },

  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss">
// src/components/course-catalog/Filters.vue
.text-capitalize {
  text-transform: capitalize;
}
</style>
