<template>
  <div class="report-row">
    <div class="img-role">
      <div class="report-img">
        <div
          class="report-img-container"
          :style="{ 'background-image': 'url(' + photo + ')' }"
        ></div>
      </div>
      <div class="report-name-role">
        <ul>
          <li class="report-name">
            <span>{{ name }}</span>
          </li>
          <li class="report-role">
            <span>{{ job_title }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
// const nanoramicLabsDetailHelpers = createNamespacedHelpers("nanoramicLabsDetail");
export default {
  name: "UserInfo",
  props: ["name", "job_title", "photo"],
};
</script>
<style lang="scss" scoped>
.report-row {
  display: flex;
  flex-flow: row;
  padding-top: 0 !important;
  justify-content: space-between;
  align-items: center;
  .img-role {
    display: flex;
    flex-flow: row;
    align-items: center;
    .report-img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;

      .report-img-container {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        background-color: #dfebf5;
      }
    }
    .report-name-role {
      .report-name {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #323a44;
        text-transform: capitalize;
      }
      .report-role {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: #a1a4ac;
        padding-top: 0.125rem;
        text-transform: capitalize;
      }
    }
  }

  .report-out-link {
    min-width: 1.875rem;
    text-align: right;
    cursor: pointer;
  }
}
</style>
