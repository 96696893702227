<template>
  <DxDataGrid
    id="gridContainer2"
    :data-source="singleRow"
    :columns="col"
    key-expr="Certifications that require the course"
    :show-borders="true"
    :showRowLines="true"
    @selection-changed="selectionChanged"
    :allow-column-resizing="true"
  >
    <DxSelection mode="single" />
  </DxDataGrid>
</template>
<script>
import DxDataGrid, { DxSelection } from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxSelection,
  },
  props: ["col", "row"],
  data() {
    return {
      singleRow: {},
    };
  },
  methods: {
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
  },
  created() {
    this.singleRow = this.row.map((e) => {
      let myObj = new Object();
      myObj[this.col] = `${e}`;
      return myObj;
    });
  },
};
</script>
<style lang="scss">
.last-margin {
  padding-left: 28px;
  // padding-right: 28px;
}
</style>
