<template>
  <div>
    <div class="table-content-1">
      <div class="content-header-1">
        <div class="table-content-title">
          <span>{{ content_title }}</span>
        </div>
        <router-link class="table-content-more" :to="linkToSeeMore">
          <span>See more...</span>
          <img class="img_outlink" src="@/assets/img/out_link_1.svg" />
        </router-link>
      </div>
      <table>
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.id">{{ col }}</th>
          </tr>
        </thead>
        <tbody v-if="route_name == 'Learning Events'">
          <tr
            v-for="(row, index) in rows"
            :key="row.id"
            @click="onSelectedRow(row, index)"
            class="selectable-row"
            ref="row"
            :class="{ selectedRow: index == selectedRow.index }"
          >
            <td v-for="col in cols" :key="col.id">
              {{ row[col] }}
            </td>
            <v-menu
              v-model="menuList[index]"
              :nudge-width="menuContentWidth"
              offset-y
              :close-on-content-click="false"
              :position-y="menu_position_y"
              :position-x="menu_position_x"
              rounded="xl"
            >
              <v-card>
                <div class="menu-content-1">
                  <div class="events-detail-1">
                    <div class="detail-1">
                      <div
                        class="img-event-1"
                        :style="selectedRow.bg ? selectedRow.bg : ''"
                        :class="{ noEventImg: !selectedRow.bg }"
                      ></div>
                      <div class="detail-info">
                        <span class="txt_name">{{ selectedRow.name }}</span>
                        <span class="txt_type">{{ selectedRow.type }}</span>
                        <span class="txt_credits"
                          >{{ selectedRow.credits }} XP</span
                        >
                      </div>
                    </div>
                    <div class="detail-2">
                      <div class="detail-txt">
                        <span class="txt-detail-name">Start Date: </span>
                        <span class="txt-detail-info">{{
                          selectedRow["started_on"]
                        }}</span>
                      </div>
                      <div class="detail-txt">
                        <span class="txt-detail-name">Due Date: </span>
                        <span class="txt-detail-info">{{
                          selectedRow["expiration_date"]
                        }}</span>
                      </div>
                      <div class="detail-txt">
                        <span class="txt-detail-name">Finish Date: </span>
                        <span class="txt-detail-info">{{
                          selectedRow.completion_date
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="events-status-1">
                    <div class="progress">
                      <div v-if="selectedRow['started_on'] == '-'">
                        <span class="txt_pending">Pending Start</span>
                      </div>

                      <div v-else-if="selectedRow['progress bar'] == 100">
                        <span class="txt_completed"
                          >Completed successfully!</span
                        >
                      </div>
                      <div v-else>
                        <span class="txt_progress">Event Progress</span>
                        <div class="progress-bar">
                          <v-progress-linear
                            class="progress-bar-container"
                            v-model="selectedRow['progress bar']"
                            :color="progress_bar_color"
                            height="16"
                            ><strong class="progress-text"
                              >{{ selectedRow["progress bar"] }}%</strong
                            ></v-progress-linear
                          >
                        </div>
                      </div>
                    </div>
                    <v-btn
                      depressed
                      rounded
                      color="#a7d42e"
                      class="txt_gotoEvent-1"
                      height="47"
                      width="147"
                      @click="openEvent(selectedRow.channel_id)"
                      v-if="
                        selectedRow.channel_id != false &&
                        selectedRow.type == 'elearning'
                      "
                      >Open Event</v-btn
                    >
                  </div>
                </div>
              </v-card>
            </v-menu>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="row in rows"
            :key="row.id"
            @click="openEvent(row.id)"
            class="selectable-row"
          >
            <td
              v-for="col in cols"
              :key="col.id"
              :class="
                row[col] == 'Today' && col == 'Deadline'
                  ? 'txt_orange'
                  : row[col] == 'Overdue' && col == 'Deadline'
                  ? 'txt_red'
                  : ''
              "
            >
              {{ row[col] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mobile_events">
      <div class="content-header-1">
        <div class="table-content-title">
          <span>{{ content_title }}</span>
        </div>
        <!-- <div class="table-content-more"> -->
        <router-link class="table-content-more" :to="linkToSeeMore">
          <span>See more...</span>

          <img class="img_outlink" src="@/assets/img/out_link_1.svg" />
          <!-- </div> -->
        </router-link>
      </div>
      <div v-for="row in rows" :key="row.id">
        <div class="detail_row">
          <div v-for="col in cols" :key="col.id">
            <div class="detail_col">
              <span class="txt_col">{{ col }}</span>
              <span
                class="txt_row_col"
                :class="
                  row[col] == 'Today' && col == 'deadline'
                    ? 'txt_orange'
                    : row[col] == 'Overdue' && col == 'deadline'
                    ? 'txt_red'
                    : row[col] == 'Today' && col == 'expiration date'
                    ? 'txt_orange'
                    : row[col] == 'Overdue' && col == 'expiration date'
                    ? 'txt_red'
                    : row[col] == 'Today' && col == 'started on'
                    ? 'txt_orange'
                    : row[col] == 'Today' && col == 'completed on'
                    ? 'txt_orange'
                    : row[col] == 'Approved' && col == 'status'
                    ? 'txt_green'
                    : row[col] == 'Submitted' && col == 'status'
                    ? 'txt_orange'
                    : row[col] == 'Rejected' && col == 'status'
                    ? 'txt_red'
                    : row[col] == 'Draft' && col == 'status'
                    ? 'txt_grey'
                    : ''
                "
                >{{ row[col] }}</span
              >
            </div>
          </div>
          <div class="btn_detailRow" v-if="route_name == 'Learning Events'">
            <span class="txt_detailRow" @click="onSelectedMobileRow(row)"
              >See Event Description</span
            >
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" rounded="xl">
      <v-card>
        <div class="dialog-content">
          <div class="events-detail-1">
            <div class="detail-1">
              <div
                class="img-event-1"
                :style="selectedRow.bg ? selectedRow.bg : ''"
                :class="{ noEventImg: !selectedRow.bg }"
              ></div>
              <div class="detail-info">
                <span class="txt_name">{{ selectedRow.name }}</span>
                <div class="type_credits">
                  <span class="txt_type">{{ selectedRow.type }}</span>
                  <span class="txt_credits">{{ selectedRow.credits }} XP</span>
                </div>
              </div>
            </div>
            <div class="detail-2">
              <div class="detail-txt">
                <span class="txt-detail-name">Start Date: </span>
                <span class="txt-detail-info">{{
                  selectedRow["started_on"]
                }}</span>
              </div>
              <div class="detail-txt">
                <span class="txt-detail-name">Due Date: </span>
                <span class="txt-detail-info">{{
                  selectedRow["expiration_date"]
                }}</span>
              </div>
              <div class="detail-txt">
                <span class="txt-detail-name">Finish Date: </span>
                <span class="txt-detail-info">{{
                  selectedRow.completion_date
                }}</span>
              </div>
            </div>
            <v-btn
              depressed
              rounded
              color="#a7d42e"
              class="txt_gotoEvent"
              height="47"
              width="147"
              @click="openEvent(selectedRow.channel_id)"
              v-if="
                selectedRow.channel_id != false &&
                selectedRow.type == 'elearning'
              "
              >Go to Event</v-btn
            >
          </div>
          <div class="events-status">
            <div class="progress">
              <div v-if="selectedRow['started on'] == '-'">
                <span class="txt_pending">Pending Start</span>
              </div>

              <div v-else-if="selectedRow['progress bar'] == 100">
                <span class="txt_completed">Completed successfully!</span>
              </div>
              <div v-else>
                <span class="txt_progress">Event Progress</span>
                <div class="progress-bar">
                  <v-progress-linear
                    class="progress-bar-container"
                    v-model="selectedRow['progress bar']"
                    :color="progress_bar_color"
                    height="16"
                    ><strong class="progress-text"
                      >{{ selectedRow["progress bar"] }}%</strong
                    ></v-progress-linear
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <CoursePopup
      v-if="openDialog"
      :course_id="course_id"
      :openDialog="openDialog"
      @exit="exit"
      @completedCourse="completedCourse"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getCourseDetailWithIDUnpublished } from "@/api";
import CoursePopup from "@/components/course/CoursePopup";
const coursePresentationHelper = createNamespacedHelpers("coursePresentation");
const { mapActions } = createNamespacedHelpers("isLoading");

export default {
  name: "TableForDashboard",
  props: ["cols", "rows", "content_title", "route_name"],
  components: {
    CoursePopup,
  },
  data() {
    return {
      openDialog: false,
      course_id: null,
      menuList: [],
      selectedRow: {},
      progress_bar_color: "#A7D42E",
      menu_position_y: 0,
      menu_position_x: 0,
      dialog: false,
    };
  },
  computed: {
    linkToSeeMore() {
      return { name: `${this.route_name}` };
    },
    menuContentWidth() {
      return window.innerWidth / 2;
    },
  },
  created() {
    this.initialMenuList();
  },
  methods: {
    ...mapActions(["getLoading"]),
    goToCourse(title) {
      let url = `/course-presentation?${title}`;
      this.$router.push(url);
    },
    initialMenuList() {
      this.menuList = Array.from(Array(this.rows.length), () => new Array(0));
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i] = false;
      }
    },
    onSelectedRow(_row, _index) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (i == _index) {
          this.menuList[i] = !this.menuList[i];
        } else {
          this.menuList[i] = false;
        }
      }
      this.selectedRow = {};
      this.selectedRow = _row.eLearning;
      this.selectedRow.index = _index;
      let row_rect = this.$refs.row[_index].getBoundingClientRect();
      this.menu_position_y = row_rect.y + 40;
      this.menu_position_x = row_rect.x - this.menuContentWidth / 2;
      // this.menu_position_x = window.innerWidth / 2 - this.menuContentWidth / 2;
    },
    onSelectedMobileRow(_row) {
      this.dialog = true;
      this.selectedRow = {};
      this.selectedRow = _row.eLearning;
    },
    openEvent(_id) {
      this.course_id = _id;
      this.openDialog = true;
    },
    async completedCourse() {
      this.closeCourse();
      this.$emit("reloadCoursesData");
    },
    closeCourse() {
      this.openDialog = false;
    },
    exit() {
      if (this.route_name == "Learning Events") this.closeCourse();
      else this.completedCourse();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile_events {
  display: none;
}
.content-header-1 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
  padding-left: 1.875rem;
  padding-bottom: 1.25rem;
  padding-right: 1.875rem;
  .table-content-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.325rem;
    color: #323a44;
  }
  .table-content-more {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    text-decoration-line: underline;
    color: #53a0de;
    cursor: pointer;
    .img_outlink {
      margin-left: 0.625rem;
      vertical-align: bottom;
    }
  }
  .table-content-more:link {
    color: $blue !important;
  }
  .table-content-more:visited {
    color: $blue !important;
  }
}
.table-content-1 {
  // width: 53.75rem;
  height: 100%;
  width: 100%;
  //   height: 22.75rem;
  background: $white;
  border-radius: 1.875rem;

  table {
    border-collapse: separate;
    border-spacing: 0;
    // width: 50rem;
    width: calc(100% - 3.75rem);
    height: 17.5rem;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    padding-bottom: 1.3125rem;
  }
  table > tbody > .selectable-row {
    cursor: pointer;
  }

  table tr td.txt_orange {
    color: #f2994a;
  }
  table tr td.txt_orange + td {
    color: #f2994a;
  }
  table tr td.txt_red {
    color: #ff6c6c;
  }
  table tr td.txt_red + td {
    color: #ff6c6c;
  }

  table tr th,
  table tr td {
    border-right: 0.0625rem solid #e0e0e7;
    border-bottom: 0.0625rem solid #e0e0e7;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #323a44;
    padding-top: 16px;
    padding-left: 20px;
    padding-bottom: 16px;
  }
  table tr th {
    border-top: 0.0625rem solid #e0e0e7;
  }
  table tr th:first-child,
  table tr td:first-child {
    border-left: 0.0625rem solid #e0e0e7;
  }
  table tr th {
    background: $white;
    text-align: left;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #53a0de;
  }
  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 1.25rem;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 1.25rem;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 1.25rem;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 1.25rem;
  }
  .selectedRow {
    background-color: #f6f6fb;
  }
}
.menu-content-1 {
  padding: 40px;
  display: flex;
  flex-flow: column;
  .events-detail-1 {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .detail-1 {
      display: flex;
      flex-flow: row;
      .img-event-1 {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .txt_type {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          margin-bottom: 12px;
          text-transform: capitalize;
        }
        .txt_credits {
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          color: #53a0de;
          padding: 0px 10px;
          border: solid 1px #53a0de;
          border-radius: 15px;
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
  }
  .events-status-1 {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 360px;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar {
        .progress-bar-container {
          margin-top: 15px;
          border-radius: 8px;
          .progress-text {
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 13px;
            text-transform: capitalize;
            color: black;
          }
        }
      }
    }
    .txt_gotoEvent-1 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: $white;
      letter-spacing: normal;
      text-transform: none;
      padding: 15px 40px !important;
    }
  }
}
.dialog-content {
  padding: 24px 30px;
  display: flex;
  flex-flow: column;
  .events-detail-1 {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 70px;
    .detail-1 {
      display: flex;
      flex-flow: column;

      .img-event-1 {
        min-width: 125px;
        height: 125px;
        border-radius: 30px;
        background: white;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 16px;
      }
      .detail-info {
        display: flex;
        flex-flow: column;
        .txt_name {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: capitalize;
          color: #323a44;
          margin-bottom: 9px;
        }
        .type_credits {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;
          .txt_type {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #323a44;

            margin-right: 16px;
            text-transform: capitalize;
          }
          .txt_credits {
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            color: #53a0de;
            padding: 0px 17px;
            border: solid 1px #53a0de;
            border-radius: 15px;
          }
        }
      }
    }
    .detail-2 {
      display: flex;
      flex-flow: column;
      margin-top: 25px;
      .detail-txt {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .txt-detail-name {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
        }
        .txt-detail-info {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #323a44;
          text-transform: capitalize;
          margin-left: 16px;
          text-align: right;
        }
      }
    }
    .txt_gotoEvent {
      margin-top: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: $white;
      letter-spacing: normal;
      text-transform: none;
      padding: 15px 40px !important;
    }
  }
  .events-status {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .progress {
      display: flex;
      flex-flow: column;
      width: 100%;
      .txt_pending {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #53a0de;
      }
      .txt_progress {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323a44;
      }
      .txt_completed {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #a7d42e;
      }
      .progress-bar-container {
        margin-top: 15px;
        border-radius: 8px;
        .progress-text {
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: black;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .table-content-1 {
    display: none !important;
  }
  .mobile_events {
    display: grid !important;
    grid-gap: 16px 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    .detail_row {
      background: white;
      border-radius: 16px;
      padding: 0px 16px;
      box-shadow: 0px 6px 10px rgba(195, 196, 198, 0.5);
      padding-bottom: 20px;
      padding-top: 4px;
      .detail_col {
        display: flex;
        flex-flow: row;
        padding: 16px 0px;
        border-bottom: solid 1px #e0e0e7;
        .txt_col {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: #53a0de;
          text-transform: capitalize;
          width: 45%;
        }
        .txt_row_col {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #323a44;
          width: 55%;
        }
      }
      & > div:nth-child(9) .detail_col {
        border-bottom: none;
      }
      .btn_detailRow {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .txt_detailRow {
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: white;
          padding: 12px 74px;
          background-color: #53a0de;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
