//   Sunday, January 1
const EditDate = (_date) => {
  if (!_date) return "";
  let regex_Date = /^\d{4}-([0][0-9]|[1][0-2])-\d{2}$/;
  let setdate;
  let setYear;
  let setMonth;
  let setDate;
  let setDay;
  if (regex_Date.test(_date)) {
    setdate = _date.split("-");
    setYear = setdate[0];
    setMonth = parseInt(setdate[1]) - 1;
    setDate = setdate[2];
  } else {
    setdate = new Date(_date);
    setYear = setdate.getFullYear();
    setMonth = setdate.getMonth();
    setDate = setdate.getDate();
    setDay = setdate.getDay();
  }
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dayOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return dayOfWeek[setDay] + ", " + months[setMonth] + " " + setDate;
};
export default EditDate;
