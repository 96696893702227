<template>
  <v-dialog
    :value="openDialog"
    transition="dialog-transition"
    max-width="1200"
    persistent
    width="unset"
    :retain-focus="false"
  >
    <template>
      <div class="modal-content">
        <p class="modal-title">{{ message }}</p>
        <div class="buttons-modal">
          <button class="btn-modal btn-blue" @click="onOk">Ok</button>
        </div>
      </div>
    </template>
  </v-dialog>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
    },
    message: {
      type: String,
    },
  },
  computed: {
    openDialog() {
      return this.open;
    },
  },
  methods: {
    onOk() {
      this.$emit("ok");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: $white;
  // width: max(100px, 30vw);
  width: 450px;
  height: 190px;
  .modal-title {
    font-weight: 400;
    font-size: 21px;
    text-align: center;
  }
  .buttons-modal {
    margin-top: 20px;
    display: flex;
    gap: 25px;
    .btn-blue {
      background-color: $blue;
    }
    .btn-red {
      background-color: $red;
    }
    .btn-modal {
      height: 35px;
      width: 100px;
      font-weight: 600;
      font-size: 18px;
      color: $white;
      text-transform: none;
      border-radius: 30px;
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
}
@media screen and (max-width: 502px) {
  .modal-content {
    width: 95vw;
    height: 80vh;
  }
  .buttons-modal {
    flex-direction: column;
  }
}
</style>
