<template>
  <div class="change-login">
    <div class="employee_search">
      <button v-if="act_as_me == false" class="btn-myself" @click="actAsMyself">
        Act as myself
      </button>
      <Transition name="slide-up" mode="out-in">
        <button
          v-if="!showUsersList"
          class="btn-another"
          @click="viewUsersList"
        >
          Act as another user
        </button>
        <v-autocomplete
          v-else
          v-model="searchEmployee"
          :items="employeeNameJobList"
          label="Search employee"
          item-text="name"
          item-value="name"
          append-icon=""
          :auto-select-first="true"
          clearable
          return-object
          solo
          rounded
          flat
          hide-details
          :menu-props="{ maxWidth: '300' }"
          @change="selectedEmployee"
          :loading="searchLoading"
          loader-height="0"
        >
          <!-- <template  v-slot:clear-icon-cb>
            <v-icon>
              $clearGrey
            </v-icon>
          </template> -->
          <template v-slot:item="data">
            <v-list-item-avatar rounded class="mt-0 mb-0">
              <div
                class="selectedImg"
                :style="{
                  backgroundImage: 'url(' + data.item.image_128 + ')',
                }"
              ></div>
            </v-list-item-avatar>
            <v-list-item-content class="pt-1 pb-1">
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.job_title"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="data.item.employee_pronoun"
                v-html="data.item.employee_pronoun"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title class="no-data">
                <DxLoadIndicator
                  :visible="searchLoading"
                  class="loading-indicator-search"
                />
                <span>Loading data...</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </Transition>
    </div>
  </div>
</template>
<script>
import { getEmployeeInfo, getEmployeeInfoAndToken } from "@/api";
import { createNamespacedHelpers } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");
const userInfoHelpers = createNamespacedHelpers("userInfo");
const alertHelpers = createNamespacedHelpers("alert");

export default {
  name: "ChangeLogin",
  components: {
    DxLoadIndicator,
  },
  data() {
    return {
      act_as_me: true,
      searchEmployee: "",
      showUsersList: false,
      searchLoading: true,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  computed: {
    ...nanoramicLabsHelpers.mapGetters(["employeeNameJobList"]),
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...userInfoHelpers.mapActions(["dispatchUser"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    ...nanoramicLabsHelpers.mapActions(["dispatchEmployeeNameJobList"]),
    async getData() {
      await this.dispatchEmployeeNameJobList();
      this.searchLoading = false;
    },
    viewUsersList() {
      this.showUsersList = true;
    },
    async selectedEmployee(e) {
      if (e) {
        this.getLoading(true);
        await this.updateDataUser(e.id);
        this.getLoading(false);
      }
    },
    async updateDataUser(_another_employee_id) {
      let datamyuser = JSON.parse(localStorage.getItem("user1"));

      let user_id;
      let _act_as_me = false;
      let token = datamyuser.token;
      let employee_id = datamyuser.employee_id;

      let _is_me = !_another_employee_id || employee_id == _another_employee_id;
      localStorage.setItem("token", token);

      try {
        if (_is_me) {
          user_id = datamyuser.user_id;
          _act_as_me = true;
        } else {
          let employee_result = await getEmployeeInfoAndToken(
            _another_employee_id
          );
          if (employee_result.status == 200) {
            let _dataNewEmployee = employee_result.data.data.response;
            if (_dataNewEmployee.access_token) {
              token = employee_result.data.data.response.access_token;
              user_id = employee_result.data.data.response.user_id[0];
              employee_id = employee_result.data.data.response.id;
            } else {
              throw "Access Token Expired";
            }
          }
        }
        this.$store.commit("resetState");
        localStorage.setItem("token", token);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("act_as_me", _act_as_me);
        localStorage.setItem("employee_id", employee_id);

        await this.dispatchUser();
        this.$router.go("/");
      } catch (error) {
        console.log("getEmployeeInfoError==>", error);
        this.dispatchOpenAlert({
          type: "error",
          message: error ? error : "Something went wrong",
        });
      }
    },
    async actAsMyself() {
      this.getLoading(true);
      await this.updateDataUser();
      this.getLoading(false);
    },
  },
};
</script>
<style lang="scss">
.employee_search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.25s ease-out;
  }
  .slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }
  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
  .v-autocomplete-list {
    width: 100%;
    text-align: left;
    border: none;
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #157977;
    border-radius: 30px;
  }
  .v-list-item__avatar {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .v-list-item__content {
    padding: 0px !important;
  }
  .loading-indicator-search {
    height: 24px;
    width: 24px;
  }
  .v-input__icon {
    min-width: 14px !important;
    width: 14px !important;
    height: 14px !important;
  }
  .v-icon {
    width: 14px !important;
    height: 14px !important;
  }
  .v-icon.v-icon {
    font-size: 12px !important;
  }
  .v-icon__component {
    width: 14px !important;
    height: 14px !important;
  }
  .btn-myself {
    margin-right: 2.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem; //14px;
    line-height: 17px;
    color: $white;
    background-color: $blue;
    border-radius: 30px;
    width: 149px;
    height: 48px;
    overflow: hidden;
    white-space: nowrap;
  }
  .btn-another {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem; //14px;
    line-height: 17px;
    color: $white;
    background-color: $green;
    border-radius: 30px;
    width: 191px;
    height: 48px;
    overflow: hidden;
    white-space: nowrap;
  }
  .srch-user {
  }
  @media screen and (max-width: 1370px) {
    .btn-myself {
      margin-right: 0;
      margin-bottom: 25px;
    }
    .btn-another {
      margin-bottom: 12.8px;
    }
  }
}
</style>
