<template>
  <div>
    <div class="tasks-page">
      <div class="back-item">
        <img class="back-item__img" src="@/assets/img/page-arrow-black.svg" />
        <span class="back-item__text" @click="backToPages"
          >&nbsp;Back to Dashboard</span
        >
      </div>
      <v-toolbar flat color="primary" class="toolbar-header">
        <v-toolbar-title
          v-if="overdueTask"
          class="toolbar-title bullet bullet__danger justify-start"
          style="margin-left: 24px"
        >
          You have overdue tasks
        </v-toolbar-title>
        <v-toolbar-title class="toolbar-title">
          You have things to do:
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs :vertical="windowWidth > 800" class="tabs" hide-slider>
        <!-- area=".tasks-page .tabs" -->
        <!-- area=".tabs-scroll .tasks-page .tabs" -->
        <DxResizable
          id="tabResizer"
          area=".tasks-page .tabs"
          :min-width="windowWidth > 800 ? 205 : windowWidth - 105"
          :max-width="windowWidth > 800 ? 700 : windowWidth - 105"
          :width="400"
          class="dx-resizable"
          :keep-aspect-ratio="true"
          handles="right"
        >
          <div class="overflow-y-auto tabs-scroll rounded-0">
            <div v-if="tasks.length <= 1">
              <v-tab class="tab">
                <span class="task-text ml-5">No tasks yet</span>
              </v-tab>
            </div>
            <div v-else>
              <v-tab
                v-for="(task, index) in tasks"
                :key="index"
                class="tab"
                :class="{ 'tab--hide': task.initial }"
              >
                <div
                  :class="
                    typeTaskColor
                      | typeTask(task.onboarding_id, task.onboard_type)
                  "
                  class="typetasks"
                >
                  <span class="typetask-text">{{
                    typeTaskLabel
                      | typeTask(task.onboarding_id, task.onboard_type)
                  }}</span>
                </div>
                <v-tooltip
                  top
                  v-if="
                    task.request_model_name ==
                    'onboarding.request.approve.course'
                  "
                  openDelay="1600"
                  transition="scroll-y-reverse-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      :class="`bullet__${calculateDeadline(
                        task.date_deadline
                      )}`"
                      class="task-text bullet"
                    >
                      <!-- Approve for publishing course {{
                        task.title ? `"${task.title}"` : ''
                      }} -->
                      {{ task.display_name }}
                    </p>
                  </template>
                  <span>
                    <!-- Approve for publishing course {{ task.title ? `"${task.title}"` : ''}} -->
                    {{ task.display_name }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-else
                  openDelay="800"
                  transition="scroll-y-reverse-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      :class="`bullet__${calculateDeadline(
                        task.date_deadline
                      )}`"
                      class="task-text bullet"
                    >
                      <!-- {{ task && task.request_name ? task.request_name : "Complete this task" }} -->
                      <!-- {{
                      task.request_model_name == 'onboarding.request.hremployee.course' ?
                        'Take the Course' : 'Approve for publishing course'
                    }} -->
                      {{
                        task && task.display_name
                          ? task.display_name
                          : "Complete this task"
                      }}
                    </p>
                  </template>
                  <span>
                    <!-- {{
                      task.request_model_name == 'onboarding.request.hremployee.course' ?
                        'Take the Course' : 'Approve for publishing course'
                    }} -->
                    {{
                      task && task.display_name
                        ? task.display_name
                        : "Complete this task"
                    }}
                  </span>
                </v-tooltip>
              </v-tab>
            </div>
          </div>
        </DxResizable>
        <v-tab-item
          v-if="tasks.length <= 1"
          class="tab-items overflow-y-auto"
          transition="fade"
          reverse-transition="fade"
        >
          <v-row class="ma-0 justify-center">
            <v-col cols="12" class="ma-0 tab-item-wrapper">
              <div class="tab-item">
                <div class="img-txt">
                  <!-- <div class="img-txt mt-10"> -->
                  <div class="img-container mb-0">
                    <div class="img-bg-no-tasks"></div>
                  </div>
                  <p class="tab-item-text mb-4 px-10">
                    You have no assigned tasks yet.
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          v-for="(task, index) in tasks"
          :key="index"
          class="tab-items"
          transition="fade"
          reverse-transition="fade"
        >
          <v-row class="ma-0 justify-center" v-if="task.initial">
            <v-col cols="12" class="ma-0 tab-item-wrapper">
              <div class="tab-item">
                <div class="img-txt mt-10">
                  <div class="img-container mb-0">
                    <div class="img-bg-select-task"></div>
                  </div>
                  <p class="tab-item-text mb-4 px-10">
                    {{ task.request_intro }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-0 justify-center" v-else>
            <v-col cols="12" class="ma-0 tab-item-wrapper">
              <DeadlineBanner
                :deadline="task.date_deadline"
                class="banner"
                :class="`banner__${calculateDeadline(task.date_deadline)}`"
              />
              <div
                v-if="
                  task.request_model_name ==
                    'onboarding.request.hremployee.course' ||
                  task.request_model_name == 'onboarding.request.approve.course'
                "
                class="tab-item-scroll"
              >
                <v-card class="item-scroll">
                  <div class="item-content">
                    <h1 class="txt-title">
                      <!-- {{
                        task.request_model_name ==
                        "onboarding.request.hremployee.course"
                          ? "Take the Course"
                          : "Approve for publishing course"
                      }}
                      "{{ task.channel_id[1] }}" -->
                      {{ task.description }}
                    </h1>
                    <span class="txt-course-intro">
                      <!-- {{task.description}} -->
                      We need you to take this course
                    </span>
                    <div class="card-elevated">
                      <CourseSelected
                        :task_id="task.id"
                        :course_id="task.channel_id[0]"
                        @completedCourse="completedCourse"
                        class="course-presentation"
                      />
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="tab-item" v-else>
                <div
                  v-if="
                    task.request_model_name ==
                    'onboarding.request.hremployee.profile.picture'
                  "
                >
                  <div v-if="imgSaved" class="img-txt mt-10 text-center">
                    <div class="img-container-complete mb-0">
                      <div class="img-bg-picture-left"></div>
                      <div
                        class="img-bg-picture-center"
                        :style="{
                          'background-image': 'url(' + getCroppedImg + ')',
                        }"
                      ></div>
                      <div class="img-bg-picture-right"></div>
                    </div>
                    <h1 class="tab-item-title">Thank you!</h1>
                    <p class="tab-item-text mb-4 px-10">
                      Your picture has been uploaded successfully
                    </p>
                    <v-btn
                      depressed
                      rounded
                      color="primary"
                      height="40"
                      width="160"
                      @click="onClickTask"
                      style="text-transform: none"
                    >
                      Close
                    </v-btn>
                  </div>
                  <div v-else class="img-txt mt-10">
                    <div class="img-container-empty mb-0">
                      <div class="img-bg-picture-empty"></div>
                    </div>
                    <h1 class="tab-item-title">Upload A Picture</h1>
                    <p class="tab-item-text mb-4 px-10">
                      We need a good picture of you to use in all our systems.
                      We have no photo of you at this time.<br />
                      Please, upload a picture in .jpeg or .png format.
                    </p>
                  </div>
                </div>
                <p v-if="task.reject_message" class="reject-message">
                  Your task was rejected : {{ task.reject_message }}
                </p>
                <PersonalInfo
                  v-if="!imgSaved"
                  :type="task.task_type"
                  :model_name="task.request_model_name"
                  :id="task.id"
                  @closeUpdateTasks="getTasksFromAPI"
                />
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import PersonalInfo from "@/components/tasks/PersonalInfo";
import CourseSelected from "@/components/course/CourseSelected";
import DeadlineBanner from "@/components/tasks/DeadlineBanner";
import DxResizable from "devextreme-vue/resizable";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const TasksHelper = createNamespacedHelpers("tasks");
const HeadshotHelpers = createNamespacedHelpers("uploadHeadshot");

export default {
  name: "Tasks",
  components: {
    DxResizable,
    PersonalInfo,
    CourseSelected,
    DeadlineBanner,
  },
  data() {
    return {
      typeTaskLabel: "label",
      typeTaskColor: "color",
    };
  },
  created() {
    this.getTasksFromAPI();
  },
  filters: {
    typeTask: (text, OnboardingId, OnboardingType) => {
      if (OnboardingId) {
        switch (OnboardingType) {
          case "preboarding":
            if (text === "label") return `Preboarding Task`;
            else return `typetask-preboarding`;
          case "onboarding":
            if (text === "label") return `Onboarding Task`;
            else return `typetask-onboarding`;
          case "offboarding":
            if (text === "label") return `Offboarding Task`;
            else return `typetask-offboarding`;
          case "transitioning":
            if (text === "label") return `Transitioning Task`;
            else return `typetask-transitioning`;
          default:
            if (text === "label") return `Not Specified`;
            else return `typetask-notspecified`;
        }
      } else {
        if (text === "label") return `HR Task`;
        else return `typetask-hr`;
      }
    },
  },
  computed: {
    ...TasksHelper.mapGetters(["tasks"]),
    ...HeadshotHelpers.mapGetters([
      "getCroppedImg",
      "getIsCropped",
      "imgSaved",
    ]),
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
    overdueTask() {
      const today = new Date();
      let hasOverdue = false;
      this.tasks.forEach((el) => {
        if (Date.parse(today) > Date.parse(el.date_deadline)) {
          hasOverdue = true;
        }
      });
      return hasOverdue;
    },
  },
  methods: {
    ...TasksHelper.mapMutations(["initTaskForm"]),
    ...TasksHelper.mapActions(["dispatchUserTasksByType"]),
    ...HeadshotHelpers.mapMutations(["setImgSaved"]),
    ...mapActions(["getLoading"]),
    async getTasksFromAPI() {
      this.getLoading(true);
      await this.dispatchUserTasksByType();
      this.getLoading(false);
    },
    backToPages() {
      let url = `/home/dashboard`;
      this.$router.push(url);
    },
    async completedCourse() {
      document.getElementsByClassName("tab")[0].click();
      this.getLoading(true);
      await this.dispatchUserTasksByType();
      this.getLoading(false);
    },
    calculateDeadline(deadline) {
      let color = "success";
      if (deadline) {
        const today = new Date();
        const diffTime = Math.abs(Date.parse(today) - Date.parse(deadline));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (Date.parse(today) > Date.parse(deadline)) {
          color = "danger";
        } else if (diffDays <= 7) {
          color = "warning";
        }
      }
      return color;
    },
    async onClickTask() {
      this.getLoading(true);
      await this.dispatchUserTasksByType();
      this.setImgSaved(false);
      this.getLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.tasks-page {
  margin: 30px 80px 0 80px;
  align-items: flex-start;
  max-width: 100%;
  height: 100%;
  .back-item {
    margin-top: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      margin-left: 5px;
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      text-transform: capitalize;
    }
  }
  .toolbar-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: $blue !important;
    .toolbar-title {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      color: white;
      flex: 2;
      &.bullet {
        flex: 1;
      }
    }
  }

  .tabs {
    height: calc(100vh - 290px);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: 1px solid $light-gray-4;
    border-right: 1px solid $light-gray-4;
    border-left: 1px solid $light-gray-4;
    overflow-y: auto;
    background-color: $white;
    ::v-deep .v-tabs-items {
      overflow-y: auto;
    }
    // ::v-deep .v-slide-group {
    //   height: 25vh;
    // }
    #tabResizer {
      height: 100%;
      // width: 100% !important;
    }
    .tabs-scroll {
      height: 100%;
      box-shadow: none;
      // resize: horizontal;
      // min-width: 205px;
      // max-width: 700px;
      // width: 480px;
      width: 100%;
      position: relative;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      white-space: nowrap;
      padding: 0;
      border-right: 1px solid $light-gray-4;
      border-bottom-left-radius: 30px;
      .tab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 78px !important;
        min-width: 100%;
        max-width: 100%;
        padding-left: 40px;
        border-bottom: 1px solid $light-gray-4;
        box-sizing: border-box;
        overflow: hidden;
        &:last-child {
          border-bottom: 0;
        }
        &--hide {
          display: none;
        }
        &.v-tab--active {
          border-left: 4px solid $blue;
          background-color: #f6f6fb !important;
          .task-text {
            text-align: left;
            font-weight: 600;
            line-height: 16px;
            color: #53a0de;
            // color: red;
            white-space: pre-line;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
          }
        }
        .task-text {
          align-self: center;
          text-align: left;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-transform: none;
          color: #5d646d;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 90%;
        }
      }
    }

    .typetasks {
      position: absolute;
      color: #3922a7;
      height: 20px;
      border-bottom-left-radius: 36px;
      width: 140px;
      right: 0px;
      text-align: center;
      .typetask-text {
        text-transform: none;
        font-weight: 600;
        font-size: 10px;
        color: #ffffff;
        vertical-align: 0%;
      }
    }
    .typetask-hr {
      background-color: #53a0de !important;
    }
    .typetask-preboarding {
      background-color: #fcba00 !important;
    }
    .typetask-onboarding {
      background-color: #a8d130 !important;
    }
    .typetask-offboarding {
      background-color: #b7bab0 !important;
    }
    .typetask-transitioning {
      background-color: #ca5d80 !important;
    }
    .typetask-notspecified {
      background-color: #878787 !important;
    }
    .tab-items {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      // overflow-y: auto;
      .tab-item-wrapper {
        .banner {
          text-align: left;
          font-weight: 600;
          font-size: 16px;
          padding-left: 20px;

          &:before {
            content: "•";
            font-size: 40px;
            font-weight: 900;
            margin-right: 5px;
            vertical-align: -15%;
          }

          &__danger {
            color: #ff6c6c;
          }
          &__warning {
            color: #ddde00;
          }
          &__success {
            color: #66b501;
          }
        }
        .tab-item {
          display: block;
          margin: 0 auto;
          .img-txt {
            margin-top: 9vh;
            margin: 0 auto;
            .img-container {
              max-width: 334px;
              max-height: 279px;
              margin: 50px auto auto auto;
              .img-bg-select-task {
                width: 100%;
                padding-top: 100%;
                background-image: url("~@/assets/img/select_task.svg");
                background-size: contain;
              }
              .img-bg-no-tasks {
                height: 100%;
                padding-top: 100%;
                background-image: url("~@/assets/img/no-tasks.svg");
                background-size: contain;
              }
            }
            .img-container-complete {
              display: flex;
              flex-direction: row;
              max-width: 608px;
              max-height: 266px;
              justify-content: center;
              margin: auto auto 50px auto;
              .img-bg-picture-left {
                width: 100%;
                max-width: 101px;
                padding-top: 16%;
                background-image: url("~@/assets/img/upload-a-picture-left.svg");
                background-size: contain;
                flex-basis: 16%;
                margin-top: 18%;
                align-self: center;
                margin-right: 10%;
              }
              .img-bg-picture-center {
                width: 100%;
                max-width: 266px;
                padding-top: 43%;
                background-size: contain;
                flex-basis: 43%;
                align-self: flex-start;
                margin-right: 4%;
                border-radius: 5.78px;
              }
              .img-bg-picture-right {
                width: 100%;
                max-width: 147px;
                padding-top: 24%;
                background-image: url("~@/assets/img/upload-a-picture-right.svg");
                background-size: contain;
                flex-basis: 24%;
              }
            }
            .img-container-empty {
              max-width: 608px;
              max-height: 266px;
              margin: auto auto 50px auto;
              .img-bg-picture-empty {
                padding-top: 100%;
                background-image: url("~@/assets/img/upload-a-picture.svg");
                background-size: contain;
              }
            }
            .tab-item-title {
              margin-top: 10%;
              margin-bottom: 24px;
              text-align: center;
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              color: #a7d42e;
            }
            .tab-item-text {
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              color: #5d646d;
              white-space: pre-line;
            }
          }
          .reject-message {
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ff6c6c;
            white-space: pre-line;
          }
        }
        .tab-item-scroll {
          display: flex;
          height: calc(100vh - 220px);
          .item-scroll {
            box-shadow: none;
            width: 100%;
            .item-content {
              display: flex;
              flex-direction: column;
              align-items: space-between;
              justify-content: flex-start;
              padding: 30px;
              .txt-title {
                margin-top: 10px;
                margin-bottom: 24px;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                color: #53a0de;
              }
              .txt-course-intro {
                margin-bottom: 24px;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                white-space: pre-line;
                color: $gray;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .fade-transtion,
  .fade-enter-active,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-leave-to,
  .fade-enter {
    opacity: 0;
  }
  .card-elevated {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
  .course-presentation {
    width: 100%;
  }
  .bullet {
    &:before {
      content: "•";
      font-size: 35px;
      font-weight: 900;
      margin-right: 10px;
      vertical-align: -45%;
    }

    &__danger:before {
      color: #ff6c6c;
    }
    &__warning:before {
      color: #ddde00;
    }
    &__success:before {
      color: #66b501;
    }
  }
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 900px) {
  .tasks-page {
    margin: 30px !important;
    .tabs {
      height: calc(100vh - 250px);
    }
  }
}
@media screen and (max-width: 800px) {
  .tabs {
    ::v-deep .v-slide-group {
      height: 25vh;
    }
    ::v-deep .v-slide-group__prev {
      min-width: 0px;
      flex: 0 1 0px;
    }
  }
  #tabResizer {
    width: 100% !important;
  }
}
@media screen and (max-width: 750px) {
  .tasks-page {
    .tabs {
      .tab {
        // padding-left: 20px;
        // padding-right: 20px;
      }
    }
  }
  .tasks-page .tabs {
    ::v-deep.v-item-group.v-tabs-items {
      // padding: 30px 10px;
    }
  }
  .tasks-page .tab-items .tab-item-wrapper {
    // padding: 0;
  }
}
@media screen and (max-width: 500px) {
  // .tasks-page {
  //   margin: 30px 10px !important;
  // }
  .tasks-page .tabs .task-text {
    width: 50%;
  }
  // .tasks-page {
  //   .tabs {
  //     .tab {
  //       padding-left: 10px;
  //       padding-right: 10px;
  //     }
  //   }
  // }
}
</style>
