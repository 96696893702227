<template>
  <div class="text-center">
    <v-dialog v-model="dialog" content-class="dialog" persistent scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          rounded
          color="#53a0de"
          v-bind="attrs"
          v-on="on"
          height="47"
          width="156"
          class="btn-popup"
          @click="onClickTask"
        >
          Ask for help
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title class="headline">
          <base-btn :onClick="closeDialog">
            <v-icon class="icon--close">$close</v-icon>
            Close
          </base-btn>
          <span class="title-text">Ask for help</span>
        </v-card-title>

        <div class="form-wrapper">
          <div class="popup-form-wrapper">
            <FormFields
              class="form"
              v-for="(section, index) in requestForm"
              :key="index"
              :fields="section.fields"
              :class="{
                'fit-form': section.fix_size,
              }"
              @inputDescription="changeDescription"
            >
              <!-- <template v-slot:header>
                <span>{{ section.name }}</span>
                <v-icon class="icon">{{ form.icon }}</v-icon>
              </template> -->
            </FormFields>
            <div class="buttons_actions_cont">
              <v-btn
                :disabled="act_as_me == false"
                depressed
                rounded
                color="#53a0de"
                height="47"
                width="130"
                class="btns_send_discard"
                @click="onSend"
              >
                Send
              </v-btn>
              <v-btn
                depressed
                rounded
                color="#ff6c6c"
                height="47"
                width="130"
                class="btns_send_discard"
                @click="closeDialog"
              >
                Discard
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseBtn from "@/components/tasks/BaseBtn";
import FormFields from "@/common/FormFields";
import FieldsData from "@/utils/fields_data.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const alertHelpers = createNamespacedHelpers("alert");
import { getSeverityLevel, createServiceTicket } from "@/api";
export default {
  components: {
    BaseBtn,
    FormFields,
  },
  created() {
    this.getLoading(false);
    this.act_as_me = JSON.parse(localStorage.getItem("act_as_me"));
  },
  data() {
    return {
      act_as_me: true,
      dialog: false,
      requestForm: [],
      list_severity: [],
    };
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...alertHelpers.mapActions(["dispatchOpenAlert"]),
    async onClickTask() {
      // When is opened the dialog (this component)
      this.getLoading(true);
      try {
        const result = await getSeverityLevel();
        if (result.status == 200) {
          this.list_severity = result.data.data.response.map((l) => {
            return {
              id: l.id,
              display_name: l.name,
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.requestForm = [
        {
          fields: [
            FieldsData("char", "name", "Summary", "Summary", 6),
            FieldsData(
              "many2one",
              "severity_level_id",
              "Severity level",
              "Select Severity",
              4,
              this.list_severity
            ),
          ],
          fix_size: false,
        },
        {
          fields: [
            FieldsData(
              "long_text",
              "description",
              "Description",
              "Description",
              12,
              [],
              "inputDescription"
            ),
          ],
          fix_size: false,
        },
      ];
      this.getLoading(false);
    },

    async onSend() {
      let result;
      try {
        result = await createServiceTicket(this.requestForm);
        const _this = this;
        if (result.status == 200) {
          this.dialog = false;
          this.$emit("completed");
        }
      } catch (error) {
        this.dispatchOpenAlert({ type: "error", message: error });
      }
    },
    onDiscard() {
      this.requestForm.forEach((container) => {
        container.fields.forEach((f) => {
          f.value = "";
        });
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    changeDescription(new_val) {
      this.requestForm[1].fields[0].value = new_val;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  height: 40px;
}
.btn-popup {
  // font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  text-transform: none;
  // margin-top: 26px;
}

.v-dialog.dialog {
  margin: 80px;
  // height: calc(100% - 160px);
  border-radius: 30px;

  .card {
    height: 100%;
    overflow: hidden;
    .v-card__title.headline {
      padding: 20px 30px;
      justify-content: space-between;
      background-color: #53a0de;

      .icon--close {
        margin-right: 8px;
      }
    }
    .title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $white;
      margin: 0 auto;
    }

    .form-wrapper {
      height: calc(100% - 64px);

      .popup-form-wrapper {
        padding: 20px 30px 10px 30px;
        background: white;
        .form {
          margin-bottom: 24px;
          background: #e5e5e5;
          &.fit-form {
            width: fit-content;
            padding-bottom: 25px;
          }
        }
        .buttons_actions_cont {
          display: flex;
          justify-content: center;
          column-gap: 60px;
          margin: 140px 0 40px 0;
          .btns_send_discard {
            font-weight: 600;
            font-size: 14px;
            color: $white;
            text-transform: none;
          }
        }
      }

      .icon {
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 896px) {
  // .v-dialog {
  //   margin: 10px;
  //   width: 100%;
  // }
  .v-dialog__content {
    ::v-deep {
      .v-dialog {
        margin: 0 10px !important;
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .v-card__title.headline {
    padding: 20px 10px !important;
    justify-content: start;
    background-color: #53a0de;
    .icon--close {
      margin-right: 2px !important;
    }
  }
  .popup-form-wrapper {
    padding: 20px !important;
  }
}
</style>
