<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="book-main_section main_section">
          <div class="main_section__header">
            <h1 class="main_section-title">Book Club</h1>
            <p class="text">
              Сorporate library with books that can be useful in your learning
              process. Read books and get credits
            </p>
          </div>
          <div class="main_section__content">
            <Filters
              :filtersTags="filtersTags"
              :searchVal="query"
              @selectedTag="filterByTags"
              @search="filterByQuery"
              :searchPlaceholder="searchPlaceholder"
            />
            <SelectedTags
              :selectedTags="selectedTags"
              :selectedTag="selectedTag"
              @removeTagOne="removeFilterOne"
              @removeTag="removeFilter"
              @removeTags="removeFilters"
            />
            <transition
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
            >
              <div v-if="show">
                <div class="not-found" ref="notFoundImg" v-show="notFound">
                  <div class="not-found__img-fluid"></div>
                  <div class="not-found__space-text"></div>
                  <p class="not-found__text">
                    No book was found matching your filters
                  </p>
                </div>
                <div
                  v-show="!notFound"
                  ref="cardsGrid"
                  class="bookClubCards-grid"
                >
                  <BookClubCard
                    v-for="book in page"
                    :key="book.id"
                    :book="book"
                    @setBookClubPageInfo="setBookClubPageInfo"
                    @creditsRequestSucceded="creditsRequestSucceded"
                  />
                </div>
              </div>
            </transition>
            <Paginator
              v-if="!notFound"
              :currentPage="currentPage"
              :pageCount="pageCount"
              :totalPages="totalPages"
              @updateRange="setPaginationProcess"
            />
          </div>
          <v-dialog
            v-show="openDialogComplete"
            transition="dialog-transition"
            max-width="1200"
            :value="openDialogComplete"
            persistent
          >
            <template>
              <DisplayComplete
                type_dialog="success1"
                title="Your request was submitted successfully"
                subtitle1="We should make this a toast"
                @close="openDialogComplete = false"
              ></DisplayComplete>
            </template>
          </v-dialog>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Filters from "@/components/course-catalog/Filters";
import SelectedTags from "@/components/course-catalog/SelectedTags";
import BookClubCard from "@/components/book-club/BookClubCard";
import Paginator from "@/components/course-catalog/Paginator";
import DisplayComplete from "@/common/DisplayComplete";
import {
  getBookClubList,
  getLearningEventByType,
  getUserCreditRequest,
} from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const setBookClubPageInfoHelpers = createNamespacedHelpers(
  "setBookClubPageInfo"
);
export default {
  name: "BookClub",
  components: {
    Filters,
    SelectedTags,
    BookClubCard,
    Paginator,
    DisplayComplete,
  },
  data() {
    return {
      show: true,
      bookClubList: [],
      list: [],
      query: "",
      filtersTags: {},
      searchPlaceholder: "Search books",
      visibleItemsPerPageCount: 9,
      init: 0,
      end: 9,
      currentPage: 1,
      pageCount: 0,
      totalPages: [],
      paginationProcess: null,
      selectedTags: [],
      selectedTag: "",
      openDialogComplete: false,
    };
  },
  computed: {
    ...setBookClubPageInfoHelpers.mapGetters(["getPageNumber", "getSearchkey"]),

    page() {
      let elements = [];
      let i = 0;

      for (i = this.init; i < this.end; i++) {
        if (this.list[i]) {
          elements.push(this.list[i]);
        } else {
          break;
        }
      }
      return elements;
    },
    notFound() {
      return this.list.length === 0;
    },
  },
  async created() {
    this.getLoading(true);
    await this.getData();
    this.getLoading(false);
  },
  methods: {
    ...mapActions(["getLoading"]),
    ...setBookClubPageInfoHelpers.mapMutations([
      "setPageNumber",
      "setSearchKey",
    ]),
    async getData() {
      let creditsRequested_ids = await this.getCreditsRequested();
      let learningEvents_ids = await this.getLearningEventsBooks();
      let booksList = await this.getBookList();

      if (
        Object.keys(learningEvents_ids).length > 0 ||
        Object.keys(creditsRequested_ids).length > 0
      ) {
        booksList = booksList.map((book) => {
          return {
            ...book,
            grantedCredits: !!(
              book.learning_activity_type_id ==
              learningEvents_ids[book.learning_activity_type_id]
            ),
            requestedCredits: !!(
              book.learning_activity_type_id ==
              creditsRequested_ids[book.learning_activity_type_id]
            ),
          };
        });
      }
      this.getBookClubPageInfo(booksList);
    },
    setBookClubPageInfo() {
      this.setPageNumber(this.currentPage);
      this.setSearchKey(this.query);
    },

    async getBookList() {
      try {
        let result = await getBookClubList();
        if (result.status == 200) {
          return result.data.data.response;
        }
      } catch (error) {
        console.log("getBookClubError==>", error);
      }
      return [];
    },
    async getCreditsRequested() {
      try {
        let result = await getUserCreditRequest();
        if (result.status == 200) {
          let creditsRequested = result.data.data.response.events;
          let objectIndexed = creditsRequested.reduce((sum, c_r) => {
            if (c_r.state == "submitted") {
              return {
                ...sum,
                [c_r.learning_activity_type_id]: c_r.learning_activity_type_id,
              };
            }
            return { ...sum };
          }, {});
          return objectIndexed;
        }
      } catch (error) {
        console.log(error);
      }
      return {};
    },
    async getLearningEventsBooks() {
      try {
        let result = await getLearningEventByType("book");
        if (result.status == 200) {
          let learningEvents = result.data.data.response.data;
          let objectIndexed = learningEvents.reduce((sum, l_e) => {
            return {
              ...sum,
              [l_e.activity_type.id]: l_e.activity_type.id,
            };
          }, {});
          return objectIndexed;
        }
      } catch (error) {
        console.log("getLearningEventsError==>", error);
      }
      return {};
    },
    creditsRequestSucceded(_bookName) {
      for (const _book of this.list) {
        if (_book.name == _bookName) {
          _book.requestedCredits = true;
          break;
        }
      }
      this.openDialogComplete = true;
    },
    getBookClubPageInfo(_bookClubList) {
      this.bookClubList = _bookClubList;
      this.list = [...this.bookClubList];
      let filtersList = this.bookClubList.map((b) => b.tags).flat();
      let setFilters = new Set();
      filtersList.forEach((f) => {
        setFilters.add(f[1]);
      });
      this.filtersTags = {
        filters: [],
        tags: Array.from(setFilters),
      };
      let _getPageNumber = this.getPageNumber;
      if (this.getPageNumber == 1 && !this.getSearchkey) {
        this.setPages();
      }

      if (this.getSearchkey) {
        this.filterByQuery(this.getSearchkey);
      }
      if (_getPageNumber != 1) {
        this.setPages();
        this.setPaginationProcess(_getPageNumber);
      }
    },

    filterByTags(tag) {
      this.selectedTag = "";
      this.query = "";

      if (this.selectedTags) {
        if (!this.selectedTags.includes(tag)) this.selectedTags.push(tag);
      } else {
        this.selectedTags.push(tag);
      }

      this.list = this.bookClubList.filter((c) =>
        this.filterTags(c.tags, this.selectedTags)
      );

      this.setPages();
    },

    filterTags(bookTags, tags) {
      let found = false;
      bookTags.forEach((b) => {
        if (tags.includes(b[1])) {
          found = true;
        }
      });
      return found;
    },

    filterByQuery(value) {
      this.selectedTag = "";
      this.selectedTags = [];
      this.query = value;

      this.list = this.bookClubList.filter((item) => {
        return this.query
          .toLowerCase()
          .split(" ")
          .every((v) => item.name.toLowerCase().includes(v));
      });

      this.setPages();
    },

    removeFilterOne(filter) {
      this.removeFilters();
    },
    removeFilter(filter) {
      const index = this.selectedTags.findIndex((t) => filter === t);
      this.selectedTags.splice(index, 1);
      if (this.selectedTags.length === 0) {
        this.list = [...this.bookClubList];
      } else {
        this.list = this.bookClubList.filter((c) =>
          this.filterTags(c.tags, this.selectedTags)
        );
      }
      this.getLoading(false);
      this.setPages();
    },
    removeFilters() {
      this.selectedTags = [];
      this.selectedTag = "";
      this.list = [...this.bookClubList];
      this.setPages();
    },

    setPages() {
      this.pageCount = Math.ceil(
        this.list.length / this.visibleItemsPerPageCount
      );
      this.totalPages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
      this.currentPage = 1;
      this.paginationProcess = this.currentPage;
      this.setRange();
    },
    setPaginationProcess(value) {
      this.paginationProcess = value;
      this.show = false;
    },

    setRange() {
      console.log("pagination===>", this.paginationProcess);

      this.end = this.visibleItemsPerPageCount * this.paginationProcess;
      this.init = this.end - 9;
      this.currentPage = this.paginationProcess;

      this.setBookClubPageInfo();
    },

    beforeLeave: function () {
      this.setRange();
    },

    afterLeave: function () {
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
// assets/styles/components/shared-distribution.scss
.bookClubCards-grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (min-width: 1650px) {
  .book-main_section {
    margin-left: 8.8125rem !important;
    margin-right: 8.8125rem !important;
  }
}
@media screen and (max-width: 1858px) {
  .bookClubCards-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1230px) {
  .bookClubCards-grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 578px) {
  .bookClubCards-grid {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 25px;
  }
  .bookClubCard {
    padding: 10px;
  }
  .bookClubCard__thumbnail {
    min-width: 100px;
    padding: 0px;
    margin-right: 10px;
    .book_img {
      .see_amazon_img {
        bottom: -2px;
      }
    }
  }
}
</style>
