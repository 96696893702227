import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getTacticalRole = (_roleID) => {
  let result = axios()({
    url: API.TACTICALROLE,
    method: "patch",
    params: { action: "get_role", role_id: _roleID },
  });
  return result;
};
