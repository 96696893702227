<template>
  <div>
    <!-- Root section -->
    <v-row no-gutters>
      <v-col class="pa-0">
        <section class="presentation-page">
          <v-row no-gutters>
            <v-col class="pa-0">
              <div class="back-item">
                <img
                  class="back-item__img"
                  src="@/assets/img/page-arrow-black.svg"
                />
                <span class="back-item__text" @click="backToAllCourse()"
                  >&nbsp;{{ textBack }}</span
                >
              </div>
            </v-col>
          </v-row>
          <ElearningDescription
            :courseDetail="course_detail"
            @setStartCourse="setStartCourse"
            @setEnrollCourse="setEnrollCourse"
          />
          <!-- Content -->
        </section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { enrollingCourse, getCourses } from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
import ElearningDescription from "@/components/course/ElearningDescription";
export default {
  name: "CoursePresentation",
  components: {
    ElearningDescription,
  },
  data() {
    return {
      course_detail: null,
      textBack: "Back to All Courses",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (`${from.name}` == "Learning Events") {
        vm.textBack = "Back to Learning Events";
      }
    });
  },
  created() {
    this.getLoading(true);
    this.getChangedCourse();
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getChangedCourse() {
      const courseTitleUrl = decodeURIComponent(
        window.location.search.slice(1)
      );
      try {
        let result_all_course = await getCourses();
        if (result_all_course.status == 200) {
          let courses = result_all_course.data.data.response;
          // let all_course_list = [...courses];
          const current_course = courses.find(
            (course) => course.title == courseTitleUrl
          );
          this.course_detail = current_course;
        }
      } catch (error) {
        console.log("getChangedCourseError==>", error);
      } finally {
        this.getLoading(false);
      }
    },

    backToAllCourse() {
      let url = "";
      switch (this.textBack) {
        case "Back to Learning Events":
          url = "/learning/learning-events";
          break;
        default:
          url = "/learning/course-catalog";
          break;
      }
      this.$router.push(url);
      // this.$router.go(-1);
      // this.$router.back();
    },
    async setEnrollCourse() {
      try {
        this.getLoading(true);
        let result_enroll = await enrollingCourse(this.course_detail.id);
        if (result_enroll.status == 200) {
          this.getChangedCourse();
        }
      } catch (error) {
        console.log("enrollError=>", error);
        this.getLoading(false);
      }
    },
    setStartCourse() {
      let url = `/take-course?${this.course_detail.title}`;
      this.$router.push(url);
    },
    setReviewCourse() {
      console.log("review");
    },
  },
};
</script>
<style lang="scss" scoped>
.content_title {
  font-weight: 600 !important;
  font-size: 0.95rem !important;
  line-height: 1.4rem !important;
}
.presentation-page {
  // width: 110rem;
  margin: 3.6rem 5rem 5rem 5rem;

  .back-item {
    margin-left: 0.125rem;
    margin-bottom: 1.875rem;
    vertical-align: middle;
    cursor: pointer;
    &__img {
      width: 0.3rem;
      height: 0.55rem;
      margin-right: 5px;
    }
    &__text {
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 1rem;
      color: #323a44;
      margin-left: 5px;
    }
  }
}
</style>
