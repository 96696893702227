import { getUserInfo, getEmployeeInfo } from "@/api";
export default {
  namespaced: true,
  state: {
    user_info: "",
  },
  actions: {
    async dispatchUser({ commit }) {
      try {
        const result = await getUserInfo(localStorage.getItem("employee_id"));
        if (result.status == 200) {
          let data = result.data.data.response.data[0];

          let myInfo = localStorage.getItem("my_name_picture");
          if (!myInfo) {
            let picture_name = {
              name: data.name ? data.name : "",
              picture: data.image_128 ? data.image_128 : "",
              // picture: data.image_128_base64
              // ? `data:image/jpeg;base64,${data.image_128_base64}`
              // : "",
            };
            localStorage.setItem(
              "my_name_picture",
              JSON.stringify(picture_name)
            );
          }
          commit("setUser", data);
        }
      } catch (error) {
        console.log("getUserError==>", error);
        // console.log(error.response)
        // if (error.response.status === 401) {
        //   console.log("opiopoioipoi")
        // }
        // let datamyuser = JSON.parse(localStorage.getItem("user1"));
        // if (datamyuser) {
        //   localStorage.setItem("token", datamyuser.token);
        //   localStorage.setItem("user_id", datamyuser.user_id);
        //   localStorage.setItem("act_as_me", true);
        //   localStorage.setItem("employee_id", datamyuser.employee_id);
        // }
      }
    },
  },
  mutations: {
    setUser(state, user_info) {
      state.user_info = user_info;
    },
  },
  getters: {
    userInfo(state) {
      return state.user_info;
    },
    getUserImg64(state) {
      return state?.user_info?.image_128_base64
        ? `data:image/jpeg;base64,${state.user_info.image_128_base64}`
        : "";
    },
    getUserImgURL(state) {
      return state?.user_info?.image_128 ? state.user_info.image_128 : "";
    },
    getUserFlag(state) {
      return state?.user_info?.flag_base64
        ? `data:image/jpeg;base64,${state.user_info.flag_base64}`
        : "";
    },
    getUserGender(state) {
      return state?.user_info?.gender || "other";
    },
    getUserName(state) {
      let full_name = "";
      if (state.user_info) {
        full_name = state.user_info.name
          ? state.user_info.name
          : `${state.user_info.first_name} ${state.user_info.last_name}`;
      }
      return full_name;
    },
  },
};
