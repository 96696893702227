<template>
  <div class="bar">
    <BlueBar :countMissing="missingCert" :barProgress="certAchieved" />
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div class="chart-container" v-bind="attrs" v-on="on">
          <div
            v-for="(bar, i) in dataJoin"
            :key="i"
            :style="{
              color: bar.color,
              'background-color': bar.color,
              width: `${bar.value * 2.3}px`,
            }"
            class="status-bar status-bar__center"
            :class="personalizeClass(bar, i)"
          />
        </div>
      </template>
      <span>{{ Caption }}</span>
    </v-tooltip>
  </div>
</template>
<script>
// import {
//   DxChart,
//   DxSeries,
//   DxTooltip,
// } from 'devextreme-vue/chart';
import CapitalizeFirstLetter from "@/utils/capitalize_first_letter.js";
import BlueBar from "@/common/DevExtreme/BlueBar";

export default {
  components: { BlueBar },
  props: {
    respAckCert: {
      type: Number,
      default: 0,
    },
    respAckNoCert: {
      type: Number,
      default: 0,
    },
    respNotAck: {
      type: Number,
      default: 0,
    },
    certAchieved: {
      type: Number,
      default: 0,
    },
    respNotAckCount: {
      type: Number,
      default: 0,
    },
    missingCert: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      grey1: "#979ea8",
      grey2: "#ced4db",
      grey3: "#f2f3f5",
    };
  },
  computed: {
    Caption() {
      let _tooltipText = "Your Job Readiness Level";
      if (this.respNotAck > 0) {
        _tooltipText = `${_tooltipText} (${this.respAckCert}% with ${this.respNotAckCount} responsiblities pending acknowledgment)`;
      } else if (this.respAckCert > 0) {
        _tooltipText = `${_tooltipText} (${this.respAckCert}%)`;
      }
      return _tooltipText;
    },
    dataJoin() {
      return [
        {
          value: this.respAckCert,
          color: this.grey1,
        },
        {
          value: this.respAckNoCert,
          color: this.grey2,
        },
        {
          value: this.respNotAck,
          color: this.grey3,
        },
      ];
    },
  },
  methods: {
    personalizeClass(bar, i) {
      let firstNotNull = false;
      let lastNotNull = false;
      for (let index = 0; index < i; index++) {
        if (this.dataJoin[index].value == 0) {
          firstNotNull = true;
        } else {
          firstNotNull = false;
          break;
        }
      }
      for (let index = this.dataJoin.length - 1; index > i; index--) {
        if (this.dataJoin[index].value == 0) {
          lastNotNull = true;
        } else {
          lastNotNull = false;
          break;
        }
      }
      if (bar.value == 100) return "bar";
      if (i == 0 || firstNotNull) return "rounded-left";
      if (i == this.dataJoin.length - 1 || lastNotNull) return "rounded-right";
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  position: relative;
  // .bar-blue {
  //   position: absolute;
  //   top: 5px;

  // }
}
.bar-blue {
  position: relative;
  z-index: 2;
  top: 5px;
  border-radius: 3px;
}
.chart-container {
  position: relative;
  display: flex;
  .status-bar {
    display: flex;
    height: 16px;
    color: $white;
    font-weight: 500;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    white-space: nowrap !important;
    position: relative;
    &__left {
      justify-content: left !important;
    }
    &__center {
      justify-content: center;
    }
  }
  .rounded-left {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .rounded-right {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .bar {
    border-radius: 8px;
    // border-radius: 16px;
  }
}
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
