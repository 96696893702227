<template>
  <div class="d-flex">
    <div v-for="img in data.icon.name" :key="img">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <img
            class="img-logo"
            :src="'https://erp.nanoramic.com' + img"
            style="height: 24px; width: 24px; margin-right: 8px"
            v-bind="attrs"
            v-on="on"
            v-if="!img.toString().includes('certification.png')"
          />
          <img
            v-if="img.toString().includes('certification.png')"
            class="img-logo"
            src="../../assets/img/Knowledge.png"
            style="height: 24px; width: 24px; margin-right: 8px"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span>{{ toolTip(img) }}</span>
      </v-tooltip>
    </div>
    <div>
      <p>
        {{ data.name }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  created() {
    // console.log(this.data);
  },
  methods: {
    toolTip(img) {
      if (img.toString().includes("assignment.png")) {
        return "Role Assignment";
      } else if (img.toString().includes("primary.png")) {
        return "Primary commitment";
      } else if (img.toString().includes("preparing.png")) {
        return "Preparing for commitment";
      } else if (img.toString().includes("backup.png")) {
        return "Backup commitment";
      } else if (img.toString().includes("responsibility.png")) {
        return "Responsibility Assignment";
      } else if (img.toString().includes("certification.png")) {
        return "Knowledge";
      } else if (img.toString().includes("elearning.png")) {
        return "E-Learning Course";
      } else if (img.toString().includes("book.png")) {
        return "Read a book Learning Activity";
      } else if (img.toString().includes("classroom.png")) {
        return "Learning activity in classroom";
      } else if (img.toString().includes("level_")) {
        if (this.data.ParentID == 0) {
          return "Role Assignment Intensity";
        } else {
          return "Responsibility Assignment Intensity";
        }
      } else if (img.toString().includes("learning-activity.png")) {
        return "Classroom Learning Activity";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// assets/components/dev-express-grid.scss
.v-tooltip__content {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
