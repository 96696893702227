//   Jan 01, 2000         (-time zone)
const EditDateToUTC = (_date) => {
  if (!_date) return "";
  let today = new Date();
  let thisTimeZone = today.getTimezoneOffset();

  let setdate = new Date(_date);

  let setdate_utc = new Date(setdate - thisTimeZone * 60 * 1000);

  let setYear = setdate_utc.getFullYear();
  let setMonth = setdate_utc.getMonth();
  let setDate = setdate_utc.getDate();
  setDate = setDate < 10 ? "0" + setDate : setDate;
  let setHour = setdate_utc.getHours();
  setHour = setHour < 10 ? "0" + setHour : setHour;
  let setMinutes = setdate_utc.getMinutes();
  setMinutes = setMinutes < 10 ? "0" + setMinutes : setMinutes;
  setMinutes;
  let setSeconds = setdate_utc.getSeconds();
  setSeconds = setSeconds < 10 ? "0" + setSeconds : setSeconds;
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[setMonth] + " " + setDate + ", " + setYear + " ";
};
export default EditDateToUTC;
