<template>
  <div>
    <section class="main_section">
      <div class="page_router" v-if="'name' in employee">
        <span class="txt_router text_redirect" @click="redirect()">Managing others</span>
        <div class="img_arrow"></div>
        <div class="txt_router">{{ employee.name }}</div>
      </div>
      <div class="main_section__header">
        <h1 class="main_section-title">Current vs Deserved Salary</h1>
      </div>
      <div class="main_section__content">
        <div v-if="!notFound && Object.keys(rows).length != 0">
          <nc-salary-to-benchmark id="widget-native" v-pre></nc-salary-to-benchmark>
        </div>
        <div v-else>
          <div class="not-found">
            <p class="not-found__text">No Data Available</p>
          </div>
        </div>
      </div>
      <div
        class="main_section__content"
        style="margin-top: 0px"
        v-if="!rows.error && rows.job_pricing_report_file != false"
      >
        <div>
          <iframe
            id="job_pricing_report_file"
            type="application/pdf"
            name="pdf-frame"
            :src="openInIFrame ? slideDocumentFile : ableToDownload"
            height="340"
            frameborder="0"
          >
          </iframe>
        </div>
        <div class="file_download_print">
          <v-btn
            class="download_btn"
            color="primary"
            rounded
            outlined
            @click="onDOwnload()"
            ><v-icon>mdi-download</v-icon> Download</v-btn
          >
          <v-btn
            class="print_btn"
            @click="onPrint()"
            rounded
            color="primary"
            outlined
            style="margin-left: 15px"
            ><v-icon>mdi-printer</v-icon> Print</v-btn
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getCurrentVsDeservedSalary } from "@/api";
import { createNamespacedHelpers } from "vuex";
import b64toBlob from "@/utils/b64_to_blob.js";
const { mapActions } = createNamespacedHelpers("isLoading");
const nanoramicLabsHelpers = createNamespacedHelpers("nanoramicLabsDetail");

export default {
  name: "CurrentvsDeservedSalary",
  data() {
    return {
      rows: {},
      employee: {},
      slideDocumentFile: "",
      openInIFrame: false,
      ableToDownload: false,
      mimeType: "",
      documentName: "job_pricing_report_file",
    };
  },
  computed: {
    ...nanoramicLabsHelpers.mapGetters(["employeeNameJobList"]),
    notFound() {
      return "error" in this.rows;
    },
  },
  async created() {
    if (this.$route.params.employeeId) {
      this.employee.Id = this.$route.params.employeeId;
      this.getData(this.$route.params.employeeId);
      for (const employees of this.employeeNameJobList) {
        if (employees.id == this.$router.currentRoute.params.employeeId) {
          this.employee.name = employees.name;
          return;
        }
      }
    } else {
      await this.getData(localStorage.getItem("employee_id"));
    }
  },
  methods: {
    ...mapActions(["getLoading"]),
    async getData(employee_id) {
      this.getLoading(true);
      try {
        let result = await getCurrentVsDeservedSalary(employee_id);
        this.rows = result.data.data.response;
        const datas = result.data.data.response.job_pricing_report_file;
        if (datas !== false) {
          const { openInIFrame, blobURL, mimeType } = b64toBlob(datas);
          (this.openInIFrame = openInIFrame),
            (this.slideDocumentFile = blobURL + "#toolbar=0&view=FitH&view=FitV&"),
            (this.mimeType = mimeType);
        }
        if (!("error" in this.rows)) {
          setTimeout(() => {
            this.load();
          });
        }
      } catch (error) {
        console.log("getCurrentVsDeservedSalaryError==>", error);
      }
      this.getLoading(false);
    },
    load() {
      // get reference to widget
      const widget = document.getElementById("widget-native");

      widget.ncWidgetInit({
        annualSalary: this.rows.current_salary,
        readiness: this.rows.employee_readiness,
        quartiles: this.rows.quartiles,
        // style: 'compact',
        // quartiles: {
        //   50: { value: 52.8 },
        //   65: { value: 59.2 },
        //   80: { value: 66.1 },
        //   100: { value: 73.4 },
        // },
        onDeservedSalaryChange: function (v) {
          // console.log("onDeservedSalaryChanged from ncWidgetInit", v);
        },
      });
      widget.addEventListener("onDeservedSalaryChange", function (evt) {
        // console.log(evt.detail);
      });
    },
    redirect() {
      // this.$router.push(path);
      this.$router.go(-1);
    },
    onDOwnload() {
      this.ableToDownload = this.slideDocumentFile;
      console.log(this.ableToDownload);
      const link = document.createElement("a");
      link.href = this.ableToDownload;
      link.download = this.documentName;
      link.click();
      setTimeout(() => {
        this.ableToDownload = "";
        console.log(this.ableToDownload);
      }, 500);
    },
    onPrint() {
      document.getElementById("job_pricing_report_file").contentWindow.print();
    },
  },
};
</script>
<style lang="scss" scoped>
.main_section__content {
  margin-top: 50px;
}
.page_router {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .txt_router {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7f868e;
  }
  .text_redirect {
    color: #7f868e;
  }
  .text_redirect:hover {
    cursor: pointer;
    color: $blue;
    text-decoration-line: underline;
  }
  .img_arrow {
    background-image: url("~@/assets/img/arrow_right.svg");
    width: 5px;
    height: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    margin-right: 10px;
  }
}
iframe {
  display: block;
  width: 100%;
  padding: 15px;
}
.file_download_print {
  margin-top: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.print_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 17px;
  color: black;
  border-radius: 30px;
  width: 100px;
  height: 48px;
  overflow: hidden;
  white-space: nowrap;
}
.download_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 17px;
  border-radius: 30px;
  width: 149px;
  height: 48px;
  overflow: hidden;
  white-space: nowrap;
}
</style>
