const EditPastDate = (_date) => {
  let today = new Date();
  let thisTimeZone = today.getTimezoneOffset();

  let setdate = new Date(_date);
  let set_date = new Date(setdate - thisTimeZone * 60 * 1000);

  let countDate = Math.trunc((today - set_date) / (60000 * 60 * 24));
  if (countDate == 0) {
    return "Today";
  } else if (countDate == 1) {
    return "Yesterday";
  } else if (countDate > 1 && countDate < 7) {
    return countDate + " Days Ago";
  } else if (countDate >= 7 && countDate < 30) {
    if (Math.trunc(countDate / 7) == 1) {
      return "Last Week";
    } else {
      return Math.trunc(countDate / 7) + " Weeks Ago";
    }
  } else if (countDate >= 30 && countDate < 365) {
    if (Math.trunc(countDate / 30) == 1) {
      return "A Month Ago";
    } else {
      return Math.trunc(countDate / 30) + " Months Ago";
    }
  } else if (countDate >= 365) {
    if (Math.trunc(countDate / 365) == 1) {
      return "A Year Ago";
    } else {
      return Math.trunc(countDate / 365) + " Years Ago";
    }
  }
};
export default EditPastDate;
